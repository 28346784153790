import { FeatureList, SelectedParty, UserInfo } from '@Terra/shared/widgets/interface';
import { LOGIC_CONST, OMS_SUPPORTED_AFFILIATION_CODES, OMS_SUPPORTED_USER_ROLES } from './oms-option-enabler.helper.config';

function isFeatureFlagForOmsPresent(featureListResponse: FeatureList): boolean {
  return featureListResponse.features.some(feature => feature.name === LOGIC_CONST.OPERATOR);
}

function isReqAffliationCodeAndRolePresent(userInfo: UserInfo, selectedPartyResponse: SelectedParty, userRoleName: string): boolean {
  const partyName = selectedPartyResponse.partyName;
  return (
    checkValidConditionForCustomerUser(userInfo, selectedPartyResponse, userRoleName) ||
    checkValidConditionForCATUser(userInfo, partyName, userRoleName)
  );
}

function checkValidConditionForCustomerUser(userInfo: UserInfo, selectedPartyResponse: SelectedParty, userRoleName: string): boolean {
  return (
    OMS_SUPPORTED_AFFILIATION_CODES.CUSTOMER.includes(userInfo.catafltncode) &&
    OMS_SUPPORTED_USER_ROLES.CUSTOMER.includes(userRoleName) &&
    isAffiliatedAndSelectedUcidMatching(userInfo, selectedPartyResponse)
  );
}

function checkValidConditionForCATUser(userInfo: UserInfo, partyName: string, userRoleName: string): boolean {
  return (
    OMS_SUPPORTED_AFFILIATION_CODES.CAT.includes(userInfo.catafltncode) &&
    OMS_SUPPORTED_USER_ROLES.CAT.includes(userRoleName) &&
    partyName !== LOGIC_CONST.ROOT
  );
}

function isAffiliatedAndSelectedUcidMatching(userInfo: UserInfo, selectedPartyResponse: SelectedParty): boolean {
  return userInfo?.ucid && userInfo?.ucid === selectedPartyResponse?.ucid;
}

// Checks all conditions req to show OMS option in Application;
export function showOmsMenuOptionAndTab(paramsForOms: {
  featureListResponse: FeatureList;
  selectedPartyResponse: SelectedParty;
  userInfo: UserInfo;
  userRoleName: string;
}): boolean {
  return (
    isFeatureFlagForOmsPresent(paramsForOms.featureListResponse) &&
    isReqAffliationCodeAndRolePresent(paramsForOms.userInfo, paramsForOms.selectedPartyResponse, paramsForOms.userRoleName)
  );
}

export function setOmsMenuOptInSessionStorage(showOmsMenuOpt: boolean): void {
  window.sessionStorage.setItem(`${LOGIC_CONST.SHOW_OMS}`, `${showOmsMenuOpt}`);
}

export function getOmsMenuOptFromSessionStorage(): boolean {
  const showOMSopt = window.sessionStorage.getItem(`${LOGIC_CONST.SHOW_OMS}`);
  return showOMSopt === 'true';
}
