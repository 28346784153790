import { ApplyRoundOffVal, ConvertUnitOfMeasurement } from '@Terra/shared/widgets/interface';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { roundoffHelper } from '@Terra/shared/widgets/pipes';
import {
  APPLY_ROUND_OFF_CONVERSIONS,
  CONVERSION_COMON,
  CONVERSION_TYPE_VALUE,
  DEFAULT_ROUNDOFF_VAL,
  LOAD_CONVERSIONS,
  MT_TO_PREF,
  TABLE_FIELDS_ROUNDOFF,
  UNITS_OF_MEASUREMENT,
  UNITS_SYMBOL,
  UOM_CONVERSION_FUNC_TO_CALL,
  UOM_CONVERSION_VALUES_BASED_ON_UNITS
} from './uomConversionUtility.helper.config';

function checkForNaN(convertedVal: number): number {
  if (isNaN(convertedVal)) {
    throw Error();
  }
  return convertedVal;
}

// Checks if conversion needs roundoff and return the req values
export function isApplyRoundOff(conversionType: string): ApplyRoundOffVal {
  let applyRoundOffValue = { ...DEFAULT_ROUNDOFF_VAL };
  if (APPLY_ROUND_OFF_CONVERSIONS?.[conversionType]) {
    applyRoundOffValue = APPLY_ROUND_OFF_CONVERSIONS?.[conversionType];
  }
  return applyRoundOffValue;
}

function getRoundOffValue(value, property: string | number): number {
  return TABLE_FIELDS_ROUNDOFF.includes(property) && value !== '' && !Number.isInteger(value)
    ? roundoffHelper.roundOffTransform(Number(value), true)
    : value;
}

function commonValueToMetric(conversionObj: ConvertUnitOfMeasurement): number {
  if (conversionObj.valueToConvert && conversionObj.valueToConvert.toString().trim() !== '') {
    const data = MT_TO_PREF?.[conversionObj.valueType?.toLowerCase()];
    if (data) {
      const result = conversionObj.valueToConvert / data;
      return getRoundOffValue(result, conversionObj.valueType);
    }
  }
  return conversionObj.valueToConvert;
}

// Add U.S Customary unit conversions here.
const usCustomaryUOMConversion = {
  convertLiterToGallon: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.literToGallon);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertMeterToYard: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * LOAD_CONVERSIONS.CUBICMETERS_TO_CUBICYARDS);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertMeterToYardCost: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert / LOAD_CONVERSIONS.CUBICMETERS_TO_CUBICYARDS);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertMetreToFeet: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.feetToMetre);
    return returnRoundOffValue(conversionObj, convertedVal);
  },
  convertKilometerToMiles: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.KmToMiles);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertMeterPerSecToMilesPerHr: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.MeterPerSecToMilesPerHr);
    return returnRoundOffValue(conversionObj, convertedVal);
  },
  convertMeterSqToMilesSq: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.MetersSqToMiSq);
    return returnRoundOffValue(conversionObj, convertedVal);
  },
  convertCelsiustoFahrenheit: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * 1.8) + 32;
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertLiterToGallonCost: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert / MT_TO_PREF.literToGallon);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertTonnesPerHourToTonsPerHour: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.TonnesPerHourToTonsPerHour);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertTonnesPerLitreToTonsPerGal: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.TonnesPerLitreToTonsPerGal);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertKilogramsToPounds: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.kgToPound);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertSquareMetresToSqaureYards: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.squareMetreToSquareYard);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertCubicMetresToCubicYards: (conversionObj: ConvertUnitOfMeasurement): number | Error => {
    const convertedVal = Number(conversionObj.valueToConvert * MT_TO_PREF.cubicMetreToCubicYard);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertTonnesToTons: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert * MT_TO_PREF.tonnesToTons);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertTonnesToPound: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert * LOAD_CONVERSIONS.TONNES_TO_TONS * LOAD_CONVERSIONS.TONS_TO_POUND);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertTonnesToKG: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert * LOAD_CONVERSIONS.TONNES_TO_KG);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertKmToYards: (conversionObj: ConvertUnitOfMeasurement) => {
    const convertedValue = Number(conversionObj.valueToConvert * LOAD_CONVERSIONS.km_TO_YARDS);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertKmToFeet: (conversionObj: ConvertUnitOfMeasurement) => {
    const convertedValue = Number(conversionObj.valueToConvert * LOAD_CONVERSIONS.KM_TO_FEET);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertKmToMeter: (conversionObj: ConvertUnitOfMeasurement) => {
    const convertedValue = Number(conversionObj.valueToConvert * LOAD_CONVERSIONS.km_TO_METER);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertToNumber: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertTonsToTonnesWeightInUS: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert / MT_TO_PREF.tonnesToTons);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertMeterPerLitreToFeetPerGal: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert * MT_TO_PREF.metrePerLitreToFeetPerGallon);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertValueToEnglish: (conversionObj: ConvertUnitOfMeasurement): number => {
    if (conversionObj.valueToConvert && conversionObj.valueToConvert.toString().trim() !== '') {
      const data = MT_TO_PREF?.[conversionObj.valueType?.toLowerCase()];
      if (data) {
        const result = conversionObj.valueToConvert * data;
        return getRoundOffValue(result, conversionObj.valueType);
      }
    }
    return conversionObj.valueToConvert;
  },

  convertValueToMetric: (conversionObj: ConvertUnitOfMeasurement): number => commonValueToMetric(conversionObj)
};

const siUnitsUomConversion = {
  convertFeetToMetre: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert / MT_TO_PREF.feetToMetre);
    return conversionObj.applyRoundOff ? Number(convertedVal.toFixed(conversionObj.decimalLength)) : convertedVal;
  },

  convertMeterPerSecToKilometerPerHr: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert * MT_TO_PREF.MeterPerSecToKilometerPerHr);
    return returnRoundOffValue(conversionObj, convertedValue);
  },
  convertMeterSqToKmSq: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = Number(conversionObj.valueToConvert / MT_TO_PREF.MetersSqToKmSq);
    return returnRoundOffValue(conversionObj, convertedVal);
  },

  convertGallonToLiter: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert / MT_TO_PREF.literToGallon);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertToNumber: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertTonsToTonnesWeight: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert / MT_TO_PREF.tonnesToTons);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertTonnesToKG: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedValue = Number(conversionObj.valueToConvert * LOAD_CONVERSIONS.TONNES_TO_KG);
    return returnRoundOffValue(conversionObj, convertedValue);
  },

  convertRoundOffValue: (conversionObj: ConvertUnitOfMeasurement): number =>
    getRoundOffValue(conversionObj.valueToConvert, conversionObj.valueType),

  convertValueToMetric: (conversionObj: ConvertUnitOfMeasurement) => commonValueToMetric(conversionObj)
};

// Add Comon conversion here;
const commonUomConversions = {
  getValueOnPreferenceType: (conversionObj: ConvertUnitOfMeasurement): number => {
    const refData = CONVERSION_TYPE_VALUE[conversionObj.conversionType];
    const convertedVal = conversionObj.applyRoundOff
      ? Math.round(conversionObj.valueToConvert * refData[conversionObj.preferenceType])
      : Number((conversionObj.valueToConvert * refData[conversionObj.preferenceType]).toFixed(conversionObj.decimalLength));
    return checkForNaN(convertedVal);
  },

  getCostConversionOnPrefType: (conversionObj: ConvertUnitOfMeasurement): number => {
    const convertedVal = conversionObj.applyRoundOff
      ? Math.round(conversionObj.valueToConvert / MT_TO_PREF[conversionObj.preferenceType])
      : Number((conversionObj.valueToConvert / MT_TO_PREF[conversionObj.preferenceType]).toFixed(conversionObj.decimalLength));
    return checkForNaN(convertedVal);
  }
};

// This will be the function called in different modules, will convert the unit to passed UOM;
export const convertUnitOfMeasurement = (conversionObj: ConvertUnitOfMeasurement): number => {
  try {
    if (CONVERSION_COMON.includes(conversionObj.conversionType)) {
      return commonUomConversions[UOM_CONVERSION_FUNC_TO_CALL[conversionObj.conversionType]](conversionObj);
    }
    return conversionObj.preferenceType === UNITS_OF_MEASUREMENT.usCustomary
      ? usCustomaryUOMConversion[UOM_CONVERSION_FUNC_TO_CALL[conversionObj.conversionType]](conversionObj)
      : siUnitsUomConversion[UOM_CONVERSION_FUNC_TO_CALL[conversionObj.conversionType]](conversionObj);
  } catch (err) {
    return conversionObj.valueToConvert;
  }
};

// This will return metric symbol based on UOM;
export const getUnitSymbolForUnitOfMeasurement = (uom: string, metricType: string): string =>
  UNITS_SYMBOL?.[uom]?.[metricType] ? UNITS_SYMBOL[uom][metricType] : '';

export const convertValueBasedOnUOM = (value: number, uom: string, preferredUom: string): number => {
  const unit = uom ? UOM_CONVERSION_VALUES_BASED_ON_UNITS[preferredUom][uom] : 1;
  return value ? value * unit : null;
};

export function returnRoundOffValue(conversionObj: ConvertUnitOfMeasurement, convertedVal: number): number {
  return conversionObj?.applyRoundOff ? Number(convertedVal.toFixed(conversionObj?.decimalLength)) : convertedVal;
}
