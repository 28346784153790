export const HOSTNAME_TO_ENV_CONFIG = {
  local: 'local',
  'dev-beta': 'dev',
  dev: 'dev',
  int: 'int',
  'qa-beta': 'qa',
  qa: 'qa',
  stg: 'stg',
  stage: 'stg',
  perf: 'perf',
  'beta-catprod': 'prod',
  prod: 'prod'
};

export const env =
  Object.keys(HOSTNAME_TO_ENV_CONFIG).find((host: string) => new URL(window.location.href).host.indexOf(host) !== -1) ||
  HOSTNAME_TO_ENV_CONFIG.prod;

export const APP_ENV = HOSTNAME_TO_ENV_CONFIG[env];

const common_links = {
  API_CAT: 'https://api.cat.com',
  CAT_INT: 'https://api-int.cat.com',
  CAT_DEV: 'https://api-dev.cat.com',
  CAT_STAGE: 'https://api-stage.cat.com'
};

export const HELIOS_BASE_URL = {
  local: common_links.API_CAT,
  devint: common_links.API_CAT,
  dev: common_links.API_CAT,
  int: common_links.API_CAT,
  qa: common_links.API_CAT,
  stg: common_links.API_CAT,
  perf: common_links.API_CAT,
  prod: common_links.API_CAT
};

export const HELIOS_CCDS_CATOS_BASE_URL = {
  local: common_links.CAT_INT,
  devint: common_links.CAT_DEV,
  dev: common_links.CAT_DEV,
  int: common_links.CAT_INT,
  qa: 'https://api-qa.cat.com',
  stg: 'https://api-stage.cat.com',
  perf: 'https://api-performance.cat.com',
  prod: common_links.API_CAT
};

const HELIOS_CCDS_BASE_URL = {
  local: common_links.CAT_STAGE,
  devint: common_links.CAT_STAGE,
  dev: common_links.CAT_STAGE,
  int: common_links.CAT_STAGE,
  qa: common_links.CAT_STAGE,
  stg: common_links.API_CAT,
  perf: common_links.CAT_STAGE,
  prod: common_links.API_CAT
};

export const BASE_URL = {
  HELIOS_BASE_URL: HELIOS_BASE_URL[env],
  HELIOS_CCDS_CATOS_BASE_URL: HELIOS_CCDS_CATOS_BASE_URL[env],
  HELIOS_CCDS_BASE_URL: HELIOS_CCDS_BASE_URL[env]
};

export const UI_API_CALL = '&uiCall=true';
