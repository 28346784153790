import { CP_COOKIE, SSO_COOKIE } from '@Terra/shared/widgets/config';
import { AssetTag, ConvertUnitOfMeasurement, IndustryTypeData, TreeNode } from '@Terra/shared/widgets/interface';
import { localStorageHelper } from '@Terra/shared/widgets/utils';
import { FormControl } from '@angular/forms';
import { DeviceInfo } from 'ngx-device-detector';
import { findOneIana, findWindows } from 'windows-iana';
import { uomHelperConfig } from '..';
import { GroupInfo } from './models/group.model';
import { UtilTreeNode } from './models/treeNode.model';
import * as utilsModel from './models/util.model';
import { convertUnitOfMeasurement, isApplyRoundOff } from './uomConversionUtility.helper';
import { CONVERSION_TYPE_VALUE, HELPER_LOGIC_CONSTANTS } from './uomConversionUtility.helper.config';
import * as utilsConfig from './utils.config';
export const prepareTree = (itemList: GroupInfo[]): TreeNode[] => {
  const treeTableData = [];
  if (itemList !== undefined) {
    for (const item of itemList) {
      treeTableData.push(generateNode(item));
    }
  }
  return treeTableData;
};

export const generateNode = (item: GroupInfo): TreeNode => {
  const nodeList: TreeNode[] = [];
  if (item.groups !== null && item.groups !== undefined) {
    for (const subItem of item.groups) {
      nodeList.push(generateNode(subItem));
    }
  }
  return new UtilTreeNode(item.name, item, nodeList);
};

export const userPreferencesUnits =
  (
    { preferenceType, conversionType, applyRoundOff = true, decimalLength = 2 }: Partial<ConvertUnitOfMeasurement>,
    isCostConversion: boolean = false
  ): Function =>
  (value: number) => {
    if (isNaN(value)) {
      return { value, unitText: utilsConfig.UNITTEXT[preferenceType], unitTranslation: utilsConfig.UNIT_TRANSLATION[preferenceType] };
    }

    const getValueBasedOnPreference = (): number => {
      const params: ConvertUnitOfMeasurement = {
        valueToConvert: value,
        conversionType,
        preferenceType,
        applyRoundOff,
        decimalLength
      };
      if (CONVERSION_TYPE_VALUE?.[conversionType]) {
        return convertUnitOfMeasurement(params);
      } else if (isCostConversion) {
        params.conversionType = HELPER_LOGIC_CONSTANTS.COST_CONVERSION;
        return convertUnitOfMeasurement(params);
      }
      params.conversionType = HELPER_LOGIC_CONSTANTS.DEFAULT;
      return convertUnitOfMeasurement(params);
    };

    const getUnits = (preferenceTypeData: string): utilsModel.UnitData => ({
      value: getValueBasedOnPreference(),
      unitText: utilsConfig.UNITTEXT[preferenceTypeData],
      unitTranslation: utilsConfig.UNIT_TRANSLATION[preferenceTypeData]
    });
    return getUnits(preferenceType) || getUnits('default');
  };

export const convertToPreferredUnit = (toBeConvertedObj: utilsModel.ConvertToPrefUnit): number => {
  if (toBeConvertedObj.value) {
    const convertedObj = userPreferencesUnits({
      preferenceType: toBeConvertedObj.preferenceUnit,
      conversionType: toBeConvertedObj.conversionType,
      applyRoundOff: toBeConvertedObj.applyRoundOff,
      decimalLength: toBeConvertedObj.decimalLength
    });
    return convertedObj(toBeConvertedObj.value).value;
  } else {
    return toBeConvertedObj.value;
  }
};

export const convertObjtoQueryParamStr = (obj): string => {
  let result = '';
  Object.keys(obj).forEach(function (key: string) {
    result += `${key}=${obj[key]}&`;
  });
  return result.slice(0, -1);
};

export const trimValidator = (control: FormControl): utilsModel.TrimValidation => {
  if (control.value) {
    if (control.value.startsWith(' ')) {
      return {
        trimError: { hasWhiteSpace: 'control has leading whitespace' }
      };
    }
    if (control.value.endsWith(' ')) {
      return {
        trimError: { hasWhiteSpace: 'control has trailing whitespace' }
      };
    }
    return null;
  }
  return null;
};

export const getArrayWithFilledNumbers = (arrLength: number, padStartIndex: number, padStartValue: string): string[] =>
  Array(arrLength)
    .fill(1)
    .map((_x: number, i: number) => `${i}`.padStart(padStartIndex, padStartValue));

/**
 * Converts the given distance to the given DistanceUnit
 * @param speedValue
 * @param targetUnit Unit of distance to convert to
 * @returns Given round off converted distance in the given unit
 */
export const getRoundedOffSpeedValueByUnit = (speedValue: number, targetUnit: string): number => {
  if (targetUnit === utilsConfig.SPEED_UNIT.MilesPerHour) {
    return Math.round(Number(speedValue * utilsConfig.MT_TO_PREF.KmToMiles));
  }
  if (targetUnit === utilsConfig.SPEED_UNIT.KilometerPerHour) {
    return Math.round(Number(speedValue * utilsConfig.MT_TO_PREF.MilesToKm));
  }
  return null;
};

// get the iana name based on standard name from package;
export const findOneIanaFromWindowsIana = (standardName: string): string => {
  const result = findOneIana(standardName);
  return result || setIanaFromTheConfig(standardName);
};

// Execution: If standard name is undefined from windows Iana package, getting it from the config for four names;
export const setIanaFromTheConfig = (standardName: string): string => utilsConfig.GetIanaFromConfig[standardName];

// Get the selected IANA;
// list -should not be empty, need to be check from the subscription end;
// ianaName - will return the string value using moment.guess(true);
export const getSelectedTimeZoneWithIana = (list: utilsConfig.TimeZoneList[], ianaName: string): utilsConfig.TimeZoneList => {
  const ianaNameFormat = getIanaFormat(ianaName);
  //Most of the cases, the Iana will Match with the list since moment.guess will give the same Iana name which WindowsIanan package has provided;
  // if given list timezone is matches with moment guess name return the selected object else process next;
  const findIanaIsAlreadyMatched = list.find((item: utilsConfig.TimeZoneList) => item.timezone === ianaNameFormat);
  if (findIanaIsAlreadyMatched) {
    return findIanaIsAlreadyMatched;
  }
  // if the list does not match with the IANA name execute this, this will be like retry;
  const getStandardNameFromWindowsIana = findstandardNameFromWindowsIanaWithTimeZoneProps(ianaNameFormat); // Get the windows standard name
  const getSelectedTimeZone = findSelectedTimeZoneWithIana(list, getStandardNameFromWindowsIana); //Find the Timezone Object
  if (!getSelectedTimeZone.standardName) {
    return list[0];
  }
  return getSelectedTimeZone; // if the standard name is availble in the list then returns;
};

// Execution: if Moment guess return 'Africa/Abidjan' need to be replace by windowsIana 'Etc/GMT'
export const getIanaFormat = (ianaName: string): string => {
  if (ianaName === utilsConfig.DeviationInGuessAndWindowsIana.MomentGuess) {
    return utilsConfig.DeviationInGuessAndWindowsIana.WindowsIana;
  }
  return ianaName;
};

// get the standard name based on iana name from package with timezone;
export const findstandardNameFromWindowsIanaWithTimeZoneProps = (ianaName: string): utilsConfig.StandardIanaNames => {
  const result = findWindows(ianaName);
  return { standardName: result, timezone: ianaName };
};

// Find the Zone name from timezoneList passing as a argument of standardName with Iana name
export const findSelectedTimeZoneWithIana = (
  list: utilsConfig.TimeZoneList[],
  timezoneNames: utilsConfig.StandardIanaNames
): utilsConfig.TimeZoneList => {
  const findNames = list.find((item: utilsConfig.TimeZoneList) => item.standardName === timezoneNames.standardName);
  return { ...findNames, timezone: timezoneNames.timezone };
};

export const getAssetTagBasedOnHistory = (
  { serialNumber, make, assetTags, assetsTagHistory }: utilsModel.AssetTagsHistory,
  isAssetPage: boolean = false
): AssetTag => {
  const assetTagValue = assetsTagHistory?.find(
    (tag: utilsConfig.ConfigRecommendation) => tag.assetTag && tag.serialNumber === serialNumber && tag.make === make
  );
  return assetTagValue
    ? getAssetTagName(assetTagValue.assetTag, assetTags, isAssetPage)
    : {
        id: utilsConfig.ASSET_TAGS.Unidentified,
        name: isAssetPage ? utilsConfig.DEFAULT_ASSET_TAG_INFO.TAGS_YET_TO_ASSIGNED : utilsConfig.DEFAULT_ASSET_TAG_INFO.UNIDENTIFIED
      };
};

export const getAssetTagName = (tagId: string, assetTags: IndustryTypeData[], isAssetPage: boolean = false): AssetTag => {
  const { id, name } = findTagIdDefined(assetTags, tagId)
    ? assetTags.find((item: IndustryTypeData) => item.id.toLowerCase() === tagId.toLowerCase()) ??
      assetTags.find((item: IndustryTypeData) => item.id.toLowerCase() === utilsConfig.ASSET_TAGS.Support.toLowerCase())
    : {
        id: utilsConfig.ASSET_TAGS.Unidentified,
        name: isAssetPage ? utilsConfig.DEFAULT_ASSET_TAG_INFO.TAGS_YET_TO_ASSIGNED : utilsConfig.DEFAULT_ASSET_TAG_INFO.UNIDENTIFIED
      };
  return { id, name } as AssetTag;
};

export const findTagIdDefined = (assetTags: IndustryTypeData[], tagId: string): boolean =>
  assetTags?.length && tagId !== utilsConfig.ASSET_TAGS.Unidentified;

export const getActivePage = (startIndex: number, itemsPerPage: number): number => Math.floor(startIndex / itemsPerPage) + 1;

export const siteStatusCheck = (
  statusHistory: utilsConfig.SiteStatusHistory[],
  dateRange: utilsConfig.DateRange
): utilsConfig.SiteStatusHistory => {
  const endDate = new Date(dateRange.endDate);
  const index = siteStatusCheckHelper(statusHistory, endDate);
  const _index = getIndex(index);
  if (_index > -1 && dateRange.startDate && siteStatusExistsCheck(statusHistory, dateRange, _index)) {
    return statusHistory[_index - 1];
  }
  return statusHistory[_index];
};

export const getIndex = (index: number): number => (index > 0 ? index - 1 : index);

export const siteArchiveStatusCheck = (
  statusHistory: utilsConfig.SiteStatusHistory[],
  dateRange: utilsConfig.DateRange
): utilsConfig.SiteStatusHistory => {
  const endDate = new Date(dateRange.endDate);
  const index = siteStatusCheckHelper(statusHistory, endDate);
  if (index > -1 && dateRange.startDate && siteStatusExistsCheck(statusHistory, dateRange, getIndex(index))) {
    return statusHistory[index - 1];
  }
  return statusHistory[index];
};

export const siteStatusCheckHelper = (statusHistory: utilsConfig.SiteStatusHistory[], endDate: Date): number => {
  if (statusHistory.length > 0 && endDate > new Date(statusHistory[statusHistory.length - 1].dateTime)) {
    return statusHistory.length;
  }
  return statusHistory.findIndex((item: utilsConfig.SiteStatusHistory) => endDate < new Date(item.dateTime));
};

export const siteStatusExistsCheck = (
  statusHistory: utilsConfig.SiteStatusHistory[],
  dateRange: utilsConfig.DateRange,
  _index: number
): boolean => statusHistory[_index].status === 'Archive' && new Date(dateRange.startDate) < new Date(statusHistory[_index].dateTime);

export const deleteAllCookies = (whitelistedCookies?: string[]): void => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    if (deleteCookiesCheck(name, whitelistedCookies)) {
      document.cookie = `${name}=;expires=${new Date(0).toUTCString()}`;
    }
  }
};

export const deleteCookiesCheck = (name: string, whitelistedCookies?: string[]): boolean =>
  !whitelistedCookies || (whitelistedCookies?.length && !whitelistedCookies.some((item: string) => item === name.trim()));

export const validateAssetType = ({ item, asset }: utilsModel.AssetType, response: any, assetType: string): boolean =>
  asset.type === assetType && Object.prototype.hasOwnProperty.call(response, item);

export const getAssetConfig = ({ item, asset }: utilsModel.AssetType, response: any, fileName: string): utilsModel.AssetConfig => {
  const icon = { url: `../shared-playback/images/${fileName}.svg`, anchor: [20, 36], labelOrigin: [20, 50] };
  const label = { text: asset.assetId, color: '#26292e', fontSize: '14px' };
  const paverPosition = response[item];
  const [position] = Object.keys(response[item]);
  const centerCoOrdinate = {
    lat: response[item][position]['latitude'],
    lng: response[item][position]['longitude']
  };

  return { icon, label, paverPosition, centerCoOrdinate };
};

export const getShapeConfig = (
  { radius, strokeColor, fillOpacity }: utilsModel.ShapeDetails,
  strokeWeight: boolean = false
): utilsModel.ShapeConfig => {
  const shapeConfig = {
    shape: 'circle',
    config: {
      radius,
      strokeColor,
      fillColor: strokeColor,
      fillOpacity
    }
  };

  if (strokeWeight) {
    shapeConfig.config['strokeWeight'] = 3;
  }
  return shapeConfig;
};

export const handleValueAndConversion = (
  value: number,
  conversionType: string,
  preferenceType: string = uomHelperConfig.UNITS_OF_MEASUREMENT.usCustomary
): number => {
  const applyRoundOffVal = isApplyRoundOff(conversionType);
  return convertUnitOfMeasurement({
    valueToConvert: value,
    conversionType,
    preferenceType,
    applyRoundOff: applyRoundOffVal.applyRoundOff,
    decimalLength: applyRoundOffVal.decimalLength
  });
};

export const getRoutePathFromURL = (selectedPathUrl: string): string =>
  utilsConfig.BASEURL_SPLIT.find((splitUrl: utilsModel.BaseUrl) => selectedPathUrl.includes(splitUrl.path))?.value;

export const formBrowserDetailsObject = (browserDetails: DeviceInfo): utilsConfig.BrowserInfo => ({
  browserName: browserDetails.browser,
  browserVersion: browserDetails.browser_version,
  browserPlatform: browserDetails.deviceType,
  browserOS: browserDetails.os
});

export const setCookie = (cname: string, exdays: number): void => {
  //setting the cookie expiry time after 24 hours from current date and time
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  localStorageHelper.setItem(cname, d.toUTCString());
};

export const getCurrentDateAndTime = (): Date => {
  const currentDateAndTime = new Date();
  currentDateAndTime.setTime(currentDateAndTime.getTime() + 0);
  return currentDateAndTime;
};

//true if current date and time is past or equals the cookie date and time in local storage
//false if current date and time is less than the cookie date and time in local storage
export const isCookieExpired = (existingCookieDateAndTime: string): boolean =>
  new Date(existingCookieDateAndTime) <= new Date(getCurrentDateAndTime());

export const updateCPCookie = (): void => {
  const getCPCookie = localStorageHelper.getItem(CP_COOKIE);
  if (getCPCookie) {
    //if cookie is available and is expired, will remove the CPCookie variable from local storage on page refresh
    if (isCookieExpired(getCPCookie)) {
      localStorageHelper.removeItem(CP_COOKIE);
    } else {
      //if cookie is not expired, will update the cookie's date and time in local storage on page refresh
      setCookie(CP_COOKIE, 1);
    }
  }
};

export const getCPCookie = (): boolean => !!localStorageHelper.getItem(CP_COOKIE);
export const getSSOAvailable = (): boolean => document.cookie.indexOf(SSO_COOKIE) !== -1;
export const removeCpCookie = (): void => localStorageHelper.removeItem(CP_COOKIE);

export const getLatestCPCookie = (): boolean => {
  updateCPCookie();
  return getCPCookie();
};

export const getQueryParamURLString = <T>(queryParams: T): string =>
  Object.entries(queryParams)
    .filter(([, value]: [string, string]) => !!value)
    .map(([key, value]: [string, string]) => `${key}=${value}`)
    .join('&');
