import { DateTimeRange, Preference } from '@Terra/shared/widgets/interface';
import moment from 'moment-timezone';
import { BetweenDateTimeRange, DateConvert, DateTimeFormatTz, DurationData, TimeData, TimeFormatRoundOff } from './models/util.model';
import { getDiffInDays } from './moment-date-formatter-helper';
import { checkDateValueToSplit, convertDateOrTimeFormat, getDateTimeByTZ } from './moment-helper';
import { getDefaultTimeZoneOffset, getTimeWithPeriod } from './moment-time-formatter-helper';
import * as timeZoneConfig from './timezone.config';
import { DATE_INTERVAL_CONFIG, DEFAULT_DATE_FORMAT, DateInterval, INTERVAL, TimeZoneFromCollection, TimeZoneList } from './utils.config';
import { findOneIanaFromWindowsIana } from './utils.helper';

// Momentjs sometimes behaving different when DD/MM/YYYY is used
export const exceptionalDateFormatValidation = (dateValue: moment.MomentInput | string, format: string): moment.MomentInput => {
  const dateValueData = dateValue as string;
  if (checkDateValueToSplit(format, dateValue) && checkFormatToSplit(format)) {
    return dateValueData?.split('/')?.reverse().join('-') || dateValue;
  }
  return dateValue;
};

export const checkFormatToSplit = (format: string): boolean =>
  format &&
  (format === timeZoneConfig.EXCEPTIONAL_DATE_VALIDATION.dateFormat ||
    format === timeZoneConfig.EXCEPTIONAL_DATE_VALIDATION.chatDateFormat);

export const getInterval = (noOfDays: number): string =>
  DATE_INTERVAL_CONFIG.find((range: DateInterval) => noOfDays >= range.minDate && noOfDays <= range.maxDate)?.value;

export const getSubtractPeriod = (diffTime: number): string => {
  const defaultDuration = timeZoneConfig.INITIALIZE_DURATION;
  const differentDuration = timeZoneConfig.DIFFERENT_DURATION;
  Object.keys(differentDuration).forEach((key: string) => {
    defaultDuration[key] = Math.floor(diffTime / differentDuration[key]);
    diffTime -= defaultDuration[key] * differentDuration[key];
  });
  return getDuration(defaultDuration);
};

const getDuration = (defaultDuration: DurationData): string => {
  const branch = (condition: boolean, ifTrue: string, ifFalse: string): string => (condition ? ifTrue : ifFalse);
  const word = branch(
    defaultDuration.y !== 0,
    `${defaultDuration.y}y`,
    branch(
      defaultDuration.mo !== 0,
      `${defaultDuration.mo}mo`,
      branch(
        defaultDuration.w !== 0,
        `${defaultDuration.w}w`,
        branch(
          defaultDuration.d !== 0,
          `${defaultDuration.d}d`,
          branch(
            defaultDuration.h !== 0,
            `${defaultDuration.h}h`,
            branch(defaultDuration.m !== 0, `${defaultDuration.m}m`, `${defaultDuration.s}s`)
          )
        )
      )
    )
  );
  return word;
};

export const getMode = (selectedDate: DateTimeRange, isDrilldownDisabled: boolean = false): string => {
  const startDate = getTimeWithPeriod(true, 'day', selectedDate.fromDateTime);
  const endDate = getTimeWithPeriod(true, 'day', selectedDate.toDateTime);
  const noOfDays = getDiffInDays(startDate, endDate, DEFAULT_DATE_FORMAT);
  const interval = getInterval(noOfDays) ?? INTERVAL.MONTHLY;
  //If the selected max date range is greater than 270 days, setting interval default value as monthly
  return interval === INTERVAL.HOURLY && isDrilldownDisabled ? INTERVAL.DAILY : interval;
};

//converts given date time from one format to another
export const convertDateTimeFormat = (dateTime: moment.MomentInput | string, currentFormat: string, convertTo: string): string =>
  convertDateOrTimeFormat({ dateTime, currentFormat, convertTo }, true);

export const checkForNullOrUndefined = (value: moment.MomentInput | string): boolean =>
  value === undefined || value === '' || value === null;

//converts date time from one format to another with time zone
export const convertDateTimeFormatByTZ = (
  { dateTime, currentFormat, convertTo }: DateTimeFormatTz,
  timeZoneOffset: string | number = getDefaultTimeZoneOffset(),
  isUTC: boolean = false
): string => {
  let dateTimeData = dateTime as string;
  if (checkForNullOrUndefined(dateTime)) {
    return '';
  }
  dateTimeData = isUTC && dateTimeData.indexOf('z') === -1 ? `${dateTimeData}z` : dateTimeData;
  return getDateTimeByTZ(dateTimeData, currentFormat).utcOffset(timeZoneOffset).format(convertTo);
};

//convert utc date to the given time zone date time
export const convertDateTimewithDST = ({ dateString, currentFormat, localeName, convertTo }: DateConvert): string =>
  getDateTimeByTZ(dateString, currentFormat).tz(localeName).format(convertTo);

export const getFormattedTime = ({ seconds, roundOff = false, length = 2, padWith = '0' }: TimeFormatRoundOff): string => {
  let hour = 0,
    minute = 0;
  seconds = Math.round(seconds); //rounding off seconds
  minute = Math.floor(seconds / 60); //converting secs to mins
  seconds = Math.round(((seconds % 60) * 100) / 100); //remaining seconds after taking hours
  hour = Math.floor(minute / 60); //converting mins to hrs
  minute = minute % 60; //remanining mins after taking hours
  let timeData: TimeData = { hour, minute, seconds };
  if (roundOff) {
    timeData = roundOffTime({ hour, minute, seconds });
  }
  return getFormattedString(timeData, padWith, length);
};

export const getFormattedString = (timeData: TimeData, padWith: string, length: number): string =>
  `${timeData.hour.toString().padStart(length, padWith) || '00'}:${timeData.minute.toString().padStart(length, padWith) || '00'}:${
    timeData.seconds.toString().padStart(length, padWith) || '00'
  }`;

export const roundOffTime = ({ hour, minute, seconds }: TimeData): TimeData => {
  let timeData: TimeData = { hour, minute, seconds };
  if (hour > 0 && seconds >= 30) {
    //Only if hour is present, we round off seconds >=30
    timeData = roundOffSeconds({ hour, minute, seconds });
  }
  if (seconds === 60) {
    ++timeData.minute;
    timeData.seconds = 0;
  }
  return timeData;
};

export const roundOffSeconds = ({ hour, minute, seconds }: TimeData): TimeData => {
  if (minute === 59) {
    ++hour;
    minute = 0;
  } else {
    ++minute;
  }
  seconds = 0;
  return { hour, minute, seconds };
};

export const getHyphenatedDateFormat = (dateTime: string): string => {
  const ddMmYyyy = dateTime.split(' ')[0].split('/');
  return `${ddMmYyyy[2]}-${ddMmYyyy[0]}-${ddMmYyyy[1]}`;
};

/**
 *
 * @param dateTime
 * @param fromDateTime
 * @param toDateTime
 * @param period
 * @returns boolean value
 */
export const isBetweenDateTime = ({ dateTime, fromDateTime, toDateTime, period }: BetweenDateTimeRange): boolean =>
  dateTime.isBetween(fromDateTime, toDateTime, period, '[]');

export const getDateTimeInStringFormat = (preferenceDateTimeFormat: string | Preference, removeSpace?: boolean): string => {
  if (typeof preferenceDateTimeFormat === 'object') {
    const preferredDateFormat = timeZoneConfig.DATE_TIME_FORMATS[preferenceDateTimeFormat?.dateFormat];
    const nonPreciousFormat = removeSpace ? timeZoneConfig.NON_PRECISION_FORMAT_WITHOUT_SPACE : timeZoneConfig.NON_PRECISION_FORMAT;
    const nonPreciousFormatWithSeconds = removeSpace
      ? timeZoneConfig.PRECISION_FORMAT_WITH_SECONDS_WITHOUT_SPACE
      : timeZoneConfig.PRECISION_FORMAT_WITH_SECONDS;
    const preferredTimeFormat = preferenceDateTimeFormat?.ignoreSeconds
      ? nonPreciousFormat[preferenceDateTimeFormat?.timeFormat]
      : nonPreciousFormatWithSeconds[preferenceDateTimeFormat?.timeFormat];

    return `${preferredDateFormat}; ${preferredTimeFormat}`;
  }
  return preferenceDateTimeFormat;
};

export const getDateTimeFormat = (preference: Preference): string => {
  let dateFormat = preference?.dateFormat;
  const timeFormat = preference?.timeFormat.split(' ');
  timeFormat[0] = preference?.timeFormat.split(' ')[0].replace('SS', 'ss').replace('MM', 'mm');
  if (!timeFormat[1].match('24')) {
    timeFormat[0] = timeFormat[0].replace('HH', 'hh');
    timeFormat[0] = `${timeFormat[0]} a`;
  }
  dateFormat = dateFormat.replace('DD', 'dd').replace('YY', 'yy');
  dateFormat = `${dateFormat} ${timeFormat[0]}`;
  return dateFormat;
};

// Set the Timezone list from the store with the Iana timezone names - Initial
export const setTimeZoneList = (timezonelist: TimeZoneFromCollection[]): TimeZoneList[] => {
  if (!timezonelist.length) {
    return [];
  }
  return timezonelist.reduce(processListToAppendTimezone.bind(this), []);
};

// Process the list with the required Parameters and append the Iana name form windows iana-package
export const processListToAppendTimezone = (list: Array<TimeZoneList>, timezoneItem: TimeZoneList): TimeZoneList[] => {
  const getIanaNameBasedOnStandardName = findOneIanaFromWindowsIana(timezoneItem.standardName);
  if (getIanaNameBasedOnStandardName) {
    list.push({
      ...timezoneItem,
      timezone: getIanaNameBasedOnStandardName
    });
  }
  return list;
};

// Return Inittime for timePicker
export const getInitTimeTimePicker = (time: string): Partial<TimeData> => {
  let timeGap = 0;
  if (time !== null && time !== undefined && time.length) {
    timeGap = getInitTimePickerHelperPm(time, timeGap);
    return {
      hour: Number(time.split(':')[0]) + timeGap,
      minute: Number(time.split(':')[1].split(' ')[0])
    };
  }
  return null;
};

export const getInitTimePickerHelperPm = (time: string, timeGap: number): number => {
  if (time.split(':')[1].split(' ')[1] === 'PM' && Number(time.split(':')[0]) !== 12) {
    timeGap = 12;
  } else {
    timeGap = getInitTimePickerHelperAm(time, timeGap);
  }
  return timeGap;
};

export const getInitTimePickerHelperAm = (time: string, timeGap: number): number => {
  if (time.split(':')[1].split(' ')[1] === 'AM' && Number(time.split(':')[0]) === 12) {
    timeGap = -12;
  }
  return timeGap;
};
