export const OMS_SUPPORTED_USER_ROLES = {
  CUSTOMER: ['Admin', 'Standard'],
  CAT: ['Super Admin']
};
export const OMS_SUPPORTED_AFFILIATION_CODES = {
  CUSTOMER: ['003', '020'],
  CAT: ['001', '014']
};

export const LOGIC_CONST = {
  ROOT: 'Root',
  OPERATOR: 'Operator',
  SHOW_OMS: 'showOMSopt'
};
