import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalSeparator',
  standalone: true
})
export class DecimalSeparator implements PipeTransform {
  transform(kpiValue, decimalSeparator: string): any {
    if (Array.isArray(kpiValue)) {
      // To handle Array of KPI values
      return kpiValue.map(element => {
        let { value } = element;
        value = value && decimalSeparator === 'Comma' ? value.toString().replace('.', ',') : value;
        return { ...element, value };
      });
    }
    // To handle single value
    return kpiValue && decimalSeparator === 'Comma' ? kpiValue.toString().replaceAll('.', ',') : kpiValue;
  }
}
