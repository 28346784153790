import { ColorCodeMapping } from '@Terra/shared/widgets/interface';

const CYCLES_COLOR_MAPPING = {
  '0': {
    description: 'Load and Carry',
    color: '#6852BE',
    localization: 'cycles.loadAndCarry'
  },
  '1': {
    description: 'Slot Dozing',
    color: '#7E20B8',
    localization: 'cycles.slotDozing'
  },
  '2': {
    description: 'Loading',
    color: '#A129EB',
    localization: 'cycles.loading'
  },
  '3': {
    description: 'Pile Cleanup',
    color: '#FF8946',
    localization: 'cycles.pileCleanup'
  },
  '4': {
    description: 'Other',
    color: '#CE5309',
    localization: 'cycles.other'
  },
  '5': {
    description: 'Roading',
    color: '#FFA230',
    localization: 'cycles.roading'
  },
  '6': {
    description: 'Grading',
    color: '#006618',
    localization: 'cycles.grading'
  },
  '7': {
    description: 'Trenching',
    color: '#EF6552',
    localization: 'cycles.trenching'
  },
  '8': {
    description: 'Waiting',
    color: '#B18D00',
    localization: 'cycles.waiting'
  },
  '9': {
    description: 'Cleanup',
    color: '#AD7229',
    localization: 'cycles.cleanup'
  },
  '10': {
    description: 'Load Haul Dump',
    color: '#B257EB',
    localization: 'cycles.loadHaulDump'
  },
  '11': {
    description: 'Delayed Engine Shutdown',
    color: '#FB5A00',
    localization: 'cycles.delayedEngineShutdown'
  },
  '12': {
    description: 'Key On - Engine Off',
    color: '#565656',
    localization: 'cycles.keyOnEngineOff'
  },
  '13': {
    description: 'Working',
    color: '#1C441D',
    localization: 'cycles.working'
  },
  '14': {
    description: 'Ripping',
    color: '#0F992F',
    localization: 'cycles.ripping'
  },
  '15': {
    description: 'Snow Removal',
    color: '#06F',
    localization: 'cycles.snowRemoval'
  },
  '16': {
    description: 'Propelling',
    color: '#EB4A34',
    localization: 'cycles.propelling'
  },
  '17': {
    description: 'Bank Preparation',
    color: '#811F18',
    localization: 'cycles.bankPreparation'
  },
  '18': {
    description: 'Drilling',
    color: '#2708A2',
    localization: 'cycles.drilling'
  },
  '19': {
    description: 'Loading in Payload Standby',
    color: '#4D94FF',
    localization: 'cycles.loadingInPayloadStandby'
  },
  '20': {
    description: 'Cutting',
    color: '#3AA1A1',
    localization: 'cycles.cutting'
  },
  '21': {
    description: 'Traveling',
    color: '#015394',
    localization: 'cycles.traveling'
  },
  '22': {
    description: 'Servicing',
    color: '#91522D',
    localization: 'cycles.servicing'
  }
};

const SEGMENTS_COLOR_MAPPING = {
  '0': {
    description: 'Dig',
    color: '#5B1785',
    localization: 'segments.dig'
  },
  '1': {
    description: 'Travel Loaded',
    color: '#015394',
    localization: 'segments.travelLoaded'
  },
  '2': {
    description: 'Dump',
    color: '#F23694',
    localization: 'segments.dump'
  },
  '3': {
    description: 'Travel Empty',
    color: '#0078D6',
    localization: 'segments.travelEmpty'
  },
  '4': {
    description: 'Travel',
    color: '#0893FF',
    localization: 'segments.travel'
  },
  '5': {
    description: 'Swing Loaded',
    color: '#078878',
    localization: 'segments.swingLoaded'
  },
  '6': {
    description: 'Swing Empty',
    color: '#194C1D',
    localization: 'segments.swingEmpty'
  },
  '7': {
    description: 'Scrape',
    color: '#B257EB',
    localization: 'segments.scrape'
  },
  '8': {
    description: 'Scrape Dump',
    color: '#6C1B9E',
    localization: 'segments.scrapeDump'
  },
  '9': {
    description: 'Load',
    color: '#9826DE',
    localization: 'segments.load'
  },
  '10': {
    description: 'Carry',
    color: '#6852BE',
    localization: 'segments.carry'
  },
  '11': {
    description: 'Stationary Lift',
    color: '#AC5757',
    localization: 'segments.stationaryLift'
  },
  '12': {
    description: 'Spread',
    color: '#557C55',
    localization: 'segments.spread'
  },
  '13': {
    description: 'Return',
    color: '#DFB100',
    localization: 'segments.return'
  },
  '14': {
    description: 'Unknown',
    color: '#3F3F3F',
    localization: 'segments.unknown'
  },
  '15': {
    description: 'Miscellaneous',
    color: '#777',
    localization: 'segments.miscellaneous'
  },
  '16': {
    description: 'Machine Idle',
    color: '#FB5A00',
    localization: 'segments.machineIdle'
  },
  '17': {
    description: 'Stationary Loaded High Idle',
    color: '#AD7229',
    localization: 'segments.stationaryLoadedHighIdle'
  },
  '18': {
    description: 'Stationary Loaded Low Idle',
    color: '#E17003',
    localization: 'segments.stationaryLoadedLowIdle'
  },
  '19': {
    description: 'Stationary Empty High Idle',
    color: '#AB470D',
    localization: 'segments.stationaryEmptyHighIdle'
  },
  '20': {
    description: 'Stationary Empty Low Idle',
    color: '#CE5309',
    localization: 'segments.stationaryEmptyLowIdle'
  },
  '21': {
    description: 'Loaded Stopped',
    color: '#940000',
    localization: 'segments.loadedStopped'
  },
  '22': {
    description: 'Empty Stopped',
    color: '#DE2222',
    localization: 'segments.emptyStopped'
  },
  '23': {
    description: 'Grade',
    color: '#006618',
    localization: 'segments.grade'
  },
  '24': {
    description: 'Partial Dump',
    color: '#D973A6',
    localization: 'segments.partialDump'
  },
  '25': {
    description: 'Back Dragging',
    color: '#9C3900',
    localization: 'segments.backDragging'
  },
  '26': {
    description: 'Key On – Engine Off',
    color: '#565656',
    localization: 'segments.keyOnEngineOff'
  },
  '27': {
    description: 'Key Off – Engine Off',
    color: '#000',
    localization: 'segments.keyOffEngineOff'
  },
  '28': {
    description: 'Key Off – Engine On',
    color: '#909090',
    localization: 'segments.keyOffEngineOn'
  },
  '29': {
    description: 'Finish Blading',
    color: '#1B9C85',
    localization: 'segments.finishBlading'
  },
  '30': {
    description: 'Heavy Blading',
    color: '#006E5A',
    localization: 'segments.heavyBlading'
  },
  '31': {
    description: 'Ripping/Scarifying',
    color: '#0F992F',
    localization: 'segments.rippingScarifying'
  },
  '32': {
    description: 'Road Maintenance',
    color: '#FF004D',
    localization: 'segments.roadMaintenance'
  },
  '33': {
    description: 'Ditch Building',
    color: '#607274',
    localization: 'segments.ditchBuilding'
  },
  '34': {
    description: 'Ditch Cleaning',
    color: '#80979A',
    localization: 'segments.ditchCleaning'
  },
  '35': {
    description: 'Snow Plowing',
    color: '#0066FF',
    localization: 'segments.snowPlowing'
  },
  '36': {
    description: 'Snow Wing',
    color: '#4D94FF',
    localization: 'segments.snowWing'
  },
  '37': {
    description: 'Blade Slope Work',
    color: '#BF3E06',
    localization: 'segments.bladeSlopeWork'
  },
  '38': {
    description: 'Side Slope Work',
    color: '#F0500C',
    localization: 'segments.sideSlopeWork'
  },
  '39': {
    description: 'Haul',
    color: '#6C5600',
    localization: 'segments.haul'
  },
  '40': {
    description: 'Blading',
    color: '#5C9500',
    localization: 'segments.blading'
  },
  '41': {
    description: 'Stopped',
    color: '#CD1212',
    localization: 'segments.stopped'
  },
  '42': {
    description: 'Manual Idle',
    color: '#0000FF',
    localization: 'segments.manualIdle'
  },
  '43': {
    description: 'Automatic Idle',
    color: '#A60082',
    localization: 'segments.automaticIdle'
  },
  '44': {
    description: 'Manual Propelling',
    color: '#459D00',
    localization: 'segments.manualPropelling'
  },
  '45': {
    description: 'Automatic Propelling',
    color: '#7000FF',
    localization: 'segments.automaticPropelling'
  },
  '46': {
    description: 'Manual Jack Leveling',
    color: '#5E4943',
    localization: 'segments.manualJackLeveling'
  },
  '47': {
    description: 'Automatic Jack Leveling',
    color: '#A6785E',
    localization: 'segments.automaticJackLeveling'
  },
  '48': {
    description: 'Manual Jack Retracting',
    color: '#D3739D',
    localization: 'segments.manualJackRetracting'
  },
  '49': {
    description: 'Automatic Jack Retracting',
    color: '#8E7CC3',
    localization: 'segments.automaticJackRetracting'
  },
  '50': {
    description: 'Manual Mast Moving to Target',
    color: '#688459',
    localization: 'segments.manualMastMovingtoTarget'
  },
  '51': {
    description: 'Automatic Mast Moving to Target',
    color: '#994141',
    localization: 'segments.automaticMastMovingtoTarget'
  },
  '52': {
    description: 'Manual Mast Stowing',
    color: '#9900CC',
    localization: 'segments.manualMastStowing'
  },
  '53': {
    description: 'Automatic Mast Stowing',
    color: '#39731B',
    localization: 'segments.automaticMastStowing'
  },
  '54': {
    description: 'Manual Drilling Hole Progressing',
    color: '#8A406F',
    localization: 'segments.manualDrillingHoleProgressing'
  },
  '55': {
    description: 'Automatic Drilling Hole Progressing',
    color: '#5D4873',
    localization: 'segments.automaticDrillingHoleProgressing'
  },
  '56': {
    description: 'Manual Drilling Non Hole Progressing',
    color: '#5F6FF2',
    localization: 'segments.manualDrillingNonHoleProgressing'
  },
  '57': {
    description: 'Automatic Drilling Non Hole Progressing',
    color: '#F44336',
    localization: 'segments.automaticDrillingNonHoleProgressing'
  },
  '58': {
    description: 'Manual Add Pipe Handling',
    color: '#174DB1',
    localization: 'segments.manualAddPipeHandling'
  },
  '59': {
    description: 'Automatic Add Pipe Handling',
    color: '#297EA1',
    localization: 'segments.automaticAddPipeHandling'
  },
  '60': {
    description: 'Manual Remove Pipe Handling',
    color: '#C74D4C',
    localization: 'segments.manualRemovePipeHandling'
  },
  '61': {
    description: 'Automatic Remove Pipe Handling',
    color: '#EC6762',
    localization: 'segments.automaticRemovePipeHandling'
  },
  '62': {
    description: 'Cutting',
    color: '#3AA1A1',
    localization: 'segments.cutting'
  },
  '63': {
    description: 'Maneuvering',
    color: '#76803A',
    localization: 'segments.maneuvering'
  },
  '64': {
    description: 'Waiting In Cut',
    color: '#B18D00',
    localization: 'segments.waitingInCut'
  },
  '65': {
    description: 'Waiting Out Of Cut',
    color: '#775F00',
    localization: 'segments.waitingOutOfCut'
  },
  '66': {
    description: 'Startup',
    color: '#009D65',
    localization: 'segments.startup'
  },
  '67': {
    description: 'Rotor Servicing',
    color: '#91522D',
    localization: 'segments.rotorServicing'
  },
  '68': {
    description: 'Water Tank Fill',
    color: '#2C78B7',
    localization: 'segments.waterTankFill'
  },
  '69': {
    description: 'Fuel Tank Fill',
    color: '#AC940E',
    localization: 'segments.fuelTankFill'
  },
  '70': {
    description: 'DEF Tank Fill',
    color: '#3C21E1',
    localization: 'segments.defTankFill'
  },
  '71': {
    description: 'Washdown',
    color: '#837A98',
    localization: 'segments.washdown'
  }
};

const appendLocaleKey = (localeKey: string, mapping: ColorCodeMapping): ColorCodeMapping => {
  const newMapping = { ...mapping };
  for (const element in newMapping) {
    const value = newMapping[element];
    newMapping[element] = { ...value, localization: `${localeKey}.${value.localization}` };
  }
  return newMapping;
};

export const generateCyclesColorMapping = (localeKey: string) => appendLocaleKey(localeKey, CYCLES_COLOR_MAPPING);

export const generateSegmentsColorMapping = (localeKey: string) => appendLocaleKey(localeKey, SEGMENTS_COLOR_MAPPING);
