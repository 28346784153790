import { DszFilesId, FileStatus, FilterFileConfig, FilterItem, SiteFile, SvdSvlFileRes } from '@Terra/shared/widgets/interface';
import { ValidDesignConfig } from './models/fmHelper.model';
import { DSZFileConfigClass, DSZFileConstants, DSZFilesIdClass, SVD_FILE, SVL_FILE, VALID_SVL_STATUS } from './utils.config';

export const checkValidSvdSvlFiles = (files: SvdSvlFileRes[]): boolean => {
  const svdFile = files.find(file => file.fileName.endsWith(SVD_FILE));
  const svlFile = files.find(file => file.fileName.endsWith(SVL_FILE));
  if (!svdFile) {
    return false;
  }
  return svdFile?.fileStatus === FileStatus.ParsingCompleted && (!svlFile || VALID_SVL_STATUS.includes(svlFile?.fileStatus));
};

// Checks if file is DSZ;
export const isDSZFile = (fileName: string): boolean => fileName?.toLowerCase().endsWith(DSZFileConstants.DSZ);

// Checks if the dsz files are valid or not;
export const isValidDszFile = (dszParentFile: SiteFile): boolean => {
  // Condition to Disable the Option in Filter;
  if (dszParentFile.status !== DSZFileConstants.DSZ_EXTRACTION_SUCCESS || !dszParentFile?.files.length) {
    return false;
  }
  const svdSvlChildFile = dszParentFile.files;
  for (const file of svdSvlChildFile) {
    // Condition to Disable the option in Filter;
    if (file.fileStatus !== DSZFileConstants.PARSING_COMPLETED && file.fileName.endsWith(DSZFileConstants.SVD)) {
      return false;
    }
  }
  return true;
};

// Checks if all DSZ file is valid or not;
export const checkAllDszFileInValid = (dszFiles: SiteFile[]): boolean => {
  for (const file of dszFiles) {
    if (isValidDszFile(file)) {
      return false;
    }
  }
  return true;
};

// Checks if only DSZ file is present in all design files;
export const isOnlyDszDesignFilePresent = (designFiles: SiteFile[]): boolean => {
  for (const file of designFiles) {
    if (!file.name.endsWith(DSZFileConstants.DSZ)) {
      return false;
    }
  }
  return true;
};

// Return the file which will be slected when the page reloads for first time;
export const getValidDesignIdForFirstTime = (designFiles: SiteFile[]): ValidDesignConfig => {
  for (const file of designFiles) {
    if (file.name.endsWith(DSZFileConstants.DSZ) && isValidDszFile(file)) {
      return {
        fileId: file.fileId,
        fileName: file.name,
        isdszFile: true,
        svdId: checkForValidDSZFile(file)?.fileIds?.svdId
      };
    } else if (!file.name.endsWith(DSZFileConstants.DSZ)) {
      return {
        fileId: file.fileId,
        fileName: file.name,
        isdszFile: false,
        svdId: ''
      };
    }
  }
  return {
    fileId: '',
    fileName: '',
    isdszFile: false,
    svdId: ''
  };
};

// Prepare Filter DTO for DSZ files;
export const getFilterDataDTOForDSZFile = (file: SiteFile, filterData: FilterItem): FilterItem => {
  if (isDSZFile(file.name)) {
    const dszValue = checkForValidDSZFile(file);
    filterData[DSZFileConstants.IS_DSZ_FILE] = true;
    filterData[DSZFileConstants.FILE_CONFIG] = dszValue;
  }
  return filterData;
};

// Checks if the DSZ file option should enable or not by checking SVD and SVL status;
export const checkForValidDSZFile = (dszParentFile: SiteFile): FilterFileConfig => {
  const dszConfig = new DSZFileConfigClass();

  // Condition to Disable the Option in Filter;
  if (!isValidDszFile(dszParentFile)) {
    dszConfig.isDisable = true;
    dszConfig.tooltipMessageKey = dszParentFile.reasonForFailure;
    return dszConfig;
  }
  const svdSvlChildFile = dszParentFile.files;
  for (const file of svdSvlChildFile) {
    if (file.fileName.endsWith(DSZFileConstants.SVD)) {
      dszConfig.fileIds.svdId = file.fileId;
    } else if (file.fileName.endsWith(DSZFileConstants.SVL) && file.fileStatus === DSZFileConstants.PARSING_COMPLETED) {
      dszConfig.fileIds.svlId = file.fileId;
    }
  }
  return dszConfig;
};

// Gets all DSZ File ID;
export const getDszFilesIds = (files: SiteFile[], selectedDesignId: string): DszFilesId => {
  const dszFiles = new DSZFilesIdClass();
  const selectedFile = files.filter(file => file?.fileId === selectedDesignId);
  dszFiles.designFileId = selectedFile[0]?.fileId || '';
  if (isDSZFile(selectedFile[0]?.name)) {
    selectedFile[0]?.files.forEach(childFile => {
      if (childFile.fileName.endsWith(DSZFileConstants.SVD)) {
        dszFiles.svdId = childFile.fileId;
        dszFiles.designFileId = childFile.fileId;
      } else if (childFile.fileName.endsWith(DSZFileConstants.SVL) && childFile.fileStatus === DSZFileConstants.PARSING_COMPLETED) {
        dszFiles.svlId = childFile.fileId;
      }
    });
  }
  return dszFiles;
};

// Returns design file id based on svd Id;
export const getDesignFileFromViewPreference = (fileId: string, designFiles: SiteFile[]): string => {
  const designIndex = designFiles.findIndex((item: SiteFile) => item?.fileId === fileId);
  if (designIndex === -1) {
    const dszFile = designFiles.filter((file: SiteFile) => file?.files?.length > 0 && checkForSvdInDsz(file.files, fileId));
    return dszFile.length > 0 ? dszFile[0].fileId : '';
  }
  return fileId;
};

// check if dsz file contains svd;
const checkForSvdInDsz = (files: SvdSvlFileRes[], fileId: string): boolean => files.some((item: SvdSvlFileRes) => item.fileId === fileId);
