import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlightSearchText', standalone: true })
export class HighlightSearchText implements PipeTransform {
  transform(value: string, args: string, isMatchSplChars = false): string {
    return highLightTextPipeTransform(value, args, isMatchSplChars);
  }
}

export const highLightTextPipeTransform = (value: string, args: string, isMatchSplChars = false): string => {
  if (!args) {
    return value;
  }

  if (isMatchSplChars) {
    const splcharactersMatch = args.match(/[+()$@^*?<>{}.]/g);

    splcharactersMatch?.length &&
      splcharactersMatch.forEach(match => {
        args = args.split(match).join(`\\${match}`);
      });
  }

  const regex = new RegExp(args, 'gi');
  const match = value.match(regex);
  if (!match) {
    return value;
  }
  return value.replace(regex, `<span class='highlightText'>${match[0]}</span>`);
};
