/*
 * The enum for position
 */
export enum Position {
  TOPLEFT = 'topleft',
  TOPRIGHT = 'topright',
  BOTTOMLEFT = 'bottomleft',
  BOTTOMRIGHT = 'bottomright',
  TOPCENTER = 'topcenter',
  BOTTOMCENTER = 'bottomcenter',
  MIDDLELEFT = 'middleleft',
  MIDDLERIGHT = 'middleright',
  MIDDLECENTER = 'middlecenter'
}

export const LegendColors = {
  color_AD7229: '#AD7229',
  color_078878: '#078878',
  color_6852BE: '#6852BE',
  color_04861C: '#04861C'
};
