export const CHART_CONSTANTS = {
  SECS_TO_HRS: 3600,
  SECS_TO_MINS: 60,
  POINTS_AFTER_DECIMAL: 100
};

export const SERIES_NAME = 'default';

export const CHART_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  CODE: 'code'
};

const CONFIG_STRINGS = {
  KPI_LOAD_COUNT_ID: 'e667c2db-9678-4ac7-bc19-4d5e709a36g',
  KPI_PAYLOAD_ID: 'bfec1fab-2c9d-45ef-8695-cba255bee0db',
  PRODUCTION_TAB_ID: 'dc59a0d3-eb34-4951-99e8-67dafed82dcd',
  KPI_WORKING_VS_IDLE_ID: '7e142088-8321-42b5-aaa7-bedc2edd17c3',
  UTILIZATION_TAB_ID: 'edd1dd73-584b-472c-afd7-d9479e6ab82'
};

export const KPI_CONFIG_MAPPING = {
  'u1-v1-w1': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_LOAD_COUNT_ID,
    name: 'cycleCount',
    isViewByOperatorSupport: true
  },
  'u2-v2-w2': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_LOAD_COUNT_ID,
    name: 'cycleDetails',
    isViewByOperatorSupport: false
  },
  'u3-v3-w3': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'cutDistancePerFuel',
    isViewByOperatorSupport: false
  },
  'u4-v4-w4': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'cuttingDuration',
    isViewByOperatorSupport: false
  },
  'u5-v5-w5': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'cycleDistance',
    isViewByOperatorSupport: false
  },
  'u6-v6-w6': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'cycleFuel',
    isViewByOperatorSupport: false
  },
  'u7-v7-w7': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'cycleTime',
    isViewByOperatorSupport: false
  },
  'u8-v8-w8': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'dailyRun',
    isViewByOperatorSupport: false
  },
  'u9-v9-w9': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'payload',
    isViewByOperatorSupport: true
  },
  'u10-v10-w10': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'payloadPerCycle',
    isViewByOperatorSupport: true
  },
  'u11-v11-w11': {
    tabId: CONFIG_STRINGS.PRODUCTION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_PAYLOAD_ID,
    name: 'payloadPerFuel',
    isViewByOperatorSupport: true
  },
  'u12-v12-w12': {
    tabId: CONFIG_STRINGS.UTILIZATION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_WORKING_VS_IDLE_ID,
    name: 'dailyDistance',
    isViewByOperatorSupport: false
  },
  'u13-v13-w13': {
    tabId: CONFIG_STRINGS.UTILIZATION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_WORKING_VS_IDLE_ID,
    name: 'fuelBurn',
    isViewByOperatorSupport: true
  },
  'u14-v14-w14': {
    tabId: CONFIG_STRINGS.UTILIZATION_TAB_ID,
    kpiId: CONFIG_STRINGS.KPI_WORKING_VS_IDLE_ID,
    name: 'runtime',
    isViewByOperatorSupport: true
  }
};
