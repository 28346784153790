import { ConvertUnitOfMeasurement, Preference } from '@Terra/shared/widgets/interface';
import { convertUnitOfMeasurement, round, uomHelperConfig } from '@Terra/shared/widgets/utils';
import { roundoffHelper } from '../..';
import { UNITS_OF_MEASUREMENT } from '../append-unit/append-unit.config';
import { getAppendUnit } from '../append-unit/append-unit.helper';
import { decimalSeparatorTransform } from '../decimal-separator/decimal-seperator-helper';
import { DEFAULT_METRIC_TYPE, METRICS, METRIC_TYPE } from './convert-and-format.config';

export const getConvertUom = (value: string, params: { preference: Preference; metric: string; isTwoDigitPrecision?: boolean }): string => {
  if (stringNullCheck(value)) {
    return '--';
  }
  return getFormatedValue(getProcessedValue(value, params?.preference?.unit, params?.metric), params);
};

const getFormatedValue = (
  convertedValue: string | number,
  params: { preference: Preference; metric: string; isTwoDigitPrecision?: boolean }
): string => {
  const roundedValue = getRoundOffValue(convertedValue, params?.isTwoDigitPrecision);
  if (params?.metric === 'time') {
    return getAppendUnit(String(convertedValue), { metric: params?.metric, uom: params?.preference?.unit });
  } else if (!['string', 'datetime', 'time'].includes(params?.metric)) {
    return decimalSeparatorTransform(roundedValue, params?.preference?.decimalSeparator);
  }
  return roundedValue;
};

const getProcessedValue = (value: string, uom: string, metric: string): number | string => {
  if (validateUomSIUnit(metric, uom)) {
    return Number(value);
  } else if (METRIC_TYPE.includes(metric) && uom === UNITS_OF_MEASUREMENT.usCustomary) {
    return convertSIUnitsToUSCustomary(Number(value), uom, metric);
  }
  return value;
};

const convertSIUnitsToUSCustomary = (value: number, uom: string, metric: string): number => {
  const params: ConvertUnitOfMeasurement = {
    valueToConvert: value,
    conversionType: uomHelperConfig.UOM_CONVERSION_METRIC_TYPE[metric],
    preferenceType: uom,
    applyRoundOff: false,
    decimalLength: DEFAULT_METRIC_TYPE.includes(metric) ? 20 : 0
  };
  return convertUnitOfMeasurement(params);
};

const stringNullCheck = (value?: string): boolean =>
  value === null || value === 'null' || value?.toString().trim() === '' || Number(value) < 0;

const validateUomSIUnit = (metric: string, uom: string): boolean =>
  (uom === UNITS_OF_MEASUREMENT.siUnit && METRICS[metric]) || (uom === UNITS_OF_MEASUREMENT.usCustomary && metric === METRICS.cost);

const getRoundOffValue = (convertedValue: string | number, isTwoDigitPrecision?: boolean) =>
  isTwoDigitPrecision ? convertValue(convertedValue) : roundoffHelper.roundOffTransform(convertedValue);

const convertValue = (value: number | string): string => (typeof value === 'number' ? roundOffWithTwoDecimals(value) : value.toString());

const roundOffWithTwoDecimals = (value: number): string => {
  if (value < 10000) {
    return `${round(value, 2)}`;
  }
  return `${round(value / 1000, 2)}K`;
};
