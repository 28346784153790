import { Pipe, PipeTransform } from '@angular/core';
import { round } from '@Terra/shared/widgets/utils';

@Pipe({
  name: 'roundOff',
  standalone: true
})
export class RoundOffPipe implements PipeTransform {
  transform(kpiValue, isTwoDigitPrecision = false) {
    if (Array.isArray(kpiValue)) {
      // To handle Array of KPI values
      return kpiValue.map(element => {
        let { value } = element;
        value = value.includes('%')
          ? `${this.roundOff(Number(value.replace('%', '')), isTwoDigitPrecision)}%`
          : !value || isNaN(value)
          ? value
          : this.roundOff(Number(value), isTwoDigitPrecision);
        return { ...element, value };
      });
    }
    // To handle single value
    return !kpiValue || typeof kpiValue !== 'number' ? kpiValue : this.roundOff(kpiValue, isTwoDigitPrecision);
  }

  roundOff(value, isTwoDigitPrecision: boolean) {
    if (isTwoDigitPrecision) {
      return Number(`${Math.round(Number(`${value}e+2`))}e-2`); //Two digit precision logic
    }
    if (value < 1000) {
      return `${round(value, 1)}`;
    } else if (value < 10000) {
      const result = round(value);
      return result === 10000 ? `${result / 1000}K` : `${result}`;
    }
    return `${round(value / 1000, 2)}K`;
  }
}
