export function isEqual(value: any, other: any): boolean {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If type is different between value and other, they're not equal
  if (type !== Object.prototype.toString.call(other)) return false;

  // If the types are different primitive types, they're not equal
  if (['[object Number]', '[object Boolean]'].includes(type)) {
    return value === other;
  }

  if (value === null || other === null) {
    return value === other;
  }

  if (type === '[object String]') {
    try {
      const objA = JSON.parse(value);
      const objB = JSON.parse(other);
      return isEqual(objA, objB);
    } catch (error) {
      return value === other;
    }
  }

  // If they're both dates, compare their values
  if (type === '[object Date]') return (value as Date).getTime() === (other as Date).getTime();

  // If they're arrays, compare their lengths and contents recursively
  if (type === '[object Array]') {
    if ((value as any[]).length !== (other as any[]).length) return false;
    for (let i = 0; i < (value as any[]).length; i++) {
      if (!isEqual((value as any[])[i], (other as any[])[i])) return false;
    }
    return true;
  }

  // If they're objects, compare their keys and values recursively
  if (type === '[object Object]') {
    const keys1 = Object.keys(value as any);
    const keys2 = Object.keys(other as any);
    if (keys1.length !== keys2.length) return false;
    for (const key of keys1) {
      if (!keys2.includes(key) || !isEqual((value as any)[key], (other as any)[key])) return false;
    }
    return true;
  }

  // If none of the above conditions are met, they're not equal
  return false;
}

export function cloneDeep<T>(value: T): T {
  if (typeof value !== 'object' || value === null) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map(item => cloneDeep(item)) as any;
  }

  const clonedObj = {} as T;
  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      clonedObj[key] = cloneDeep(value[key]);
    }
  }
  return clonedObj;
}

export function cloneDeepUsingJson<T>(value: T): T {
  return JSON.parse(stringify<T>(value));
}

/**TempFix for circular structure json error */
export function stringify<T>(obj: T): string {
  let cache = [];
  const str = JSON.stringify(obj, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
}

export function merge<T extends object>(...objects: any[]): any {
  const isObject = (obj: any) => obj && typeof obj === 'object';

  return objects.reduce((merged, obj) => {
    if (!isObject(obj)) return merged;

    Object.keys(obj).forEach(key => {
      if (isObject(obj[key]) && isObject(merged[key])) {
        merged[key] = merge(merged[key], obj[key]);
      } else {
        merged[key] = obj[key];
      }
    });

    return merged;
  }, {} as T);
}

export function omit<T extends object, K extends keyof T>(object: T, props: K[]): Omit<T, K> {
  const omitted = { ...object };

  props.forEach(prop => {
    delete omitted[prop];
  });

  return omitted;
}

export function pick<T extends object>(object: T, props: any[]): Pick<T, any> {
  const picked = {} as Pick<T, any>;

  props.forEach(prop => {
    if (prop in object) {
      picked[prop] = object[prop];
    }
  });

  return picked;
}

export function omitBy<T extends object>(object: T, predicate: (value: T[keyof T], key: keyof T) => boolean): T {
  const omitted: T = {} as T;

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key) && !predicate(object[key], key)) {
      omitted[key] = object[key];
    }
  }

  return omitted;
}

export function get<T, K extends keyof T>(object: T, path, defaultValue?: any): T[K] | typeof defaultValue {
  if (!Array.isArray(path)) {
    path = path.split('.');
  }

  return path.reduce((obj, key) => obj?.[key], object) ?? defaultValue;
}

export function xorWith<T>(array1: T[], array2: T[], comparator: (a: T, b: T) => boolean): T[] {
  const result: T[] = [];

  for (const item1 of array1) {
    let found = false;
    for (const item2 of array2) {
      if (comparator(item1, item2)) {
        found = true;
        break;
      }
    }
    if (!found) {
      result.push(item1);
    }
  }

  for (const item2 of array2) {
    let found = false;
    for (const item1 of array1) {
      if (comparator(item1, item2)) {
        found = true;
        break;
      }
    }
    if (!found) {
      result.push(item2);
    }
  }

  return result;
}

export function groupBy<T, K>(collection: T[], iteratee: (item: T) => K): Record<string, T[]> {
  return collection.reduce((acc: Record<string, T[]>, item: T) => {
    const key = String(iteratee(item));
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
}

export function isEmpty<T>(value: T): boolean {
  if (value == null) {
    return true;
  }

  return (
    value === null ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
}

export function differenceWith<T>(array: T[], values: T[], comparator: (a: T, b: T) => boolean): T[] {
  return array.filter(item1 => !values.some(item2 => comparator(item1, item2)));
}

export function round(number: number, precision: number = 0): number {
  const multiplier = Math.pow(10, precision);
  return Math.round(number * multiplier) / multiplier;
}

export function omitByKey<T>(raw: T, allowed: string[]): T {
  const omitted: T = { ...raw };
  allowed.forEach(prop => {
      delete omitted[prop];
  });
  return omitted;
}