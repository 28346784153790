import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'domSanitizer',
  standalone: true
})
export class DomSanitizerPipe implements PipeTransform {
  constructor(private readonly sanitizer?: DomSanitizer) {}

  transform(content): SafeHtml {
    return this.sanitizer?.bypassSecurityTrustHtml(content);
  }
}
