export const APPEND_UNIT_PIPE_METRICS_CONSTANT = ['cost', 'costweight', 'costvolume', 'costfuel'];

export const APPEND_UNIT_PIPE_CONSTANTS = {
  NonTimeValueUnit: 'nonTimeValueWithUnit',
  SplitHrMinAndSec: 'splitHrMinAndSec',
  CostCurrencyUnit: 'unitSymbolForMeasurementOrCostCurrency',
  UnitSymbolForMeasurement: 'checkNullAndUnitSymbolForMeasurement'
};

export const COST_CURRENCY_CONFIG = [
  { name: '$ (US Dollar)', key: 'US_DOLLAR', symbol: '$' },
  { name: 'C$ (Canadian Dollar)', key: 'CANADIAN_DOLLAR', symbol: 'C$' },
  { name: 'Mex$ (Mexican Peso)', key: 'MEXICAN_PESO', symbol: 'Mex$' },
  { name: 'A$ (Australian Dollar)', key: 'AUSTRALIAN_DOLLAR', symbol: 'A$' },
  { name: 'NZ$ (New Zealand Dollar)', key: 'NEW_ZEALAND_DOLLAR', symbol: 'NZ$' },
  { name: '€ (Euro)', key: 'EURO', symbol: '€' },
  { name: '£ (Pound Sterling)', key: 'POUND_STERLING', symbol: '£' },
  { name: 'Kč (Czech Koruna)', key: 'CZECH_KORUNA', symbol: 'Kč' },
  { name: '₱ (Peso)', key: 'PESO', symbol: '₱' },
  { name: 'Ft (Hungarian Forint)', key: 'HUNGARIAN_FORINT', symbol: 'Ft' },
  { name: 'zł (Polish złoty)', key: 'POLISH_ZLOTY', symbol: 'zł' },
  { name: 'R$ (Brazilian real)', key: 'BRAZILIAN_REAL', symbol: 'R$' },
  { name: 'kr (Swedish Krona)', key: 'SWEDISH_KRONA', symbol: 'kr' },
  { name: '₺ (Turkish lira)', key: 'TURKISH_LIRA', symbol: '₺' },
  { name: 'RUB (Russian ruble)', key: 'RUSSIAN_RUBLE', symbol: 'RUB' },
  { name: '฿ (Thai Baht)', key: 'THAI_BAHT', symbol: '฿' },
  { name: '¥ (Renminbi)', key: 'RENMINBI', symbol: '¥' },
  { name: 'JP¥ (Japanese Yen)', key: 'JAPANESE_YEN', symbol: 'JP¥' },
  { name: '₩ (South Korean won)', key: 'SOUTH_KOREAN_WON', symbol: '₩' },
  { name: '₩ (North Korean won)', key: 'NORTH_KOREAN_WON', symbol: '₩' },
  { name: 'kr (Norwegian krone)', key: 'NORWEGIAN_KRONE', symbol: 'kr' },
  { name: 'CHF (Swiss Franc)', key: 'SWISS_FRANC', symbol: 'CHF' },
  { name: 'R (South African rand)', key: 'SOUTH_AFRICAN_RAND', symbol: 'R' }
];

export const UNITS_OF_MEASUREMENT = {
  siUnit: 'SI Units',
  usCustomary: 'U.S. Customary'
};

// Unit Label;
export const UNITS_SYMBOL = {
  [UNITS_OF_MEASUREMENT.usCustomary]: {
    payload: 'ton',
    distance: 'mi',
    area: 'yd²',
    speed: 'mi/h',
    weight: 'ton',
    fuel: 'gal',
    volume: 'yd³',
    payloadPerHour: 'ton/h',
    payloadPerFuel: 'ton/gal',
    fuelPerRate: 'gal/h',
    mass: 'lb',
    height: 'ft',
    english: 'english',
    elevation: 'us_feet',
    temperature: 'Fahrenheit',
    volumeInWords: 'cubicYard',
    density: 'lbPerCubicYard'
  },
  [UNITS_OF_MEASUREMENT.siUnit]: {
    payload: 't',
    area: 'm²',
    weight: 't',
    fuel: 'L',
    volume: 'm³',
    payloadPerHour: 't/h',
    payloadPerFuel: 't/L',
    distance: 'km',
    fuelPerRate: 'L/h',
    mass: 'kg',
    height: 'm',
    english: 'metric',
    speed: 'mph',
    elevation: 'meter',
    temperature: 'Celsius',
    volumeInWords: 'cubicMeter',
    density: 'kgPerCubicMeter'
  }
};
