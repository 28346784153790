import { Pipe, PipeTransform } from '@angular/core';
import { momentDateFormatterHelper } from '@Terra/shared/widgets/utils';

export const MOMENT_LOCALE_MAP = {
  'zh-Hans': 'zh-cn'
};

@Pipe({
  name: 'dateLocale',
  standalone: true
})
export class DateLocalePipe implements PipeTransform {
  transform(date: string | Date, datePayload: { locale: string; format: string; enableTranslation: boolean }, currentFormat?: string): any {
    return dateLocalePipeTransform(date, datePayload, currentFormat);
  }
}

export const dateLocalePipeTransform = (
  date: string | Date,
  datePayload: { locale: string; format: string; enableTranslation: boolean },
  currentFormat?: string
): any => {
  if (!datePayload.enableTranslation) {
    return date;
  }
  const _locale = MOMENT_LOCALE_MAP[datePayload.locale] || datePayload.locale;
  if (currentFormat) {
    return momentDateFormatterHelper.getLocaleDate({ dateString: date, localeName: _locale, convertTo: datePayload.format, currentFormat });
  }
  return momentDateFormatterHelper.getLocaleDate({ dateString: date, localeName: _locale, convertTo: datePayload.format });
};
