import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validate',
  standalone: true
})
export class ValidationPipe implements PipeTransform {
  transform(value: string | number, type = 'time') {
    return validationPipeTransform(value, type);
  }
}
export const validationPipeTransform = (value: string | number, type = 'time') => {
  if (value) {
    const rejex = type === 'time' ? /[^0-9]/g : /[^0-9.]/g;
    let val = value.toString().trim().replace(rejex, '');
    val = val !== '' ? val : '0';
    return getValueIfNotEmpty(type, val);
  }
  return type === 'time' ? '00' : '0';
};

const getValueIfNotEmpty = (type: string, val: string): string =>
  type === 'time' ? (val.length === 1 ? `0${val}` : /^0+$/.test(val) ? '00' : val) : /^0+$/.test(val) ? '0' : val;
