import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CcLoadersModule } from '@cat-digital-workspace/shared-ui-core/loaders';
import { SpinnerComponent } from './spinner/spinner.component';
import { ThreeDotSpinnerComponent } from './three-dot-spinner/three-dot-spinner.component';

export { SpinnerComponent };
@NgModule({
  imports: [CommonModule, CcLoadersModule],
  declarations: [SpinnerComponent, ThreeDotSpinnerComponent],
  exports: [SpinnerComponent, ThreeDotSpinnerComponent]
})
export class SpinnerModule {}
