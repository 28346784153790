import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { DateConvert, UniversalTimeZone } from './models/util.model';
import { getTimeZoneOffset } from './moment-time-formatter-helper';
import { ERROR_TIMEZONE, LatLngCoOrdinates, TIMEZONE_API_URL, TimeZone } from './timezone.config';
@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private readonly timeZoneSubject: Subject<LatLngCoOrdinates> = new Subject();
  timeZone$: Observable<LatLngCoOrdinates> = this.timeZoneSubject.asObservable();
  private httpClient: HttpClient;

  constructor(private readonly httpHandler: HttpBackend, private readonly datePipe: DatePipe) {
    this.httpClient = new HttpClient(httpHandler);
  }

  getTimeZoneInfo(googleApiKey: string): Observable<TimeZone> {
    return this.timeZone$.pipe(
      debounceTime(500),
      switchMap((latLng: LatLngCoOrdinates) => this.getTimeZone(latLng, googleApiKey))
    );
  }

  getTimeZone(latLng: LatLngCoOrdinates, googleApiKey: string): Observable<TimeZone> {
    return this.httpClient
      .get<TimeZone>(`${TIMEZONE_API_URL}?location=${latLng.lat},${latLng.lng}&timestamp=${latLng.today}&key=${googleApiKey}`)
      .pipe(catchError(() => of(ERROR_TIMEZONE)));
  }

  changeLatLng(latLng: LatLngCoOrdinates): void {
    this.timeZoneSubject.next(latLng);
  }

  /**
   *Use this function to convert the date object to respective timeZone and returns in ISOformat
   *
   * @param {Date} date
   * @param {string} timeZone
   */
  convertToTimeZone({ dateString, localeName }: DateConvert, toUTC: boolean = true, toISOformat: boolean = true): string | Date {
    const format = 'yyyy-MM-ddTHH:mm:ss';
    const formatter = `${this.datePipe.transform(dateString as Date, format)}.000Z`;
    let dateVal: string | Date = this.datePipe.transform(formatter, format, getTimeZoneOffset(localeName, toUTC, dateString as Date));
    if (toISOformat) {
      dateVal = `${dateVal}.000Z`;
    } else {
      dateVal = moment(dateVal).toDate();
    }
    return dateVal;
  }

  //get the default timezone
  setDefaultTimeZoneTarget(zoneList: Array<UniversalTimeZone>): UniversalTimeZone {
    let selectedtimeZone: UniversalTimeZone = {
      id: 46,
      displayName: '(UTC) Coordinated Universal Time',
      standardName: 'UTC',
      daylightName: 'Coordinated Universal Time',
      timezone: 'Etc/UTC',
      utc: ['Etc/UTC'],
      standardCode: '(UTC)',
      daylightCode: 'CUT',
      supportsDaylightSavingTime: false
    };
    const date = new Date();
    const offset = this.datePipe.transform(date, 'ZZZZZ');
    selectedtimeZone =
      zoneList.find((zone: UniversalTimeZone) => {
        const zoneName = String(zone['displayName'] || '');
        const searchOffset = `(UTC${offset === 'Z' ? '' : offset})`;
        return zoneName.indexOf(searchOffset) !== -1;
      }) || selectedtimeZone;
    return selectedtimeZone;
  }

  setDefaultTimeZoneTargetUtcIana(zoneList: Array<UniversalTimeZone>, ianaStandardZone?: string): UniversalTimeZone {
    let selectedtimeZone;
    zoneList.forEach((zone: UniversalTimeZone) => {
      const getZoneUtc = zone.utc ? zone.utc.filter((zoneUtc: string) => zoneUtc === ianaStandardZone) : [];
      if (getZoneUtc.length) {
        selectedtimeZone = zone;
      }
      // return getZoneUtc.length;
    });
    return selectedtimeZone || this.setDefaultTimeZoneTarget(zoneList);
  }
}
