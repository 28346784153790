import { InputUnitParam } from '../common.model';
import { APPEND_UNIT_PIPE_CONSTANTS, APPEND_UNIT_PIPE_METRICS_CONSTANT, COST_CURRENCY_CONFIG, UNITS_SYMBOL } from './append-unit.config';
import { FormatTimeParam } from './model/append-unit.model';

export const getUnits = (value?: string, params?: InputUnitParam, transformType?: string): string | number => {
  if (transformType === APPEND_UNIT_PIPE_CONSTANTS.SplitHrMinAndSec) {
    return getSplitHrMinAndSec(value, params);
  }
  return getUnitSymbolForMeasurement(value, params);
};
export const getNonTimeValueUnit = (value?: string, params?: InputUnitParam): string => {
  if (value === '') {
    return '';
  }
  return formatNonTimeValueWithUnit(value, params);
};
export const getSplitHrMinAndSec = (value?: string, params?: InputUnitParam): string => {
  const isEmpty = (): boolean => [null, 'null', undefined]?.includes(value) || value?.toString().trim() === '';
  if (isEmpty()) {
    return '';
  } else if (params.metric === 'time' && value !== '') {
    return splitHrMinAndSec(Number(value));
  }
  return getUnitSymbolForUnitOfMeasurement(params.uom, params.metric);
};
export const getUnitSymbolForMeasurement = (value?: string, params?: InputUnitParam): string | number =>
  checkvalue(value) && checkvalue(params.metric) && checkvalue(params.uom) ? getUnitTextValue(value, params) : isEqualsZero(value);
const isEqualsZero = (value?: string): string | number => {
  const returnValue = value as string | number;
  return returnValue === 0 ? 0 : value || '--';
};
export const getAppendUnit = (value?: string, params?: InputUnitParam, transformType?: string): string => {
  if (value === '--' || '') {
    return value === '--' ? '--' : '';
  }
  return formatNonTimeValueWithUnit(value, params, transformType);
};

export const formatNonTimeValueWithUnit = (value: string, params: InputUnitParam, transformType?: string): string => {
  if (params.metric === 'time' && value !== '') {
    return formatTime(Number(value));
  }
  return getUnitTextValue(value, params, transformType);
};

export const getUnitTextValue = (value: string, params: InputUnitParam, transformType?: string): string => {
  const unit = getUnitText(params, transformType);
  const _unit = params.encloseUOM && unit !== '' ? `(${unit})` : unit;
  return value ? `${value} ${_unit}` : _unit;
};

const getUnitText = (params: InputUnitParam, transformType?: string): string => {
  if (params.metric === 'percentage') {
    return '%';
  }
  if (transformType === 'unitSymbolForMeasurementOrCostCurrency' && costCurrencyCheck(params.metric)) {
    return isCostCurrencyConfig();
  }
  return getUnitSymbolForUnitOfMeasurement(params.uom, params.metric);
};

const costCurrencyCheck = (metric: string): boolean => APPEND_UNIT_PIPE_METRICS_CONSTANT.includes(metric);
const isCostCurrencyConfig = (): string =>
  COST_CURRENCY_CONFIG.find((_currency: { name: string; key: string; symbol: string }) => _currency.key === COST_CURRENCY_CONFIG[0].key)
    ?.symbol || '';

export const getUnitSymbolForUnitOfMeasurement = (uom: string, metricType: string): string =>
  UNITS_SYMBOL?.[uom]?.[metricType] ? UNITS_SYMBOL[uom][metricType] : '';

export const formatTime = (seconds: number): string => {
  if (seconds < 60) {
    return `${Math.round(seconds)}s`;
  }

  return getFormattedTimeString(seconds);
};

const getFormattedTimeString = (seconds: number): string => {
  seconds = Math.round(seconds); //rounding off milliseconds
  let minute = Math.floor(seconds / 60); //converting secs to mins
  seconds = Math.round(((seconds % 60) * 100) / 100); //remaining seconds after taking hours
  const hour = Math.floor(minute / 60); //converting mins to hrs
  minute = minute % 60; //remanining mins after taking hour
  return formatTimeString(hour, minute, seconds);
};

const formatTimeString = (hour: number, minute: number, seconds: number): string => {
  const result = calculateHourMinuteAndSeconds(hour, minute, seconds);
  if (hour < 1) {
    return result.seconds ? `${result.minute}m ${result.seconds}s` : `${result.minute}m`;
  }
  return result.minute ? `${result.hour}h ${result.minute}m` : `${result.hour}h`;
};

const calculateHourMinuteAndSeconds = (hour: number, minute: number, seconds: number): FormatTimeParam => {
  if (hour !== 0 && seconds >= 30) {
    if (minute === 59) {
      ++hour;
      minute = 0;
    } else {
      ++minute;
    }
    seconds = 0;
  }
  return { ...calculateMinuteWhenSecondsIsSixty(minute, seconds), hour };
};

export const calculateMinuteWhenSecondsIsSixty = (minute: number, seconds: number): FormatTimeParam => {
  if (seconds === 60) {
    ++minute;
    seconds = 0;
  }
  return { minute, seconds };
};

export const checkvalue = (value: string | number): boolean => value !== undefined && value !== null && value !== '' && value !== '--';

const getTime = (t: number, str: string): string => (t >= 0 ? `<b>${t}</b> <small>${str}</small>` : '');

const getMinutes = (min: number, secVal: string): string => (min > 0 ? `<b>${min}</b> <small>m</small> ${secVal}` : secVal);

function isSecGreaterEqual60(seconds: number, minutes: number): { minutes: number; seconds: number } {
  const secVal = Math.floor(seconds) + 1;
  if (secVal === 60) {
    seconds = 0;
    minutes++;
  } else {
    seconds = secVal;
  }
  return { minutes, seconds };
}

function isHoursGreaterThanZero(hours: number, sec: number, minutes: number): { hours: number; minutes: number } {
  if (sec >= 30) {
    if (minutes === 59) {
      hours++;
      minutes = 0;
    } else {
      minutes++;
    }
  }
  return { hours, minutes };
}
export function splitHrMinAndSec(value: number): string {
  let hr = Math.floor(value / 3600);
  let min = Math.floor((value % 3600) / 60);
  let sec = (value % 3600) % 60;
  if ((sec - Math.floor(sec)) * 1000 < 500) {
    sec = Math.floor(sec);
  } else {
    const { minutes, seconds } = isSecGreaterEqual60(sec, min);
    sec = seconds;
    min = minutes;
  }
  if (hr > 0) {
    const { hours, minutes } = isHoursGreaterThanZero(hr, sec, min);
    hr = hours;
    min = minutes;
    return `<b>${hr}</b> <small>h</small> ${getTime(min, 'm')}`;
  }
  const secVal = getTime(sec, 's');
  return getMinutes(min, secVal);
}
