/*
 * The enum for API state
 */
export enum API_STATES {
  INITIAL_STATE = 'initial_state',
  NO_DATA = 'no_data_from_api',
  API_ERROR = 'api_error',
  API_PENDING = 'api_pending',
  API_SUCCESS = 'api_success',
  API_COMPLETED = 'api_completed',
  SUBSCRIPTION_ERROR = 'subscription_error'
}

export const HTTP_CODE = {
  SUCCESS: '200',
  NO_DATA: '404',
  BADREQUEST: '400',
  API_ERROR: '500'
};

export const HELP_LINK_URL = {
  int: 'https://intpitmgtuisa.z21.web.core.windows.net',
  qa: 'https://qapitmgtuisa.z21.web.core.windows.net'
};

export const UCID_STORAGE_KEY = 'ucidData';

export const CONSENT_STATUS = {
  AGREE: 'Agree',
  DECLINE: 'Decline'
};

export const CP_COOKIE = 'CPCookie';
export const SSO_COOKIE = 'cat-b2c-checksso';

export const applicationJson = 'application/json';

export const RESPONSE_TYPE = {
  get: 'get'
};

export const SPINNER_CONFIG = {
  canShowSpinner: 'canShowSpinner',
  showSpinner: 'true'
};

export const UCID_EVENTS = {
  CHANGE_ACCOUNT: 'changeAccount',
  UCID_SELECTION: 'accountSelection',
  LOAD_MORE_UCID: 'loadMore',
  RESET_UCID_SEARCH: 'resetSearch',
  INIT_UCID_SEARCH: 'initSearch',
  TRY_AGAIN: 'tryAgain',
  ACCOUNT: 'Account'
};

export const AVAILABLE_LANGUAGES = [
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'el-GR',
  'en-US',
  'es-ES',
  'es-419',
  'fr-FR',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nb-NO',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sl-SL',
  'sv-SE',
  'th-TH',
  'tr-TR',
  'zh-Hans',
  'fi-FI',
  'id-ID',
  'tr-CN',
  'ta-IN',
  'mn-MN',
  'zh-Hant'
];

export const API_HEADER = {
  contentType: 'Content-Type',
  applicationJson: 'application/json',
  accept: 'Accept',
  catTrackingId: 'X-Cat-API-Tracking-Id',
  canShowSpinner: 'canShowSpinner',
  catUserPref: 'X-Cat-User-Preferences',
  pipe_encode: '&pipe;'
};
