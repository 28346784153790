import { FileStatus } from '@Terra/shared/widgets/interface';
import { BaseUrl } from './models/util.model';
/**Allocate the strings with the constant as a fix for string literals not be duplicated rule */
const usCustomary = 'U.S. Customary';
const configStrings = {
  usStandard: 'US Standard',
  usCustomary: 'U.S. Customary',
  timezoneEtcGmt12: 'Etc/GMT+12',
  timezonePago: 'Pacific/Pago_Pago',
  timezoneEtcGmt10: 'Etc/GMT+10',
  timezoneMarquesas: 'Pacific/Marquesas',
  timezoneAnchorage: 'America/Anchorage',
  timezoneDawson: 'America/Dawson',
  timezoneTijuana: 'America/Tijuana',
  timezoneCreston: 'America/Creston',
  timezoneChihuahua: 'America/Chihuahua',
  timezoneManagua: 'America/Managua',
  timezoneMexico_City: 'America/Mexico_City',
  timezoneCancun: 'America/Cancun',
  timezoneLima: 'America/Lima',
  timezoneHavana: 'America/Havana',
  timezonePortauPrince: 'America/Port-au-Prince',
  timezoneCaracas: 'America/Caracas',
  timezoneAsuncion: 'America/Asuncion',
  timezoneSaoPaulo: 'America/Sao_Paulo',
  timezoneLaPaz: 'America/La_Paz',
  timezoneGrandTurk: 'America/Grand_Turk',
  timezoneSantiago: 'America/Santiago',
  timezoneCayenne: 'America/Cayenne',
  timezoneAraguaina: 'America/Araguaina',
  timezoneGodthab: 'America/Godthab',
  timezoneMontevideo: 'America/Montevideo',
  timezoneMidAtlanticName: 'Mid-Atlantic Standard Time',
  timezoneNoronha: 'America/Noronha',
  timezoneAzores: 'Atlantic/Azores',
  timezoneCapeVerde: 'Atlantic/Cape_Verde',
  timezoneLondon: 'Europe/London',
  timezoneMonrovia: 'Africa/Monrovia',
  timezoneAbidjan: 'Africa/Abidjan',
  timezoneSaoTome: 'Africa/Sao_Tome',
  timezoneCasablanca: 'Africa/Casablanca',
  timezoneAmsterdam: 'Europe/Amsterdam',
  timezoneBudapest: 'Europe/Budapest',
  timezoneParis: 'Europe/Paris',
  timezoneWarsaw: 'Europe/Warsaw',
  timezoneLagos: 'Africa/Lagos',
  timezoneWindhoek: 'Africa/Windhoek',
  timezoneAthens: 'Europe/Athens',
  timezoneNicosia: 'Asia/Nicosia',
  timezoneBucharest: 'Europe/Bucharest',
  timezoneBeirut: 'Asia/Beirut',
  timezoneCairo: 'Africa/Cairo',
  timezoneDamascus: 'Asia/Damascus',
  timezoneChisinau: 'Europe/Chisinau',
  timezoneHelsinki: 'Europe/Helsinki',
  timezoneKiev: 'Europe/Kiev',
  timezoneMariehamn: 'Europe/Mariehamn',
  timezoneRiga: 'Europe/Riga',
  timezoneSofia: 'Europe/Sofia',
  timezoneTallinn: 'Europe/Tallinn',
  timezoneUzhgorod: 'Europe/Uzhgorod',
  timezoneVilnius: 'Europe/Vilnius',
  timezoneZaporozhye: 'Europe/Zaporozhye',
  timezoneHarare: 'Africa/Harare',
  timezoneJerusalem: 'Asia/Jerusalem',
  timezoneTripoli: 'Africa/Tripoli',
  timezoneAmman: 'Asia/Amman',
  timezoneMinsk: 'Europe/Minsk',
  timezoneKaliningrad: 'Europe/Kaliningrad',
  timezoneIstanbul: 'Europe/Istanbul',
  timezoneBaghdad: 'Asia/Baghdad',
  timezoneKuwait: 'Asia/Kuwait',
  timezoneNairobi: 'Africa/Nairobi',
  timezoneKhartoum: 'Africa/Khartoum',
  timezoneMoscow: 'Europe/Moscow',
  timezoneVolgograd: 'Europe/Volgograd',
  timezoneTehran: 'Asia/Tehran',
  timezoneMuscat: 'Asia/Muscat',
  timezoneMauritius: 'Indian/Mauritius',
  timezoneTbilisi: 'Asia/Tbilisi',
  timezoneYerevan: 'Asia/Yerevan',
  timezoneKabul: 'Asia/Kabul',
  timezoneTashkent: 'Asia/Tashkent',
  timezoneKarachi: 'Asia/Karachi',
  timezoneYekaterinburg: 'Asia/Yekaterinburg',
  timezoneKolkata: 'Asia/Kolkata',
  timezoneColombo: 'Asia/Colombo',
  timezoneDhaka: 'Asia/Dhaka',
  timezoneQyzylorda: 'Asia/Qyzylorda',
  timezoneRangoon: 'Asia/Rangoon',
  timezoneBangkok: 'Asia/Bangkok',
  timezoneNovosibirsk: 'Asia/Novosibirsk',
  timezoneBarnaul: 'Asia/Barnaul',
  timezoneKrasnoyarsk: 'Asia/Krasnoyarsk',
  timezoneHongKong: 'Asia/Hong_Kong',
  timezoneKualaLumpur: 'Asia/Kuala_Lumpur',
  timezonePerth: 'Australia/Perth',
  timezoneTaipei: 'Asia/Taipei',
  timezoneUlaanbaatar: 'Asia/Ulaanbaatar',
  timezoneIrkutsk: 'Asia/Irkutsk',
  timezoneEucla: 'Australia/Eucla',
  timezoneTokyo: 'Asia/Tokyo',
  timezoneSeoul: 'Asia/Seoul',
  timezonePyongyang: 'Asia/Pyongyang',
  timezoneYakutsk: 'Asia/Yakutsk',
  timezoneChita: 'Asia/Chita',
  timezoneAdelaide: 'Australia/Adelaide',
  timezoneDarwin: 'Australia/Darwin',
  timezoneBrisbane: 'Australia/Brisbane',
  timezoneMelbourne: 'Australia/Melbourne',
  timezonePortMoresby: 'Pacific/Port_Moresby',
  timezoneHobart: 'Australia/Hobart',
  timezoneVladivostok: 'Asia/Vladivostok',
  timezoneSakhalin: 'Asia/Sakhalin',
  timezoneLordHowe: 'Australia/Lord_Howe',
  timezoneGuadalcanal: 'Pacific/Guadalcanal',
  timezoneMagadan: 'Asia/Magadan',
  timezoneKamchatka: 'Asia/Kamchatka',
  timezoneSrednekolymsk: 'Asia/Srednekolymsk',
  timezoneAuckland: 'Pacific/Auckland',
  timezoneKwajalein: 'Pacific/Kwajalein',
  timezoneFiji: 'Pacific/Fiji',
  timezoneKamchatkaStandardName: 'Kamchatka Standard Time',
  timezoneChatham: 'Pacific/Chatham',
  timezoneTongatapu: 'Pacific/Tongatapu',
  timezoneEtcGmt13: 'Etc/GMT-13',
  timezoneApia: 'Pacific/Apia',
  timezoneKiritimati: 'Pacific/Kiritimati',
  standardCodeAst: '(UTC+04:00) AST',
  dateFormat: 'MMM DD YYYY',
  dateFormat_dd: 'MMM dd yyyy'
};

const FILE_STATUS = {
  PARSING_COMPLETED: 'PARSING_COMPLETED',
  DSZ_EXTRACTION_SUCCESS: 'DSZ_EXTRACTION_SUCCESS',
  DSZ_EXTRACTION_FAILURE: 'DSZ_EXTRACTION_FAILURE'
};

export const ERROR_MESSAGES = {
  SPECIAL_CHAR_NOT_ALLOWED: 'Special charatcer not allowed',
  PLEASE_ENTER_MIN_CHAR: 'Please enter minimum ',
  CHAR: ' characters',
  PLEASE_ENTER_VALUES_BETWEEN: 'Please enter values between',
  AND: ' and '
};

export const UtilsConstants = {
  STRING: 'string'
};

export enum UNITTEXT {
  Imperial = 'Tons',
  Metric = 'Tonne',
  'SI Units' = 'Tonne',
  usStandard = 'US Tons',
  usCustomary = 'US Tons'
}

export enum UNIT_TRANSLATION {
  'Imperial' = 'WIDGETS_UTILS_PREF_UNIT_IMPERIAL',
  'Metric' = 'WIDGETS_UTILS_PREF_UNIT_METRIC',
  'SI Units' = 'WIDGETS_UTILS_PREF_UNIT_METRIC',
  usStandard = 'WIDGETS_UTILS_PREF_UNIT_US_STD',
  usCustomary = 'WIDGETS_UTILS_PREF_UNIT_US_STD'
}

export enum UNIT {
  Imperial = 'Imperial',
  Metric = 'Metric',
  'SI Units' = 'SI Units',
  usStandard = 'US Standard',
  usCustomary = 'U.S Customary'
}

export const MT_TO_PREF = {
  WeightMetric: 'US Tons',
  FuelMetric: 'gal',
  literToGallon: 0.264172,
  Imperial: 0.98420652761106,
  Metric: 1,
  'SI Units': 1,
  'US Standard': 1.1023113109244,
  [usCustomary]: 1.1023113109244,
  KmToMiles: 0.621371,
  MeterPerSecToKilometerPerHr: 3.6,
  MeterPerSecToMilesPerHr: 2.237,
  MilesToKm: 1.60934,
  TonnesPerLitreToTonsPerGal: 4.1727,
  kgToPound: 2.20462
};

export const PREF_TO_MT = {
  Imperial: 1.0160469088,
  Metric: 1,
  'SI Units': 1,
  [configStrings.usStandard]: 0.90718474,
  [configStrings.usCustomary]: 0.90718474
};

export const PREF_TO_CUBIC_METER = {
  'SI Units': 1,
  [configStrings.usCustomary]: 0.7645552200007646
};

export const CUBIC_METER_TO_PREF = {
  'SI Units': 1,
  [configStrings.usCustomary]: 1.30795
};

export const HEAVYCONSTRUCTION = '08972290-DAF3-E911-B5E9-501AC51B1F7D';

export const TRANSLATION_LOCALE_MAPPING = {
  en: 'en-US',
  da: 'da-DK',
  fr: 'fr-FR',
  it: 'it-IT',
  is: 'is-IS',
  no: 'nb-NO',
  de: 'de-DE',
  pt: 'pt-PT',
  rw: 'rw-RW',
  'sv-FI': 'sv-FI',
  'sv-SE': 'sv-SE'
};

export const UNIVERSAL_TIME_ZONE = [
  {
    id: 1,
    displayName: '(UTC-12:00) International Date Line West',
    translation: 'TIMEZONE_DATELINE_STANDARD_TIME',
    standardName: 'Dateline Standard Time',
    timezone: configStrings.timezoneEtcGmt12,
    utc: [configStrings.timezoneEtcGmt12],
    standardCode: 'DST',
    daylightCode: 'DDT'
  },
  {
    id: 2,
    displayName: '(UTC-11:00) Coordinated Universal Time-11',
    translation: 'TIMEZONE_COORDINATED_UNIVERSAL_TIME_11',
    standardName: 'UTC-11',
    timezone: configStrings.timezonePago,
    utc: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', configStrings.timezonePago],
    standardCode: '',
    daylightCode: 'UTC-11'
  },
  {
    id: 3,
    displayName: '(UTC-10:00) Hawaii',
    translation: 'TIMEZONE_HAWAII',
    standardName: 'Hawaiian Standard Time',
    timezone: configStrings.timezoneEtcGmt10,
    utc: [configStrings.timezoneEtcGmt10, 'Pacific/Honolulu', 'Pacific/Johnston', 'Pacific/Rarotonga', 'Pacific/Tahiti'],
    standardCode: 'HST',
    daylightCode: 'HDT'
  },
  {
    id: 102,
    displayName: '(UTC-09:30) Marquesas',
    translation: 'TIMEZONE_MARQUESAS',
    standardName: 'Marquesas Standard Time',
    timezone: configStrings.timezoneMarquesas,
    utc: [configStrings.timezoneMarquesas],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 4,
    displayName: '(UTC-09:00) Alaska',
    translation: 'TIMEZONE_ALASKA',
    standardName: 'Alaskan Standard Time',
    timezone: configStrings.timezoneAnchorage,
    utc: [configStrings.timezoneAnchorage, 'America/Juneau', 'America/Nome', 'America/Sitka', 'America/Yakutat'],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 5,
    displayName: '(UTC-08:00) Baja California',
    translation: 'TIMEZONE_PACIFIC_MEXICO',
    standardName: 'Pacific Standard Time (Mexico)',
    timezone: configStrings.timezoneDawson,
    utc: ['America/Santa_Isabel'],
    standardCode: 'PST',
    daylightCode: 'PDT'
  },
  {
    id: 6,
    displayName: '(UTC-08:00) Pacific Time (US & Canada)',
    translation: 'TIMEZONE_PACIFIC_US_CANADA',
    standardName: 'Pacific Standard Time',
    timezone: configStrings.timezoneDawson,
    utc: [
      configStrings.timezoneDawson,
      'America/Los_Angeles',
      configStrings.timezoneTijuana,
      'America/Vancouver',
      'America/Whitehorse',
      'PST8PDT'
    ],
    standardCode: 'PST',
    daylightCode: 'PDT'
  },
  {
    id: 7,
    displayName: '(UTC-07:00) Arizona',
    translation: 'TIMEZONE_ARIZONA',
    standardName: 'US Mountain Standard Time',
    timezone: configStrings.timezoneCreston,
    utc: [configStrings.timezoneCreston, 'America/Dawson_Creek', 'America/Hermosillo', 'America/Phoenix', 'Etc/GMT+7'],
    standardCode: 'UMST',
    daylightCode: 'UMDT'
  },
  {
    id: 8,
    displayName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    translation: 'TIMEZONE_MOUNTAIN_STD_MEXICO',
    standardName: 'Mountain Standard Time (Mexico)',
    timezone: configStrings.timezoneChihuahua,
    utc: [configStrings.timezoneChihuahua, 'America/Mazatlan'],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 9,
    displayName: '(UTC-07:00) Mountain Time (US & Canada)',
    translation: 'TIMEZONE_MOUNTAIN_STD_US_CANADA',
    standardName: 'Mountain Standard Time',
    timezone: 'US/Mountain',
    utc: [
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Denver',
      'America/Edmonton',
      'America/Inuvik',
      'America/Ojinaga',
      'America/Yellowknife',
      'MST7MDT'
    ],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 10,
    displayName: '(UTC-06:00) Central America',
    translation: 'TIMEZONE_CENTRAL_AMERICA',
    standardName: 'Central America Standard Time',
    timezone: configStrings.timezoneManagua,
    utc: [
      'America/Belize',
      'America/Costa_Rica',
      'America/El_Salvador',
      'America/Guatemala',
      configStrings.timezoneManagua,
      'America/Tegucigalpa',
      'Etc/GMT+6',
      'Pacific/Galapagos'
    ],
    standardCode: 'CAST',
    daylightCode: 'CADT'
  },
  {
    id: 11,
    displayName: '(UTC-06:00) Central Time (US and Canada)',
    translation: 'TIMEZONE_CENTRAL_US_CANADA',
    standardName: 'Central Standard Time',
    timezone: 'US/Central',
    utc: [
      'America/Chicago',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Matamoros',
      'America/Menominee',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
      'CST6CDT'
    ],
    standardCode: 'CST',
    daylightCode: 'CDT'
  },
  {
    id: 12,
    displayName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    translation: 'TIMEZONE_CENTRAL_STD_MEXICO',
    standardName: 'Central Standard Time (Mexico)',
    timezone: configStrings.timezoneMexico_City,
    utc: ['America/Bahia_Banderas', configStrings.timezoneCancun, 'America/Merida', configStrings.timezoneMexico_City, 'America/Monterrey'],
    standardCode: 'CST',
    daylightCode: 'CDT'
  },
  {
    id: 13,
    displayName: '(UTC-06:00) Saskatchewan',
    translation: 'TIMEZONE_SASKATCHEWAN',
    standardName: 'Canada Central Standard Time',
    timezone: 'Canada/Saskatchewan',
    utc: ['America/Regina', 'America/Swift_Current'],
    standardCode: 'CCST',
    daylightCode: 'CCDT'
  },
  {
    id: 14,
    displayName: '(UTC-05:00) Bogota, Lima, Quito',
    translation: 'TIMEZONE_SA_PACIFIC',
    standardName: 'SA Pacific Standard Time',
    timezone: configStrings.timezoneLima,
    utc: [
      'America/Bogota',
      'America/Cayman',
      'America/Coral_Harbour',
      'America/Eirunepe',
      'America/Guayaquil',
      'America/Jamaica',
      configStrings.timezoneLima,
      'America/Panama',
      'America/Rio_Branco',
      'Etc/GMT+5'
    ],
    standardCode: 'SPST',
    daylightCode: 'SPDT'
  },
  {
    id: 16,
    displayName: '(UTC-05:00) Indiana (East)',
    translation: 'TIMEZONE_INDIANA',
    standardName: 'US Eastern Standard Time',
    timezone: 'US/East-Indiana',
    utc: ['America/Indiana/Marengo', 'America/Indiana/Vevay', 'America/Indianapolis'],
    standardCode: 'UEST',
    daylightCode: 'UEDT'
  },
  {
    id: 15,
    displayName: '(UTC-04:00) Eastern Time (US & Canada)',
    translation: 'TIMEZONE_EASTERN_STD',
    standardName: 'Eastern Standard Time',
    timezone: 'US/Eastern',
    utc: [
      'America/Detroit',
      configStrings.timezoneHavana,
      'America/Indiana/Petersburg',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Iqaluit',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      configStrings.timezonePortauPrince,
      'America/Thunder_Bay',
      'America/Toronto',
      'America/Indiana/Indianapolis',
      'EST5EDT'
    ],
    standardCode: 'EST',
    daylightCode: 'EDT'
  },
  {
    id: 17,
    displayName: '(UTC-04:00) Caracas',
    translation: 'TIMEZONE_CARACAS',
    standardName: 'Venezuela Standard Time',
    timezone: configStrings.timezoneCaracas,
    utc: [configStrings.timezoneCaracas],
    standardCode: 'VST',
    daylightCode: 'VDT'
  },
  {
    id: 18,
    displayName: '(UTC-04:00) Asuncion',
    translation: 'TIMEZONE_ASUNCION',
    standardName: 'Paraguay Standard Time',
    timezone: configStrings.timezoneAsuncion,
    utc: [configStrings.timezoneAsuncion],
    standardCode: 'PST',
    daylightCode: 'PDT'
  },
  {
    id: 19,
    displayName: '(UTC-04:00) Atlantic Time (Canada)',
    translation: 'TIMEZONE_ATLANTIC_CANADA',
    standardName: 'Atlantic Standard Time',
    timezone: 'Canada/Atlantic',
    utc: ['America/Glace_Bay', 'America/Goose_Bay', 'America/Halifax', 'America/Moncton', 'America/Thule', 'Atlantic/Bermuda'],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 20,
    displayName: '(UTC-04:00) Cuiaba',
    translation: 'TIMEZONE_CUIABA',
    standardName: 'Central Brazilian Standard Time',
    timezone: configStrings.timezoneSaoPaulo,
    utc: ['America/Campo_Grande', 'America/Cuiaba'],
    standardCode: 'CBST',
    daylightCode: 'CBDT'
  },
  {
    id: 21,
    displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    translation: 'TIMEZONE_SA_WESTERN_STD',
    standardName: 'SA Western Standard Time',
    timezone: configStrings.timezoneLaPaz,
    utc: [
      'America/Anguilla',
      'America/Antigua',
      'America/Aruba',
      'America/Barbados',
      'America/Blanc-Sablon',
      'America/Boa_Vista',
      'America/Curacao',
      'America/Dominica',
      configStrings.timezoneGrandTurk,
      'America/Grenada',
      'America/Guadeloupe',
      'America/Guyana',
      'America/Kralendijk',
      configStrings.timezoneLaPaz,
      'America/Lower_Princes',
      'America/Manaus',
      'America/Marigot',
      'America/Martinique',
      'America/Montserrat',
      'America/Port_of_Spain',
      'America/Porto_Velho',
      'America/Puerto_Rico',
      'America/Santo_Domingo',
      'America/St_Barthelemy',
      'America/St_Kitts',
      'America/St_Lucia',
      'America/St_Thomas',
      'America/St_Vincent',
      'America/Tortola',
      'Etc/GMT+4'
    ],
    standardCode: 'SWST',
    daylightCode: 'SWDT'
  },
  {
    id: 22,
    displayName: '(UTC-04:00) Santiago',
    translation: 'TIMEZONE_SANTIAGO',
    standardName: 'Pacific SA Standard Time',
    timezone: configStrings.timezoneSantiago,
    utc: [configStrings.timezoneSantiago, 'Antarctica/Palmer'],
    standardCode: 'PSST',
    daylightCode: 'PSDT'
  },
  {
    id: 23,
    displayName: '(UTC-03:30) Newfoundland',
    translation: 'TIMEZONE_NEWFOUNDLAND',
    standardName: 'Newfoundland Standard Time',
    timezone: 'Canada/Newfoundland',
    utc: ['America/St_Johns'],
    standardCode: 'NST',
    daylightCode: 'NDT'
  },
  {
    id: 24,
    displayName: '(UTC-03:00) Brasilia',
    translation: 'TIMEZONE_BRASILIA',
    standardName: 'E. South America Standard Time',
    timezone: configStrings.timezoneSaoPaulo,
    utc: [configStrings.timezoneSaoPaulo],
    standardCode: 'ESAST',
    daylightCode: 'ESADT'
  },
  {
    id: 25,
    displayName: '(UTC-03:00) Buenos Aires',
    translation: 'TIMEZONE_ARGENTINA',
    standardName: 'Argentina Standard Time',
    timezone: 'America/Argentina/Buenos_Aires',
    utc: [
      'America/Argentina/La_Rioja',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
      'America/Buenos_Aires',
      'America/Catamarca',
      'America/Cordoba',
      'America/Jujuy',
      'America/Mendoza'
    ],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 26,
    displayName: '(UTC-03:00) Cayenne, Fortaleza',
    translation: 'TIMEZONE_SA_EASTERN',
    standardName: 'SA Eastern Standard Time',
    timezone: configStrings.timezoneCayenne,
    utc: [
      configStrings.timezoneAraguaina,
      'America/Belem',
      configStrings.timezoneCayenne,
      'America/Fortaleza',
      'America/Maceio',
      'America/Paramaribo',
      'America/Recife',
      'America/Santarem',
      'Antarctica/Rothera',
      'Atlantic/Stanley',
      'Etc/GMT+3'
    ],
    standardCode: 'SEST',
    daylightCode: 'SEDT'
  },
  {
    id: 27,
    displayName: '(UTC-03:00) Greenland',
    translation: 'TIMEZONE_GREENLAND',
    standardName: 'Greenland Standard Time',
    timezone: configStrings.timezoneGodthab,
    utc: [configStrings.timezoneGodthab],
    standardCode: 'GST',
    daylightCode: 'GDT'
  },
  {
    id: 28,
    displayName: '(UTC-03:00) Montevideo',
    translation: 'TIMEZONE_MONTEVIDEO',
    standardName: 'Montevideo Standard Time',
    timezone: configStrings.timezoneMontevideo,
    utc: [configStrings.timezoneMontevideo],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 29,
    displayName: '(UTC-03:00) Salvador',
    translation: 'TIMEZONE_SALVADOR',
    standardName: 'Bahia Standard Time',
    timezone: configStrings.timezoneMontevideo,
    utc: ['America/Bahia'],
    standardCode: 'BST',
    daylightCode: 'BDT'
  },
  {
    id: 30,
    displayName: '(UTC-02:00) Coordinated Universal Time-02',
    translation: 'TIMEZONE_COORDINATED_UNIVERSAL_TIME_02',
    standardName: 'UTC-02',
    timezone: configStrings.timezoneGodthab,
    utc: ['Atlantic/South_Georgia', 'Etc/GMT+2'],
    standardCode: '',
    daylightCode: 'UTC-02'
  },
  {
    id: 31,
    displayName: '(UTC-02:00) Mid-Atlantic - Old',
    translation: 'TIMEZONE_MID_ATLANTIC',
    standardName: configStrings.timezoneMidAtlanticName,
    timezone: configStrings.timezoneNoronha,
    utc: [],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 32,
    displayName: '(UTC-01:00) Azores',
    translation: 'TIMEZONE_AZORES',
    standardName: 'Azores Standard Time',
    timezone: configStrings.timezoneAzores,
    utc: ['America/Scoresbysund', configStrings.timezoneAzores],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 33,
    displayName: '(UTC-01:00) Cape Verde Is.',
    translation: 'TIMEZONE_CAPE_VERDE',
    standardName: 'Cape Verde Standard Time',
    timezone: configStrings.timezoneCapeVerde,
    utc: [configStrings.timezoneCapeVerde, 'Etc/GMT+1'],
    standardCode: 'CVST',
    daylightCode: 'CVDT'
  },
  {
    id: 35,
    displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    translation: 'TIMEZONE_GMT_STD',
    standardName: 'GMT Standard Time',
    timezone: configStrings.timezoneLondon,
    utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/Dublin', configStrings.timezoneLondon],
    standardCode: 'GST',
    daylightCode: 'GDT'
  },
  {
    id: 36,
    displayName: '(UTC+00:00) Monrovia, Reykjavik',
    translation: 'TIMEZONE_GREENWICH_STD',
    standardName: 'Greenwich Standard Time',
    timezone: configStrings.timezoneMonrovia,
    utc: [
      configStrings.timezoneAbidjan,
      'Africa/Accra',
      'Africa/Bamako',
      'Africa/Banjul',
      'Africa/Bissau',
      'Africa/Conakry',
      'Africa/Dakar',
      'Africa/Freetown',
      'Africa/Lome',
      configStrings.timezoneMonrovia,
      'Africa/Nouakchott',
      'Africa/Ouagadougou',
      configStrings.timezoneSaoTome,
      'Atlantic/Reykjavik',
      'Atlantic/St_Helena'
    ],
    standardCode: 'GST',
    daylightCode: 'GDT'
  },
  {
    id: 34,
    displayName: '(UTC+01:00) Casablanca',
    translation: 'TIMEZONE_CASABLANCA',
    standardName: 'Morocco Standard Time',
    timezone: configStrings.timezoneCasablanca,
    utc: [configStrings.timezoneCasablanca, 'Africa/El_Aaiun'],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 37,
    displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    translation: 'TIMEZONE_W_EURO_STD',
    standardName: 'W. Europe Standard Time',
    timezone: configStrings.timezoneAmsterdam,
    utc: [
      'Arctic/Longyearbyen',
      configStrings.timezoneAmsterdam,
      'Europe/Andorra',
      'Europe/Berlin',
      'Europe/Busingen',
      'Europe/Gibraltar',
      'Europe/Luxembourg',
      'Europe/Malta',
      'Europe/Monaco',
      'Europe/Oslo',
      'Europe/Rome',
      'Europe/San_Marino',
      'Europe/Stockholm',
      'Europe/Vaduz',
      'Europe/Vatican',
      'Europe/Vienna',
      'Europe/Zurich'
    ],
    standardCode: 'WEST',
    daylightCode: 'WEDT'
  },
  {
    id: 38,
    displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    translation: 'TIMEZONE_CENTRAL_EURO_STD_BUDAPEST',
    standardName: 'Central Europe Standard Time',
    timezone: configStrings.timezoneBudapest,
    utc: [
      'Europe/Belgrade',
      'Europe/Bratislava',
      configStrings.timezoneBudapest,
      'Europe/Ljubljana',
      'Europe/Podgorica',
      'Europe/Prague',
      'Europe/Tirane'
    ],
    standardCode: 'CEST',
    daylightCode: 'CEDT'
  },
  {
    id: 39,
    displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    translation: 'TIMEZONE_ROMANCE_STD',
    standardName: 'Romance Standard Time',
    timezone: configStrings.timezoneParis,
    utc: ['Africa/Ceuta', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', configStrings.timezoneParis],
    standardCode: 'RST',
    daylightCode: 'RDT'
  },
  {
    id: 40,
    displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    translation: 'TIMEZONE_CENTRAL_EURO_STD_WARSAW',
    standardName: 'Central European Standard Time',
    timezone: configStrings.timezoneWarsaw,
    utc: ['Europe/Sarajevo', 'Europe/Skopje', configStrings.timezoneWarsaw, 'Europe/Zagreb'],
    standardCode: 'CEST',
    daylightCode: 'CEDT'
  },
  {
    id: 41,
    displayName: '(UTC+01:00) West Central Africa',
    translation: 'TIMEZONE_WEST_CNTRL_AFTRICA',
    standardName: 'W. Central Africa Standard Time',
    timezone: configStrings.timezoneLagos,
    utc: [
      'Africa/Algiers',
      'Africa/Bangui',
      'Africa/Brazzaville',
      'Africa/Douala',
      'Africa/Kinshasa',
      configStrings.timezoneLagos,
      'Africa/Libreville',
      'Africa/Luanda',
      'Africa/Malabo',
      'Africa/Ndjamena',
      'Africa/Niamey',
      'Africa/Porto-Novo',
      'Africa/Tunis',
      'Etc/GMT-1'
    ],
    standardCode: 'WCAST',
    daylightCode: 'WCADT'
  },
  {
    id: 42,
    displayName: '(UTC+02:00) Windhoek',
    translation: 'TIMEZONE_WINDHOEK',
    standardName: 'Namibia Standard Time',
    timezone: configStrings.timezoneWindhoek,
    utc: [configStrings.timezoneWindhoek],
    standardCode: 'NST',
    daylightCode: 'NDT'
  },
  {
    id: 43,
    displayName: '(UTC+02:00) Athens, Bucharest',
    translation: 'TIMEZONE_GTB_STD',
    standardName: 'GTB Standard Time',
    timezone: configStrings.timezoneAthens,
    utc: [configStrings.timezoneNicosia, configStrings.timezoneAthens, configStrings.timezoneBucharest],
    standardCode: 'GST',
    daylightCode: 'GDT'
  },
  {
    id: 44,
    displayName: '(UTC+02:00) Beirut',
    translation: 'TIMEZONE_BEIRUT',
    standardName: 'Middle East Standard Time',
    timezone: configStrings.timezoneBeirut,
    utc: [configStrings.timezoneBeirut],
    standardCode: 'MEST',
    daylightCode: 'MEDT'
  },
  {
    id: 45,
    displayName: '(UTC+02:00) Cairo',
    translation: 'TIMEZONE_CAIRO',
    standardName: 'Egypt Standard Time',
    timezone: configStrings.timezoneCairo,
    utc: [configStrings.timezoneCairo],
    standardCode: 'EST',
    daylightCode: 'EDT'
  },
  {
    id: 46,
    displayName: '(UTC+02:00) Damascus',
    translation: 'TIMEZONE_DAMASCUS',
    standardName: 'Syria Standard Time',
    timezone: configStrings.timezoneDamascus,
    utc: [configStrings.timezoneDamascus],
    standardCode: 'SST',
    daylightCode: 'SDT'
  },
  {
    id: 47,
    displayName: '(UTC+02:00) E. Europe',
    translation: 'TIMEZONE_EAST_EURO',
    standardName: 'E. Europe Standard Time',
    timezone: configStrings.timezoneAthens,
    utc: [
      configStrings.timezoneNicosia,
      configStrings.timezoneAthens,
      configStrings.timezoneBucharest,
      configStrings.timezoneChisinau,
      configStrings.timezoneHelsinki,
      configStrings.timezoneKiev,
      configStrings.timezoneMariehamn,
      'Europe/Nicosia',
      configStrings.timezoneRiga,
      configStrings.timezoneSofia,
      configStrings.timezoneTallinn,
      configStrings.timezoneUzhgorod,
      configStrings.timezoneVilnius,
      configStrings.timezoneZaporozhye
    ],
    standardCode: 'EEST',
    daylightCode: 'EEDT'
  },
  {
    id: 48,
    displayName: '(UTC+02:00) Harare, Pretoria',
    translation: 'TIMEZONE_SA_STD',
    standardName: 'South Africa Standard Time',
    timezone: configStrings.timezoneHarare,
    utc: [
      'Africa/Blantyre',
      'Africa/Bujumbura',
      'Africa/Gaborone',
      configStrings.timezoneHarare,
      'Africa/Johannesburg',
      'Africa/Kigali',
      'Africa/Lubumbashi',
      'Africa/Lusaka',
      'Africa/Maputo',
      'Africa/Maseru',
      'Africa/Mbabane',
      'Etc/GMT-2'
    ],
    standardCode: 'SAST',
    daylightCode: 'SADT'
  },
  {
    id: 49,
    displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    translation: 'TIMEZONE_FLE_STD',
    standardName: 'FLE Standard Time',
    timezone: configStrings.timezoneHelsinki,
    utc: [
      configStrings.timezoneHelsinki,
      configStrings.timezoneKiev,
      configStrings.timezoneMariehamn,
      configStrings.timezoneRiga,
      configStrings.timezoneSofia,
      configStrings.timezoneTallinn,
      configStrings.timezoneUzhgorod,
      configStrings.timezoneVilnius,
      configStrings.timezoneZaporozhye
    ],
    standardCode: 'FST',
    daylightCode: 'FDT'
  },
  {
    id: 51,
    displayName: '(UTC+02:00) Jerusalem',
    translation: 'TIMEZONE_JERISALEM',
    standardName: 'Israel Standard Time',
    timezone: configStrings.timezoneJerusalem,
    utc: [configStrings.timezoneJerusalem],
    standardCode: 'IST',
    daylightCode: 'JDT'
  },
  {
    id: 52,
    displayName: '(UTC+02:00) Tripoli',
    translation: 'TIMEZONE_TRIPOLI',
    standardName: 'Libya Standard Time',
    timezone: configStrings.timezoneTripoli,
    utc: [configStrings.timezoneTripoli],
    standardCode: 'LST',
    daylightCode: 'LDT'
  },
  {
    id: 53,
    displayName: '(UTC+02:00) Amman',
    translation: 'TIMEZONE_JORDAN_STD',
    standardName: 'Jordan Standard Time',
    timezone: configStrings.timezoneAmman,
    utc: [configStrings.timezoneAmman],
    standardCode: 'JST',
    daylightCode: 'JDT'
  },
  {
    id: 55,
    displayName: '(UTC+02:00) Kaliningrad, Minsk',
    translation: 'TIMEZONE_KALININGRAND',
    standardName: 'Kaliningrad Standard Time',
    timezone: configStrings.timezoneMinsk,
    utc: [configStrings.timezoneKaliningrad],
    standardCode: 'KST',
    daylightCode: 'RT1DT'
  },
  {
    id: 50,
    displayName: '(UTC+03:00) Istanbul',
    translation: 'TIMEZONE_ISTANBUL',
    standardName: 'Turkey Standard Time',
    timezone: configStrings.timezoneIstanbul,
    utc: [configStrings.timezoneIstanbul],
    standardCode: 'TST',
    daylightCode: 'TDT'
  },
  {
    id: 54,
    displayName: '(UTC+03:00) Baghdad',
    translation: 'TIMEZONE_BAGHDAD',
    standardName: 'Arabic Standard Time',
    timezone: configStrings.timezoneBaghdad,
    utc: [configStrings.timezoneBaghdad],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 56,
    displayName: '(UTC+03:00) Kuwait, Riyadh',
    translation: 'TIMEZONE_ARAB',
    standardName: 'Arab Standard Time',
    timezone: configStrings.timezoneKuwait,
    utc: ['Asia/Aden', 'Asia/Bahrain', configStrings.timezoneKuwait, 'Asia/Qatar', 'Asia/Riyadh'],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 57,
    displayName: '(UTC+03:00) Nairobi',
    translation: 'TIMEZONE_EAST_AFRICA_STD',
    standardName: 'E. Africa Standard Time',
    timezone: configStrings.timezoneNairobi,
    utc: [
      'Africa/Addis_Ababa',
      'Africa/Asmera',
      'Africa/Dar_es_Salaam',
      'Africa/Djibouti',
      'Africa/Juba',
      'Africa/Kampala',
      configStrings.timezoneKhartoum,
      'Africa/Mogadishu',
      configStrings.timezoneNairobi,
      'Antarctica/Syowa',
      'Etc/GMT-3',
      'Indian/Antananarivo',
      'Indian/Comoro',
      'Indian/Mayotte'
    ],
    standardCode: 'EAST',
    daylightCode: 'EADT'
  },
  {
    id: 61,
    displayName: '(UTC+03:00) Moscow, St. Petersburg, Volgograd',
    translation: 'TIMEZONE_RUSSIAN_STD',
    standardName: 'Russian Standard Time',
    timezone: configStrings.timezoneMoscow,
    utc: ['Europe/Kirov', configStrings.timezoneMoscow, 'Europe/Simferopol', configStrings.timezoneVolgograd],
    standardCode: 'RST',
    daylightCode: 'RT2DT'
  },
  {
    id: 58,
    displayName: '(UTC+03:30) Tehran',
    translation: 'TIMEZONE_IRAN_STD',
    standardName: 'Iran Standard Time',
    timezone: configStrings.timezoneTehran,
    utc: [configStrings.timezoneTehran],
    standardCode: 'IST',
    daylightCode: 'IDT'
  },
  {
    id: 59,
    displayName: '(UTC+04:00) Abu Dhabi, Muscat',
    translation: 'TIMEZONE_ARABIAN_STD',
    standardName: 'Arabian Standard Time',
    timezone: configStrings.timezoneMuscat,
    utc: ['Asia/Dubai', configStrings.timezoneMuscat, 'Etc/GMT-4'],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 60,
    displayName: '(UTC+04:00) Baku',
    translation: 'TIMEZONE_AZERBAIJAN_STD',
    standardName: 'Azerbaijan Standard Time',
    timezone: 'Asia/Baku',
    utc: ['Asia/Baku'],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 62,
    displayName: '(UTC+04:00) Port Louis',
    translation: 'TIMEZONE_MAURITIUS_STD',
    standardName: 'Mauritius Standard Time',
    timezone: configStrings.timezoneMauritius,
    utc: ['Indian/Mahe', configStrings.timezoneMauritius, 'Indian/Reunion'],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 63,
    displayName: '(UTC+04:00) Tbilisi',
    translation: 'TIMEZONE_GEORGIAN_STD',
    standardName: 'Georgian Standard Time',
    timezone: configStrings.timezoneTbilisi,
    utc: [configStrings.timezoneTbilisi],
    standardCode: 'GST',
    daylightCode: 'GDT'
  },
  {
    id: 64,
    displayName: '(UTC+04:00) Yerevan',
    translation: 'TIMEZONE_CAUCASUS_STD',
    standardName: 'Caucasus Standard Time',
    timezone: configStrings.timezoneYerevan,
    utc: [configStrings.timezoneYerevan],
    standardCode: 'CST',
    daylightCode: 'CDT'
  },
  {
    id: 65,
    displayName: '(UTC+04:30) Kabul',
    translation: 'TIMEZONE_AFGHANISTAN_STD',
    standardName: 'Afghanistan Standard Time',
    timezone: configStrings.timezoneKabul,
    utc: [configStrings.timezoneKabul],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 66,
    displayName: '(UTC+05:00) Ashgabat, Tashkent',
    translation: 'TIMEZONE_WEST_ASIA_STD',
    standardName: 'West Asia Standard Time',
    timezone: configStrings.timezoneTashkent,
    utc: [
      'Antarctica/Mawson',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Ashgabat',
      'Asia/Dushanbe',
      'Asia/Oral',
      'Asia/Samarkand',
      configStrings.timezoneTashkent,
      'Etc/GMT-5',
      'Indian/Kerguelen',
      'Indian/Maldives'
    ],
    standardCode: 'WAST',
    daylightCode: 'WADT'
  },
  {
    id: 67,
    displayName: '(UTC+05:00) Islamabad, Karachi',
    translation: 'TIMEZONE_PAKISTAN_STD',
    standardName: 'Pakistan Standard Time',
    timezone: configStrings.timezoneKarachi,
    utc: [configStrings.timezoneKarachi],
    standardCode: 'PST',
    daylightCode: 'PDT'
  },
  {
    id: 73,
    displayName: '(UTC+05:00) Ekaterinburg',
    translation: 'TIMEZONE_EKATERINBURG_STD',
    standardName: 'Ekaterinburg Standard Time',
    timezone: configStrings.timezoneYekaterinburg,
    utc: [configStrings.timezoneYekaterinburg],
    standardCode: 'EST',
    daylightCode: 'RT4DT'
  },
  {
    id: 68,
    displayName: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    translation: 'TIMEZONE_INDIAN_STD',
    standardName: 'India Standard Time',
    timezone: configStrings.timezoneKolkata,
    utc: [configStrings.timezoneKolkata, 'Asia/Calcutta'],
    standardCode: 'IST',
    daylightCode: 'IDT'
  },
  {
    id: 69,
    displayName: '(UTC+05:30) Sri Jayawardenepura',
    translation: 'TIMEZONE_SRI_LANKA_STD',
    standardName: 'Sri Lanka Standard Time',
    timezone: configStrings.timezoneColombo,
    utc: [configStrings.timezoneColombo],
    standardCode: 'SLST',
    daylightCode: 'SLDT'
  },
  {
    id: 70,
    displayName: '(UTC+05:45) Kathmandu',
    translation: 'TIMEZONE_NEPAL_STD',
    standardName: 'Nepal Standard Time',
    timezone: 'Asia/Katmandu',
    utc: ['Asia/Kathmandu'],
    standardCode: 'NST',
    daylightCode: 'NDT'
  },
  {
    id: 71,
    displayName: '(UTC+06:00) Astana',
    translation: 'TIMEZONE_CENTRAL_ASIA_STD',
    standardName: 'Central Asia Standard Time',
    timezone: configStrings.timezoneDhaka,
    utc: ['Antarctica/Vostok', 'Asia/Almaty', 'Asia/Bishkek', configStrings.timezoneQyzylorda, 'Asia/Urumqi', 'Etc/GMT-6', 'Indian/Chagos'],
    standardCode: 'CAST',
    daylightCode: 'CADT'
  },
  {
    id: 72,
    displayName: '(UTC+06:00) Dhaka',
    translation: 'TIMEZONE_BANGLADESH_STD',
    standardName: 'Bangladesh Standard Time',
    timezone: configStrings.timezoneDhaka,
    utc: [configStrings.timezoneDhaka, 'Asia/Thimphu'],
    standardCode: 'BST',
    daylightCode: 'BDT'
  },
  {
    id: 103,
    displayName: '(UTC+06:00) Omsk',
    translation: 'TIMEZONE_OMSK_STD',
    standardName: 'Omsk Standard Time',
    timezone: 'Asia/Omsk',
    utc: ['Asia/Omsk'],
    standardCode: 'OST',
    daylightCode: 'ODT'
  },
  {
    id: 74,
    displayName: '(UTC+06:30) Yangon (Rangoon)',
    translation: 'TIMEZONE_MYANMAR_STD',
    standardName: 'Myanmar Standard Time',
    timezone: configStrings.timezoneRangoon,
    utc: [configStrings.timezoneRangoon, 'Indian/Cocos'],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 75,
    displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    translation: 'TIMEZONE_SE_ASIA_STD',
    standardName: 'SE Asia Standard Time',
    timezone: configStrings.timezoneBangkok,
    utc: [
      'Antarctica/Davis',
      configStrings.timezoneBangkok,
      'Asia/Hovd',
      'Asia/Jakarta',
      'Asia/Phnom_Penh',
      'Asia/Pontianak',
      'Asia/Saigon',
      'Asia/Vientiane',
      'Etc/GMT-7',
      'Indian/Christmas'
    ],
    standardCode: 'SAST',
    daylightCode: 'SADT'
  },
  {
    id: 76,
    displayName: '(UTC+07:00) Novosibirsk',
    translation: 'TIMEZONE_N_CENTRAL_ASIA',
    standardName: 'N. Central Asia Standard Time',
    timezone: configStrings.timezoneNovosibirsk,
    utc: ['Asia/Novokuznetsk', configStrings.timezoneNovosibirsk],
    standardCode: 'NCAST',
    daylightCode: 'NDT'
  },
  {
    id: 104,
    displayName: '(UTC+07:00) Barnaul, Gorno-Altaysk',
    translation: 'TIMEZONE_ALTAI_STD',
    standardName: 'Altai Standard Time',
    timezone: configStrings.timezoneBarnaul,
    utc: [configStrings.timezoneBarnaul],
    standardCode: 'AST',
    daylightCode: 'ADT'
  },
  {
    id: 78,
    displayName: '(UTC+07:00) Krasnoyarsk',
    translation: 'TIMEZONE_NORTH_ASIA_STD',
    standardName: 'North Asia Standard Time',
    timezone: configStrings.timezoneKrasnoyarsk,
    utc: [configStrings.timezoneKrasnoyarsk],
    standardCode: 'NAST',
    daylightCode: 'RT6DT'
  },
  {
    id: 77,
    displayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    translation: 'TIMEZONE_CHINA_STD',
    standardName: 'China Standard Time',
    timezone: configStrings.timezoneHongKong,
    utc: [configStrings.timezoneHongKong, 'Asia/Macau', 'Asia/Shanghai'],
    standardCode: 'CST',
    daylightCode: 'CDT'
  },
  {
    id: 79,
    displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
    translation: 'TIMEZONE_SINGAPORE_STD',
    standardName: 'Singapore Standard Time',
    timezone: configStrings.timezoneKualaLumpur,
    utc: ['Asia/Brunei', configStrings.timezoneKualaLumpur, 'Asia/Kuching', 'Asia/Makassar', 'Asia/Manila', 'Asia/Singapore', 'Etc/GMT-8'],
    standardCode: 'SST',
    daylightCode: 'MPDT'
  },
  {
    id: 80,
    displayName: '(UTC+08:00) Perth',
    translation: 'TIMEZONE_W_AUSTRALIA_STD',
    standardName: 'W. Australia Standard Time',
    timezone: configStrings.timezonePerth,
    utc: ['Antarctica/Casey', configStrings.timezonePerth],
    standardCode: 'WAST',
    daylightCode: 'WADT'
  },
  {
    id: 81,
    displayName: '(UTC+08:00) Taipei',
    translation: 'TIMEZONE_TAIPEI',
    standardName: 'Taipei Standard Time',
    timezone: configStrings.timezoneTaipei,
    utc: [configStrings.timezoneTaipei],
    standardCode: 'TST',
    daylightCode: 'TDT'
  },
  {
    id: 82,
    displayName: '(UTC+08:00) Ulaanbaatar',
    translation: 'TIMEZONE_ULAANBAATAR',
    standardName: 'Ulaanbaatar Standard Time',
    timezone: configStrings.timezoneUlaanbaatar,
    utc: ['Asia/Choibalsan', configStrings.timezoneUlaanbaatar],
    standardCode: 'UST',
    daylightCode: 'UDT'
  },
  {
    id: 83,
    displayName: '(UTC+08:00) Irkutsk',
    translation: 'TIMEZONE_NORTH_ASIA_EAST_STD',
    standardName: 'North Asia East Standard Time',
    timezone: configStrings.timezoneIrkutsk,
    utc: [configStrings.timezoneIrkutsk],
    standardCode: 'NAEST',
    daylightCode: 'RT7DT'
  },
  {
    id: 105,
    displayName: '(UTC+08:45) Eucla',
    translation: 'TIMEZONE_AUS_CENTRAL_W_STD',
    standardName: 'Aus Central W. Standard Time',
    timezone: configStrings.timezoneEucla,
    utc: [configStrings.timezoneEucla],
    standardCode: 'ACWST',
    daylightCode: 'ACWDT'
  },
  {
    id: 84,
    displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    translation: 'TIMEZONE_TOKYO_STD',
    standardName: 'Tokyo Standard Time',
    timezone: configStrings.timezoneTokyo,
    utc: ['Asia/Dili', 'Asia/Jayapura', configStrings.timezoneTokyo, 'Etc/GMT-9', 'Pacific/Palau'],
    standardCode: 'TST',
    daylightCode: 'TDT'
  },
  {
    id: 85,
    displayName: '(UTC+09:00) Seoul',
    translation: 'TIMEZONE_KOREA_STD',
    standardName: 'Korea Standard Time',
    timezone: configStrings.timezoneSeoul,
    utc: [configStrings.timezonePyongyang, configStrings.timezoneSeoul],
    standardCode: 'KST',
    daylightCode: 'KDT'
  },
  {
    id: 92,
    displayName: '(UTC+09:00) Yakutsk',
    translation: 'TIMEZONE_YAKUTSK',
    standardName: 'Yakutsk Standard Time',
    timezone: configStrings.timezoneYakutsk,
    utc: [configStrings.timezoneChita, 'Asia/Khandyga', configStrings.timezoneYakutsk],
    standardCode: 'YST',
    daylightCode: 'RT8DT'
  },
  {
    id: 86,
    displayName: '(UTC+09:30) Adelaide',
    translation: 'TIMEZONE_ADELAIDE',
    standardName: 'Cen. Australia Standard Time',
    timezone: configStrings.timezoneAdelaide,
    utc: [configStrings.timezoneAdelaide, 'Australia/Broken_Hill'],
    standardCode: 'CAST',
    daylightCode: 'CADT'
  },
  {
    id: 87,
    displayName: '(UTC+09:30) Darwin',
    translation: 'TIMEZONE_DARWIN',
    standardName: 'AUS Central Standard Time',
    timezone: configStrings.timezoneDarwin,
    utc: [configStrings.timezoneDarwin],
    standardCode: 'ACST',
    daylightCode: 'ACDT'
  },
  {
    id: 88,
    displayName: '(UTC+10:00) Brisbane',
    translation: 'TIMEZONE_BRISBANE',
    standardName: 'E. Australia Standard Time',
    timezone: configStrings.timezoneBrisbane,
    utc: [configStrings.timezoneBrisbane, 'Australia/Lindeman'],
    standardCode: 'EAST',
    daylightCode: 'EADT'
  },
  {
    id: 89,
    displayName: '(UTC+10:00) Canberra, Melbourne, Sydney',
    translation: 'TIMEZONE_MELBOURNE_STD',
    standardName: 'AUS Eastern Standard Time',
    timezone: configStrings.timezoneMelbourne,
    utc: [configStrings.timezoneMelbourne, 'Australia/Sydney'],
    standardCode: 'AEST',
    daylightCode: 'AEDT'
  },
  {
    id: 90,
    displayName: '(UTC+10:00) Guam, Port Moresby',
    translation: 'TIMEZONE_PORT_MORESBY',
    standardName: 'West Pacific Standard Time',
    timezone: configStrings.timezonePortMoresby,
    utc: ['Antarctica/DumontDUrville', 'Etc/GMT-10', 'Pacific/Guam', configStrings.timezonePortMoresby, 'Pacific/Saipan', 'Pacific/Truk'],
    standardCode: 'WPST',
    daylightCode: 'WPDT'
  },
  {
    id: 91,
    displayName: '(UTC+10:00) Hobart',
    translation: 'TIMEZONE_TASMANIA_STD',
    standardName: 'Tasmania Standard Time',
    timezone: configStrings.timezoneHobart,
    utc: ['Australia/Currie', configStrings.timezoneHobart],
    standardCode: 'TST',
    daylightCode: 'TDT'
  },
  {
    id: 94,
    displayName: '(UTC+10:00) Vladivostok',
    translation: 'TIMEZONE_VLANDIVOSTOK',
    standardName: 'Vladivostok Standard Time',
    timezone: configStrings.timezoneVladivostok,
    utc: [configStrings.timezoneSakhalin, 'Asia/Ust-Nera', configStrings.timezoneVladivostok],
    standardCode: 'VST',
    daylightCode: 'RT9DT'
  },
  {
    id: 107,
    displayName: '(UTC+10:30) Lord Howe Island',
    translation: 'TIMEZONE_LORD_HOWE',
    standardName: 'Lord Howe Standard Time',
    timezone: configStrings.timezoneLordHowe,
    utc: [configStrings.timezoneLordHowe],
    standardCode: 'LHST',
    daylightCode: 'LHDT'
  },
  {
    id: 93,
    displayName: '(UTC+11:00) Solomon Is., New Caledonia',
    translation: 'TIMEZONE_CENTRAL_PACIFIC_STD',
    standardName: 'Central Pacific Standard Time',
    timezone: configStrings.timezoneGuadalcanal,
    utc: [
      'Antarctica/Macquarie',
      'Etc/GMT-11',
      'Pacific/Efate',
      configStrings.timezoneGuadalcanal,
      'Pacific/Kosrae',
      'Pacific/Noumea',
      'Pacific/Ponape'
    ],
    standardCode: 'CPST',
    daylightCode: 'CPDT'
  },
  {
    id: 98,
    displayName: '(UTC+11:00) Magadan',
    translation: 'TIMEZONE_MAGADAN',
    standardName: 'Magadan Standard Time',
    timezone: configStrings.timezoneMagadan,
    utc: ['Asia/Anadyr', configStrings.timezoneKamchatka, configStrings.timezoneMagadan, configStrings.timezoneSrednekolymsk],
    standardCode: 'MST',
    daylightCode: 'MDT'
  },
  {
    id: 95,
    displayName: '(UTC+12:00) Auckland, Wellington',
    translation: 'TIMEZONE_NEW_ZELAND_STD',
    standardName: 'New Zealand Standard Time',
    timezone: configStrings.timezoneAuckland,
    utc: ['Antarctica/McMurdo', configStrings.timezoneAuckland],
    standardCode: 'NZST',
    daylightCode: 'NZDT'
  },
  {
    id: 96,
    displayName: '(UTC+12:00) Coordinated Universal Time+12',
    translation: 'TIMEZONE_COORDINATED_UNIVERSAL_TIME_12',
    standardName: 'UTC+12',
    timezone: configStrings.timezoneKwajalein,
    utc: [
      'Etc/GMT-12',
      'Pacific/Funafuti',
      configStrings.timezoneKwajalein,
      'Pacific/Majuro',
      'Pacific/Nauru',
      'Pacific/Tarawa',
      'Pacific/Wake',
      'Pacific/Wallis'
    ],
    standardCode: '',
    daylightCode: 'UTC+12'
  },
  {
    id: 97,
    displayName: '(UTC+12:00) Fiji',
    translation: 'TIMEZONE_FIJI',
    standardName: 'Fiji Standard Time',
    timezone: configStrings.timezoneFiji,
    utc: [configStrings.timezoneFiji],
    standardCode: 'FST',
    daylightCode: 'FDT'
  },
  {
    id: 99,
    displayName: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    translation: 'TIMEZONE_KAMCHATKA',
    standardName: configStrings.timezoneKamchatkaStandardName,
    timezone: configStrings.timezoneKamchatka,
    utc: [configStrings.timezoneKamchatka],
    standardCode: 'KST',
    daylightCode: 'KDT'
  },
  {
    id: 108,
    displayName: '(UTC+12:45) Chatham Islands',
    translation: 'TIMEZONE_CHATHAM_ISLANDS',
    standardName: 'Chatham Islands Standard Time',
    timezone: configStrings.timezoneChatham,
    utc: [configStrings.timezoneChatham],
    standardCode: 'CIST',
    daylightCode: 'CIDT'
  },
  {
    id: 100,
    displayName: '(UTC+13:00) Nuku alofa',
    translation: 'TIMEZONE_TONGA_STD',
    standardName: 'Tonga Standard Time',
    timezone: configStrings.timezoneTongatapu,
    utc: [configStrings.timezoneEtcGmt13, 'Pacific/Enderbury', 'Pacific/Fakaofo', configStrings.timezoneTongatapu],
    standardCode: 'TST',
    daylightCode: 'TDT'
  },
  {
    id: 101,
    displayName: '(UTC+13:00) Samoa',
    translation: 'TIMEZONE_SAMOA',
    standardName: 'Samoa Standard Time',
    timezone: configStrings.timezoneApia,
    utc: [configStrings.timezoneApia],
    standardCode: 'SST',
    daylightCode: 'SDT'
  },
  {
    id: 106,
    displayName: '(UTC+14:00) Kiritimati Island',
    translation: 'TIMEZONE_LINE_ISLANDS_STD',
    standardName: 'Line Islands Standard Time',
    timezone: configStrings.timezoneKiritimati,
    utc: [configStrings.timezoneKiritimati],
    standardCode: 'LIST',
    daylightCode: 'LIDT'
  }
];

export const WOA_UNIVERSAL_TIME_ZONE = [
  {
    id: 1,
    displayName: '(UTC-12:00) International Date Line West',
    standardName: 'Dateline Standard Time',
    daylightName: 'Dateline Daylight Time',
    timezone: configStrings.timezoneEtcGmt12,
    utc: [configStrings.timezoneEtcGmt12],
    standardCode: '(UTC-12:00) DST',
    daylightCode: 'DDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 2,
    displayName: '(UTC-11:00) Coordinated Universal Time-11',
    standardName: 'UTC-11',
    daylightName: 'UTC-11',
    timezone: configStrings.timezonePago,
    utc: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', configStrings.timezonePago],
    standardCode: '(UTC-11:00)',
    daylightCode: 'UTC-11',
    supportsDaylightSavingTime: false
  },
  {
    id: 3,
    displayName: '(UTC-10:00) Aleutian Islands',
    standardName: 'Aleutian Standard Time',
    daylightName: 'Aleutian Daylight Time',
    timezone: 'America/Adak',
    utc: ['America/Adak'],
    standardCode: '(UTC-10:00) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 4,
    displayName: '(UTC-10:00) Hawaii',
    standardName: 'Hawaiian Standard Time',
    daylightName: 'Hawaiian Daylight Time',
    timezone: configStrings.timezoneEtcGmt10,
    utc: [configStrings.timezoneEtcGmt10, 'Pacific/Honolulu', 'Pacific/Johnston', 'Pacific/Rarotonga', 'Pacific/Tahiti'],
    standardCode: '(UTC-10:00) HST',
    daylightCode: 'HDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 5,
    displayName: '(UTC-09:30) Marquesas Islands',
    standardName: 'Marquesas Standard Time',
    daylightName: 'Marquesas Daylight Time',
    timezone: configStrings.timezoneMarquesas,
    utc: [configStrings.timezoneMarquesas],
    standardCode: '(UTC-09:30) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 6,
    displayName: '(UTC-09:00) Alaska',
    standardName: 'Alaskan Standard Time',
    daylightName: 'Alaskan Daylight Time',
    timezone: configStrings.timezoneAnchorage,
    utc: [configStrings.timezoneAnchorage, 'America/Juneau', 'America/Nome', 'America/Sitka', 'America/Yakutat'],
    standardCode: '(UTC-09:00) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 7,
    displayName: '(UTC-09:00) Coordinated Universal Time-09',
    standardName: 'UTC-09',
    daylightName: 'UTC-09',
    timezone: 'Etc/GMT+9',
    utc: ['Etc/GMT+9'],
    standardCode: '(UTC-09:00)',
    daylightCode: 'UTC-09',
    supportsDaylightSavingTime: false
  },
  {
    id: 8,
    displayName: '(UTC-08:00) Baja California',
    standardName: 'Pacific Standard Time (Mexico)',
    daylightName: 'Pacific Daylight Time (Mexico)',
    timezone: configStrings.timezoneTijuana,
    utc: [configStrings.timezoneTijuana, 'America/Santa_Isabel'],
    standardCode: '(UTC-08:00) PST',
    daylightCode: 'PDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 9,
    displayName: '(UTC-08:00) Coordinated Universal Time-08',
    standardName: 'UTC-08',
    daylightName: 'UTC-08',
    timezone: 'Etc/GMT+8',
    utc: ['Etc/GMT+8'],
    standardCode: '(UTC-08:00)',
    daylightCode: 'UTC-08',
    supportsDaylightSavingTime: false
  },
  {
    id: 10,
    displayName: '(UTC-08:00) Pacific Time (US & Canada)',
    standardName: 'Pacific Standard Time',
    daylightName: 'Pacific Daylight Time',
    timezone: configStrings.timezoneDawson,
    utc: [configStrings.timezoneDawson, 'America/Los_Angeles', 'America/Vancouver', 'America/Whitehorse', 'PST8PDT'],
    standardCode: '(UTC-08:00) PST',
    daylightCode: 'PDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 11,
    displayName: '(UTC-07:00) Arizona',
    standardName: 'US Mountain Standard Time',
    daylightName: 'US Mountain Daylight Time',
    timezone: configStrings.timezoneCreston,
    utc: [configStrings.timezoneCreston, 'America/Dawson_Creek', 'America/Hermosillo', 'America/Phoenix', 'Etc/GMT+7'],
    standardCode: '(UTC-07:00) UMST',
    daylightCode: 'UMDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 12,
    displayName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    standardName: 'Mountain Standard Time (Mexico)',
    daylightName: 'Mountain Daylight Time (Mexico)',
    timezone: configStrings.timezoneChihuahua,
    utc: [configStrings.timezoneChihuahua, 'America/Mazatlan'],
    standardCode: '(UTC-07:00) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 13,
    displayName: '(UTC-07:00) Mountain Time (US & Canada)',
    standardName: 'Mountain Standard Time',
    daylightName: 'Mountain Daylight Time',
    timezone: 'US/Mountain',
    utc: [
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Denver',
      'America/Edmonton',
      'America/Inuvik',
      'America/Ojinaga',
      'America/Yellowknife',
      'MST7MDT'
    ],
    standardCode: '(UTC-07:00) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 14,
    displayName: '(UTC-06:00) Central America',
    standardName: 'Central America Standard Time',
    daylightName: 'Central America Daylight Time',
    timezone: configStrings.timezoneManagua,
    utc: [
      'America/Belize',
      'America/Costa_Rica',
      'America/El_Salvador',
      'America/Guatemala',
      configStrings.timezoneManagua,
      'America/Tegucigalpa',
      'Etc/GMT+6',
      'Pacific/Galapagos'
    ],
    standardCode: '(UTC-06:00) CAST',
    daylightCode: 'CADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 15,
    displayName: '(UTC-06:00) Central Time (US & Canada)',
    standardName: 'Central Standard Time',
    daylightName: 'Central Daylight Time',
    timezone: 'US/Central',
    utc: [
      'America/Chicago',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Matamoros',
      'America/Menominee',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
      'CST6CDT'
    ],
    standardCode: '(UTC-06:00) CST',
    daylightCode: 'CDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 16,
    displayName: '(UTC-06:00) Easter Island',
    standardName: 'Easter Island Standard Time',
    daylightName: 'Easter Island Daylight Time',
    timezone: 'Pacific/Easter',
    utc: ['Pacific/Easter'],
    standardCode: '(UTC-06:00) EIST',
    daylightCode: 'EIDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 17,
    displayName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    standardName: 'Central Standard Time (Mexico)',
    daylightName: 'Central Daylight Time (Mexico)',
    timezone: configStrings.timezoneMexico_City,
    utc: ['America/Bahia_Banderas', configStrings.timezoneCancun, 'America/Merida', configStrings.timezoneMexico_City, 'America/Monterrey'],
    standardCode: '(UTC-06:00) CST',
    daylightCode: 'CDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 18,
    displayName: '(UTC-06:00) Saskatchewan',
    standardName: 'Canada Central Standard Time',
    daylightName: 'Canada Central Daylight Time',
    timezone: 'Canada/Saskatchewan',
    utc: ['America/Regina', 'America/Swift_Current'],
    standardCode: '(UTC-06:00) CCST',
    daylightCode: 'CCDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 19,
    displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    standardName: 'SA Pacific Standard Time',
    daylightName: 'SA Pacific Daylight Time',
    timezone: configStrings.timezoneLima,
    utc: [
      'America/Bogota',
      'America/Cayman',
      'America/Coral_Harbour',
      'America/Eirunepe',
      'America/Guayaquil',
      'America/Jamaica',
      configStrings.timezoneLima,
      'America/Panama',
      'America/Rio_Branco',
      'Etc/GMT+5'
    ],
    standardCode: '(UTC-05:00) SPST',
    daylightCode: 'SPDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 20,
    displayName: '(UTC-05:00) Chetumal',
    standardName: 'Eastern Standard Time (Mexico)',
    daylightName: 'Eastern Daylight Time (Mexico)',
    timezone: configStrings.timezoneCancun,
    utc: [configStrings.timezoneCancun],
    standardCode: '(UTC-05:00) EST',
    daylightCode: 'EDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 25,
    displayName: '(UTC-05:00) Eastern Time (US & Canada)',
    standardName: 'Eastern Standard Time',
    daylightName: 'Eastern Daylight Time',
    timezone: 'US/Eastern',
    utc: [
      'America/Detroit',
      configStrings.timezoneHavana,
      'America/Indiana/Petersburg',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Iqaluit',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      configStrings.timezonePortauPrince,
      'America/Thunder_Bay',
      'America/Toronto',
      'EST5EDT'
    ],
    standardCode: '(UTC-05:00) EST',
    daylightCode: 'EDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 21,
    displayName: '(UTC-05:00) Haiti',
    standardName: 'Haiti Standard Time',
    daylightName: 'Haiti Daylight Time',
    timezone: configStrings.timezonePortauPrince,
    utc: [configStrings.timezonePortauPrince],
    standardCode: '(UTC-05:00) HST',
    daylightCode: 'HDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 22,
    displayName: '(UTC-05:00) Havana',
    standardName: 'Cuba Standard Time',
    daylightName: 'Cuba Daylight Time',
    timezone: configStrings.timezoneHavana,
    utc: [configStrings.timezoneHavana],
    standardCode: '(UTC-05:00) CST',
    daylightCode: 'CDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 23,
    displayName: '(UTC-05:00) Indiana (East)',
    standardName: 'US Eastern Standard Time',
    daylightName: 'US Eastern Daylight Time',
    timezone: 'US/East-Indiana',
    utc: ['America/Indiana/Marengo', 'America/Indiana/Vevay', 'America/Indianapolis'],
    standardCode: '(UTC-05:00) UEST',
    daylightCode: 'UEDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 24,
    displayName: '(UTC-05:00) Turks and Caicos',
    standardName: 'Turks And Caicos Standard Time',
    daylightName: 'Turks and Caicos Daylight Time',
    timezone: configStrings.timezoneGrandTurk,
    utc: [configStrings.timezoneGrandTurk],
    standardCode: '(UTC-05:00) TACST',
    daylightCode: 'TCDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 26,
    displayName: '(UTC-04:00) Asuncion',
    standardName: 'Paraguay Standard Time',
    daylightName: 'Paraguay Daylight Time',
    timezone: configStrings.timezoneAsuncion,
    utc: [configStrings.timezoneAsuncion],
    standardCode: '(UTC-04:00) PST',
    daylightCode: 'PDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 27,
    displayName: '(UTC-04:00) Atlantic Time (Canada)',
    standardName: 'Atlantic Standard Time',
    daylightName: 'Atlantic Daylight Time',
    timezone: 'Canada/Atlantic',
    utc: ['America/Glace_Bay', 'America/Goose_Bay', 'America/Halifax', 'America/Moncton', 'America/Thule', 'Atlantic/Bermuda'],
    standardCode: '(UTC-04:00) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 28,
    displayName: '(UTC-04:00) Caracas',
    standardName: 'Venezuela Standard Time',
    daylightName: 'Venezuela Daylight Time',
    timezone: configStrings.timezoneCaracas,
    utc: [configStrings.timezoneCaracas],
    standardCode: '(UTC-04:00) VST',
    daylightCode: 'VDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 29,
    displayName: '(UTC-04:00) Cuiaba',
    standardName: 'Central Brazilian Standard Time',
    daylightName: 'Central Brazilian Daylight Time',
    timezone: configStrings.timezoneSaoPaulo,
    utc: ['America/Campo_Grande', 'America/Cuiaba'],
    standardCode: '(UTC-04:00) CBST',
    daylightCode: 'CBDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 30,
    displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    standardName: 'SA Western Standard Time',
    daylightName: 'SA Western Daylight Time',
    timezone: configStrings.timezoneLaPaz,
    utc: [
      'America/Anguilla',
      'America/Antigua',
      'America/Aruba',
      'America/Barbados',
      'America/Blanc-Sablon',
      'America/Boa_Vista',
      'America/Curacao',
      'America/Dominica',
      configStrings.timezoneGrandTurk,
      'America/Grenada',
      'America/Guadeloupe',
      'America/Guyana',
      'America/Kralendijk',
      configStrings.timezoneLaPaz,
      'America/Lower_Princes',
      'America/Manaus',
      'America/Marigot',
      'America/Martinique',
      'America/Montserrat',
      'America/Port_of_Spain',
      'America/Porto_Velho',
      'America/Puerto_Rico',
      'America/Santo_Domingo',
      'America/St_Barthelemy',
      'America/St_Kitts',
      'America/St_Lucia',
      'America/St_Thomas',
      'America/St_Vincent',
      'America/Tortola',
      'Etc/GMT+4'
    ],
    standardCode: '(UTC-04:00) SWST',
    daylightCode: 'SWDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 31,
    displayName: '(UTC-04:00) Santiago',
    standardName: 'Pacific SA Standard Time',
    daylightName: 'Pacific SA Daylight Time',
    timezone: configStrings.timezoneSantiago,
    utc: [configStrings.timezoneSantiago, 'Antarctica/Palmer'],
    standardCode: '(UTC-04:00) PSST',
    daylightCode: 'PSDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 32,
    displayName: '(UTC-03:30) Newfoundland',
    standardName: 'Newfoundland Standard Time',
    daylightName: 'Newfoundland Daylight Time',
    timezone: 'Canada/Newfoundland',
    utc: ['America/St_Johns'],
    standardCode: '(UTC-03:30) NST',
    daylightCode: 'NDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 33,
    displayName: '(UTC-03:00) Araguaina',
    standardName: 'Tocantins Standard Time',
    daylightName: 'Tocantins Daylight Time',
    timezone: configStrings.timezoneAraguaina,
    utc: [configStrings.timezoneAraguaina],
    standardCode: '(UTC-03:00) TST',
    daylightCode: 'TDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 34,
    displayName: '(UTC-03:00) Brasilia',
    standardName: 'E. South America Standard Time',
    daylightName: 'E. South America Daylight Time',
    timezone: configStrings.timezoneSaoPaulo,
    utc: [configStrings.timezoneSaoPaulo],
    standardCode: '(UTC-03:00) ESAST',
    daylightCode: 'ESADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 35,
    displayName: '(UTC-03:00) Cayenne, Fortaleza',
    standardName: 'SA Eastern Standard Time',
    daylightName: 'SA Eastern Daylight Time',
    timezone: configStrings.timezoneCayenne,
    utc: [
      configStrings.timezoneAraguaina,
      'America/Belem',
      configStrings.timezoneCayenne,
      'America/Fortaleza',
      'America/Maceio',
      'America/Paramaribo',
      'America/Recife',
      'America/Santarem',
      'Antarctica/Rothera',
      'Atlantic/Stanley',
      'Etc/GMT+3'
    ],
    standardCode: '(UTC-03:00) SEST',
    daylightCode: 'SEDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 36,
    displayName: '(UTC-03:00) City of Buenos Aires',
    standardName: 'Argentina Standard Time',
    daylightName: 'Argentina Daylight Time',
    timezone: 'America/Argentina/Buenos_Aires',
    utc: [
      'America/Argentina/La_Rioja',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
      'America/Buenos_Aires',
      'America/Catamarca',
      'America/Cordoba',
      'America/Jujuy',
      'America/Mendoza'
    ],
    standardCode: '(UTC-03:00) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 37,
    displayName: '(UTC-03:00) Greenland',
    standardName: 'Greenland Standard Time',
    daylightName: 'Greenland Daylight Time',
    timezone: configStrings.timezoneGodthab,
    utc: [configStrings.timezoneGodthab],
    standardCode: '(UTC-03:00) GST',
    daylightCode: 'GDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 38,
    displayName: '(UTC-03:00) Montevideo',
    standardName: 'Montevideo Standard Time',
    daylightName: 'Montevideo Daylight Time',
    timezone: configStrings.timezoneMontevideo,
    utc: [configStrings.timezoneMontevideo],
    standardCode: '(UTC-03:00) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 39,
    displayName: '(UTC-03:00) Punta Arenas',
    standardName: 'Magallanes Standard Time',
    daylightName: 'Magallanes Daylight Time',
    timezone: 'America/Punta_Arenas',
    utc: ['America/Punta_Arenas'],
    standardCode: '(UTC-03:00) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 40,
    displayName: '(UTC-03:00) Saint Pierre and Miquelon',
    standardName: 'Saint Pierre Standard Time',
    daylightName: 'Saint Pierre Daylight Time',
    timezone: 'America/Miquelon',
    utc: ['America/Miquelon'],
    standardCode: '(UTC-03:00) SPST',
    daylightCode: 'SPDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 41,
    displayName: '(UTC-03:00) Salvador',
    standardName: 'Bahia Standard Time',
    daylightName: 'Bahia Daylight Time',
    timezone: configStrings.timezoneMontevideo,
    utc: ['America/Bahia'],
    standardCode: '(UTC-03:00) BST',
    daylightCode: 'BDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 42,
    displayName: '(UTC-02:00) Coordinated Universal Time-02',
    standardName: 'UTC-02',
    daylightName: 'UTC-02',
    timezone: configStrings.timezoneGodthab,
    utc: ['Atlantic/South_Georgia', 'Etc/GMT+2'],
    standardCode: '(UTC-02:00)',
    daylightCode: 'UTC-02',
    supportsDaylightSavingTime: false
  },
  {
    id: 43,
    displayName: '(UTC-02:00) Mid-Atlantic - Old',
    standardName: configStrings.timezoneMidAtlanticName,
    daylightName: 'Mid-Atlantic Daylight Time',
    timezone: configStrings.timezoneNoronha,
    utc: [configStrings.timezoneNoronha],
    standardCode: '(UTC-02:00) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 44,
    displayName: '(UTC-01:00) Azores',
    standardName: 'Azores Standard Time',
    daylightName: 'Azores Daylight Time',
    timezone: configStrings.timezoneAzores,
    utc: ['America/Scoresbysund', configStrings.timezoneAzores],
    standardCode: '(UTC-01:00) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 45,
    displayName: '(UTC-01:00) Cabo Verde Is.',
    standardName: 'Cape Verde Standard Time',
    daylightName: 'Cabo Verde Daylight Time',
    timezone: configStrings.timezoneCapeVerde,
    utc: [configStrings.timezoneCapeVerde, 'Etc/GMT+1'],
    standardCode: '(UTC-01:00) CVST',
    daylightCode: 'CVDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 46,
    displayName: '(UTC) Coordinated Universal Time',
    standardName: 'UTC',
    daylightName: 'Coordinated Universal Time',
    timezone: 'Etc/UTC',
    utc: ['Etc/UTC'],
    standardCode: '(UTC)',
    daylightCode: 'CUT',
    supportsDaylightSavingTime: false
  },
  {
    id: 47,
    displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    standardName: 'GMT Standard Time',
    daylightName: 'GMT Daylight Time',
    timezone: configStrings.timezoneLondon,
    utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', configStrings.timezoneLondon],
    standardCode: '(UTC+00:00) GST',
    daylightCode: 'GDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 48,
    displayName: '(UTC+00:00) Monrovia, Reykjavik',
    standardName: 'Greenwich Standard Time',
    daylightName: 'Greenwich Daylight Time',
    timezone: configStrings.timezoneMonrovia,
    utc: [
      configStrings.timezoneAbidjan,
      'Africa/Accra',
      'Africa/Bamako',
      'Africa/Banjul',
      'Africa/Bissau',
      'Africa/Conakry',
      'Africa/Dakar',
      'Africa/Freetown',
      'Africa/Lome',
      configStrings.timezoneMonrovia,
      'Africa/Nouakchott',
      'Africa/Ouagadougou',
      configStrings.timezoneSaoTome,
      'Atlantic/Reykjavik',
      'Atlantic/St_Helena'
    ],
    standardCode: '(UTC+00:00) GST',
    daylightCode: 'GDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 49,
    displayName: '(UTC+00:00) Sao Tome',
    standardName: 'Sao Tome Standard Time',
    daylightName: 'Sao Tome Daylight Time',
    timezone: configStrings.timezoneSaoTome,
    utc: [configStrings.timezoneSaoTome],
    standardCode: '(UTC+00:00) STST',
    daylightCode: 'STDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 50,
    displayName: '(UTC+01:00) Casablanca',
    standardName: 'Morocco Standard Time',
    daylightName: 'Morocco Daylight Time',
    timezone: configStrings.timezoneCasablanca,
    utc: [configStrings.timezoneCasablanca, 'Africa/El_Aaiun'],
    standardCode: '(UTC+01:00) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 51,
    displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    standardName: 'W. Europe Standard Time',
    daylightName: 'W. Europe Daylight Time',
    timezone: configStrings.timezoneAmsterdam,
    utc: [
      'Arctic/Longyearbyen',
      configStrings.timezoneAmsterdam,
      'Europe/Andorra',
      'Europe/Berlin',
      'Europe/Busingen',
      'Europe/Gibraltar',
      'Europe/Luxembourg',
      'Europe/Malta',
      'Europe/Monaco',
      'Europe/Oslo',
      'Europe/Rome',
      'Europe/San_Marino',
      'Europe/Stockholm',
      'Europe/Vaduz',
      'Europe/Vatican',
      'Europe/Vienna',
      'Europe/Zurich'
    ],
    standardCode: '(UTC+01:00) WEST',
    daylightCode: 'WEDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 52,
    displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    standardName: 'Central Europe Standard Time',
    daylightName: 'Central Europe Daylight Time',
    timezone: configStrings.timezoneBudapest,
    utc: [
      'Europe/Belgrade',
      'Europe/Bratislava',
      configStrings.timezoneBudapest,
      'Europe/Ljubljana',
      'Europe/Podgorica',
      'Europe/Prague',
      'Europe/Tirane'
    ],
    standardCode: '(UTC+01:00) CEST',
    daylightCode: 'CEDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 53,
    displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    standardName: 'Romance Standard Time',
    daylightName: 'Romance Daylight Time',
    timezone: configStrings.timezoneParis,
    utc: ['Africa/Ceuta', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', configStrings.timezoneParis],
    standardCode: '(UTC+01:00) RST',
    daylightCode: 'RDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 54,
    displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    standardName: 'Central European Standard Time',
    daylightName: 'Central European Daylight Time',
    timezone: configStrings.timezoneWarsaw,
    utc: ['Europe/Sarajevo', 'Europe/Skopje', configStrings.timezoneWarsaw, 'Europe/Zagreb'],
    standardCode: '(UTC+01:00) CEST',
    daylightCode: 'CEDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 55,
    displayName: '(UTC+01:00) West Central Africa',
    standardName: 'W. Central Africa Standard Time',
    daylightName: 'W. Central Africa Daylight Time',
    timezone: configStrings.timezoneLagos,
    utc: [
      'Africa/Algiers',
      'Africa/Bangui',
      'Africa/Brazzaville',
      'Africa/Douala',
      'Africa/Kinshasa',
      configStrings.timezoneLagos,
      'Africa/Libreville',
      'Africa/Luanda',
      'Africa/Malabo',
      'Africa/Ndjamena',
      'Africa/Niamey',
      'Africa/Porto-Novo',
      'Africa/Tunis',
      'Etc/GMT-1'
    ],
    standardCode: '(UTC+01:00) WCAST',
    daylightCode: 'WCADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 56,
    displayName: '(UTC+02:00) Amman',
    standardName: 'Jordan Standard Time',
    daylightName: 'Jordan Daylight Time',
    timezone: configStrings.timezoneAmman,
    utc: [configStrings.timezoneAmman],
    standardCode: '(UTC+02:00) JST',
    daylightCode: 'JDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 57,
    displayName: '(UTC+02:00) Athens, Bucharest',
    standardName: 'GTB Standard Time',
    daylightName: 'GTB Daylight Time',
    timezone: configStrings.timezoneAthens,
    utc: [configStrings.timezoneNicosia, configStrings.timezoneAthens, configStrings.timezoneBucharest],
    standardCode: '(UTC+02:00) GST',
    daylightCode: 'GDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 58,
    displayName: '(UTC+02:00) Beirut',
    standardName: 'Middle East Standard Time',
    daylightName: 'Middle East Daylight Time',
    timezone: configStrings.timezoneBeirut,
    utc: [configStrings.timezoneBeirut],
    standardCode: '(UTC+02:00) MEST',
    daylightCode: 'MEDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 59,
    displayName: '(UTC+02:00) Cairo',
    standardName: 'Egypt Standard Time',
    daylightName: 'Egypt Daylight Time',
    timezone: configStrings.timezoneCairo,
    utc: [configStrings.timezoneCairo],
    standardCode: '(UTC+02:00) EST',
    daylightCode: 'EDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 60,
    displayName: '(UTC+02:00) Chisinau',
    standardName: 'E. Europe Standard Time',
    daylightName: 'E. Europe Daylight Time',
    timezone: configStrings.timezoneChisinau,
    utc: [
      configStrings.timezoneChisinau,
      configStrings.timezoneNicosia,
      configStrings.timezoneBucharest,
      configStrings.timezoneChisinau,
      configStrings.timezoneHelsinki,
      configStrings.timezoneKiev,
      configStrings.timezoneMariehamn,
      'Europe/Nicosia',
      configStrings.timezoneRiga,
      configStrings.timezoneSofia,
      configStrings.timezoneTallinn,
      configStrings.timezoneUzhgorod,
      configStrings.timezoneVilnius,
      configStrings.timezoneZaporozhye
    ],
    standardCode: '(UTC+02:00) EEST',
    daylightCode: 'EEDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 61,
    displayName: '(UTC+02:00) Damascus',
    standardName: 'Syria Standard Time',
    daylightName: 'Syria Daylight Time',
    timezone: configStrings.timezoneDamascus,
    utc: [configStrings.timezoneDamascus],
    standardCode: '(UTC+02:00) SST',
    daylightCode: 'SDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 62,
    displayName: '(UTC+02:00) Gaza, Hebron',
    standardName: 'West Bank Standard Time',
    daylightName: 'West Bank Gaza Daylight Time',
    timezone: 'Asia/Hebron',
    utc: ['Asia/Hebron'],
    standardCode: '(UTC+02:00) WBST',
    daylightCode: 'WBGDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 63,
    displayName: '(UTC+02:00) Harare, Pretoria',
    standardName: 'South Africa Standard Time',
    daylightName: 'South Africa Daylight Time',
    timezone: configStrings.timezoneHarare,
    utc: [
      'Africa/Blantyre',
      'Africa/Bujumbura',
      'Africa/Gaborone',
      configStrings.timezoneHarare,
      'Africa/Johannesburg',
      'Africa/Kigali',
      'Africa/Lubumbashi',
      'Africa/Lusaka',
      'Africa/Maputo',
      'Africa/Maseru',
      'Africa/Mbabane',
      'Etc/GMT-2'
    ],
    standardCode: '(UTC+02:00) SAST',
    daylightCode: 'SADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 64,
    displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    standardName: 'FLE Standard Time',
    daylightName: 'FLE Daylight Time',
    timezone: configStrings.timezoneHelsinki,
    utc: [
      configStrings.timezoneHelsinki,
      configStrings.timezoneKiev,
      configStrings.timezoneMariehamn,
      configStrings.timezoneRiga,
      configStrings.timezoneSofia,
      configStrings.timezoneTallinn,
      configStrings.timezoneUzhgorod,
      configStrings.timezoneVilnius,
      configStrings.timezoneZaporozhye
    ],
    standardCode: '(UTC+02:00) FST',
    daylightCode: 'FDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 66,
    displayName: '(UTC+02:00) Jerusalem',
    standardName: 'Israel Standard Time',
    daylightName: 'Jerusalem Daylight Time',
    timezone: configStrings.timezoneJerusalem,
    utc: [configStrings.timezoneJerusalem],
    standardCode: '(UTC+02:00) IST',
    daylightCode: 'JDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 67,
    displayName: '(UTC+02:00) Kaliningrad',
    standardName: 'Kaliningrad Standard Time',
    daylightName: 'Russia TZ 1 Daylight Time',
    timezone: configStrings.timezoneKaliningrad,
    utc: [configStrings.timezoneKaliningrad],
    standardCode: '(UTC+02:00) KST',
    daylightCode: 'RT1DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 68,
    displayName: '(UTC+02:00) Khartoum',
    standardName: 'Sudan Standard Time',
    daylightName: 'Sudan Daylight Time',
    timezone: configStrings.timezoneKhartoum,
    utc: [configStrings.timezoneKhartoum],
    standardCode: '(UTC+02:00) SST',
    daylightCode: 'SDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 69,
    displayName: '(UTC+02:00) Tripoli',
    standardName: 'Libya Standard Time',
    daylightName: 'Libya Daylight Time',
    timezone: configStrings.timezoneTripoli,
    utc: [configStrings.timezoneTripoli],
    standardCode: '(UTC+02:00) LST',
    daylightCode: 'LDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 65,
    displayName: '(UTC+02:00) Windhoek',
    standardName: 'Namibia Standard Time',
    daylightName: 'Namibia Daylight Time',
    timezone: configStrings.timezoneWindhoek,
    utc: [configStrings.timezoneWindhoek],
    standardCode: '(UTC+02:00) NST',
    daylightCode: 'NDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 70,
    displayName: '(UTC+03:00) Baghdad',
    standardName: 'Arabic Standard Time',
    daylightName: 'Arabic Daylight Time',
    timezone: configStrings.timezoneBaghdad,
    utc: [configStrings.timezoneBaghdad],
    standardCode: '(UTC+03:00) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 71,
    displayName: '(UTC+03:00) Istanbul',
    standardName: 'Turkey Standard Time',
    daylightName: 'Turkey Daylight Time',
    timezone: configStrings.timezoneIstanbul,
    utc: [configStrings.timezoneIstanbul],
    standardCode: '(UTC+03:00) TST',
    daylightCode: 'TDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 72,
    displayName: '(UTC+03:00) Kuwait, Riyadh',
    standardName: 'Arab Standard Time',
    daylightName: 'Arab Daylight Time',
    timezone: configStrings.timezoneKuwait,
    utc: ['Asia/Aden', 'Asia/Bahrain', configStrings.timezoneKuwait, 'Asia/Qatar', 'Asia/Riyadh'],
    standardCode: '(UTC+03:00) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 73,
    displayName: '(UTC+03:00) Minsk',
    standardName: 'Belarus Standard Time',
    daylightName: 'Belarus Daylight Time',
    timezone: configStrings.timezoneMinsk,
    utc: [configStrings.timezoneMinsk],
    standardCode: '(UTC+03:00) BST',
    daylightCode: 'BDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 74,
    displayName: '(UTC+03:00) Moscow, St. Petersburg',
    standardName: 'Russian Standard Time',
    daylightName: 'Russia TZ 2 Daylight Time',
    timezone: configStrings.timezoneMoscow,
    utc: ['Europe/Kirov', configStrings.timezoneMoscow, 'Europe/Simferopol'],
    standardCode: '(UTC+03:00) RST',
    daylightCode: 'RT2DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 75,
    displayName: '(UTC+03:00) Nairobi',
    standardName: 'E. Africa Standard Time',
    daylightName: 'E. Africa Daylight Time',
    timezone: configStrings.timezoneNairobi,
    utc: [
      'Africa/Addis_Ababa',
      'Africa/Asmera',
      'Africa/Dar_es_Salaam',
      'Africa/Djibouti',
      'Africa/Juba',
      'Africa/Kampala',
      'Africa/Mogadishu',
      configStrings.timezoneNairobi,
      'Antarctica/Syowa',
      'Etc/GMT-3',
      'Indian/Antananarivo',
      'Indian/Comoro',
      'Indian/Mayotte'
    ],
    standardCode: '(UTC+03:00) EAST',
    daylightCode: 'EADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 76,
    displayName: '(UTC+03:30) Tehran',
    standardName: 'Iran Standard Time',
    daylightName: 'Iran Daylight Time',
    timezone: configStrings.timezoneTehran,
    utc: [configStrings.timezoneTehran],
    standardCode: '(UTC+03:30) IST',
    daylightCode: 'IDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 77,
    displayName: '(UTC+04:00) Abu Dhabi, Muscat',
    standardName: 'Arabian Standard Time',
    daylightName: 'Arabian Daylight Time',
    timezone: configStrings.timezoneMuscat,
    utc: ['Asia/Dubai', configStrings.timezoneMuscat, 'Etc/GMT-4'],
    standardCode: configStrings.standardCodeAst,
    daylightCode: 'ADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 78,
    displayName: '(UTC+04:00) Astrakhan, Ulyanovsk',
    standardName: 'Astrakhan Standard Time',
    daylightName: 'Astrakhan Daylight Time',
    timezone: 'Europe/Astrakhan',
    utc: ['Europe/Astrakhan'],
    standardCode: configStrings.standardCodeAst,
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 79,
    displayName: '(UTC+04:00) Baku',
    standardName: 'Azerbaijan Standard Time',
    daylightName: 'Azerbaijan Daylight Time',
    timezone: 'Asia/Baku',
    utc: ['Asia/Baku'],
    standardCode: configStrings.standardCodeAst,
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 80,
    displayName: '(UTC+04:00) Izhevsk, Samara',
    standardName: 'Russia Time Zone 3',
    daylightName: 'Russia TZ 3 Daylight Time',
    timezone: 'Europe/Samara',
    utc: ['Europe/Samara'],
    standardCode: '(UTC+04:00) RTZ3',
    daylightCode: 'RT3DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 81,
    displayName: '(UTC+04:00) Port Louis',
    standardName: 'Mauritius Standard Time',
    daylightName: 'Mauritius Daylight Time',
    timezone: configStrings.timezoneMauritius,
    utc: ['Indian/Mahe', configStrings.timezoneMauritius, 'Indian/Reunion'],
    standardCode: '(UTC+04:00) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 82,
    displayName: '(UTC+04:00) Saratov',
    standardName: 'Saratov Standard Time',
    daylightName: 'Saratov Daylight Time',
    timezone: 'Europe/Saratov',
    utc: ['Europe/Saratov'],
    standardCode: '(UTC+04:00) SST',
    daylightCode: 'SDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 83,
    displayName: '(UTC+04:00) Tbilisi',
    standardName: 'Georgian Standard Time',
    daylightName: 'Georgian Daylight Time',
    timezone: configStrings.timezoneTbilisi,
    utc: [configStrings.timezoneTbilisi],
    standardCode: '(UTC+04:00) GST',
    daylightCode: 'GDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 84,
    displayName: '(UTC+04:00) Volgograd',
    standardName: 'Volgograd Standard Time',
    daylightName: 'Volgograd Daylight Time',
    timezone: configStrings.timezoneVolgograd,
    utc: [configStrings.timezoneVolgograd],
    standardCode: '(UTC+04:00) VST',
    daylightCode: 'VDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 85,
    displayName: '(UTC+04:00) Yerevan',
    standardName: 'Caucasus Standard Time',
    daylightName: 'Caucasus Daylight Time',
    timezone: configStrings.timezoneYerevan,
    utc: [configStrings.timezoneYerevan],
    standardCode: '(UTC+04:00) CST',
    daylightCode: 'CDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 86,
    displayName: '(UTC+04:30) Kabul',
    standardName: 'Afghanistan Standard Time',
    daylightName: 'Afghanistan Daylight Time',
    timezone: configStrings.timezoneKabul,
    utc: [configStrings.timezoneKabul],
    standardCode: '(UTC+04:30) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 87,
    displayName: '(UTC+05:00) Ashgabat, Tashkent',
    standardName: 'West Asia Standard Time',
    daylightName: 'West Asia Daylight Time',
    timezone: configStrings.timezoneTashkent,
    utc: [
      'Antarctica/Mawson',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Ashgabat',
      'Asia/Dushanbe',
      'Asia/Oral',
      'Asia/Samarkand',
      configStrings.timezoneTashkent,
      'Etc/GMT-5',
      'Indian/Kerguelen',
      'Indian/Maldives'
    ],
    standardCode: '(UTC+05:00) WAST',
    daylightCode: 'WADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 88,
    displayName: '(UTC+05:00) Ekaterinburg',
    standardName: 'Ekaterinburg Standard Time',
    daylightName: 'Russia TZ 4 Daylight Time',
    timezone: configStrings.timezoneYekaterinburg,
    utc: [configStrings.timezoneYekaterinburg],
    standardCode: '(UTC+05:00) EST',
    daylightCode: 'RT4DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 89,
    displayName: '(UTC+05:00) Islamabad, Karachi',
    standardName: 'Pakistan Standard Time',
    daylightName: 'Pakistan Daylight Time',
    timezone: configStrings.timezoneKarachi,
    utc: [configStrings.timezoneKarachi],
    standardCode: '(UTC+05:00) PST',
    daylightCode: 'PDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 90,
    displayName: '(UTC+05:00) Qyzylorda',
    standardName: 'Qyzylorda Standard Time',
    daylightName: 'Qyzylorda Daylight Time',
    timezone: configStrings.timezoneQyzylorda,
    utc: [configStrings.timezoneQyzylorda],
    standardCode: '(UTC+05:00) QST',
    daylightCode: 'QDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 91,
    displayName: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    standardName: 'India Standard Time',
    daylightName: 'India Daylight Time',
    timezone: configStrings.timezoneKolkata,
    utc: [configStrings.timezoneKolkata, 'Asia/Calcutta'],
    standardCode: '(UTC+05:30) IST',
    daylightCode: 'IDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 92,
    displayName: '(UTC+05:30) Sri Jayawardenepura',
    standardName: 'Sri Lanka Standard Time',
    daylightName: 'Sri Lanka Daylight Time',
    timezone: configStrings.timezoneColombo,
    utc: [configStrings.timezoneColombo],
    standardCode: '(UTC+05:30) SLST',
    daylightCode: 'SLDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 93,
    displayName: '(UTC+05:45) Kathmandu',
    standardName: 'Nepal Standard Time',
    daylightName: 'Nepal Daylight Time',
    timezone: 'Asia/Katmandu',
    utc: ['Asia/Kathmandu'],
    standardCode: '(UTC+05:45) NST',
    daylightCode: 'NDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 94,
    displayName: '(UTC+06:00) Astana',
    standardName: 'Central Asia Standard Time',
    daylightName: 'Central Asia Daylight Time',
    timezone: configStrings.timezoneDhaka,
    utc: ['Antarctica/Vostok', 'Asia/Almaty', 'Asia/Bishkek', configStrings.timezoneQyzylorda, 'Asia/Urumqi', 'Etc/GMT-6', 'Indian/Chagos'],
    standardCode: '(UTC+06:00) CAST',
    daylightCode: 'CADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 95,
    displayName: '(UTC+06:00) Dhaka',
    standardName: 'Bangladesh Standard Time',
    daylightName: 'Bangladesh Daylight Time',
    timezone: configStrings.timezoneDhaka,
    utc: [configStrings.timezoneDhaka, 'Asia/Thimphu'],
    standardCode: '(UTC+06:00) BST',
    daylightCode: 'BDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 96,
    displayName: '(UTC+06:00) Omsk',
    standardName: 'Omsk Standard Time',
    daylightName: 'Omsk Daylight Time',
    timezone: 'Asia/Omsk',
    utc: ['Asia/Omsk'],
    standardCode: '(UTC+06:00) OST',
    daylightCode: 'ODT',
    supportsDaylightSavingTime: true
  },
  {
    id: 97,
    displayName: '(UTC+06:30) Yangon (Rangoon)',
    standardName: 'Myanmar Standard Time',
    daylightName: 'Myanmar Daylight Time',
    timezone: configStrings.timezoneRangoon,
    utc: [configStrings.timezoneRangoon, 'Indian/Cocos'],
    standardCode: '(UTC+06:30) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 98,
    displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    standardName: 'SE Asia Standard Time',
    daylightName: 'SE Asia Daylight Time',
    timezone: configStrings.timezoneBangkok,
    utc: [
      'Antarctica/Davis',
      configStrings.timezoneBangkok,
      'Asia/Jakarta',
      'Asia/Phnom_Penh',
      'Asia/Pontianak',
      'Asia/Saigon',
      'Asia/Vientiane',
      'Etc/GMT-7',
      'Indian/Christmas'
    ],
    standardCode: '(UTC+07:00) SAST',
    daylightCode: 'SADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 99,
    displayName: '(UTC+07:00) Barnaul, Gorno-Altaysk',
    standardName: 'Altai Standard Time',
    daylightName: 'Altai Daylight Time',
    timezone: configStrings.timezoneBarnaul,
    utc: [configStrings.timezoneBarnaul],
    standardCode: '(UTC+07:00) AST',
    daylightCode: 'ADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 100,
    displayName: '(UTC+07:00) Hovd',
    standardName: 'W. Mongolia Standard Time',
    daylightName: 'W. Mongolia Daylight Time',
    timezone: 'Asia/Hovd',
    utc: ['Asia/Hovd'],
    standardCode: '(UTC+07:00) WMST',
    daylightCode: 'WMDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 101,
    displayName: '(UTC+07:00) Krasnoyarsk',
    standardName: 'North Asia Standard Time',
    daylightName: 'Russia TZ 6 Daylight Time',
    timezone: configStrings.timezoneKrasnoyarsk,
    utc: [configStrings.timezoneKrasnoyarsk],
    standardCode: '(UTC+07:00) NAST',
    daylightCode: 'RT6DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 102,
    displayName: '(UTC+07:00) Novosibirsk',
    standardName: 'N. Central Asia Standard Time',
    daylightName: 'Novosibirsk Daylight Time',
    timezone: configStrings.timezoneNovosibirsk,
    utc: ['Asia/Novokuznetsk', configStrings.timezoneNovosibirsk],
    standardCode: '(UTC+07:00) NCAST',
    daylightCode: 'NDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 103,
    displayName: '(UTC+07:00) Tomsk',
    standardName: 'Tomsk Standard Time',
    daylightName: 'Tomsk Daylight Time',
    timezone: 'Asia/Tomsk',
    utc: ['Asia/Tomsk'],
    standardCode: '(UTC+07:00) TST',
    daylightCode: 'TDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 104,
    displayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    standardName: 'China Standard Time',
    daylightName: 'China Daylight Time',
    timezone: configStrings.timezoneHongKong,
    utc: [configStrings.timezoneHongKong, 'Asia/Macau', 'Asia/Shanghai'],
    standardCode: '(UTC+08:00) CST',
    daylightCode: 'CDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 105,
    displayName: '(UTC+08:00) Irkutsk',
    standardName: 'North Asia East Standard Time',
    daylightName: 'Russia TZ 7 Daylight Time',
    timezone: configStrings.timezoneIrkutsk,
    utc: [configStrings.timezoneIrkutsk],
    standardCode: '(UTC+08:00) NAEST',
    daylightCode: 'RT7DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 106,
    displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
    standardName: 'Singapore Standard Time',
    daylightName: 'Malay Peninsula Daylight Time',
    timezone: configStrings.timezoneKualaLumpur,
    utc: ['Asia/Brunei', configStrings.timezoneKualaLumpur, 'Asia/Kuching', 'Asia/Makassar', 'Asia/Manila', 'Asia/Singapore', 'Etc/GMT-8'],
    standardCode: '(UTC+08:00) SST',
    daylightCode: 'MPDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 107,
    displayName: '(UTC+08:00) Perth',
    standardName: 'W. Australia Standard Time',
    daylightName: 'W. Australia Daylight Time',
    timezone: configStrings.timezonePerth,
    utc: ['Antarctica/Casey', configStrings.timezonePerth],
    standardCode: '(UTC+08:00) WAST',
    daylightCode: 'WADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 108,
    displayName: '(UTC+08:00) Taipei',
    standardName: 'Taipei Standard Time',
    daylightName: 'Taipei Daylight Time',
    timezone: configStrings.timezoneTaipei,
    utc: [configStrings.timezoneTaipei],
    standardCode: '(UTC+08:00) TST',
    daylightCode: 'TDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 109,
    displayName: '(UTC+08:00) Ulaanbaatar',
    standardName: 'Ulaanbaatar Standard Time',
    daylightName: 'Ulaanbaatar Daylight Time',
    timezone: configStrings.timezoneUlaanbaatar,
    utc: ['Asia/Choibalsan', configStrings.timezoneUlaanbaatar],
    standardCode: '(UTC+08:00) UST',
    daylightCode: 'UDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 110,
    displayName: '(UTC+08:45) Eucla',
    standardName: 'Aus Central W. Standard Time',
    daylightName: 'Aus Central W. Daylight Time',
    timezone: configStrings.timezoneEucla,
    utc: [configStrings.timezoneEucla],
    standardCode: '(UTC+08:45) ACWST',
    daylightCode: 'ACWDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 111,
    displayName: '(UTC+09:00) Chita',
    standardName: 'Transbaikal Standard Time',
    daylightName: 'Transbaikal Daylight Time',
    timezone: configStrings.timezoneChita,
    utc: [configStrings.timezoneChita],
    standardCode: '(UTC+09:00) TST',
    daylightCode: 'TDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 112,
    displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    standardName: 'Tokyo Standard Time',
    daylightName: 'Tokyo Daylight Time',
    timezone: configStrings.timezoneTokyo,
    utc: ['Asia/Dili', 'Asia/Jayapura', configStrings.timezoneTokyo, 'Etc/GMT-9', 'Pacific/Palau'],
    standardCode: '(UTC+09:00) TST',
    daylightCode: 'TDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 113,
    displayName: '(UTC+09:00) Pyongyang',
    standardName: 'North Korea Standard Time',
    daylightName: 'North Korea Daylight Time',
    timezone: configStrings.timezonePyongyang,
    utc: [configStrings.timezonePyongyang],
    standardCode: '(UTC+09:00) NKST',
    daylightCode: 'NKDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 114,
    displayName: '(UTC+09:00) Seoul',
    standardName: 'Korea Standard Time',
    daylightName: 'Korea Daylight Time',
    timezone: configStrings.timezoneSeoul,
    utc: [configStrings.timezoneSeoul],
    standardCode: '(UTC+09:00) KST',
    daylightCode: 'KDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 115,
    displayName: '(UTC+09:00) Yakutsk',
    standardName: 'Yakutsk Standard Time',
    daylightName: 'Russia TZ 8 Daylight Time',
    timezone: configStrings.timezoneYakutsk,
    utc: [configStrings.timezoneChita, 'Asia/Khandyga', configStrings.timezoneYakutsk],
    standardCode: '(UTC+09:00) YST',
    daylightCode: 'RT8DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 116,
    displayName: '(UTC+09:30) Adelaide',
    standardName: 'Cen. Australia Standard Time',
    daylightName: 'Cen. Australia Daylight Time',
    timezone: configStrings.timezoneAdelaide,
    utc: [configStrings.timezoneAdelaide, 'Australia/Broken_Hill'],
    standardCode: '(UTC+09:30) CAST',
    daylightCode: 'CADT',
    supportsDaylightSavingTime: true
  },
  {
    id: 117,
    displayName: '(UTC+09:30) Darwin',
    standardName: 'AUS Central Standard Time',
    daylightName: 'AUS Central Daylight Time',
    timezone: configStrings.timezoneDarwin,
    utc: [configStrings.timezoneDarwin],
    standardCode: '(UTC+09:30) ACST',
    daylightCode: 'ACDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 118,
    displayName: '(UTC+10:00) Brisbane',
    standardName: 'E. Australia Standard Time',
    daylightName: 'E. Australia Daylight Time',
    timezone: configStrings.timezoneBrisbane,
    utc: [configStrings.timezoneBrisbane, 'Australia/Lindeman'],
    standardCode: '(UTC+10:00) EAST',
    daylightCode: 'EADT',
    supportsDaylightSavingTime: false
  },
  {
    id: 119,
    displayName: '(UTC+10:00) Canberra, Melbourne, Sydney',
    standardName: 'AUS Eastern Standard Time',
    daylightName: 'AUS Eastern Daylight Time',
    timezone: configStrings.timezoneMelbourne,
    utc: [configStrings.timezoneMelbourne, 'Australia/Sydney'],
    standardCode: '(UTC+10:00) AEST',
    daylightCode: 'AEDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 120,
    displayName: '(UTC+10:00) Guam, Port Moresby',
    standardName: 'West Pacific Standard Time',
    daylightName: 'West Pacific Daylight Time',
    timezone: configStrings.timezonePortMoresby,
    utc: ['Antarctica/DumontDUrville', 'Etc/GMT-10', 'Pacific/Guam', configStrings.timezonePortMoresby, 'Pacific/Saipan', 'Pacific/Truk'],
    standardCode: '(UTC+10:00) WPST',
    daylightCode: 'WPDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 121,
    displayName: '(UTC+10:00) Hobart',
    standardName: 'Tasmania Standard Time',
    daylightName: 'Tasmania Daylight Time',
    timezone: configStrings.timezoneHobart,
    utc: ['Australia/Currie', configStrings.timezoneHobart],
    standardCode: '(UTC+10:00) TST',
    daylightCode: 'TDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 122,
    displayName: '(UTC+10:00) Vladivostok',
    standardName: 'Vladivostok Standard Time',
    daylightName: 'Russia TZ 9 Daylight Time',
    timezone: configStrings.timezoneVladivostok,
    utc: [configStrings.timezoneSakhalin, 'Asia/Ust-Nera', configStrings.timezoneVladivostok],
    standardCode: '(UTC+10:00) VST',
    daylightCode: 'RT9DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 123,
    displayName: '(UTC+10:30) Lord Howe Island',
    standardName: 'Lord Howe Standard Time',
    daylightName: 'Lord Howe Daylight Time',
    timezone: configStrings.timezoneLordHowe,
    utc: [configStrings.timezoneLordHowe],
    standardCode: '(UTC+10:30) LHST',
    daylightCode: 'LHDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 124,
    displayName: '(UTC+11:00) Bougainville Island',
    standardName: 'Bougainville Standard Time',
    daylightName: 'Bougainville Daylight Time',
    timezone: 'Pacific/Bougainville',
    utc: ['Pacific/Bougainville'],
    standardCode: '(UTC+11:00) BST',
    daylightCode: 'BDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 125,
    displayName: '(UTC+11:00) Chokurdakh',
    standardName: 'Russia Time Zone 10',
    daylightName: 'Russia TZ 10 Daylight Time',
    timezone: configStrings.timezoneSrednekolymsk,
    utc: [configStrings.timezoneSrednekolymsk],
    standardCode: '(UTC+11:00) RTZ1',
    daylightCode: 'RT1DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 126,
    displayName: '(UTC+11:00) Magadan',
    standardName: 'Magadan Standard Time',
    daylightName: 'Magadan Daylight Time',
    timezone: configStrings.timezoneMagadan,
    utc: ['Asia/Anadyr', configStrings.timezoneKamchatka, configStrings.timezoneMagadan, configStrings.timezoneSrednekolymsk],
    standardCode: '(UTC+11:00) MST',
    daylightCode: 'MDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 127,
    displayName: '(UTC+11:00) Norfolk Island',
    standardName: 'Norfolk Standard Time',
    daylightName: 'Norfolk Daylight Time',
    timezone: 'Pacific/Norfolk',
    utc: ['Pacific/Norfolk'],
    standardCode: '(UTC+11:00) NST',
    daylightCode: 'NDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 128,
    displayName: '(UTC+11:00) Sakhalin',
    standardName: 'Sakhalin Standard Time',
    daylightName: 'Sakhalin Daylight Time',
    timezone: configStrings.timezoneSakhalin,
    utc: [configStrings.timezoneSakhalin],
    standardCode: '(UTC+11:00) SST',
    daylightCode: 'SDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 129,
    displayName: '(UTC+11:00) Solomon Is., New Caledonia',
    standardName: 'Central Pacific Standard Time',
    daylightName: 'Central Pacific Daylight Time',
    timezone: configStrings.timezoneGuadalcanal,
    utc: [
      'Antarctica/Macquarie',
      'Etc/GMT-11',
      'Pacific/Efate',
      configStrings.timezoneGuadalcanal,
      'Pacific/Kosrae',
      'Pacific/Noumea',
      'Pacific/Ponape'
    ],
    standardCode: '(UTC+11:00) CPST',
    daylightCode: 'CPDT',
    supportsDaylightSavingTime: false
  },
  {
    id: 130,
    displayName: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
    standardName: 'Russia Time Zone 11',
    daylightName: 'Russia TZ 11 Daylight Time',
    timezone: configStrings.timezoneKamchatka,
    utc: [configStrings.timezoneKamchatka],
    standardCode: '(UTC+12:00) RTZ1',
    daylightCode: 'RT1DT',
    supportsDaylightSavingTime: true
  },
  {
    id: 131,
    displayName: '(UTC+12:00) Auckland, Wellington',
    standardName: 'New Zealand Standard Time',
    daylightName: 'New Zealand Daylight Time',
    timezone: configStrings.timezoneAuckland,
    utc: ['Antarctica/McMurdo', configStrings.timezoneAuckland],
    standardCode: '(UTC+12:00) NZST',
    daylightCode: 'NZDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 132,
    displayName: '(UTC+12:00) Coordinated Universal Time+12',
    standardName: 'UTC+12',
    daylightName: 'UTC+12',
    timezone: configStrings.timezoneKwajalein,
    utc: [
      'Etc/GMT-12',
      'Pacific/Funafuti',
      configStrings.timezoneKwajalein,
      'Pacific/Majuro',
      'Pacific/Nauru',
      'Pacific/Tarawa',
      'Pacific/Wake',
      'Pacific/Wallis'
    ],
    standardCode: '(UTC+12:00)',
    daylightCode: 'UTC+12',
    supportsDaylightSavingTime: false
  },
  {
    id: 133,
    displayName: '(UTC+12:00) Fiji',
    standardName: 'Fiji Standard Time',
    daylightName: 'Fiji Daylight Time',
    timezone: configStrings.timezoneFiji,
    utc: [configStrings.timezoneFiji],
    standardCode: '(UTC+12:00) FST',
    daylightCode: 'FDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 134,
    displayName: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    standardName: configStrings.timezoneKamchatkaStandardName,
    daylightName: 'Kamchatka Daylight Time',
    timezone: configStrings.timezoneKamchatka,
    utc: [configStrings.timezoneKamchatka],
    standardCode: '(UTC+12:00) KST',
    daylightCode: 'KDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 135,
    displayName: '(UTC+12:45) Chatham Islands',
    standardName: 'Chatham Islands Standard Time',
    daylightName: 'Chatham Islands Daylight Time',
    timezone: configStrings.timezoneChatham,
    utc: [configStrings.timezoneChatham],
    standardCode: '(UTC+12:45) CIST',
    daylightCode: 'CIDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 136,
    displayName: '(UTC+13:00) Coordinated Universal Time+13',
    standardName: 'UTC+13',
    daylightName: 'UTC+13',
    timezone: configStrings.timezoneEtcGmt13,
    utc: [configStrings.timezoneEtcGmt13],
    standardCode: '(UTC+13:00)',
    daylightCode: 'UTC+13',
    supportsDaylightSavingTime: false
  },
  {
    id: 137,
    displayName: '(UTC+13:00) Nuku alofa',
    standardName: 'Tonga Standard Time',
    daylightName: 'Tonga Daylight Time',
    timezone: configStrings.timezoneTongatapu,
    utc: [configStrings.timezoneEtcGmt13, 'Pacific/Enderbury', 'Pacific/Fakaofo', configStrings.timezoneTongatapu],
    standardCode: '(UTC+13:00) TST',
    daylightCode: 'TDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 138,
    displayName: '(UTC+13:00) Samoa',
    standardName: 'Samoa Standard Time',
    daylightName: 'Samoa Daylight Time',
    timezone: configStrings.timezoneApia,
    utc: [configStrings.timezoneApia],
    standardCode: '(UTC+13:00) SST',
    daylightCode: 'SDT',
    supportsDaylightSavingTime: true
  },
  {
    id: 139,
    displayName: '(UTC+14:00) Kiritimati Island',
    standardName: 'Line Islands Standard Time',
    daylightName: 'Line Islands Daylight Time',
    timezone: configStrings.timezoneKiritimati,
    utc: [configStrings.timezoneKiritimati],
    standardCode: '(UTC+14:00) LIST',
    daylightCode: 'LIDT',
    supportsDaylightSavingTime: false
  }
];

export const DateFormat = {
  dateFormat: 'DD/MM/YY'
};

export const PreferenceDateAndTime = {
  PREFERENCE_MONTH_DATE_YEAR: configStrings.dateFormat,
  PREFERENCE_TWENTY_FOUR_HOUR: 'HH:MM:SS 24hr'
};
export const MomentDateFormat = {
  MONTH_DATE_YEAR: configStrings.dateFormat,
  DATE_MONTH_YEAR: 'DD MMM YYYY',
  TWENTY_FOUR_HOUR: 'HH:mm:ss',
  TWELVE_HOUR: 'hh:mm:ss A'
};

export const DatePipeFormat = {
  MONTH_DATE_YEAR: configStrings.dateFormat_dd,
  DATE_MONTH_YEAR: 'dd MMM yyyy',
  TWENTY_FOUR_HOUR: 'HH:mm:ss',
  TWELVE_HOUR: 'hh:mm:ss a'
};

export interface PreferenceEntity {
  dateFormat: string;
  timeFormat: string;
}

export enum ReportTimeFormat {
  'HH:MM:SS 24hr' = '_HH:mm:ss',
  'HH:MM:SS AM/PM' = '_hh:mm:ss:a'
}

export enum ReportDateFormat {
  'DD MMM YYYY' = 'dd/MMM/yyyy',
  'MMM DD YYYY' = 'MMM/dd/yyyy'
}

export interface FilteredVauleType {
  key: string;
  label: string;
  name: string[];
  value: string[];
}

export interface FilterDataType {
  label: string;
  selectedValue: any[];
  value?: string;
  multiSelect?: boolean;
}

export interface IDateInputFieldConfig {
  disabled: boolean;
  labelValue: string;
  isLeading: boolean;
  width?: string;
  isTrailing: boolean;
  ariaDescribedBy: string;
  required: boolean;
}

export const defaultDateInputFieldConfig = {
  disabled: false,
  labelValue: '',
  isLeading: true,
  isTrailing: false,
  ariaDescribedBy: '',
  required: true
};

// Missing standard name in windows-Iana package;
// Creating an enum for missing standard names;
// This will help whenever the package is returning Iana name as undefined,
export enum GetIanaFromConfig {
  'Volgograd Standard Time' = 'Europe/Volgograd',
  'Qyzylorda Standard Time' = 'Asia/Qyzylorda',
  'Kamchatka Standard Time' = 'Asia/Kamchatka',
  'Mid-Atlantic Standard Time' = 'America/Noronha'
}

// This one is special case from the analysis, found that the mismatch/contradiction happens in [(UTC) Coordinated Universal Time] for Moment.guess and windows Iana Package
// For  [(UTC) Coordinated Universal Time] Moment.guess returns 'Africa/Abidjan'
// windows Iana Package returns 'Etc/GMT'
// A per windows Iana package the standard name of 'Africa/Abidjan' is equivalent to   (UTC+00:00) Manroviya
// Execution: if Moment guess return 'Africa/Abidjan' code will get replace by windowsIana 'Etc/GMT'
export enum DeviationInGuessAndWindowsIana {
  MomentGuess = 'Africa/Abidjan',
  WindowsIana = 'Etc/GMT'
}

export interface StandardIanaNames {
  standardName: string;
  timezone: string;
}

export interface BrowserInfo {
  browserName: string;
  browserVersion: string;
  browserPlatform: string;
  browserOS?: string;
}

export interface TimeZoneFromCollection {
  id: number;
  displayName: string;
  standardName: string;
}
export interface TimeZoneList extends TimeZoneFromCollection {
  timezone: string;
}

export interface AssetTagDetails {
  assetKey: string;
  make: string;
  serialNumber: string;
  assetTag?: string;
}

export enum ASSET_TAGS {
  'Support' = '0C972290-DAF3-E911-B5E9-501AC51B1F7D',
  'Unidentified' = '-1'
}

export const DEFAULT_ASSET_TAG_INFO = {
  TAGS_YET_TO_ASSIGNED: 'assignAssetTag',
  UNIDENTIFIED: 'UNIDENTIFIED'
};
export const INPUT_FIELD_DATE_TIME_PICKER_CONFIG = {
  disabled: false,
  labelValue: '',
  width: '100%',
  isLeading: true,
  isTrailing: false,
  ariaDescribedBy: 'Date and Time',
  required: false
};

export const DATE_TIME_PICKER_TIME_CONFIG = {
  twentyfourhour: false,
  minuteincrement: 30,
  minTime: { hour: 0, minute: 0 },
  maxTime: { hour: 23, minute: 59 }
};

export interface IDATETIMEPICKERTIMECONFIG {
  twentyfourhour: boolean;
  minuteincrement: number;
  minTime: ITimePickerMinMaxTime;
  maxTime: ITimePickerMinMaxTime;
}

export interface ITimePickerMinMaxTime {
  hour: number;
  minute: number;
}

export interface SiteStatusHistory {
  dateTime: string;
  status: string;
}

export interface DateRange {
  startDate?: string;
  endDate?: string;
}

export interface IDateTime {
  date: Date | string;
  time: {
    hour: number;
    minute: number;
  };
}

export interface IDateLabels {
  applyLabel: string;
  cancelLabel: string;
  timepickerLabel?: string;
}

export interface ConfigRecommendation {
  assetKey: string;
  make: string;
  serialNumber: string;
  assetTag: string;
  deviceType: string;
  switchConfigId: string | number;
  nominalPayload: string | number;
  nominalVolume: string | number;
}

export interface DateInterval {
  value: string;
  minDate: number;
  maxDate: number;
}

export const SPEED_UNIT = {
  KilometerPerHour: 'kmph',
  MilesPerHour: 'mph'
};

export const DSZFileConstants = {
  SVD: '.svd',
  SVL: '.svl',
  PARSING_COMPLETED: FILE_STATUS.PARSING_COMPLETED,
  IS_DISABLED: 'isDisabled',
  IS_DSZ_FILE: 'isDszFile',
  DSZ_CONFIG: 'dszConfig',
  FILE_CONFIG: 'fileConfig',
  TOOL_TIP_MESSAGE_KEY: 'tooltipMessageKey',
  DSZ: '.dsz',
  DSZ_EXTRACTION_SUCCESS: FILE_STATUS.DSZ_EXTRACTION_SUCCESS,
  DSZ_EXTRACTION_FAILURE: FILE_STATUS.DSZ_EXTRACTION_FAILURE
};

export const DESIGN_FILE_STATUS = [FILE_STATUS.PARSING_COMPLETED, FILE_STATUS.DSZ_EXTRACTION_FAILURE, FILE_STATUS.DSZ_EXTRACTION_SUCCESS];

export class DSZFileConfigClass {
  isDisable = false;
  tooltipMessageKey = '';
  fileIds = {
    svdId: '',
    svlId: ''
  };
}

export class DSZFilesIdClass {
  svdId = '';
  svlId = '';
  designFileId = '';
}

export const VALID_SVL_STATUS: string[] = [FileStatus.ParsingCompleted, FileStatus.ParsingFailed, FileStatus.UploadFailed];

export const SVD_FILE = '.svd';
export const SVL_FILE = '.svl';

export const BASEURL_SPLIT: BaseUrl[] = [
  { path: 'dashboard/activesite', value: 'dashboard' },
  { path: 'dashboard/archivesite', value: 'dashboard' },
  { path: 'dashboard/summary', value: 'dashboard' },
  { path: 'dashboard/add-site', value: 'dashboard' },
  { path: 'dashboard/edit-site', value: 'dashboard' },
  { path: 'dashboard/copy-site', value: 'dashboard' },
  { path: 'dashboard/insights', value: 'dashboard' },
  { path: 'safety/activesite', value: 'safety' },
  { path: 'safety/archivesite', value: 'safety' },
  { path: 'dashboard/safety', value: 'safety' },
  { path: 'missionManagement', value: 'missionManagement' },
  { path: 'allAssets', value: 'allAssets' },
  { path: 'targets', value: 'targets' },
  { path: 'userManagement', value: 'userManagement' },
  { path: 'reportManagement', value: 'reportManagement' },
  { path: 'remoteListManagement', value: 'remoteListManagement' },
  { path: 'auditLogs', value: 'auditLogs' },
  { path: 'fileManagement', value: 'fileManagement' }
];

export const INTERVAL = {
  DAILY: 'DAILY',
  HOURLY: 'HOURLY',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY'
};

export const DATE_INTERVAL_CONFIG = [
  { value: INTERVAL.HOURLY, minDate: 1, maxDate: 1 },
  { value: INTERVAL.DAILY, minDate: 2, maxDate: 31 },
  { value: INTERVAL.WEEKLY, minDate: 32, maxDate: 90 },
  { value: INTERVAL.MONTHLY, minDate: 91, maxDate: 270 }
];

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss';

export const TURF_CONFIG = {
  SCRIPT_SRC: '/turf/turf.min.js',
  SCRIPT: 'script',
  SCRIT_LOAD_ERROR: 'Error loading turf script',
  MIN_COUNT_CHECK: 3,
  DELAY_TIMER: 1000
};
