"use strict";

exports.__esModule = true;
var WindowsZoneName;
(function (WindowsZoneName) {
  WindowsZoneName["DatelineStandardTime"] = "Dateline Standard Time";
  WindowsZoneName["UtcMinus_11"] = "UTC-11";
  WindowsZoneName["AleutianStandardTime"] = "Aleutian Standard Time";
  WindowsZoneName["HawaiianStandardTime"] = "Hawaiian Standard Time";
  WindowsZoneName["MarquesasStandardTime"] = "Marquesas Standard Time";
  WindowsZoneName["AlaskanStandardTime"] = "Alaskan Standard Time";
  WindowsZoneName["UtcMinus_09"] = "UTC-09";
  WindowsZoneName["PacificStandardTimeMexico"] = "Pacific Standard Time (Mexico)";
  WindowsZoneName["UtcMinus_08"] = "UTC-08";
  WindowsZoneName["PacificStandardTime"] = "Pacific Standard Time";
  WindowsZoneName["UsMountainStandardTime"] = "US Mountain Standard Time";
  WindowsZoneName["MountainStandardTimeMexico"] = "Mountain Standard Time (Mexico)";
  WindowsZoneName["MountainStandardTime"] = "Mountain Standard Time";
  WindowsZoneName["CentralAmericaStandardTime"] = "Central America Standard Time";
  WindowsZoneName["CentralStandardTime"] = "Central Standard Time";
  WindowsZoneName["EasterIslandStandardTime"] = "Easter Island Standard Time";
  WindowsZoneName["CentralStandardTimeMexico"] = "Central Standard Time (Mexico)";
  WindowsZoneName["CanadaCentralStandardTime"] = "Canada Central Standard Time";
  WindowsZoneName["SaPacificStandardTime"] = "SA Pacific Standard Time";
  WindowsZoneName["EasternStandardTimeMexico"] = "Eastern Standard Time (Mexico)";
  WindowsZoneName["EasternStandardTime"] = "Eastern Standard Time";
  WindowsZoneName["HaitiStandardTime"] = "Haiti Standard Time";
  WindowsZoneName["CubaStandardTime"] = "Cuba Standard Time";
  WindowsZoneName["UsEasternStandardTime"] = "US Eastern Standard Time";
  WindowsZoneName["ParaguayStandardTime"] = "Paraguay Standard Time";
  WindowsZoneName["AtlanticStandardTime"] = "Atlantic Standard Time";
  WindowsZoneName["VenezuelaStandardTime"] = "Venezuela Standard Time";
  WindowsZoneName["CentralBrazilianStandardTime"] = "Central Brazilian Standard Time";
  WindowsZoneName["SaWesternStandardTime"] = "SA Western Standard Time";
  WindowsZoneName["PacificSaStandardTime"] = "Pacific SA Standard Time";
  WindowsZoneName["TurksAndCaicosStandardTime"] = "Turks And Caicos Standard Time";
  WindowsZoneName["NewfoundlandStandardTime"] = "Newfoundland Standard Time";
  WindowsZoneName["TocantinsStandardTime"] = "Tocantins Standard Time";
  WindowsZoneName["ESouthAmericaStandardTime"] = "E. South America Standard Time";
  WindowsZoneName["SaEasternStandardTime"] = "SA Eastern Standard Time";
  WindowsZoneName["ArgentinaStandardTime"] = "Argentina Standard Time";
  WindowsZoneName["GreenlandStandardTime"] = "Greenland Standard Time";
  WindowsZoneName["MontevideoStandardTime"] = "Montevideo Standard Time";
  WindowsZoneName["MagallanesStandardTime"] = "Magallanes Standard Time";
  WindowsZoneName["SaintPierreStandardTime"] = "Saint Pierre Standard Time";
  WindowsZoneName["BahiaStandardTime"] = "Bahia Standard Time";
  WindowsZoneName["UtcMinus_02"] = "UTC-02";
  WindowsZoneName["AzoresStandardTime"] = "Azores Standard Time";
  WindowsZoneName["CapeVerdeStandardTime"] = "Cape Verde Standard Time";
  WindowsZoneName["Utc"] = "UTC";
  WindowsZoneName["GmtStandardTime"] = "GMT Standard Time";
  WindowsZoneName["GreenwichStandardTime"] = "Greenwich Standard Time";
  WindowsZoneName["WEuropeStandardTime"] = "W. Europe Standard Time";
  WindowsZoneName["CentralEuropeStandardTime"] = "Central Europe Standard Time";
  WindowsZoneName["RomanceStandardTime"] = "Romance Standard Time";
  WindowsZoneName["MoroccoStandardTime"] = "Morocco Standard Time";
  WindowsZoneName["SaoTomeStandardTime"] = "Sao Tome Standard Time";
  WindowsZoneName["CentralEuropeanStandardTime"] = "Central European Standard Time";
  WindowsZoneName["WCentralAfricaStandardTime"] = "W. Central Africa Standard Time";
  WindowsZoneName["JordanStandardTime"] = "Jordan Standard Time";
  WindowsZoneName["GtbStandardTime"] = "GTB Standard Time";
  WindowsZoneName["MiddleEastStandardTime"] = "Middle East Standard Time";
  WindowsZoneName["EgyptStandardTime"] = "Egypt Standard Time";
  WindowsZoneName["EEuropeStandardTime"] = "E. Europe Standard Time";
  WindowsZoneName["SyriaStandardTime"] = "Syria Standard Time";
  WindowsZoneName["WestBankStandardTime"] = "West Bank Standard Time";
  WindowsZoneName["SouthAfricaStandardTime"] = "South Africa Standard Time";
  WindowsZoneName["FleStandardTime"] = "FLE Standard Time";
  WindowsZoneName["IsraelStandardTime"] = "Israel Standard Time";
  WindowsZoneName["KaliningradStandardTime"] = "Kaliningrad Standard Time";
  WindowsZoneName["SudanStandardTime"] = "Sudan Standard Time";
  WindowsZoneName["LibyaStandardTime"] = "Libya Standard Time";
  WindowsZoneName["NamibiaStandardTime"] = "Namibia Standard Time";
  WindowsZoneName["ArabicStandardTime"] = "Arabic Standard Time";
  WindowsZoneName["TurkeyStandardTime"] = "Turkey Standard Time";
  WindowsZoneName["ArabStandardTime"] = "Arab Standard Time";
  WindowsZoneName["BelarusStandardTime"] = "Belarus Standard Time";
  WindowsZoneName["RussianStandardTime"] = "Russian Standard Time";
  WindowsZoneName["EAfricaStandardTime"] = "E. Africa Standard Time";
  WindowsZoneName["IranStandardTime"] = "Iran Standard Time";
  WindowsZoneName["ArabianStandardTime"] = "Arabian Standard Time";
  WindowsZoneName["AstrakhanStandardTime"] = "Astrakhan Standard Time";
  WindowsZoneName["AzerbaijanStandardTime"] = "Azerbaijan Standard Time";
  WindowsZoneName["RussiaTimeZone_3"] = "Russia Time Zone 3";
  WindowsZoneName["MauritiusStandardTime"] = "Mauritius Standard Time";
  WindowsZoneName["SaratovStandardTime"] = "Saratov Standard Time";
  WindowsZoneName["GeorgianStandardTime"] = "Georgian Standard Time";
  WindowsZoneName["CaucasusStandardTime"] = "Caucasus Standard Time";
  WindowsZoneName["AfghanistanStandardTime"] = "Afghanistan Standard Time";
  WindowsZoneName["WestAsiaStandardTime"] = "West Asia Standard Time";
  WindowsZoneName["EkaterinburgStandardTime"] = "Ekaterinburg Standard Time";
  WindowsZoneName["PakistanStandardTime"] = "Pakistan Standard Time";
  WindowsZoneName["IndiaStandardTime"] = "India Standard Time";
  WindowsZoneName["SriLankaStandardTime"] = "Sri Lanka Standard Time";
  WindowsZoneName["NepalStandardTime"] = "Nepal Standard Time";
  WindowsZoneName["CentralAsiaStandardTime"] = "Central Asia Standard Time";
  WindowsZoneName["BangladeshStandardTime"] = "Bangladesh Standard Time";
  WindowsZoneName["OmskStandardTime"] = "Omsk Standard Time";
  WindowsZoneName["MyanmarStandardTime"] = "Myanmar Standard Time";
  WindowsZoneName["SeAsiaStandardTime"] = "SE Asia Standard Time";
  WindowsZoneName["AltaiStandardTime"] = "Altai Standard Time";
  WindowsZoneName["WMongoliaStandardTime"] = "W. Mongolia Standard Time";
  WindowsZoneName["NorthAsiaStandardTime"] = "North Asia Standard Time";
  WindowsZoneName["NCentralAsiaStandardTime"] = "N. Central Asia Standard Time";
  WindowsZoneName["TomskStandardTime"] = "Tomsk Standard Time";
  WindowsZoneName["ChinaStandardTime"] = "China Standard Time";
  WindowsZoneName["NorthAsiaEastStandardTime"] = "North Asia East Standard Time";
  WindowsZoneName["SingaporeStandardTime"] = "Singapore Standard Time";
  WindowsZoneName["WAustraliaStandardTime"] = "W. Australia Standard Time";
  WindowsZoneName["TaipeiStandardTime"] = "Taipei Standard Time";
  WindowsZoneName["UlaanbaatarStandardTime"] = "Ulaanbaatar Standard Time";
  WindowsZoneName["AusCentralWStandardTime"] = "Aus Central W. Standard Time";
  WindowsZoneName["TransbaikalStandardTime"] = "Transbaikal Standard Time";
  WindowsZoneName["TokyoStandardTime"] = "Tokyo Standard Time";
  WindowsZoneName["NorthKoreaStandardTime"] = "North Korea Standard Time";
  WindowsZoneName["KoreaStandardTime"] = "Korea Standard Time";
  WindowsZoneName["YakutskStandardTime"] = "Yakutsk Standard Time";
  WindowsZoneName["CenAustraliaStandardTime"] = "Cen. Australia Standard Time";
  WindowsZoneName["AusCentralStandardTime"] = "AUS Central Standard Time";
  WindowsZoneName["EAustraliaStandardTime"] = "E. Australia Standard Time";
  WindowsZoneName["AusEasternStandardTime"] = "AUS Eastern Standard Time";
  WindowsZoneName["WestPacificStandardTime"] = "West Pacific Standard Time";
  WindowsZoneName["TasmaniaStandardTime"] = "Tasmania Standard Time";
  WindowsZoneName["VladivostokStandardTime"] = "Vladivostok Standard Time";
  WindowsZoneName["LordHoweStandardTime"] = "Lord Howe Standard Time";
  WindowsZoneName["BougainvilleStandardTime"] = "Bougainville Standard Time";
  WindowsZoneName["RussiaTimeZone_10"] = "Russia Time Zone 10";
  WindowsZoneName["MagadanStandardTime"] = "Magadan Standard Time";
  WindowsZoneName["NorfolkStandardTime"] = "Norfolk Standard Time";
  WindowsZoneName["SakhalinStandardTime"] = "Sakhalin Standard Time";
  WindowsZoneName["CentralPacificStandardTime"] = "Central Pacific Standard Time";
  WindowsZoneName["RussiaTimeZone_11"] = "Russia Time Zone 11";
  WindowsZoneName["NewZealandStandardTime"] = "New Zealand Standard Time";
  WindowsZoneName["UtcMinusPlus_12"] = "UTC+12";
  WindowsZoneName["FijiStandardTime"] = "Fiji Standard Time";
  WindowsZoneName["ChathamIslandsStandardTime"] = "Chatham Islands Standard Time";
  WindowsZoneName["UtcMinusPlus_13"] = "UTC+13";
  WindowsZoneName["TongaStandardTime"] = "Tonga Standard Time";
  WindowsZoneName["SamoaStandardTime"] = "Samoa Standard Time";
  WindowsZoneName["LineIslandsStandardTime"] = "Line Islands Standard Time";
})(WindowsZoneName = exports.WindowsZoneName || (exports.WindowsZoneName = {}));
var Territory;
(function (Territory) {
  Territory["001"] = "001";
  Territory["Zz"] = "ZZ";
  Territory["As"] = "AS";
  Territory["Nu"] = "NU";
  Territory["Um"] = "UM";
  Territory["Us"] = "US";
  Territory["Ck"] = "CK";
  Territory["Pf"] = "PF";
  Territory["Mx"] = "MX";
  Territory["Pn"] = "PN";
  Territory["Ca"] = "CA";
  Territory["Bz"] = "BZ";
  Territory["Cr"] = "CR";
  Territory["Ec"] = "EC";
  Territory["Gt"] = "GT";
  Territory["Hn"] = "HN";
  Territory["Ni"] = "NI";
  Territory["Sv"] = "SV";
  Territory["Cl"] = "CL";
  Territory["Br"] = "BR";
  Territory["Co"] = "CO";
  Territory["Jm"] = "JM";
  Territory["Ky"] = "KY";
  Territory["Pa"] = "PA";
  Territory["Pe"] = "PE";
  Territory["Bs"] = "BS";
  Territory["Ht"] = "HT";
  Territory["Cu"] = "CU";
  Territory["Py"] = "PY";
  Territory["Bm"] = "BM";
  Territory["Gl"] = "GL";
  Territory["Ve"] = "VE";
  Territory["Ag"] = "AG";
  Territory["Ai"] = "AI";
  Territory["Aw"] = "AW";
  Territory["Bb"] = "BB";
  Territory["Bl"] = "BL";
  Territory["Bo"] = "BO";
  Territory["Bq"] = "BQ";
  Territory["Cw"] = "CW";
  Territory["Dm"] = "DM";
  Territory["Do"] = "DO";
  Territory["Gd"] = "GD";
  Territory["Gp"] = "GP";
  Territory["Gy"] = "GY";
  Territory["Kn"] = "KN";
  Territory["Lc"] = "LC";
  Territory["Mf"] = "MF";
  Territory["Mq"] = "MQ";
  Territory["Ms"] = "MS";
  Territory["Pr"] = "PR";
  Territory["Sx"] = "SX";
  Territory["Tt"] = "TT";
  Territory["Vc"] = "VC";
  Territory["Vg"] = "VG";
  Territory["Vi"] = "VI";
  Territory["Tc"] = "TC";
  Territory["Aq"] = "AQ";
  Territory["Fk"] = "FK";
  Territory["Gf"] = "GF";
  Territory["Sr"] = "SR";
  Territory["Ar"] = "AR";
  Territory["Uy"] = "UY";
  Territory["Pm"] = "PM";
  Territory["Gs"] = "GS";
  Territory["Pt"] = "PT";
  Territory["Cv"] = "CV";
  Territory["Es"] = "ES";
  Territory["Fo"] = "FO";
  Territory["Gb"] = "GB";
  Territory["Gg"] = "GG";
  Territory["Ie"] = "IE";
  Territory["Im"] = "IM";
  Territory["Je"] = "JE";
  Territory["Bf"] = "BF";
  Territory["Ci"] = "CI";
  Territory["Gh"] = "GH";
  Territory["Gm"] = "GM";
  Territory["Gn"] = "GN";
  Territory["Gw"] = "GW";
  Territory["Is"] = "IS";
  Territory["Lr"] = "LR";
  Territory["Ml"] = "ML";
  Territory["Mr"] = "MR";
  Territory["Sh"] = "SH";
  Territory["Sl"] = "SL";
  Territory["Sn"] = "SN";
  Territory["Tg"] = "TG";
  Territory["Ad"] = "AD";
  Territory["At"] = "AT";
  Territory["Ch"] = "CH";
  Territory["De"] = "DE";
  Territory["Gi"] = "GI";
  Territory["It"] = "IT";
  Territory["Li"] = "LI";
  Territory["Lu"] = "LU";
  Territory["Mc"] = "MC";
  Territory["Mt"] = "MT";
  Territory["Nl"] = "NL";
  Territory["No"] = "NO";
  Territory["Se"] = "SE";
  Territory["Sj"] = "SJ";
  Territory["Sm"] = "SM";
  Territory["Va"] = "VA";
  Territory["Al"] = "AL";
  Territory["Cz"] = "CZ";
  Territory["Hu"] = "HU";
  Territory["Me"] = "ME";
  Territory["Rs"] = "RS";
  Territory["Si"] = "SI";
  Territory["Sk"] = "SK";
  Territory["Be"] = "BE";
  Territory["Dk"] = "DK";
  Territory["Fr"] = "FR";
  Territory["Eh"] = "EH";
  Territory["Ma"] = "MA";
  Territory["St"] = "ST";
  Territory["Ba"] = "BA";
  Territory["Hr"] = "HR";
  Territory["Mk"] = "MK";
  Territory["Pl"] = "PL";
  Territory["Ao"] = "AO";
  Territory["Bj"] = "BJ";
  Territory["Cd"] = "CD";
  Territory["Cf"] = "CF";
  Territory["Cg"] = "CG";
  Territory["Cm"] = "CM";
  Territory["Dz"] = "DZ";
  Territory["Ga"] = "GA";
  Territory["Gq"] = "GQ";
  Territory["Ne"] = "NE";
  Territory["Ng"] = "NG";
  Territory["Td"] = "TD";
  Territory["Tn"] = "TN";
  Territory["Jo"] = "JO";
  Territory["Cy"] = "CY";
  Territory["Gr"] = "GR";
  Territory["Ro"] = "RO";
  Territory["Lb"] = "LB";
  Territory["Eg"] = "EG";
  Territory["Md"] = "MD";
  Territory["Sy"] = "SY";
  Territory["Ps"] = "PS";
  Territory["Bi"] = "BI";
  Territory["Bw"] = "BW";
  Territory["Ls"] = "LS";
  Territory["Mw"] = "MW";
  Territory["Mz"] = "MZ";
  Territory["Rw"] = "RW";
  Territory["Sz"] = "SZ";
  Territory["Za"] = "ZA";
  Territory["Zm"] = "ZM";
  Territory["Zw"] = "ZW";
  Territory["Ax"] = "AX";
  Territory["Bg"] = "BG";
  Territory["Ee"] = "EE";
  Territory["Fi"] = "FI";
  Territory["Lt"] = "LT";
  Territory["Lv"] = "LV";
  Territory["Ua"] = "UA";
  Territory["Il"] = "IL";
  Territory["Ru"] = "RU";
  Territory["Sd"] = "SD";
  Territory["Ly"] = "LY";
  Territory["Na"] = "NA";
  Territory["Iq"] = "IQ";
  Territory["Tr"] = "TR";
  Territory["Bh"] = "BH";
  Territory["Kw"] = "KW";
  Territory["Qa"] = "QA";
  Territory["Sa"] = "SA";
  Territory["Ye"] = "YE";
  Territory["By"] = "BY";
  Territory["Dj"] = "DJ";
  Territory["Er"] = "ER";
  Territory["Et"] = "ET";
  Territory["Ke"] = "KE";
  Territory["Km"] = "KM";
  Territory["Mg"] = "MG";
  Territory["So"] = "SO";
  Territory["Ss"] = "SS";
  Territory["Tz"] = "TZ";
  Territory["Ug"] = "UG";
  Territory["Yt"] = "YT";
  Territory["Ir"] = "IR";
  Territory["Ae"] = "AE";
  Territory["Om"] = "OM";
  Territory["Az"] = "AZ";
  Territory["Mu"] = "MU";
  Territory["Re"] = "RE";
  Territory["Sc"] = "SC";
  Territory["Ge"] = "GE";
  Territory["Am"] = "AM";
  Territory["Af"] = "AF";
  Territory["Kz"] = "KZ";
  Territory["Mv"] = "MV";
  Territory["Tf"] = "TF";
  Territory["Tj"] = "TJ";
  Territory["Tm"] = "TM";
  Territory["Uz"] = "UZ";
  Territory["Pk"] = "PK";
  Territory["In"] = "IN";
  Territory["Lk"] = "LK";
  Territory["Np"] = "NP";
  Territory["Cn"] = "CN";
  Territory["Io"] = "IO";
  Territory["Kg"] = "KG";
  Territory["Bd"] = "BD";
  Territory["Bt"] = "BT";
  Territory["Cc"] = "CC";
  Territory["Mm"] = "MM";
  Territory["Cx"] = "CX";
  Territory["Id"] = "ID";
  Territory["Kh"] = "KH";
  Territory["La"] = "LA";
  Territory["Th"] = "TH";
  Territory["Vn"] = "VN";
  Territory["Mn"] = "MN";
  Territory["Hk"] = "HK";
  Territory["Mo"] = "MO";
  Territory["Bn"] = "BN";
  Territory["My"] = "MY";
  Territory["Ph"] = "PH";
  Territory["Sg"] = "SG";
  Territory["Au"] = "AU";
  Territory["Tw"] = "TW";
  Territory["Jp"] = "JP";
  Territory["Pw"] = "PW";
  Territory["Tl"] = "TL";
  Territory["Kp"] = "KP";
  Territory["Kr"] = "KR";
  Territory["Fm"] = "FM";
  Territory["Gu"] = "GU";
  Territory["Mp"] = "MP";
  Territory["Pg"] = "PG";
  Territory["Nf"] = "NF";
  Territory["Nc"] = "NC";
  Territory["Sb"] = "SB";
  Territory["Vu"] = "VU";
  Territory["Nz"] = "NZ";
  Territory["Ki"] = "KI";
  Territory["Mh"] = "MH";
  Territory["Nr"] = "NR";
  Territory["Tv"] = "TV";
  Territory["Wf"] = "WF";
  Territory["Fj"] = "FJ";
  Territory["Tk"] = "TK";
  Territory["To"] = "TO";
  Territory["Ws"] = "WS";
})(Territory = exports.Territory || (exports.Territory = {}));
var IanaName;
(function (IanaName) {
  IanaName["EtcGmtMinusPlus_12"] = "Etc/GMT+12";
  IanaName["EtcGmtMinusPlus_11"] = "Etc/GMT+11";
  IanaName["PacificPagoPago"] = "Pacific/Pago_Pago";
  IanaName["PacificNiue"] = "Pacific/Niue";
  IanaName["PacificMidway"] = "Pacific/Midway";
  IanaName["AmericaAdak"] = "America/Adak";
  IanaName["PacificHonolulu"] = "Pacific/Honolulu";
  IanaName["PacificRarotonga"] = "Pacific/Rarotonga";
  IanaName["PacificTahiti"] = "Pacific/Tahiti";
  IanaName["PacificJohnston"] = "Pacific/Johnston";
  IanaName["EtcGmtMinusPlus_10"] = "Etc/GMT+10";
  IanaName["PacificMarquesas"] = "Pacific/Marquesas";
  IanaName["AmericaAnchorage"] = "America/Anchorage";
  IanaName["AmericaJuneau"] = "America/Juneau";
  IanaName["AmericaNome"] = "America/Nome";
  IanaName["AmericaSitka"] = "America/Sitka";
  IanaName["AmericaYakutat"] = "America/Yakutat";
  IanaName["EtcGmtMinusPlus_9"] = "Etc/GMT+9";
  IanaName["PacificGambier"] = "Pacific/Gambier";
  IanaName["AmericaTijuana"] = "America/Tijuana";
  IanaName["AmericaSantaIsabel"] = "America/Santa_Isabel";
  IanaName["EtcGmtMinusPlus_8"] = "Etc/GMT+8";
  IanaName["PacificPitcairn"] = "Pacific/Pitcairn";
  IanaName["AmericaLosAngeles"] = "America/Los_Angeles";
  IanaName["AmericaVancouver"] = "America/Vancouver";
  IanaName["AmericaDawson"] = "America/Dawson";
  IanaName["AmericaWhitehorse"] = "America/Whitehorse";
  IanaName["AmericaMetlakatla"] = "America/Metlakatla";
  IanaName["Pst8Pdt"] = "PST8PDT";
  IanaName["AmericaPhoenix"] = "America/Phoenix";
  IanaName["AmericaDawsonCreek"] = "America/Dawson_Creek";
  IanaName["AmericaCreston"] = "America/Creston";
  IanaName["AmericaFortNelson"] = "America/Fort_Nelson";
  IanaName["AmericaHermosillo"] = "America/Hermosillo";
  IanaName["EtcGmtMinusPlus_7"] = "Etc/GMT+7";
  IanaName["AmericaChihuahua"] = "America/Chihuahua";
  IanaName["AmericaMazatlan"] = "America/Mazatlan";
  IanaName["AmericaDenver"] = "America/Denver";
  IanaName["AmericaEdmonton"] = "America/Edmonton";
  IanaName["AmericaCambridgeBay"] = "America/Cambridge_Bay";
  IanaName["AmericaInuvik"] = "America/Inuvik";
  IanaName["AmericaYellowknife"] = "America/Yellowknife";
  IanaName["AmericaOjinaga"] = "America/Ojinaga";
  IanaName["AmericaBoise"] = "America/Boise";
  IanaName["Mst7Mdt"] = "MST7MDT";
  IanaName["AmericaGuatemala"] = "America/Guatemala";
  IanaName["AmericaBelize"] = "America/Belize";
  IanaName["AmericaCostaRica"] = "America/Costa_Rica";
  IanaName["PacificGalapagos"] = "Pacific/Galapagos";
  IanaName["AmericaTegucigalpa"] = "America/Tegucigalpa";
  IanaName["AmericaManagua"] = "America/Managua";
  IanaName["AmericaElSalvador"] = "America/El_Salvador";
  IanaName["EtcGmtMinusPlus_6"] = "Etc/GMT+6";
  IanaName["AmericaChicago"] = "America/Chicago";
  IanaName["AmericaWinnipeg"] = "America/Winnipeg";
  IanaName["AmericaRainyRiver"] = "America/Rainy_River";
  IanaName["AmericaRankinInlet"] = "America/Rankin_Inlet";
  IanaName["AmericaResolute"] = "America/Resolute";
  IanaName["AmericaMatamoros"] = "America/Matamoros";
  IanaName["AmericaIndianaKnox"] = "America/Indiana/Knox";
  IanaName["AmericaIndianaTellCity"] = "America/Indiana/Tell_City";
  IanaName["AmericaMenominee"] = "America/Menominee";
  IanaName["AmericaNorthDakotaBeulah"] = "America/North_Dakota/Beulah";
  IanaName["AmericaNorthDakotaCenter"] = "America/North_Dakota/Center";
  IanaName["AmericaNorthDakotaNewSalem"] = "America/North_Dakota/New_Salem";
  IanaName["Cst6Cdt"] = "CST6CDT";
  IanaName["PacificEaster"] = "Pacific/Easter";
  IanaName["AmericaMexicoCity"] = "America/Mexico_City";
  IanaName["AmericaBahiaBanderas"] = "America/Bahia_Banderas";
  IanaName["AmericaMerida"] = "America/Merida";
  IanaName["AmericaMonterrey"] = "America/Monterrey";
  IanaName["AmericaRegina"] = "America/Regina";
  IanaName["AmericaSwiftCurrent"] = "America/Swift_Current";
  IanaName["AmericaBogota"] = "America/Bogota";
  IanaName["AmericaRioBranco"] = "America/Rio_Branco";
  IanaName["AmericaEirunepe"] = "America/Eirunepe";
  IanaName["AmericaCoralHarbour"] = "America/Coral_Harbour";
  IanaName["AmericaGuayaquil"] = "America/Guayaquil";
  IanaName["AmericaJamaica"] = "America/Jamaica";
  IanaName["AmericaCayman"] = "America/Cayman";
  IanaName["AmericaPanama"] = "America/Panama";
  IanaName["AmericaLima"] = "America/Lima";
  IanaName["EtcGmtMinusPlus_5"] = "Etc/GMT+5";
  IanaName["AmericaCancun"] = "America/Cancun";
  IanaName["AmericaNewYork"] = "America/New_York";
  IanaName["AmericaNassau"] = "America/Nassau";
  IanaName["AmericaToronto"] = "America/Toronto";
  IanaName["AmericaIqaluit"] = "America/Iqaluit";
  IanaName["AmericaMontreal"] = "America/Montreal";
  IanaName["AmericaNipigon"] = "America/Nipigon";
  IanaName["AmericaPangnirtung"] = "America/Pangnirtung";
  IanaName["AmericaThunderBay"] = "America/Thunder_Bay";
  IanaName["AmericaDetroit"] = "America/Detroit";
  IanaName["AmericaIndianaPetersburg"] = "America/Indiana/Petersburg";
  IanaName["AmericaIndianaVincennes"] = "America/Indiana/Vincennes";
  IanaName["AmericaIndianaWinamac"] = "America/Indiana/Winamac";
  IanaName["AmericaKentuckyMonticello"] = "America/Kentucky/Monticello";
  IanaName["AmericaLouisville"] = "America/Louisville";
  IanaName["Est5Edt"] = "EST5EDT";
  IanaName["AmericaPortMinusAuPrince"] = "America/Port-au-Prince";
  IanaName["AmericaHavana"] = "America/Havana";
  IanaName["AmericaIndianapolis"] = "America/Indianapolis";
  IanaName["AmericaIndianaMarengo"] = "America/Indiana/Marengo";
  IanaName["AmericaIndianaVevay"] = "America/Indiana/Vevay";
  IanaName["AmericaAsuncion"] = "America/Asuncion";
  IanaName["AmericaHalifax"] = "America/Halifax";
  IanaName["AtlanticBermuda"] = "Atlantic/Bermuda";
  IanaName["AmericaGlaceBay"] = "America/Glace_Bay";
  IanaName["AmericaGooseBay"] = "America/Goose_Bay";
  IanaName["AmericaMoncton"] = "America/Moncton";
  IanaName["AmericaThule"] = "America/Thule";
  IanaName["AmericaCaracas"] = "America/Caracas";
  IanaName["AmericaCuiaba"] = "America/Cuiaba";
  IanaName["AmericaCampoGrande"] = "America/Campo_Grande";
  IanaName["AmericaLaPaz"] = "America/La_Paz";
  IanaName["AmericaAntigua"] = "America/Antigua";
  IanaName["AmericaAnguilla"] = "America/Anguilla";
  IanaName["AmericaAruba"] = "America/Aruba";
  IanaName["AmericaBarbados"] = "America/Barbados";
  IanaName["AmericaStBarthelemy"] = "America/St_Barthelemy";
  IanaName["AmericaKralendijk"] = "America/Kralendijk";
  IanaName["AmericaManaus"] = "America/Manaus";
  IanaName["AmericaBoaVista"] = "America/Boa_Vista";
  IanaName["AmericaPortoVelho"] = "America/Porto_Velho";
  IanaName["AmericaBlancMinusSablon"] = "America/Blanc-Sablon";
  IanaName["AmericaCuracao"] = "America/Curacao";
  IanaName["AmericaDominica"] = "America/Dominica";
  IanaName["AmericaSantoDomingo"] = "America/Santo_Domingo";
  IanaName["AmericaGrenada"] = "America/Grenada";
  IanaName["AmericaGuadeloupe"] = "America/Guadeloupe";
  IanaName["AmericaGuyana"] = "America/Guyana";
  IanaName["AmericaStKitts"] = "America/St_Kitts";
  IanaName["AmericaStLucia"] = "America/St_Lucia";
  IanaName["AmericaMarigot"] = "America/Marigot";
  IanaName["AmericaMartinique"] = "America/Martinique";
  IanaName["AmericaMontserrat"] = "America/Montserrat";
  IanaName["AmericaPuertoRico"] = "America/Puerto_Rico";
  IanaName["AmericaLowerPrinces"] = "America/Lower_Princes";
  IanaName["AmericaPortOfSpain"] = "America/Port_of_Spain";
  IanaName["AmericaStVincent"] = "America/St_Vincent";
  IanaName["AmericaTortola"] = "America/Tortola";
  IanaName["AmericaStThomas"] = "America/St_Thomas";
  IanaName["EtcGmtMinusPlus_4"] = "Etc/GMT+4";
  IanaName["AmericaSantiago"] = "America/Santiago";
  IanaName["AmericaGrandTurk"] = "America/Grand_Turk";
  IanaName["AmericaStJohns"] = "America/St_Johns";
  IanaName["AmericaAraguaina"] = "America/Araguaina";
  IanaName["AmericaSaoPaulo"] = "America/Sao_Paulo";
  IanaName["AmericaCayenne"] = "America/Cayenne";
  IanaName["AntarcticaRothera"] = "Antarctica/Rothera";
  IanaName["AmericaFortaleza"] = "America/Fortaleza";
  IanaName["AmericaBelem"] = "America/Belem";
  IanaName["AmericaMaceio"] = "America/Maceio";
  IanaName["AmericaRecife"] = "America/Recife";
  IanaName["AmericaSantarem"] = "America/Santarem";
  IanaName["AtlanticStanley"] = "Atlantic/Stanley";
  IanaName["AmericaParamaribo"] = "America/Paramaribo";
  IanaName["EtcGmtMinusPlus_3"] = "Etc/GMT+3";
  IanaName["AmericaBuenosAires"] = "America/Buenos_Aires";
  IanaName["AmericaArgentinaLaRioja"] = "America/Argentina/La_Rioja";
  IanaName["AmericaArgentinaRioGallegos"] = "America/Argentina/Rio_Gallegos";
  IanaName["AmericaArgentinaSalta"] = "America/Argentina/Salta";
  IanaName["AmericaArgentinaSanJuan"] = "America/Argentina/San_Juan";
  IanaName["AmericaArgentinaSanLuis"] = "America/Argentina/San_Luis";
  IanaName["AmericaArgentinaTucuman"] = "America/Argentina/Tucuman";
  IanaName["AmericaArgentinaUshuaia"] = "America/Argentina/Ushuaia";
  IanaName["AmericaCatamarca"] = "America/Catamarca";
  IanaName["AmericaCordoba"] = "America/Cordoba";
  IanaName["AmericaJujuy"] = "America/Jujuy";
  IanaName["AmericaMendoza"] = "America/Mendoza";
  IanaName["AmericaGodthab"] = "America/Godthab";
  IanaName["AmericaMontevideo"] = "America/Montevideo";
  IanaName["AmericaPuntaArenas"] = "America/Punta_Arenas";
  IanaName["AntarcticaPalmer"] = "Antarctica/Palmer";
  IanaName["AmericaMiquelon"] = "America/Miquelon";
  IanaName["AmericaBahia"] = "America/Bahia";
  IanaName["EtcGmtMinusPlus_2"] = "Etc/GMT+2";
  IanaName["AmericaNoronha"] = "America/Noronha";
  IanaName["AtlanticSouthGeorgia"] = "Atlantic/South_Georgia";
  IanaName["AtlanticAzores"] = "Atlantic/Azores";
  IanaName["AmericaScoresbysund"] = "America/Scoresbysund";
  IanaName["AtlanticCapeVerde"] = "Atlantic/Cape_Verde";
  IanaName["EtcGmtMinusPlus_1"] = "Etc/GMT+1";
  IanaName["EtcGmt"] = "Etc/GMT";
  IanaName["AmericaDanmarkshavn"] = "America/Danmarkshavn";
  IanaName["EtcUtc"] = "Etc/UTC";
  IanaName["EuropeLondon"] = "Europe/London";
  IanaName["AtlanticCanary"] = "Atlantic/Canary";
  IanaName["AtlanticFaeroe"] = "Atlantic/Faeroe";
  IanaName["EuropeGuernsey"] = "Europe/Guernsey";
  IanaName["EuropeDublin"] = "Europe/Dublin";
  IanaName["EuropeIsleOfMan"] = "Europe/Isle_of_Man";
  IanaName["EuropeJersey"] = "Europe/Jersey";
  IanaName["EuropeLisbon"] = "Europe/Lisbon";
  IanaName["AtlanticMadeira"] = "Atlantic/Madeira";
  IanaName["AtlanticReykjavik"] = "Atlantic/Reykjavik";
  IanaName["AfricaOuagadougou"] = "Africa/Ouagadougou";
  IanaName["AfricaAbidjan"] = "Africa/Abidjan";
  IanaName["AfricaAccra"] = "Africa/Accra";
  IanaName["AfricaBanjul"] = "Africa/Banjul";
  IanaName["AfricaConakry"] = "Africa/Conakry";
  IanaName["AfricaBissau"] = "Africa/Bissau";
  IanaName["AfricaMonrovia"] = "Africa/Monrovia";
  IanaName["AfricaBamako"] = "Africa/Bamako";
  IanaName["AfricaNouakchott"] = "Africa/Nouakchott";
  IanaName["AtlanticStHelena"] = "Atlantic/St_Helena";
  IanaName["AfricaFreetown"] = "Africa/Freetown";
  IanaName["AfricaDakar"] = "Africa/Dakar";
  IanaName["AfricaLome"] = "Africa/Lome";
  IanaName["EuropeBerlin"] = "Europe/Berlin";
  IanaName["EuropeAndorra"] = "Europe/Andorra";
  IanaName["EuropeVienna"] = "Europe/Vienna";
  IanaName["EuropeZurich"] = "Europe/Zurich";
  IanaName["EuropeBusingen"] = "Europe/Busingen";
  IanaName["EuropeGibraltar"] = "Europe/Gibraltar";
  IanaName["EuropeRome"] = "Europe/Rome";
  IanaName["EuropeVaduz"] = "Europe/Vaduz";
  IanaName["EuropeLuxembourg"] = "Europe/Luxembourg";
  IanaName["EuropeMonaco"] = "Europe/Monaco";
  IanaName["EuropeMalta"] = "Europe/Malta";
  IanaName["EuropeAmsterdam"] = "Europe/Amsterdam";
  IanaName["EuropeOslo"] = "Europe/Oslo";
  IanaName["EuropeStockholm"] = "Europe/Stockholm";
  IanaName["ArcticLongyearbyen"] = "Arctic/Longyearbyen";
  IanaName["EuropeSanMarino"] = "Europe/San_Marino";
  IanaName["EuropeVatican"] = "Europe/Vatican";
  IanaName["EuropeBudapest"] = "Europe/Budapest";
  IanaName["EuropeTirane"] = "Europe/Tirane";
  IanaName["EuropePrague"] = "Europe/Prague";
  IanaName["EuropePodgorica"] = "Europe/Podgorica";
  IanaName["EuropeBelgrade"] = "Europe/Belgrade";
  IanaName["EuropeLjubljana"] = "Europe/Ljubljana";
  IanaName["EuropeBratislava"] = "Europe/Bratislava";
  IanaName["EuropeParis"] = "Europe/Paris";
  IanaName["EuropeBrussels"] = "Europe/Brussels";
  IanaName["EuropeCopenhagen"] = "Europe/Copenhagen";
  IanaName["EuropeMadrid"] = "Europe/Madrid";
  IanaName["AfricaCeuta"] = "Africa/Ceuta";
  IanaName["AfricaCasablanca"] = "Africa/Casablanca";
  IanaName["AfricaElAaiun"] = "Africa/El_Aaiun";
  IanaName["AfricaSaoTome"] = "Africa/Sao_Tome";
  IanaName["EuropeWarsaw"] = "Europe/Warsaw";
  IanaName["EuropeSarajevo"] = "Europe/Sarajevo";
  IanaName["EuropeZagreb"] = "Europe/Zagreb";
  IanaName["EuropeSkopje"] = "Europe/Skopje";
  IanaName["AfricaLagos"] = "Africa/Lagos";
  IanaName["AfricaLuanda"] = "Africa/Luanda";
  IanaName["AfricaPortoMinusNovo"] = "Africa/Porto-Novo";
  IanaName["AfricaKinshasa"] = "Africa/Kinshasa";
  IanaName["AfricaBangui"] = "Africa/Bangui";
  IanaName["AfricaBrazzaville"] = "Africa/Brazzaville";
  IanaName["AfricaDouala"] = "Africa/Douala";
  IanaName["AfricaAlgiers"] = "Africa/Algiers";
  IanaName["AfricaLibreville"] = "Africa/Libreville";
  IanaName["AfricaMalabo"] = "Africa/Malabo";
  IanaName["AfricaNiamey"] = "Africa/Niamey";
  IanaName["AfricaNdjamena"] = "Africa/Ndjamena";
  IanaName["AfricaTunis"] = "Africa/Tunis";
  IanaName["EtcGmtMinus_1"] = "Etc/GMT-1";
  IanaName["AsiaAmman"] = "Asia/Amman";
  IanaName["EuropeBucharest"] = "Europe/Bucharest";
  IanaName["AsiaFamagusta"] = "Asia/Famagusta";
  IanaName["AsiaNicosia"] = "Asia/Nicosia";
  IanaName["EuropeAthens"] = "Europe/Athens";
  IanaName["AsiaBeirut"] = "Asia/Beirut";
  IanaName["AfricaCairo"] = "Africa/Cairo";
  IanaName["EuropeChisinau"] = "Europe/Chisinau";
  IanaName["AsiaDamascus"] = "Asia/Damascus";
  IanaName["AsiaHebron"] = "Asia/Hebron";
  IanaName["AsiaGaza"] = "Asia/Gaza";
  IanaName["AfricaJohannesburg"] = "Africa/Johannesburg";
  IanaName["AfricaBujumbura"] = "Africa/Bujumbura";
  IanaName["AfricaGaborone"] = "Africa/Gaborone";
  IanaName["AfricaLubumbashi"] = "Africa/Lubumbashi";
  IanaName["AfricaMaseru"] = "Africa/Maseru";
  IanaName["AfricaBlantyre"] = "Africa/Blantyre";
  IanaName["AfricaMaputo"] = "Africa/Maputo";
  IanaName["AfricaKigali"] = "Africa/Kigali";
  IanaName["AfricaMbabane"] = "Africa/Mbabane";
  IanaName["AfricaLusaka"] = "Africa/Lusaka";
  IanaName["AfricaHarare"] = "Africa/Harare";
  IanaName["EtcGmtMinus_2"] = "Etc/GMT-2";
  IanaName["EuropeKiev"] = "Europe/Kiev";
  IanaName["EuropeMariehamn"] = "Europe/Mariehamn";
  IanaName["EuropeSofia"] = "Europe/Sofia";
  IanaName["EuropeTallinn"] = "Europe/Tallinn";
  IanaName["EuropeHelsinki"] = "Europe/Helsinki";
  IanaName["EuropeVilnius"] = "Europe/Vilnius";
  IanaName["EuropeRiga"] = "Europe/Riga";
  IanaName["EuropeUzhgorod"] = "Europe/Uzhgorod";
  IanaName["EuropeZaporozhye"] = "Europe/Zaporozhye";
  IanaName["AsiaJerusalem"] = "Asia/Jerusalem";
  IanaName["EuropeKaliningrad"] = "Europe/Kaliningrad";
  IanaName["AfricaKhartoum"] = "Africa/Khartoum";
  IanaName["AfricaTripoli"] = "Africa/Tripoli";
  IanaName["AfricaWindhoek"] = "Africa/Windhoek";
  IanaName["AsiaBaghdad"] = "Asia/Baghdad";
  IanaName["EuropeIstanbul"] = "Europe/Istanbul";
  IanaName["AsiaRiyadh"] = "Asia/Riyadh";
  IanaName["AsiaBahrain"] = "Asia/Bahrain";
  IanaName["AsiaKuwait"] = "Asia/Kuwait";
  IanaName["AsiaQatar"] = "Asia/Qatar";
  IanaName["AsiaAden"] = "Asia/Aden";
  IanaName["EuropeMinsk"] = "Europe/Minsk";
  IanaName["EuropeMoscow"] = "Europe/Moscow";
  IanaName["EuropeKirov"] = "Europe/Kirov";
  IanaName["EuropeVolgograd"] = "Europe/Volgograd";
  IanaName["EuropeSimferopol"] = "Europe/Simferopol";
  IanaName["AfricaNairobi"] = "Africa/Nairobi";
  IanaName["AntarcticaSyowa"] = "Antarctica/Syowa";
  IanaName["AfricaDjibouti"] = "Africa/Djibouti";
  IanaName["AfricaAsmera"] = "Africa/Asmera";
  IanaName["AfricaAddisAbaba"] = "Africa/Addis_Ababa";
  IanaName["IndianComoro"] = "Indian/Comoro";
  IanaName["IndianAntananarivo"] = "Indian/Antananarivo";
  IanaName["AfricaMogadishu"] = "Africa/Mogadishu";
  IanaName["AfricaJuba"] = "Africa/Juba";
  IanaName["AfricaDarEsSalaam"] = "Africa/Dar_es_Salaam";
  IanaName["AfricaKampala"] = "Africa/Kampala";
  IanaName["IndianMayotte"] = "Indian/Mayotte";
  IanaName["EtcGmtMinus_3"] = "Etc/GMT-3";
  IanaName["AsiaTehran"] = "Asia/Tehran";
  IanaName["AsiaDubai"] = "Asia/Dubai";
  IanaName["AsiaMuscat"] = "Asia/Muscat";
  IanaName["EtcGmtMinus_4"] = "Etc/GMT-4";
  IanaName["EuropeAstrakhan"] = "Europe/Astrakhan";
  IanaName["EuropeUlyanovsk"] = "Europe/Ulyanovsk";
  IanaName["AsiaBaku"] = "Asia/Baku";
  IanaName["EuropeSamara"] = "Europe/Samara";
  IanaName["IndianMauritius"] = "Indian/Mauritius";
  IanaName["IndianReunion"] = "Indian/Reunion";
  IanaName["IndianMahe"] = "Indian/Mahe";
  IanaName["EuropeSaratov"] = "Europe/Saratov";
  IanaName["AsiaTbilisi"] = "Asia/Tbilisi";
  IanaName["AsiaYerevan"] = "Asia/Yerevan";
  IanaName["AsiaKabul"] = "Asia/Kabul";
  IanaName["AsiaTashkent"] = "Asia/Tashkent";
  IanaName["AntarcticaMawson"] = "Antarctica/Mawson";
  IanaName["AsiaOral"] = "Asia/Oral";
  IanaName["AsiaAqtau"] = "Asia/Aqtau";
  IanaName["AsiaAqtobe"] = "Asia/Aqtobe";
  IanaName["AsiaAtyrau"] = "Asia/Atyrau";
  IanaName["AsiaQyzylorda"] = "Asia/Qyzylorda";
  IanaName["IndianMaldives"] = "Indian/Maldives";
  IanaName["IndianKerguelen"] = "Indian/Kerguelen";
  IanaName["AsiaDushanbe"] = "Asia/Dushanbe";
  IanaName["AsiaAshgabat"] = "Asia/Ashgabat";
  IanaName["AsiaSamarkand"] = "Asia/Samarkand";
  IanaName["EtcGmtMinus_5"] = "Etc/GMT-5";
  IanaName["AsiaYekaterinburg"] = "Asia/Yekaterinburg";
  IanaName["AsiaKarachi"] = "Asia/Karachi";
  IanaName["AsiaCalcutta"] = "Asia/Calcutta";
  IanaName["AsiaColombo"] = "Asia/Colombo";
  IanaName["AsiaKatmandu"] = "Asia/Katmandu";
  IanaName["AsiaAlmaty"] = "Asia/Almaty";
  IanaName["AntarcticaVostok"] = "Antarctica/Vostok";
  IanaName["AsiaUrumqi"] = "Asia/Urumqi";
  IanaName["IndianChagos"] = "Indian/Chagos";
  IanaName["AsiaBishkek"] = "Asia/Bishkek";
  IanaName["AsiaQostanay"] = "Asia/Qostanay";
  IanaName["EtcGmtMinus_6"] = "Etc/GMT-6";
  IanaName["AsiaDhaka"] = "Asia/Dhaka";
  IanaName["AsiaThimphu"] = "Asia/Thimphu";
  IanaName["AsiaOmsk"] = "Asia/Omsk";
  IanaName["AsiaRangoon"] = "Asia/Rangoon";
  IanaName["IndianCocos"] = "Indian/Cocos";
  IanaName["AsiaBangkok"] = "Asia/Bangkok";
  IanaName["AntarcticaDavis"] = "Antarctica/Davis";
  IanaName["IndianChristmas"] = "Indian/Christmas";
  IanaName["AsiaJakarta"] = "Asia/Jakarta";
  IanaName["AsiaPontianak"] = "Asia/Pontianak";
  IanaName["AsiaPhnomPenh"] = "Asia/Phnom_Penh";
  IanaName["AsiaVientiane"] = "Asia/Vientiane";
  IanaName["AsiaSaigon"] = "Asia/Saigon";
  IanaName["EtcGmtMinus_7"] = "Etc/GMT-7";
  IanaName["AsiaBarnaul"] = "Asia/Barnaul";
  IanaName["AsiaHovd"] = "Asia/Hovd";
  IanaName["AsiaKrasnoyarsk"] = "Asia/Krasnoyarsk";
  IanaName["AsiaNovokuznetsk"] = "Asia/Novokuznetsk";
  IanaName["AsiaNovosibirsk"] = "Asia/Novosibirsk";
  IanaName["AsiaTomsk"] = "Asia/Tomsk";
  IanaName["AsiaShanghai"] = "Asia/Shanghai";
  IanaName["AsiaHongKong"] = "Asia/Hong_Kong";
  IanaName["AsiaMacau"] = "Asia/Macau";
  IanaName["AsiaIrkutsk"] = "Asia/Irkutsk";
  IanaName["AsiaSingapore"] = "Asia/Singapore";
  IanaName["AsiaBrunei"] = "Asia/Brunei";
  IanaName["AsiaMakassar"] = "Asia/Makassar";
  IanaName["AsiaKualaLumpur"] = "Asia/Kuala_Lumpur";
  IanaName["AsiaKuching"] = "Asia/Kuching";
  IanaName["AsiaManila"] = "Asia/Manila";
  IanaName["EtcGmtMinus_8"] = "Etc/GMT-8";
  IanaName["AustraliaPerth"] = "Australia/Perth";
  IanaName["AntarcticaCasey"] = "Antarctica/Casey";
  IanaName["AsiaTaipei"] = "Asia/Taipei";
  IanaName["AsiaUlaanbaatar"] = "Asia/Ulaanbaatar";
  IanaName["AsiaChoibalsan"] = "Asia/Choibalsan";
  IanaName["AustraliaEucla"] = "Australia/Eucla";
  IanaName["AsiaChita"] = "Asia/Chita";
  IanaName["AsiaTokyo"] = "Asia/Tokyo";
  IanaName["AsiaJayapura"] = "Asia/Jayapura";
  IanaName["PacificPalau"] = "Pacific/Palau";
  IanaName["AsiaDili"] = "Asia/Dili";
  IanaName["EtcGmtMinus_9"] = "Etc/GMT-9";
  IanaName["AsiaPyongyang"] = "Asia/Pyongyang";
  IanaName["AsiaSeoul"] = "Asia/Seoul";
  IanaName["AsiaYakutsk"] = "Asia/Yakutsk";
  IanaName["AsiaKhandyga"] = "Asia/Khandyga";
  IanaName["AustraliaAdelaide"] = "Australia/Adelaide";
  IanaName["AustraliaBrokenHill"] = "Australia/Broken_Hill";
  IanaName["AustraliaDarwin"] = "Australia/Darwin";
  IanaName["AustraliaBrisbane"] = "Australia/Brisbane";
  IanaName["AustraliaLindeman"] = "Australia/Lindeman";
  IanaName["AustraliaSydney"] = "Australia/Sydney";
  IanaName["AustraliaMelbourne"] = "Australia/Melbourne";
  IanaName["PacificPortMoresby"] = "Pacific/Port_Moresby";
  IanaName["AntarcticaDumontDUrville"] = "Antarctica/DumontDUrville";
  IanaName["PacificTruk"] = "Pacific/Truk";
  IanaName["PacificGuam"] = "Pacific/Guam";
  IanaName["PacificSaipan"] = "Pacific/Saipan";
  IanaName["EtcGmtMinus_10"] = "Etc/GMT-10";
  IanaName["AustraliaHobart"] = "Australia/Hobart";
  IanaName["AustraliaCurrie"] = "Australia/Currie";
  IanaName["AsiaVladivostok"] = "Asia/Vladivostok";
  IanaName["AsiaUstMinusNera"] = "Asia/Ust-Nera";
  IanaName["AustraliaLordHowe"] = "Australia/Lord_Howe";
  IanaName["PacificBougainville"] = "Pacific/Bougainville";
  IanaName["AsiaSrednekolymsk"] = "Asia/Srednekolymsk";
  IanaName["AsiaMagadan"] = "Asia/Magadan";
  IanaName["PacificNorfolk"] = "Pacific/Norfolk";
  IanaName["AsiaSakhalin"] = "Asia/Sakhalin";
  IanaName["PacificGuadalcanal"] = "Pacific/Guadalcanal";
  IanaName["AntarcticaMacquarie"] = "Antarctica/Macquarie";
  IanaName["PacificPonape"] = "Pacific/Ponape";
  IanaName["PacificKosrae"] = "Pacific/Kosrae";
  IanaName["PacificNoumea"] = "Pacific/Noumea";
  IanaName["PacificEfate"] = "Pacific/Efate";
  IanaName["EtcGmtMinus_11"] = "Etc/GMT-11";
  IanaName["AsiaKamchatka"] = "Asia/Kamchatka";
  IanaName["AsiaAnadyr"] = "Asia/Anadyr";
  IanaName["PacificAuckland"] = "Pacific/Auckland";
  IanaName["AntarcticaMcMurdo"] = "Antarctica/McMurdo";
  IanaName["EtcGmtMinus_12"] = "Etc/GMT-12";
  IanaName["PacificTarawa"] = "Pacific/Tarawa";
  IanaName["PacificMajuro"] = "Pacific/Majuro";
  IanaName["PacificKwajalein"] = "Pacific/Kwajalein";
  IanaName["PacificNauru"] = "Pacific/Nauru";
  IanaName["PacificFunafuti"] = "Pacific/Funafuti";
  IanaName["PacificWake"] = "Pacific/Wake";
  IanaName["PacificWallis"] = "Pacific/Wallis";
  IanaName["PacificFiji"] = "Pacific/Fiji";
  IanaName["PacificChatham"] = "Pacific/Chatham";
  IanaName["EtcGmtMinus_13"] = "Etc/GMT-13";
  IanaName["PacificEnderbury"] = "Pacific/Enderbury";
  IanaName["PacificFakaofo"] = "Pacific/Fakaofo";
  IanaName["PacificTongatapu"] = "Pacific/Tongatapu";
  IanaName["PacificApia"] = "Pacific/Apia";
  IanaName["PacificKiritimati"] = "Pacific/Kiritimati";
  IanaName["EtcGmtMinus_14"] = "Etc/GMT-14";
  IanaName["AntarcticaTroll"] = "Antarctica/Troll";
  IanaName["AmericaArgentinaBuenosAires"] = "America/Argentina/Buenos_Aires";
  IanaName["AmericaArgentinaCordoba"] = "America/Argentina/Cordoba";
  IanaName["AmericaRosario"] = "America/Rosario";
  IanaName["AmericaArgentinaCatamarca"] = "America/Argentina/Catamarca";
  IanaName["AmericaArgentinaComodRivadavia"] = "America/Argentina/ComodRivadavia";
  IanaName["AmericaArgentinaJujuy"] = "America/Argentina/Jujuy";
  IanaName["AmericaArgentinaMendoza"] = "America/Argentina/Mendoza";
  IanaName["PacificSamoa"] = "Pacific/Samoa";
  IanaName["UsSamoa"] = "US/Samoa";
  IanaName["AustraliaSouth"] = "Australia/South";
  IanaName["AustraliaYancowinna"] = "Australia/Yancowinna";
  IanaName["AustraliaQueensland"] = "Australia/Queensland";
  IanaName["AustraliaNorth"] = "Australia/North";
  IanaName["AustraliaTasmania"] = "Australia/Tasmania";
  IanaName["AustraliaLhi"] = "Australia/LHI";
  IanaName["AustraliaVictoria"] = "Australia/Victoria";
  IanaName["AustraliaWest"] = "Australia/West";
  IanaName["AustraliaAct"] = "Australia/ACT";
  IanaName["AustraliaCanberra"] = "Australia/Canberra";
  IanaName["AustraliaNsw"] = "Australia/NSW";
  IanaName["AsiaDacca"] = "Asia/Dacca";
  IanaName["BrazilDeNoronha"] = "Brazil/DeNoronha";
  IanaName["BrazilWest"] = "Brazil/West";
  IanaName["AmericaPortoAcre"] = "America/Porto_Acre";
  IanaName["BrazilAcre"] = "Brazil/Acre";
  IanaName["BrazilEast"] = "Brazil/East";
  IanaName["AsiaThimbu"] = "Asia/Thimbu";
  IanaName["CanadaMountain"] = "Canada/Mountain";
  IanaName["CanadaAtlantic"] = "Canada/Atlantic";
  IanaName["CanadaEastMinusSaskatchewan"] = "Canada/East-Saskatchewan";
  IanaName["CanadaSaskatchewan"] = "Canada/Saskatchewan";
  IanaName["CanadaNewfoundland"] = "Canada/Newfoundland";
  IanaName["CanadaEastern"] = "Canada/Eastern";
  IanaName["CanadaPacific"] = "Canada/Pacific";
  IanaName["CanadaCentral"] = "Canada/Central";
  IanaName["CanadaYukon"] = "Canada/Yukon";
  IanaName["AmericaAtikokan"] = "America/Atikokan";
  IanaName["ChileEasterIsland"] = "Chile/EasterIsland";
  IanaName["ChileContinental"] = "Chile/Continental";
  IanaName["AsiaChongqing"] = "Asia/Chongqing";
  IanaName["AsiaChungking"] = "Asia/Chungking";
  IanaName["AsiaHarbin"] = "Asia/Harbin";
  IanaName["Prc"] = "PRC";
  IanaName["AsiaKashgar"] = "Asia/Kashgar";
  IanaName["Cuba"] = "Cuba";
  IanaName["EuropeNicosia"] = "Europe/Nicosia";
  IanaName["Egypt"] = "Egypt";
  IanaName["AfricaAsmara"] = "Africa/Asmara";
  IanaName["PacificPohnpei"] = "Pacific/Pohnpei";
  IanaName["PacificChuuk"] = "Pacific/Chuuk";
  IanaName["PacificYap"] = "Pacific/Yap";
  IanaName["AtlanticFaroe"] = "Atlantic/Faroe";
  IanaName["EuropeBelfast"] = "Europe/Belfast";
  IanaName["Gb"] = "GB";
  IanaName["GbMinusEire"] = "GB-Eire";
  IanaName["EtcGmtMinusPlus_0"] = "Etc/GMT+0";
  IanaName["EtcGmtMinus_0"] = "Etc/GMT-0";
  IanaName["EtcGmt0"] = "Etc/GMT0";
  IanaName["EtcGreenwich"] = "Etc/Greenwich";
  IanaName["Gmt"] = "GMT";
  IanaName["GmtMinusPlus_0"] = "GMT+0";
  IanaName["GmtMinus_0"] = "GMT-0";
  IanaName["Gmt0"] = "GMT0";
  IanaName["Greenwich"] = "Greenwich";
  IanaName["Hongkong"] = "Hongkong";
  IanaName["AsiaUjungPandang"] = "Asia/Ujung_Pandang";
  IanaName["Eire"] = "Eire";
  IanaName["AsiaKolkata"] = "Asia/Kolkata";
  IanaName["Iran"] = "Iran";
  IanaName["Iceland"] = "Iceland";
  IanaName["AsiaTelAviv"] = "Asia/Tel_Aviv";
  IanaName["Israel"] = "Israel";
  IanaName["Jamaica"] = "Jamaica";
  IanaName["Japan"] = "Japan";
  IanaName["Rok"] = "ROK";
  IanaName["Libya"] = "Libya";
  IanaName["EuropeTiraspol"] = "Europe/Tiraspol";
  IanaName["Kwajalein"] = "Kwajalein";
  IanaName["AfricaTimbuktu"] = "Africa/Timbuktu";
  IanaName["AsiaYangon"] = "Asia/Yangon";
  IanaName["AsiaUlanBator"] = "Asia/Ulan_Bator";
  IanaName["AsiaMacao"] = "Asia/Macao";
  IanaName["MexicoGeneral"] = "Mexico/General";
  IanaName["MexicoBajaSur"] = "Mexico/BajaSur";
  IanaName["AmericaEnsenada"] = "America/Ensenada";
  IanaName["MexicoBajaNorte"] = "Mexico/BajaNorte";
  IanaName["AsiaKathmandu"] = "Asia/Kathmandu";
  IanaName["AntarcticaSouthPole"] = "Antarctica/South_Pole";
  IanaName["Nz"] = "NZ";
  IanaName["NzMinusChat"] = "NZ-CHAT";
  IanaName["Poland"] = "Poland";
  IanaName["Portugal"] = "Portugal";
  IanaName["WMinusSu"] = "W-SU";
  IanaName["Singapore"] = "Singapore";
  IanaName["AtlanticJanMayen"] = "Atlantic/Jan_Mayen";
  IanaName["AsiaAshkhabad"] = "Asia/Ashkhabad";
  IanaName["AsiaIstanbul"] = "Asia/Istanbul";
  IanaName["Turkey"] = "Turkey";
  IanaName["Roc"] = "ROC";
  IanaName["EtcUnknown"] = "Etc/Unknown";
  IanaName["AmericaAtka"] = "America/Atka";
  IanaName["UsAleutian"] = "US/Aleutian";
  IanaName["UsAlaska"] = "US/Alaska";
  IanaName["UsCentral"] = "US/Central";
  IanaName["AmericaShiprock"] = "America/Shiprock";
  IanaName["Navajo"] = "Navajo";
  IanaName["UsMountain"] = "US/Mountain";
  IanaName["UsMichigan"] = "US/Michigan";
  IanaName["UsHawaii"] = "US/Hawaii";
  IanaName["AmericaFortWayne"] = "America/Fort_Wayne";
  IanaName["AmericaIndianaIndianapolis"] = "America/Indiana/Indianapolis";
  IanaName["UsEastMinusIndiana"] = "US/East-Indiana";
  IanaName["AmericaKnoxIn"] = "America/Knox_IN";
  IanaName["UsIndianaMinusStarke"] = "US/Indiana-Starke";
  IanaName["UsPacific"] = "US/Pacific";
  IanaName["UsPacificMinusNew"] = "US/Pacific-New";
  IanaName["AmericaKentuckyLouisville"] = "America/Kentucky/Louisville";
  IanaName["UsEastern"] = "US/Eastern";
  IanaName["UsArizona"] = "US/Arizona";
  IanaName["EtcUct"] = "Etc/UCT";
  IanaName["EtcUniversal"] = "Etc/Universal";
  IanaName["EtcZulu"] = "Etc/Zulu";
  IanaName["Uct"] = "UCT";
  IanaName["Utc"] = "UTC";
  IanaName["Universal"] = "Universal";
  IanaName["Zulu"] = "Zulu";
  IanaName["Est"] = "EST";
  IanaName["Mst"] = "MST";
  IanaName["Hst"] = "HST";
  IanaName["AmericaVirgin"] = "America/Virgin";
  IanaName["AsiaHoChiMinh"] = "Asia/Ho_Chi_Minh";
})(IanaName = exports.IanaName || (exports.IanaName = {}));
