import { RoundOffDateParams } from '@Terra/shared/widgets/interface';
import moment, { Moment, unitOfTime } from 'moment-timezone';
import { checkForNullOrUndefined, exceptionalDateFormatValidation } from './date-time-formatter-helper';
import { DateConvert, DateRange, DateTimeFormatTz, NthDateTime, NthMonth } from './models/util.model';
import { getEndMonthDate } from './moment-date-formatter-helper';
import { UNIVERSAL_DATEFORMAT } from './timezone.config';
import { IDateTime, MomentDateFormat } from './utils.config';

export const getDateTimeByTZ = (dateTime: moment.MomentInput | string, currentFormat: string): moment.Moment =>
  moment.utc(dateTime, currentFormat);

//convert given date to utc date
export const getUTCDateTimeByTZWithDST = ({ dateString, currentFormat, localeName, convertTo }: DateConvert): string =>
  moment
    .tz(dateString as string, currentFormat, localeName)
    .utc()
    .format(convertTo);

export const getNthDateOrTimeWithoutTimeZone = (
  { dateTime, currentFormat, duration, period, convertTo }: NthDateTime,
  isDoubleConversion: boolean
): string => {
  if (isDoubleConversion) {
    return moment(moment(dateTime).add(duration, period).format(currentFormat)).format(convertTo);
  }
  return moment(dateTime, currentFormat).add(duration, period).format(convertTo).toUpperCase();
};

export const getCurrentDateTimeByTZ = (tzName: string, convertTo: string): string =>
  tzName ? moment.tz(tzName).format(convertTo) : moment().format(convertTo);

/**
 *Returns UTC time for the given timezone
 */
export const getUTCDateTimeWithTZ = (date: string, dateFormatMoment: string, timezone: string): string =>
  moment.tz(date, dateFormatMoment, timezone).utc().toISOString();

export const setDateOrTime = (date: string | Date | Moment, duration: number, period: unitOfTime.All): Moment =>
  moment(date).set(period, duration);

export const convertDateRangeTZToISO = (fromDate: string, toDate: string, tzName: string): DateRange => ({
  fromDate: moment.tz(fromDate, tzName).set({ hour: 0, minute: 0, second: 0 }).utc().toISOString(),
  toDate: moment.tz(toDate, tzName).set({ hour: 23, minute: 59, second: 59 }).utc().toISOString()
});

/**
 * convert the datetime to utc timezone, based on the site timezone
 */
export const convertCCDSDateRangeTZToISO = (fromDate: string, toDate: string, tzName: string): DateRange => {
  const jobsiteTodayDate = moment.tz(tzName).format(UNIVERSAL_DATEFORMAT);
  const isTodaySelected = !!(moment(toDate).isSame(jobsiteTodayDate) || moment(toDate).isAfter(jobsiteTodayDate));
  let hour = 23,
    minute = 59,
    second = 59;

  if (isTodaySelected) {
    [hour, minute, second] = moment.tz(tzName).format('HH:mm:ss').split(':') as unknown as number[];
  }
  return {
    fromDate: moment.tz(fromDate, tzName).set({ hour: 0, minute: 0, second: 0 }).utc().toISOString(),
    toDate: moment.tz(toDate, tzName).set({ hour, minute, second }).utc().toISOString()
  };
};

/**
 * @description incase of dateOrTimeFormat it will return currentDate
 * @param dateOrTime
 * @param format
 * @returns return date or time
 */
export const getDateOrTime = (dateOrTime: string, format: string): moment.Moment => moment(dateOrTime, format);

/**
 *
 * @param timezone
 * @returns gives current date/time using timezone
 */
export const getDateByTimeZone = (timezone: string): Moment => moment().tz(timezone);

/**
 *
 * @param offset
 * @returns  gives current date using utcoffset method
 */
export const getDateAndTimeByOffset = (offset: number): moment.Moment => {
  const timeZoneString = moment.tz.guess(true);
  return moment().tz(timeZoneString, true) ?? moment().utcOffset(offset);
};

/**
 * @param startDateOrTime - Input start date or date with time
 * @param endDateDateOrTime - Input end date or date with time
 * @param maxDays - number
 * @param unit - months as unitOfTime.StartOf | unitOfTime.DurationConstructor
 * @param dateFormat - output and input in expected date format
 * @param siteCreationDate - site creation date
 * @returns New Date in required format as { startDateOrTime: string , endDateOrTime: string }.
 */
export const getRoundOffStartAndEndDateOrTime = (params: RoundOffDateParams): Partial<RoundOffDateParams> => {
  const exceeds = (startDate: string, endDate: string): boolean =>
    moment(endDate, params.dateFormat).diff(moment(startDate, params.dateFormat), 'days') + 1 > params.maxDays;
  //if the start date month is same as site creation month then the start date will same as site creation date itself.
  let startDate = moment(params.startDateOrTime, params.dateFormat).isSame(moment(params.siteCreationDate, params.dateFormat), 'month')
    ? getStartDateBasedOnSiteCreationDate(params)
    : moment(params.startDateOrTime, params.dateFormat) //should be the first day of the selected month
        .startOf(params.unit as unitOfTime.StartOf)
        .format(params.dateFormat);

  //check if difference exceeds specified maxDays and it is not current month
  let endDate = getEndMonthDate(
    { dateTime: params.endDateOrTime, currentFormat: params.dateFormat, convertTo: params.dateFormat },
    params.unit as unitOfTime.StartOf
  );
  if (exceeds(startDate, endDate)) {
    //Adjust endDate to be specified maxDays after startDate
    startDate = getNthMonth({ dateTime: startDate, unit: params.unit, noOfMonths: 1, format: params.dateFormat, isStartOf: true });
  }

  //Need to revisit when weekly, quarterly, yearly and other options are introduced
  if (exceeds(startDate, endDate)) {
    endDate = getNthMonth({ dateTime: endDate, unit: params.unit, noOfMonths: 1, format: params.dateFormat, isStartOf: false });
  }

  return {
    startDateOrTime: startDate,
    endDateOrTime: endDate
  };
};

/**
 * @param startDateOrTime - Input start date or date with time
 * @param unit - months as unitOfTime.StartOf | unitOfTime.DurationConstructor
 * @param dateFormat - output and input in expected date format
 * @param siteCreationDate - site creation date
 * @returns new start date (if the it's under 13 month scenario start date should be the first day of next month otherwise same as site creation date).
 */
export const getStartDateBasedOnSiteCreationDate = (params: RoundOffDateParams): string => {
  if (moment(moment().format(params.dateFormat)).diff(moment(params.siteCreationDate, params.dateFormat), 'month', true) < 13) {
    return moment(params.siteCreationDate, params.dateFormat).format(params.dateFormat);
  }
  return getNthMonth({ dateTime: params.startDateOrTime, unit: params.unit, noOfMonths: 1, format: params.dateFormat, isStartOf: true });
};

/**
 * @param dateTime - moment date time
 * @param unit - Date format of return value
 * @param noOfMonths - number of months
 * @param isStartOf - get the start of or end of value
 * @returns start of or end of the months
 */
export const getNthMonth = ({ dateTime, unit, noOfMonths, format, isStartOf }: NthMonth): string => {
  const momentDate = moment(dateTime, format).clone();
  return (
    isStartOf
      ? momentDate.add(noOfMonths, 'month').startOf(unit as unitOfTime.StartOf)
      : momentDate.subtract(noOfMonths, 'month').endOf(unit as unitOfTime.StartOf)
  ).format(format);
};

/**
 * @param date - Input start date or date with time
 * @returns  Date in required format as MMM Date(start date of input) - Date(end date of input month) year.
 */

export const getStartEndDateMonth = (date: string, dateFormat: string): string => {
  const monthStartDate = moment(date, dateFormat).startOf('month').format('MMM DD');
  const year = moment(date, dateFormat).format('YYYY');
  const endDate = moment(getEndMonthDate({ dateTime: date, currentFormat: dateFormat, convertTo: dateFormat }, 'M')).format('DD');
  return `${monthStartDate} - ${endDate}, ${year}`;
};

/**
 * @param date - Input date with time
 * @param currentFormat - Format of the input
 * @returns Month Name and Year
 */

export const getMonthNameAndYear = (dateOrTime: string, currentFormat: string): string =>
  moment(dateOrTime, currentFormat).format('MMM, YYYY');

/**
 *
 * @param dateTime1 Input Date1
 * @param dateTime2 Input Date2
 * @param currentFormat Input Date Format
 * @param unit months as unitOfTime.StartOf | unitOfTime.DurationConstructor
 * @returns check date is same based on unit
 */
export const checkSameDates = ({ dateTime, toDateTime, format, unit }: NthMonth): boolean =>
  moment(dateTime, format).isSame(moment(toDateTime, format), unit as unitOfTime.StartOf);

export const checkDateValueToSplit = (format: string, dateValue: moment.MomentInput): boolean =>
  typeof dateValue === 'string' && moment(dateValue, [format], true).isValid();

export const convertDateOrTimeFormat = ({ dateTime, currentFormat, convertTo }: DateTimeFormatTz, convert: boolean = false): string => {
  if (checkForNullOrUndefined(dateTime)) {
    return '';
  }
  const dateFormat = exceptionalDateFormatValidation(dateTime, convertTo);
  const _dateFormat = currentFormat ? moment(dateFormat, [currentFormat]) : moment(dateFormat);
  return convert ? _dateFormat.format(convertTo) : _dateFormat['_d'];
};

// Return dateTime Picker string date Time
export const getDateTimePickerStringValue = (dateTime: IDateTime, dateFormat: string = MomentDateFormat.DATE_MONTH_YEAR): string => {
  let finalValue = '';
  let updatedFullTime = '';
  const updatedtime = dateTime.time;
  const timeGap = updatedtime.hour > 12 ? 12 : 0;
  const timeFormat = updatedtime.hour >= 12 ? 'PM' : 'AM';
  updatedFullTime = `${updatedtime.hour - timeGap}:${updatedtime.minute} ${timeFormat}`;
  if (dateFormat === MomentDateFormat.DATE_MONTH_YEAR) {
    finalValue = `${new Date(dateTime.date).getDate()} ${moment().month(new Date(dateTime.date).getMonth()).format('MMM')} ${new Date(
      dateTime.date
    ).getFullYear()} ${updatedFullTime}`;
  } else {
    finalValue = `${moment().month(new Date(dateTime.date).getMonth()).format('MMM')} ${new Date(dateTime.date).getDate()} ${new Date(
      dateTime.date
    ).getFullYear()} ${updatedFullTime}`;
  }

  return finalValue;
};
