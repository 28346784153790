"use strict";

exports.__esModule = true;
var enums_1 = require("./enums");
var iana_aliases_1 = require("./iana-aliases");
var time_zone_map_1 = require("./time-zone-map");
exports.findIana = function (windowsTimeZone, territory) {
  if (territory === void 0) {
    territory = enums_1.Territory["001"];
  }
  var windowsZoneNameEnum = windowsTimeZone;
  var territoryEnum = territory;
  var entry = time_zone_map_1.map.find(function (_a) {
    var itemName = _a.windowsName,
      itemTerritory = _a.territory;
    return itemName === windowsZoneNameEnum && itemTerritory === territoryEnum;
  });
  if (typeof entry === "undefined") return undefined;
  var result = [];
  entry.iana.map(exports.findAlias).forEach(function (aliasSet) {
    if (typeof aliasSet === "undefined") return;
    result.push.apply(result, aliasSet);
  });
  return result;
};
exports.findOneIana = function (windowsTimeZone, territory) {
  if (territory === void 0) {
    territory = enums_1.Territory["001"];
  }
  var result = exports.findIana(windowsTimeZone, territory);
  if (typeof result === "undefined") return undefined;
  return result[0];
};
exports.findWindows = function (ianaTimeZone) {
  var result;
  var aliases = exports.findAlias(ianaTimeZone);
  if (typeof aliases === "undefined") return undefined;
  aliases.find(function (alias) {
    var entry = time_zone_map_1.map.find(function (_a) {
      var itemName = _a.iana;
      return itemName.includes(alias);
    });
    if (typeof entry === "undefined") return false;
    result = entry.windowsName;
    return true;
  });
  return result;
};
exports.findAlias = function (ianaTimeZone) {
  var ianaNameEnum = ianaTimeZone;
  var entry = iana_aliases_1.map.find(function (_a) {
    var alias = _a.alias;
    return alias.includes(ianaNameEnum);
  });
  if (typeof entry === "undefined") return undefined;
  return entry.alias;
};
exports.getAllIanaWindowsMap = function () {
  var map = new Map();
  for (var IanaKeyName in enums_1.IanaName) {
    if (enums_1.IanaName.hasOwnProperty(IanaKeyName)) {
      var ianaKeyName = IanaKeyName;
      var windowsAlias = exports.findWindows(enums_1.IanaName[ianaKeyName]);
      if (typeof windowsAlias !== "undefined") {
        map.set(enums_1.IanaName[ianaKeyName], windowsAlias);
      }
    }
  }
  return map;
};
