import { BBox, Coord, Feature, FeatureCollection, Id, LineString, MultiPolygon, Point, Polygon, Position, Units } from '@turf/turf';
import { catchError, Observable, Observer, retry, throwError, timer } from 'rxjs';
import { TURF_CONFIG } from './utils.config';
declare const turf: any;

export const removeScript = (src: string): void => {
  const scripts = document.querySelectorAll(`script[src="${src}"]`);
  scripts.forEach(script => script.remove());
};

export const loadTurfScript = (src: string): HTMLScriptElement => {
  const script: any = document.createElement(TURF_CONFIG.SCRIPT);
  script.src = src;
  document.head.appendChild(script);
  return script;
};

export const loadScript$ = (): Observable<HTMLScriptElement> =>
  new Observable((observer: Observer<HTMLScriptElement>) => {
    if (typeof turf !== 'undefined') {
      observer.next(turf);
      observer.complete();
      return;
    }
    const script = loadTurfScript(TURF_CONFIG.SCRIPT_SRC);
    script.onload = () => {
      observer.next(turf);
      observer.complete();
    };
    script.onerror = () => {
      removeScript(TURF_CONFIG.SCRIPT_SRC);
      observer.error({ script: 'turf', loaded: false, status: 'Load Failed' });
    };
  }).pipe(
    retry({
      count: TURF_CONFIG.MIN_COUNT_CHECK,
      delay: () => timer(TURF_CONFIG.DELAY_TIMER) // Wait 1 second before retrying
    }),
    catchError(error => throwError(() => error))
  );

// Returns a Point feature from a Position.
export const getPoint = (
  position: Position,
  properties?: { [name: string]: any },
  options?: { bbox?: BBox; id?: Id }
): Feature<Point, { [name: string]: any }> => turf.point(position, properties, options);

// Calculates and returns the distance between two points in degrees, radians, miles, or kilometers
export const getDistance = (from: Coord, to: Coord, options?: { units?: Units }): number => turf.distance(from, to, options);

export const getPointsWithinPolygon = (
  points: Feature<Point, { [name: string]: any }>,
  polygons: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>
): FeatureCollection<Point> => turf.pointsWithinPolygon(points, polygons);

export const getPolygon = (coordinates: Position[][]): Feature<Polygon> => turf.polygon(coordinates);

export const getTurf = () => turf;

export const getLineString = (coordinates: Polygon[][]): LineString => turf.lineString(coordinates);

export const getLineIntersect = (line1: LineString, line2: LineString): FeatureCollection<Point> => turf.lineIntersect(line1, line2);
