import { TableConfiguration } from '@Terra/shared/widgets/interface';

export enum TableDataTypes {
  string = 'string',
  number = 'number',
  weight = 'weight',
  distance = 'distance',
  percentage = 'percentage',
  payloadPerFuel = 'payloadPerFuel',
  fuel = 'fuel',
  fuelPerRate = 'fuelPerRate',
  datetimeReport = 'datetimeReport',
  payloadPerHour = 'payloadPerHour'
}

export const VIEW_CONFIG = {
  views: null,
  selectedView: null,
  pageId: null
};

export const TABLE_COLUMN_HEADER_TEXT = (translationScopeText: string): { [key: string]: string } => ({
  RUNTIME_HOURS: `${translationScopeText}.runtime_hours`,
  IDLE_HOURS: `${translationScopeText}.idle_hours`,
  DOWNLOAD_TITLE: `${translationScopeText}.download`,
  PAYLOAD_PER_CYCLE: `${translationScopeText}.payload_per_cycle`,
  DISTANCE_PER_CYCLE: `${translationScopeText}.distance_per_cycle`,
  WORKING_HOURS: `${translationScopeText}.working_hours`,
  IDLE_PERCENTAGE: `${translationScopeText}.idlePercentage`,
  DAILY_FUEL: `${translationScopeText}.dailyFuel`,
  IDLE_FUEL: `${translationScopeText}.idleFuel`,
  IDLE_FUEL_RATE: `${translationScopeText}.idleFuelRate`,
  WORKING_FUEL: `${translationScopeText}.workingFuel`,
  WORKING_FUEL_RATE: `${translationScopeText}.workingFuelRate`,
  LIFE_TIME_CYCLES_METER: `${translationScopeText}.lifetimeCyclesMeter`,
  PAYLOAD_PER_FUEL: `${translationScopeText}.payload_per_fuel`
});

export const PAGE_SIZE_OPTIONS = [20, 40, 60, 80];

export const INITIAL_PAGE = 1;

export const VIEW_PAGINATION_CONTENT = (translationScope: string): { [key: string]: string } => ({
  viewLbl: `${translationScope}.viewing_LBL`,
  ofLbl: `${translationScope}.of`,
  itemsPerPage: `${translationScope}.itemsPerpage`
});

export const SINGLE_ASSETS_TABLE_CONFIG = (translationScope: string): TableConfiguration => ({
  height: 430,
  stickyHeader: true,
  isSortEnable: true,
  expandable: false,
  footer: true,
  isPaginationEnable: true, //pagination (header and footer)
  isEnableHeaderPagination: true, //enables header pagination (supports 2.0 only)
  showFooterPaginationOnScroll: false, //show footer pagination only when scrolled to the bottom of table
  isDisableFooterPagination: true,
  showFooterPaginationOnlyOnScroll: true,
  columnConfig: [],
  isTheme: '2.0',
  noOfColumnInMobile: 0,
  scrollOnHover: false,
  pagination: {
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    advanced: false,
    activePage: 1,
    defaultPageSize: 20,
    setTheme: true,
    enableDropdownWithPrefix: true,
    totalNumberOfRecords: 0,
    paginationLabelText: VIEW_PAGINATION_CONTENT(translationScope)['viewLbl'],
    paginationItemsLabelText: VIEW_PAGINATION_CONTENT(translationScope)['itemsPerPage'],
    ofText: VIEW_PAGINATION_CONTENT(translationScope)['ofLbl']
  }
});

export const PAGINATION_EVENTS = {
  PAGE_CHANGE_EVENT: 'PAGE_CHANGE_EVENT',
  SORTING_EVENT: 'SORTING',
  DROPDOWN_EVENT: 'PAGINATION_DROPDOWN_EVENT'
};

export const COLUMN_PARAMS_FOR_DOWNLOAD = {
  cycleCount: 'totalCycle',
  fuelBurned: 'totalFuel'
};

export const SORT_ORDER = {
  desc: 'DESC',
  asc: 'ASC'
};

export const SINGLE_ASSETS_TABLE_DEFAULT_PARAM = {
  detailsSelectedPage: INITIAL_PAGE,
  detailsSelectedPageRange: PAGE_SIZE_OPTIONS[0],
  detailsSortOrder: SORT_ORDER.desc,
  detailsTotalLength: 0
};

export const DEFAULT_TABLE_VIEW = {
  id: 0,
  name: '',
  gridColumns: [],
  sortedColumns: null,
  pageId: '',
  catRecID: ''
};
