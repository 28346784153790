import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widget-three-dot-spinner',
  templateUrl: './three-dot-spinner.component.html',
  styleUrls: ['./three-dot-spinner.component.scss']
})
export class ThreeDotSpinnerComponent {
  @Input() size = 'medium';
  loaderColor = '#0078D6';
}
