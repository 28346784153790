import { Pipe, PipeTransform } from '@angular/core';
import { InputUnitParam } from '../common.model';
import { APPEND_UNIT_PIPE_CONSTANTS } from './append-unit.config';
import { getAppendUnit, getUnits } from './append-unit.helper';

@Pipe({
  name: 'appendUnit',
  standalone: true
})
export class AppendUnitPipe implements PipeTransform {
  transform(value?: string, params?: InputUnitParam, transformType?: string): string | number {
    if (
      transformType === APPEND_UNIT_PIPE_CONSTANTS.SplitHrMinAndSec ||
      transformType === APPEND_UNIT_PIPE_CONSTANTS.UnitSymbolForMeasurement
    ) {
      return getUnits(value, params, transformType);
    }
    return getAppendUnit(value, params, transformType);
  }
}
