export const METRICS = {
  volume: 'volume',
  weight: 'weight',
  fuel: 'fuel',
  cost: 'cost',
  costvolume: 'costvolume',
  costweight: 'costweight',
  mass: 'mass'
};

export const METRIC_TYPE = [
  METRICS.volume,
  METRICS.costvolume,
  'costfuel',
  METRICS.fuel,
  'fuelPerRate',
  'payloadPerHour',
  'payloadPerFuel',
  'distance',
  METRICS.mass,
  METRICS.weight,
  METRICS.costweight
];

export const DEFAULT_METRIC_TYPE = [METRICS.weight, METRICS.costweight];
