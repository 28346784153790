import { round } from '@Terra/shared/widgets/utils';

export const roundOffTransform = (kpiValue, isTwoDigitPrecision = false) => {
  if (Array.isArray(kpiValue)) {
    // To handle Array of KPI values
    return kpiValue.map(element => {
      let { value } = element;
      if (value && value.includes('%')) {
        value = `${roundOff(Number(value.replace('%', '')), isTwoDigitPrecision)}%`;
      } else {
        value = !value || isNaN(value) ? value : roundOff(Number(value), isTwoDigitPrecision);
      }
      return { ...element, value };
    });
  } else {
    // To handle single value
    return !kpiValue || typeof kpiValue !== 'number' ? kpiValue : roundOff(kpiValue, isTwoDigitPrecision);
  }
};

export const roundOff = (value, isTwoDigitPrecision: boolean) => {
  if (isTwoDigitPrecision) {
    return Number(Math.round(Number(value + 'e+2')) + 'e-2'); //Two digit precision logic
  } else {
    if (value < 1000) {
      return `${round(value, 1)}`;
    } else if (value < 10000) {
      const result = round(value);
      return result === 10000 ? `${result / 1000}K` : `${result}`;
    } else {
      return `${round(value / 1000, 2)}K`;
    }
  }
};
