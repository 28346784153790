import moment, { unitOfTime } from 'moment-timezone';
import { NthDateTime, StartEndTime, UniversalTimeZone } from './models/util.model';
import { TimeZoneList, UNIVERSAL_TIME_ZONE } from './utils.config';
import { getSelectedTimeZoneWithIana } from './utils.helper';

// convert the date to UTC time format
export const convertToUTCTime = (date: string | Date, utcOffset: number, convertToFormat: string): string =>
  moment.utc(date).utcOffset(utcOffset).format(convertToFormat);

/* returns difference in hours between start date and end date */
export const getDiffInHours = (
  startDate: string | moment.MomentInput,
  endDate: string | moment.MomentInput,
  unit: unitOfTime.Diff
): number => {
  const first = moment(startDate);
  const last = moment(endDate);
  return last.diff(first, unit);
};

export const getCurrentTZOffset = (): number => moment().utcOffset();

/***
 * Returns standard timeszone for the given timezone
 */
export const getStandardTimezone = (timezone?: string): string => {
  timezone = timezone || moment.tz.guess(true);
  return timezone;
};

export const getTimeInSeconds = (time: string, format: string): number => moment(time, format).diff(moment().startOf('day'), 'seconds');

//get browser time zone
export const getBrowserTimeZone = (): string => moment.tz.guess();

/**
 *
 * @param hasPeriod
 * @param isStartOf
 * @param period
 * @param format
 * @returns return the UTC date using startof and format
 */
export const getUTCStartEndTime = ({ hasPeriod, isStartOf, period, format }: StartEndTime): string => {
  if (hasPeriod) {
    return isStartOf ? moment().startOf(period).utc().format(format) : moment().endOf(period).utc().format(format);
  }
  return moment().utc().format(format);
};

/**
 *
 * @param isStartOf
 * @param period
 * @returns return date with startof or endOf methods
 */
export const getTimeWithPeriod = (isStartOf: boolean, period: unitOfTime.StartOf, date?: string): moment.Moment => {
  if (period === 'week') {
    moment.updateLocale(' ', { week: { dow: 1 } });
  }
  const momentObj = date ? moment(date) : moment();
  return isStartOf ? momentObj.startOf(period) : momentObj.endOf(period);
};

/**
 *
 * @param date
 * @param format
 * @param days
 * @param period
 * @returns gives Datetimein the given format using subtract method
 */
export const getTimeWithSubtract = ({ dateTime, currentFormat, duration, period }: NthDateTime): moment.Moment =>
  moment(dateTime, currentFormat).subtract(duration, period);

/**
 *
 * @param date
 * @param format
 * @param offset
 * @param days
 * @param period
 * @returns gives dateTime in the Given format using utcOffset and subtract method
 */
export const getUTCTimeWithOffSet = ({ dateTime, currentFormat, timeZoneOffset, duration, period }: NthDateTime): string =>
  moment(dateTime, currentFormat)
    .utcOffset(timeZoneOffset)
    .subtract(duration, period)
    .utc()
    .format(currentFormat as string);

/**
 *
 * @param date
 * @param format
 * @param offset
 * @param days
 * @param period
 * @returns gives date/time for given format using utcoffset and subtract method
 */
export const getTimeWithOffSet = ({ dateTime, currentFormat, timeZoneOffset, duration, period }: NthDateTime): string =>
  moment(dateTime, currentFormat)
    .utcOffset(timeZoneOffset)
    .subtract(duration, period)
    .format(currentFormat as string);

//return offset time
export const getOffsetTime = (ianaName: string): string => moment(new Date().toUTCString()).tz(ianaName).format('Z');

export const getDefaultTimeZoneOffset = (): string | number => moment().utcOffset();

/**
 *This function returns offset from time zone to be added
 *
 * @param {string} zone
 * @returns
 */
export const getTimeZoneOffset = (zone: string, isAdd: boolean = true, dateUTC?: Date): string => {
  let offset = '';
  let val;
  let timezone;
  UNIVERSAL_TIME_ZONE.forEach((item: UniversalTimeZone) => {
    if (item.standardName === zone) {
      offset = String(item.displayName);
      ({ timezone } = item);
    }
  });
  if (moment.tz.zone(timezone) && dateUTC) {
    val = moment(dateUTC).tz(timezone).format('Z');
  } else {
    const parser = /([+]|-)\d{2}:\d{2}/;
    const matchedVal = offset.match(parser);
    val = matchedVal ? matchedVal[0] : null;
  }
  return getTimeZoneOffsetHelper(isAdd, val);
};

export const getTimeZoneOffsetHelper = (isAdd: boolean, val: string): string => {
  if (isAdd && val) {
    val = val[0] === '+' ? val.replace('+', '-') : val.replace('-', '+');
  }
  return val;
};

//Get Daylight savings UTC timezone
export const getDSTTimeZoneWithIana = (list: TimeZoneList[], ianaName: string): string => {
  const dstTimezone = getSelectedTimeZoneWithIana(list, ianaName);
  if (!dstTimezone) {
    return null;
  }
  let { displayName } = dstTimezone;
  if (moment(new Date().toUTCString()).tz(ianaName)?.isDST()) {
    const offset = moment(new Date().toUTCString()).tz(ianaName).format('Z');
    displayName = displayName.replace(displayName.slice(displayName.indexOf('('), displayName.indexOf(')') + 1), `(UTC${offset})`);
  }
  return displayName;
};

export const getDatepickerZoneOffsetInMinutes = (zone: string): number => {
  let offsetInMinutes;
  if (zone) {
    offsetInMinutes = -1 * moment.tz(zone).utcOffset();
  }
  return offsetInMinutes;
};
