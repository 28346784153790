[
  {
    "name": "adalv",
    "description": "Andorra",
    "alias": ["Europe/Andorra"]
  },
  {
    "name": "aedxb",
    "description": "Dubai, United Arab Emirates",
    "alias": ["Asia/Dubai"]
  },
  {
    "name": "afkbl",
    "description": "Kabul, Afghanistan",
    "alias": ["Asia/Kabul"]
  },
  {
    "name": "aganu",
    "description": "Antigua",
    "alias": ["America/Antigua"]
  },
  {
    "name": "aiaxa",
    "description": "Anguilla",
    "alias": ["America/Anguilla"]
  },
  {
    "name": "altia",
    "description": "Tirane, Albania",
    "alias": ["Europe/Tirane"]
  },
  {
    "name": "amevn",
    "description": "Yerevan, Armenia",
    "alias": ["Asia/Yerevan"]
  },
  {
    "name": "ancur",
    "description": "Curaçao",
    "alias": ["America/Curacao"]
  },
  {
    "name": "aolad",
    "description": "Luanda, Angola",
    "alias": ["Africa/Luanda"]
  },
  {
    "name": "aqcas",
    "description": "Casey Station, Bailey Peninsula",
    "alias": ["Antarctica/Casey"]
  },
  {
    "name": "aqdav",
    "description": "Davis Station, Vestfold Hills",
    "alias": ["Antarctica/Davis"]
  },
  {
    "name": "aqddu",
    "description": "Dumont d'Urville Station, Terre Adélie",
    "alias": ["Antarctica/DumontDUrville"]
  },
  {
    "name": "aqmaw",
    "description": "Mawson Station, Holme Bay",
    "alias": ["Antarctica/Mawson"]
  },
  {
    "name": "aqmcm",
    "description": "McMurdo Station, Ross Island",
    "alias": ["Antarctica/McMurdo"]
  },
  {
    "name": "aqplm",
    "description": "Palmer Station, Anvers Island",
    "alias": ["Antarctica/Palmer"]
  },
  {
    "name": "aqrot",
    "description": "Rothera Station, Adelaide Island",
    "alias": ["Antarctica/Rothera"]
  },
  {
    "name": "aqsyw",
    "description": "Syowa Station, East Ongul Island",
    "alias": ["Antarctica/Syowa"]
  },
  {
    "name": "aqtrl",
    "description": "Troll Station, Queen Maud Land",
    "alias": ["Antarctica/Troll"]
  },
  {
    "name": "aqvos",
    "description": "Vostok Station, Lake Vostok",
    "alias": ["Antarctica/Vostok"]
  },
  {
    "name": "arbue",
    "description": "Buenos Aires, Argentina",
    "alias": ["America/Buenos_Aires", "America/Argentina/Buenos_Aires"]
  },
  {
    "name": "arcor",
    "description": "Córdoba, Argentina",
    "alias": ["America/Cordoba", "America/Argentina/Cordoba", "America/Rosario"]
  },
  {
    "name": "arctc",
    "description": "Catamarca, Argentina",
    "alias": ["America/Catamarca", "America/Argentina/Catamarca", "America/Argentina/ComodRivadavia"]
  },
  {
    "name": "arirj",
    "description": "La Rioja, Argentina",
    "alias": ["America/Argentina/La_Rioja"]
  },
  {
    "name": "arjuj",
    "description": "Jujuy, Argentina",
    "alias": ["America/Jujuy", "America/Argentina/Jujuy"]
  },
  {
    "name": "arluq",
    "description": "San Luis, Argentina",
    "alias": ["America/Argentina/San_Luis"]
  },
  {
    "name": "armdz",
    "description": "Mendoza, Argentina",
    "alias": ["America/Mendoza", "America/Argentina/Mendoza"]
  },
  {
    "name": "arrgl",
    "description": "Río Gallegos, Argentina",
    "alias": ["America/Argentina/Rio_Gallegos"]
  },
  {
    "name": "arsla",
    "description": "Salta, Argentina",
    "alias": ["America/Argentina/Salta"]
  },
  {
    "name": "artuc",
    "description": "Tucumán, Argentina",
    "alias": ["America/Argentina/Tucuman"]
  },
  {
    "name": "aruaq",
    "description": "San Juan, Argentina",
    "alias": ["America/Argentina/San_Juan"]
  },
  {
    "name": "arush",
    "description": "Ushuaia, Argentina",
    "alias": ["America/Argentina/Ushuaia"]
  },
  {
    "name": "asppg",
    "description": "Pago Pago, American Samoa",
    "alias": ["Pacific/Pago_Pago", "Pacific/Samoa", "US/Samoa"]
  },
  {
    "name": "atvie",
    "description": "Vienna, Austria",
    "alias": ["Europe/Vienna"]
  },
  {
    "name": "auadl",
    "description": "Adelaide, Australia",
    "alias": ["Australia/Adelaide", "Australia/South"]
  },
  {
    "name": "aubhq",
    "description": "Broken Hill, Australia",
    "alias": ["Australia/Broken_Hill", "Australia/Yancowinna"]
  },
  {
    "name": "aubne",
    "description": "Brisbane, Australia",
    "alias": ["Australia/Brisbane", "Australia/Queensland"]
  },
  {
    "name": "audrw",
    "description": "Darwin, Australia",
    "alias": ["Australia/Darwin", "Australia/North"]
  },
  {
    "name": "aueuc",
    "description": "Eucla, Australia",
    "alias": ["Australia/Eucla"]
  },
  {
    "name": "auhba",
    "description": "Hobart, Australia",
    "alias": ["Australia/Hobart", "Australia/Tasmania"]
  },
  {
    "name": "aukns",
    "description": "Currie, Australia",
    "alias": ["Australia/Currie"]
  },
  {
    "name": "auldc",
    "description": "Lindeman Island, Australia",
    "alias": ["Australia/Lindeman"]
  },
  {
    "name": "auldh",
    "description": "Lord Howe Island, Australia",
    "alias": ["Australia/Lord_Howe", "Australia/LHI"]
  },
  {
    "name": "aumel",
    "description": "Melbourne, Australia",
    "alias": ["Australia/Melbourne", "Australia/Victoria"]
  },
  {
    "name": "aumqi",
    "description": "Macquarie Island Station, Macquarie Island",
    "alias": ["Antarctica/Macquarie"]
  },
  {
    "name": "auper",
    "description": "Perth, Australia",
    "alias": ["Australia/Perth", "Australia/West"]
  },
  {
    "name": "ausyd",
    "description": "Sydney, Australia",
    "alias": ["Australia/Sydney", "Australia/ACT", "Australia/Canberra", "Australia/NSW"]
  },
  {
    "name": "awaua",
    "description": "Aruba",
    "alias": ["America/Aruba"]
  },
  {
    "name": "azbak",
    "description": "Baku, Azerbaijan",
    "alias": ["Asia/Baku"]
  },
  {
    "name": "basjj",
    "description": "Sarajevo, Bosnia and Herzegovina",
    "alias": ["Europe/Sarajevo"]
  },
  {
    "name": "bbbgi",
    "description": "Barbados",
    "alias": ["America/Barbados"]
  },
  {
    "name": "bddac",
    "description": "Dhaka, Bangladesh",
    "alias": ["Asia/Dhaka", "Asia/Dacca"]
  },
  {
    "name": "bebru",
    "description": "Brussels, Belgium",
    "alias": ["Europe/Brussels"]
  },
  {
    "name": "bfoua",
    "description": "Ouagadougou, Burkina Faso",
    "alias": ["Africa/Ouagadougou"]
  },
  {
    "name": "bgsof",
    "description": "Sofia, Bulgaria",
    "alias": ["Europe/Sofia"]
  },
  {
    "name": "bhbah",
    "description": "Bahrain",
    "alias": ["Asia/Bahrain"]
  },
  {
    "name": "bibjm",
    "description": "Bujumbura, Burundi",
    "alias": ["Africa/Bujumbura"]
  },
  {
    "name": "bjptn",
    "description": "Porto-Novo, Benin",
    "alias": ["Africa/Porto-Novo"]
  },
  {
    "name": "bmbda",
    "description": "Bermuda",
    "alias": ["Atlantic/Bermuda"]
  },
  {
    "name": "bnbwn",
    "description": "Brunei",
    "alias": ["Asia/Brunei"]
  },
  {
    "name": "bolpb",
    "description": "La Paz, Bolivia",
    "alias": ["America/La_Paz"]
  },
  {
    "name": "bqkra",
    "description": "Bonaire, Sint Estatius and Saba",
    "alias": ["America/Kralendijk"]
  },
  {
    "name": "braux",
    "description": "Araguaína, Brazil",
    "alias": ["America/Araguaina"]
  },
  {
    "name": "brbel",
    "description": "Belém, Brazil",
    "alias": ["America/Belem"]
  },
  {
    "name": "brbvb",
    "description": "Boa Vista, Brazil",
    "alias": ["America/Boa_Vista"]
  },
  {
    "name": "brcgb",
    "description": "Cuiabá, Brazil",
    "alias": ["America/Cuiaba"]
  },
  {
    "name": "brcgr",
    "description": "Campo Grande, Brazil",
    "alias": ["America/Campo_Grande"]
  },
  {
    "name": "brern",
    "description": "Eirunepé, Brazil",
    "alias": ["America/Eirunepe"]
  },
  {
    "name": "brfen",
    "description": "Fernando de Noronha, Brazil",
    "alias": ["America/Noronha", "Brazil/DeNoronha"]
  },
  {
    "name": "brfor",
    "description": "Fortaleza, Brazil",
    "alias": ["America/Fortaleza"]
  },
  {
    "name": "brmao",
    "description": "Manaus, Brazil",
    "alias": ["America/Manaus", "Brazil/West"]
  },
  {
    "name": "brmcz",
    "description": "Maceió, Brazil",
    "alias": ["America/Maceio"]
  },
  {
    "name": "brpvh",
    "description": "Porto Velho, Brazil",
    "alias": ["America/Porto_Velho"]
  },
  {
    "name": "brrbr",
    "description": "Rio Branco, Brazil",
    "alias": ["America/Rio_Branco", "America/Porto_Acre", "Brazil/Acre"]
  },
  {
    "name": "brrec",
    "description": "Recife, Brazil",
    "alias": ["America/Recife"]
  },
  {
    "name": "brsao",
    "description": "São Paulo, Brazil",
    "alias": ["America/Sao_Paulo", "Brazil/East"]
  },
  {
    "name": "brssa",
    "description": "Bahia, Brazil",
    "alias": ["America/Bahia"]
  },
  {
    "name": "brstm",
    "description": "Santarém, Brazil",
    "alias": ["America/Santarem"]
  },
  {
    "name": "bsnas",
    "description": "Nassau, Bahamas",
    "alias": ["America/Nassau"]
  },
  {
    "name": "btthi",
    "description": "Thimphu, Bhutan",
    "alias": ["Asia/Thimphu", "Asia/Thimbu"]
  },
  {
    "name": "bwgbe",
    "description": "Gaborone, Botswana",
    "alias": ["Africa/Gaborone"]
  },
  {
    "name": "bymsq",
    "description": "Minsk, Belarus",
    "alias": ["Europe/Minsk"]
  },
  {
    "name": "bzbze",
    "description": "Belize",
    "alias": ["America/Belize"]
  },
  {
    "name": "cacfq",
    "description": "Creston, Canada",
    "alias": ["America/Creston"]
  },
  {
    "name": "caedm",
    "description": "Edmonton, Canada",
    "alias": ["America/Edmonton", "Canada/Mountain"]
  },
  {
    "name": "caffs",
    "description": "Rainy River, Canada",
    "alias": ["America/Rainy_River"]
  },
  {
    "name": "cafne",
    "description": "Fort Nelson, Canada",
    "alias": ["America/Fort_Nelson"]
  },
  {
    "name": "caglb",
    "description": "Glace Bay, Canada",
    "alias": ["America/Glace_Bay"]
  },
  {
    "name": "cagoo",
    "description": "Goose Bay, Canada",
    "alias": ["America/Goose_Bay"]
  },
  {
    "name": "cahal",
    "description": "Halifax, Canada",
    "alias": ["America/Halifax", "Canada/Atlantic"]
  },
  {
    "name": "caiql",
    "description": "Iqaluit, Canada",
    "alias": ["America/Iqaluit"]
  },
  {
    "name": "camon",
    "description": "Moncton, Canada",
    "alias": ["America/Moncton"]
  },
  {
    "name": "capnt",
    "description": "Pangnirtung, Canada",
    "alias": ["America/Pangnirtung"]
  },
  {
    "name": "careb",
    "description": "Resolute, Canada",
    "alias": ["America/Resolute"]
  },
  {
    "name": "careg",
    "description": "Regina, Canada",
    "alias": ["America/Regina", "Canada/East-Saskatchewan", "Canada/Saskatchewan"]
  },
  {
    "name": "casjf",
    "description": "St. John's, Canada",
    "alias": ["America/St_Johns", "Canada/Newfoundland"]
  },
  {
    "name": "canpg",
    "description": "Nipigon, Canada",
    "alias": ["America/Nipigon"]
  },
  {
    "name": "cathu",
    "description": "Thunder Bay, Canada",
    "alias": ["America/Thunder_Bay"]
  },
  {
    "name": "cator",
    "description": "Toronto, Canada",
    "alias": ["America/Toronto", "Canada/Eastern"]
  },
  {
    "name": "cavan",
    "description": "Vancouver, Canada",
    "alias": ["America/Vancouver", "Canada/Pacific"]
  },
  {
    "name": "cawnp",
    "description": "Winnipeg, Canada",
    "alias": ["America/Winnipeg", "Canada/Central"]
  },
  {
    "name": "caybx",
    "description": "Blanc-Sablon, Canada",
    "alias": ["America/Blanc-Sablon"]
  },
  {
    "name": "caycb",
    "description": "Cambridge Bay, Canada",
    "alias": ["America/Cambridge_Bay"]
  },
  {
    "name": "cayda",
    "description": "Dawson, Canada",
    "alias": ["America/Dawson"]
  },
  {
    "name": "caydq",
    "description": "Dawson Creek, Canada",
    "alias": ["America/Dawson_Creek"]
  },
  {
    "name": "cayek",
    "description": "Rankin Inlet, Canada",
    "alias": ["America/Rankin_Inlet"]
  },
  {
    "name": "cayev",
    "description": "Inuvik, Canada",
    "alias": ["America/Inuvik"]
  },
  {
    "name": "cayxy",
    "description": "Whitehorse, Canada",
    "alias": ["America/Whitehorse", "Canada/Yukon"]
  },
  {
    "name": "cayyn",
    "description": "Swift Current, Canada",
    "alias": ["America/Swift_Current"]
  },
  {
    "name": "cayzf",
    "description": "Yellowknife, Canada",
    "alias": ["America/Yellowknife"]
  },
  {
    "name": "cayzs",
    "description": "Atikokan, Canada",
    "alias": ["America/Coral_Harbour", "America/Atikokan"]
  },
  {
    "name": "cccck",
    "description": "Cocos (Keeling) Islands",
    "alias": ["Indian/Cocos"]
  },
  {
    "name": "cdfbm",
    "description": "Lubumbashi, Democratic Republic of the Congo",
    "alias": ["Africa/Lubumbashi"]
  },
  {
    "name": "cdfih",
    "description": "Kinshasa, Democratic Republic of the Congo",
    "alias": ["Africa/Kinshasa"]
  },
  {
    "name": "cfbgf",
    "description": "Bangui, Central African Republic",
    "alias": ["Africa/Bangui"]
  },
  {
    "name": "cgbzv",
    "description": "Brazzaville, Republic of the Congo",
    "alias": ["Africa/Brazzaville"]
  },
  {
    "name": "chzrh",
    "description": "Zurich, Switzerland",
    "alias": ["Europe/Zurich"]
  },
  {
    "name": "ciabj",
    "description": "Abidjan, Côte d'Ivoire",
    "alias": ["Africa/Abidjan"]
  },
  {
    "name": "ckrar",
    "description": "Rarotonga, Cook Islands",
    "alias": ["Pacific/Rarotonga"]
  },
  {
    "name": "clipc",
    "description": "Easter Island, Chile",
    "alias": ["Pacific/Easter", "Chile/EasterIsland"]
  },
  {
    "name": "clpuq",
    "description": "Punta Arenas, Chile",
    "alias": ["America/Punta_Arenas"]
  },
  {
    "name": "clscl",
    "description": "Santiago, Chile",
    "alias": ["America/Santiago", "Chile/Continental"]
  },
  {
    "name": "cmdla",
    "description": "Douala, Cameroon",
    "alias": ["Africa/Douala"]
  },
  {
    "name": "cnsha",
    "description": "Shanghai, China",
    "alias": ["Asia/Shanghai", "Asia/Chongqing", "Asia/Chungking", "Asia/Harbin", "PRC"]
  },
  {
    "name": "cnurc",
    "description": "Ürümqi, China",
    "alias": ["Asia/Urumqi", "Asia/Kashgar"]
  },
  {
    "name": "cobog",
    "description": "Bogotá, Colombia",
    "alias": ["America/Bogota"]
  },
  {
    "name": "crsjo",
    "description": "Costa Rica",
    "alias": ["America/Costa_Rica"]
  },
  {
    "name": "cst6cdt",
    "description": "POSIX style time zone for US Central Time",
    "alias": ["CST6CDT"]
  },
  {
    "name": "cuhav",
    "description": "Havana, Cuba",
    "alias": ["America/Havana", "Cuba"]
  },
  {
    "name": "cvrai",
    "description": "Cape Verde",
    "alias": ["Atlantic/Cape_Verde"]
  },
  {
    "name": "cxxch",
    "description": "Christmas Island",
    "alias": ["Indian/Christmas"]
  },
  {
    "name": "cyfmg",
    "description": "Famagusta, Cyprus",
    "alias": ["Asia/Famagusta"]
  },
  {
    "name": "cynic",
    "description": "Nicosia, Cyprus",
    "alias": ["Asia/Nicosia", "Europe/Nicosia"]
  },
  {
    "name": "czprg",
    "description": "Prague, Czech Republic",
    "alias": ["Europe/Prague"]
  },
  {
    "name": "deber",
    "description": "Berlin, Germany",
    "alias": ["Europe/Berlin"]
  },
  {
    "name": "debsngn",
    "description": "Busingen, Germany",
    "alias": ["Europe/Busingen"]
  },
  {
    "name": "djjib",
    "description": "Djibouti",
    "alias": ["Africa/Djibouti"]
  },
  {
    "name": "dkcph",
    "description": "Copenhagen, Denmark",
    "alias": ["Europe/Copenhagen"]
  },
  {
    "name": "dmdom",
    "description": "Dominica",
    "alias": ["America/Dominica"]
  },
  {
    "name": "dosdq",
    "description": "Santo Domingo, Dominican Republic",
    "alias": ["America/Santo_Domingo"]
  },
  {
    "name": "dzalg",
    "description": "Algiers, Algeria",
    "alias": ["Africa/Algiers"]
  },
  {
    "name": "ecgps",
    "description": "Galápagos Islands, Ecuador",
    "alias": ["Pacific/Galapagos"]
  },
  {
    "name": "ecgye",
    "description": "Guayaquil, Ecuador",
    "alias": ["America/Guayaquil"]
  },
  {
    "name": "eetll",
    "description": "Tallinn, Estonia",
    "alias": ["Europe/Tallinn"]
  },
  {
    "name": "egcai",
    "description": "Cairo, Egypt",
    "alias": ["Africa/Cairo", "Egypt"]
  },
  {
    "name": "eheai",
    "description": "El Aaiún, Western Sahara",
    "alias": ["Africa/El_Aaiun"]
  },
  {
    "name": "erasm",
    "description": "Asmara, Eritrea",
    "alias": ["Africa/Asmera", "Africa/Asmara"]
  },
  {
    "name": "esceu",
    "description": "Ceuta, Spain",
    "alias": ["Africa/Ceuta"]
  },
  {
    "name": "eslpa",
    "description": "Canary Islands, Spain",
    "alias": ["Atlantic/Canary"]
  },
  {
    "name": "esmad",
    "description": "Madrid, Spain",
    "alias": ["Europe/Madrid"]
  },
  {
    "name": "est5edt",
    "description": "POSIX style time zone for US Eastern Time",
    "alias": ["EST5EDT"]
  },
  {
    "name": "etadd",
    "description": "Addis Ababa, Ethiopia",
    "alias": ["Africa/Addis_Ababa"]
  },
  {
    "name": "fihel",
    "description": "Helsinki, Finland",
    "alias": ["Europe/Helsinki"]
  },
  {
    "name": "fimhq",
    "description": "Mariehamn, Åland, Finland",
    "alias": ["Europe/Mariehamn"]
  },
  {
    "name": "fjsuv",
    "description": "Fiji",
    "alias": ["Pacific/Fiji"]
  },
  {
    "name": "fkpsy",
    "description": "Stanley, Falkland Islands",
    "alias": ["Atlantic/Stanley"]
  },
  {
    "name": "fmksa",
    "description": "Kosrae, Micronesia",
    "alias": ["Pacific/Kosrae"]
  },
  {
    "name": "fmpni",
    "description": "Pohnpei, Micronesia",
    "alias": ["Pacific/Ponape", "Pacific/Pohnpei"]
  },
  {
    "name": "fmtkk",
    "description": "Chuuk, Micronesia",
    "alias": ["Pacific/Truk", "Pacific/Chuuk", "Pacific/Yap"]
  },
  {
    "name": "fotho",
    "description": "Faroe Islands",
    "alias": ["Atlantic/Faeroe", "Atlantic/Faroe"]
  },
  {
    "name": "frpar",
    "description": "Paris, France",
    "alias": ["Europe/Paris"]
  },
  {
    "name": "galbv",
    "description": "Libreville, Gabon",
    "alias": ["Africa/Libreville"]
  },
  {
    "name": "gaza",
    "description": "Gaza Strip, Palestinian Territories",
    "alias": ["Asia/Gaza"]
  },
  {
    "name": "gblon",
    "description": "London, United Kingdom",
    "alias": ["Europe/London", "Europe/Belfast", "GB", "GB-Eire"]
  },
  {
    "name": "gdgnd",
    "description": "Grenada",
    "alias": ["America/Grenada"]
  },
  {
    "name": "getbs",
    "description": "Tbilisi, Georgia",
    "alias": ["Asia/Tbilisi"]
  },
  {
    "name": "gfcay",
    "description": "Cayenne, French Guiana",
    "alias": ["America/Cayenne"]
  },
  {
    "name": "gggci",
    "description": "Guernsey",
    "alias": ["Europe/Guernsey"]
  },
  {
    "name": "ghacc",
    "description": "Accra, Ghana",
    "alias": ["Africa/Accra"]
  },
  {
    "name": "gigib",
    "description": "Gibraltar",
    "alias": ["Europe/Gibraltar"]
  },
  {
    "name": "gldkshvn",
    "description": "Danmarkshavn, Greenland",
    "alias": ["America/Danmarkshavn"]
  },
  {
    "name": "glgoh",
    "description": "Nuuk (Godthåb), Greenland",
    "alias": ["America/Godthab"]
  },
  {
    "name": "globy",
    "description": "Ittoqqortoormiit (Scoresbysund), Greenland",
    "alias": ["America/Scoresbysund"]
  },
  {
    "name": "glthu",
    "description": "Qaanaaq (Thule), Greenland",
    "alias": ["America/Thule"]
  },
  {
    "name": "gmbjl",
    "description": "Banjul, Gambia",
    "alias": ["Africa/Banjul"]
  },
  {
    "name": "gmt",
    "description": "Greenwich Mean Time",
    "alias": [
      "Etc/GMT",
      "Etc/GMT+0",
      "Etc/GMT-0",
      "Etc/GMT0",
      "Etc/Greenwich",
      "GMT",
      "GMT+0",
      "GMT-0",
      "GMT0",
      "Greenwich"
    ]
  },
  {
    "name": "gncky",
    "description": "Conakry, Guinea",
    "alias": ["Africa/Conakry"]
  },
  {
    "name": "gpbbr",
    "description": "Guadeloupe",
    "alias": ["America/Guadeloupe"]
  },
  {
    "name": "gpmsb",
    "description": "Marigot, Saint Martin",
    "alias": ["America/Marigot"]
  },
  {
    "name": "gpsbh",
    "description": "Saint Barthélemy",
    "alias": ["America/St_Barthelemy"]
  },
  {
    "name": "gqssg",
    "description": "Malabo, Equatorial Guinea",
    "alias": ["Africa/Malabo"]
  },
  {
    "name": "grath",
    "description": "Athens, Greece",
    "alias": ["Europe/Athens"]
  },
  {
    "name": "gsgrv",
    "description": "South Georgia and the South Sandwich Islands",
    "alias": ["Atlantic/South_Georgia"]
  },
  {
    "name": "gtgua",
    "description": "Guatemala",
    "alias": ["America/Guatemala"]
  },
  {
    "name": "gugum",
    "description": "Guam",
    "alias": ["Pacific/Guam"]
  },
  {
    "name": "gwoxb",
    "description": "Bissau, Guinea-Bissau",
    "alias": ["Africa/Bissau"]
  },
  {
    "name": "gygeo",
    "description": "Guyana",
    "alias": ["America/Guyana"]
  },
  {
    "name": "hebron",
    "description": "West Bank, Palestinian Territories",
    "alias": ["Asia/Hebron"]
  },
  {
    "name": "hkhkg",
    "description": "Hong Kong SAR China",
    "alias": ["Asia/Hong_Kong", "Hongkong"]
  },
  {
    "name": "hntgu",
    "description": "Tegucigalpa, Honduras",
    "alias": ["America/Tegucigalpa"]
  },
  {
    "name": "hrzag",
    "description": "Zagreb, Croatia",
    "alias": ["Europe/Zagreb"]
  },
  {
    "name": "htpap",
    "description": "Port-au-Prince, Haiti",
    "alias": ["America/Port-au-Prince"]
  },
  {
    "name": "hubud",
    "description": "Budapest, Hungary",
    "alias": ["Europe/Budapest"]
  },
  {
    "name": "iddjj",
    "description": "Jayapura, Indonesia",
    "alias": ["Asia/Jayapura"]
  },
  {
    "name": "idjkt",
    "description": "Jakarta, Indonesia",
    "alias": ["Asia/Jakarta"]
  },
  {
    "name": "idmak",
    "description": "Makassar, Indonesia",
    "alias": ["Asia/Makassar", "Asia/Ujung_Pandang"]
  },
  {
    "name": "idpnk",
    "description": "Pontianak, Indonesia",
    "alias": ["Asia/Pontianak"]
  },
  {
    "name": "iedub",
    "description": "Dublin, Ireland",
    "alias": ["Europe/Dublin", "Eire"]
  },
  {
    "name": "imdgs",
    "description": "Isle of Man",
    "alias": ["Europe/Isle_of_Man"]
  },
  {
    "name": "inccu",
    "description": "Kolkata, India",
    "alias": ["Asia/Calcutta", "Asia/Kolkata"]
  },
  {
    "name": "iodga",
    "description": "Chagos Archipelago",
    "alias": ["Indian/Chagos"]
  },
  {
    "name": "iqbgw",
    "description": "Baghdad, Iraq",
    "alias": ["Asia/Baghdad"]
  },
  {
    "name": "irthr",
    "description": "Tehran, Iran",
    "alias": ["Asia/Tehran", "Iran"]
  },
  {
    "name": "isrey",
    "description": "Reykjavik, Iceland",
    "alias": ["Atlantic/Reykjavik", "Iceland"]
  },
  {
    "name": "itrom",
    "description": "Rome, Italy",
    "alias": ["Europe/Rome"]
  },
  {
    "name": "jeruslm",
    "description": "Jerusalem",
    "alias": ["Asia/Jerusalem", "Asia/Tel_Aviv", "Israel"]
  },
  {
    "name": "jesth",
    "description": "Jersey",
    "alias": ["Europe/Jersey"]
  },
  {
    "name": "jmkin",
    "description": "Jamaica",
    "alias": ["America/Jamaica", "Jamaica"]
  },
  {
    "name": "joamm",
    "description": "Amman, Jordan",
    "alias": ["Asia/Amman"]
  },
  {
    "name": "jptyo",
    "description": "Tokyo, Japan",
    "alias": ["Asia/Tokyo", "Japan"]
  },
  {
    "name": "kenbo",
    "description": "Nairobi, Kenya",
    "alias": ["Africa/Nairobi"]
  },
  {
    "name": "kgfru",
    "description": "Bishkek, Kyrgyzstan",
    "alias": ["Asia/Bishkek"]
  },
  {
    "name": "khpnh",
    "description": "Phnom Penh, Cambodia",
    "alias": ["Asia/Phnom_Penh"]
  },
  {
    "name": "kicxi",
    "description": "Kiritimati, Kiribati",
    "alias": ["Pacific/Kiritimati"]
  },
  {
    "name": "kipho",
    "description": "Enderbury Island, Kiribati",
    "alias": ["Pacific/Enderbury"]
  },
  {
    "name": "kitrw",
    "description": "Tarawa, Kiribati",
    "alias": ["Pacific/Tarawa"]
  },
  {
    "name": "kmyva",
    "description": "Comoros",
    "alias": ["Indian/Comoro"]
  },
  {
    "name": "knbas",
    "description": "Saint Kitts",
    "alias": ["America/St_Kitts"]
  },
  {
    "name": "kpfnj",
    "description": "Pyongyang, North Korea",
    "alias": ["Asia/Pyongyang"]
  },
  {
    "name": "krsel",
    "description": "Seoul, South Korea",
    "alias": ["Asia/Seoul", "ROK"]
  },
  {
    "name": "kwkwi",
    "description": "Kuwait",
    "alias": ["Asia/Kuwait"]
  },
  {
    "name": "kygec",
    "description": "Cayman Islands",
    "alias": ["America/Cayman"]
  },
  {
    "name": "kzaau",
    "description": "Aqtau, Kazakhstan",
    "alias": ["Asia/Aqtau"]
  },
  {
    "name": "kzakx",
    "description": "Aqtobe, Kazakhstan",
    "alias": ["Asia/Aqtobe"]
  },
  {
    "name": "kzala",
    "description": "Almaty, Kazakhstan",
    "alias": ["Asia/Almaty"]
  },
  {
    "name": "kzguw",
    "description": "Atyrau (Guryev), Kazakhstan",
    "alias": ["Asia/Atyrau"]
  },
  {
    "name": "kzksn",
    "description": "Qostanay (Kostanay), Kazakhstan",
    "alias": ["Asia/Qostanay"]
  },
  {
    "name": "kzkzo",
    "description": "Kyzylorda, Kazakhstan",
    "alias": ["Asia/Qyzylorda"]
  },
  {
    "name": "kzura",
    "description": "Oral, Kazakhstan",
    "alias": ["Asia/Oral"]
  },
  {
    "name": "lavte",
    "description": "Vientiane, Laos",
    "alias": ["Asia/Vientiane"]
  },
  {
    "name": "lbbey",
    "description": "Beirut, Lebanon",
    "alias": ["Asia/Beirut"]
  },
  {
    "name": "lccas",
    "description": "Saint Lucia",
    "alias": ["America/St_Lucia"]
  },
  {
    "name": "livdz",
    "description": "Vaduz, Liechtenstein",
    "alias": ["Europe/Vaduz"]
  },
  {
    "name": "lkcmb",
    "description": "Colombo, Sri Lanka",
    "alias": ["Asia/Colombo"]
  },
  {
    "name": "lrmlw",
    "description": "Monrovia, Liberia",
    "alias": ["Africa/Monrovia"]
  },
  {
    "name": "lsmsu",
    "description": "Maseru, Lesotho",
    "alias": ["Africa/Maseru"]
  },
  {
    "name": "ltvno",
    "description": "Vilnius, Lithuania",
    "alias": ["Europe/Vilnius"]
  },
  {
    "name": "lulux",
    "description": "Luxembourg",
    "alias": ["Europe/Luxembourg"]
  },
  {
    "name": "lvrix",
    "description": "Riga, Latvia",
    "alias": ["Europe/Riga"]
  },
  {
    "name": "lytip",
    "description": "Tripoli, Libya",
    "alias": ["Africa/Tripoli", "Libya"]
  },
  {
    "name": "macas",
    "description": "Casablanca, Morocco",
    "alias": ["Africa/Casablanca"]
  },
  {
    "name": "mcmon",
    "description": "Monaco",
    "alias": ["Europe/Monaco"]
  },
  {
    "name": "mdkiv",
    "description": "Chişinău, Moldova",
    "alias": ["Europe/Chisinau", "Europe/Tiraspol"]
  },
  {
    "name": "metgd",
    "description": "Podgorica, Montenegro",
    "alias": ["Europe/Podgorica"]
  },
  {
    "name": "mgtnr",
    "description": "Antananarivo, Madagascar",
    "alias": ["Indian/Antananarivo"]
  },
  {
    "name": "mhkwa",
    "description": "Kwajalein, Marshall Islands",
    "alias": ["Pacific/Kwajalein", "Kwajalein"]
  },
  {
    "name": "mhmaj",
    "description": "Majuro, Marshall Islands",
    "alias": ["Pacific/Majuro"]
  },
  {
    "name": "mkskp",
    "description": "Skopje, Macedonia",
    "alias": ["Europe/Skopje"]
  },
  {
    "name": "mlbko",
    "description": "Bamako, Mali",
    "alias": ["Africa/Bamako", "Africa/Timbuktu"]
  },
  {
    "name": "mmrgn",
    "description": "Yangon (Rangoon), Burma",
    "alias": ["Asia/Rangoon", "Asia/Yangon"]
  },
  {
    "name": "mncoq",
    "description": "Choibalsan, Mongolia",
    "alias": ["Asia/Choibalsan"]
  },
  {
    "name": "mnhvd",
    "description": "Khovd (Hovd), Mongolia",
    "alias": ["Asia/Hovd"]
  },
  {
    "name": "mnuln",
    "description": "Ulaanbaatar (Ulan Bator), Mongolia",
    "alias": ["Asia/Ulaanbaatar", "Asia/Ulan_Bator"]
  },
  {
    "name": "momfm",
    "description": "Macau SAR China",
    "alias": ["Asia/Macau", "Asia/Macao"]
  },
  {
    "name": "mpspn",
    "description": "Saipan, Northern Mariana Islands",
    "alias": ["Pacific/Saipan"]
  },
  {
    "name": "mqfdf",
    "description": "Martinique",
    "alias": ["America/Martinique"]
  },
  {
    "name": "mrnkc",
    "description": "Nouakchott, Mauritania",
    "alias": ["Africa/Nouakchott"]
  },
  {
    "name": "msmni",
    "description": "Montserrat",
    "alias": ["America/Montserrat"]
  },
  {
    "name": "mst7mdt",
    "description": "POSIX style time zone for US Mountain Time",
    "alias": ["MST7MDT"]
  },
  {
    "name": "mtmla",
    "description": "Malta",
    "alias": ["Europe/Malta"]
  },
  {
    "name": "muplu",
    "description": "Mauritius",
    "alias": ["Indian/Mauritius"]
  },
  {
    "name": "mvmle",
    "description": "Maldives",
    "alias": ["Indian/Maldives"]
  },
  {
    "name": "mwblz",
    "description": "Blantyre, Malawi",
    "alias": ["Africa/Blantyre"]
  },
  {
    "name": "mxchi",
    "description": "Chihuahua, Mexico",
    "alias": ["America/Chihuahua"]
  },
  {
    "name": "mxcun",
    "description": "Cancún, Mexico",
    "alias": ["America/Cancun"]
  },
  {
    "name": "mxhmo",
    "description": "Hermosillo, Mexico",
    "alias": ["America/Hermosillo"]
  },
  {
    "name": "mxmam",
    "description": "Matamoros, Mexico",
    "alias": ["America/Matamoros"]
  },
  {
    "name": "mxmex",
    "description": "Mexico City, Mexico",
    "alias": ["America/Mexico_City", "Mexico/General"]
  },
  {
    "name": "mxmid",
    "description": "Mérida, Mexico",
    "alias": ["America/Merida"]
  },
  {
    "name": "mxmty",
    "description": "Monterrey, Mexico",
    "alias": ["America/Monterrey"]
  },
  {
    "name": "mxmzt",
    "description": "Mazatlán, Mexico",
    "alias": ["America/Mazatlan", "Mexico/BajaSur"]
  },
  {
    "name": "mxoji",
    "description": "Ojinaga, Mexico",
    "alias": ["America/Ojinaga"]
  },
  {
    "name": "mxpvr",
    "description": "Bahía de Banderas, Mexico",
    "alias": ["America/Bahia_Banderas"]
  },
  {
    "name": "mxstis",
    "description": "Santa Isabel (Baja California), Mexico",
    "alias": ["America/Santa_Isabel"]
  },
  {
    "name": "mxtij",
    "description": "Tijuana, Mexico",
    "alias": ["America/Tijuana", "America/Ensenada", "Mexico/BajaNorte"]
  },
  {
    "name": "mykch",
    "description": "Kuching, Malaysia",
    "alias": ["Asia/Kuching"]
  },
  {
    "name": "mykul",
    "description": "Kuala Lumpur, Malaysia",
    "alias": ["Asia/Kuala_Lumpur"]
  },
  {
    "name": "mzmpm",
    "description": "Maputo, Mozambique",
    "alias": ["Africa/Maputo"]
  },
  {
    "name": "nawdh",
    "description": "Windhoek, Namibia",
    "alias": ["Africa/Windhoek"]
  },
  {
    "name": "ncnou",
    "description": "Noumea, New Caledonia",
    "alias": ["Pacific/Noumea"]
  },
  {
    "name": "nenim",
    "description": "Niamey, Niger",
    "alias": ["Africa/Niamey"]
  },
  {
    "name": "nfnlk",
    "description": "Norfolk Island",
    "alias": ["Pacific/Norfolk"]
  },
  {
    "name": "nglos",
    "description": "Lagos, Nigeria",
    "alias": ["Africa/Lagos"]
  },
  {
    "name": "nimga",
    "description": "Managua, Nicaragua",
    "alias": ["America/Managua"]
  },
  {
    "name": "nlams",
    "description": "Amsterdam, Netherlands",
    "alias": ["Europe/Amsterdam"]
  },
  {
    "name": "noosl",
    "description": "Oslo, Norway",
    "alias": ["Europe/Oslo"]
  },
  {
    "name": "npktm",
    "description": "Kathmandu, Nepal",
    "alias": ["Asia/Katmandu", "Asia/Kathmandu"]
  },
  {
    "name": "nrinu",
    "description": "Nauru",
    "alias": ["Pacific/Nauru"]
  },
  {
    "name": "nuiue",
    "description": "Niue",
    "alias": ["Pacific/Niue"]
  },
  {
    "name": "nzakl",
    "description": "Auckland, New Zealand",
    "alias": ["Pacific/Auckland", "Antarctica/South_Pole", "NZ"]
  },
  {
    "name": "nzcht",
    "description": "Chatham Islands, New Zealand",
    "alias": ["Pacific/Chatham", "NZ-CHAT"]
  },
  {
    "name": "ommct",
    "description": "Muscat, Oman",
    "alias": ["Asia/Muscat"]
  },
  {
    "name": "papty",
    "description": "Panama",
    "alias": ["America/Panama"]
  },
  {
    "name": "pelim",
    "description": "Lima, Peru",
    "alias": ["America/Lima"]
  },
  {
    "name": "pfgmr",
    "description": "Gambiera Islands, French Polynesia",
    "alias": ["Pacific/Gambier"]
  },
  {
    "name": "pfnhv",
    "description": "Marquesas Islands, French Polynesia",
    "alias": ["Pacific/Marquesas"]
  },
  {
    "name": "pfppt",
    "description": "Tahiti, French Polynesia",
    "alias": ["Pacific/Tahiti"]
  },
  {
    "name": "pgpom",
    "description": "Port Moresby, Papua New Guinea",
    "alias": ["Pacific/Port_Moresby"]
  },
  {
    "name": "pgraw",
    "description": "Bougainville, Papua New Guinea",
    "alias": ["Pacific/Bougainville"]
  },
  {
    "name": "phmnl",
    "description": "Manila, Philippines",
    "alias": ["Asia/Manila"]
  },
  {
    "name": "pkkhi",
    "description": "Karachi, Pakistan",
    "alias": ["Asia/Karachi"]
  },
  {
    "name": "plwaw",
    "description": "Warsaw, Poland",
    "alias": ["Europe/Warsaw", "Poland"]
  },
  {
    "name": "pmmqc",
    "description": "Saint Pierre and Miquelon",
    "alias": ["America/Miquelon"]
  },
  {
    "name": "pnpcn",
    "description": "Pitcairn Islands",
    "alias": ["Pacific/Pitcairn"]
  },
  {
    "name": "prsju",
    "description": "Puerto Rico",
    "alias": ["America/Puerto_Rico"]
  },
  {
    "name": "pst8pdt",
    "description": "POSIX style time zone for US Pacific Time",
    "alias": ["PST8PDT"]
  },
  {
    "name": "ptfnc",
    "description": "Madeira, Portugal",
    "alias": ["Atlantic/Madeira"]
  },
  {
    "name": "ptlis",
    "description": "Lisbon, Portugal",
    "alias": ["Europe/Lisbon", "Portugal"]
  },
  {
    "name": "ptpdl",
    "description": "Azores, Portugal",
    "alias": ["Atlantic/Azores"]
  },
  {
    "name": "pwror",
    "description": "Palau",
    "alias": ["Pacific/Palau"]
  },
  {
    "name": "pyasu",
    "description": "Asunción, Paraguay",
    "alias": ["America/Asuncion"]
  },
  {
    "name": "qadoh",
    "description": "Qatar",
    "alias": ["Asia/Qatar"]
  },
  {
    "name": "rereu",
    "description": "Réunion",
    "alias": ["Indian/Reunion"]
  },
  {
    "name": "robuh",
    "description": "Bucharest, Romania",
    "alias": ["Europe/Bucharest"]
  },
  {
    "name": "rsbeg",
    "description": "Belgrade, Serbia",
    "alias": ["Europe/Belgrade"]
  },
  {
    "name": "ruasf",
    "description": "Astrakhan, Russia",
    "alias": ["Europe/Astrakhan"]
  },
  {
    "name": "rubax",
    "description": "Barnaul, Russia",
    "alias": ["Asia/Barnaul"]
  },
  {
    "name": "ruchita",
    "description": "Chita Zabaykalsky, Russia",
    "alias": ["Asia/Chita"]
  },
  {
    "name": "rudyr",
    "description": "Anadyr, Russia",
    "alias": ["Asia/Anadyr"]
  },
  {
    "name": "rugdx",
    "description": "Magadan, Russia",
    "alias": ["Asia/Magadan"]
  },
  {
    "name": "ruikt",
    "description": "Irkutsk, Russia",
    "alias": ["Asia/Irkutsk"]
  },
  {
    "name": "rukgd",
    "description": "Kaliningrad, Russia",
    "alias": ["Europe/Kaliningrad"]
  },
  {
    "name": "rukhndg",
    "description": "Khandyga Tomponsky, Russia",
    "alias": ["Asia/Khandyga"]
  },
  {
    "name": "rukra",
    "description": "Krasnoyarsk, Russia",
    "alias": ["Asia/Krasnoyarsk"]
  },
  {
    "name": "rukuf",
    "description": "Samara, Russia",
    "alias": ["Europe/Samara"]
  },
  {
    "name": "rukvx",
    "description": "Kirov, Russia",
    "alias": ["Europe/Kirov"]
  },
  {
    "name": "rumow",
    "description": "Moscow, Russia",
    "alias": ["Europe/Moscow", "W-SU"]
  },
  {
    "name": "runoz",
    "description": "Novokuznetsk, Russia",
    "alias": ["Asia/Novokuznetsk"]
  },
  {
    "name": "ruoms",
    "description": "Omsk, Russia",
    "alias": ["Asia/Omsk"]
  },
  {
    "name": "ruovb",
    "description": "Novosibirsk, Russia",
    "alias": ["Asia/Novosibirsk"]
  },
  {
    "name": "rupkc",
    "description": "Kamchatka Peninsula, Russia",
    "alias": ["Asia/Kamchatka"]
  },
  {
    "name": "rurtw",
    "description": "Saratov, Russia",
    "alias": ["Europe/Saratov"]
  },
  {
    "name": "rusred",
    "description": "Srednekolymsk, Russia",
    "alias": ["Asia/Srednekolymsk"]
  },
  {
    "name": "rutof",
    "description": "Tomsk, Russia",
    "alias": ["Asia/Tomsk"]
  },
  {
    "name": "ruuly",
    "description": "Ulyanovsk, Russia",
    "alias": ["Europe/Ulyanovsk"]
  },
  {
    "name": "ruunera",
    "description": "Ust-Nera Oymyakonsky, Russia",
    "alias": ["Asia/Ust-Nera"]
  },
  {
    "name": "ruuus",
    "description": "Sakhalin, Russia",
    "alias": ["Asia/Sakhalin"]
  },
  {
    "name": "ruvog",
    "description": "Volgograd, Russia",
    "alias": ["Europe/Volgograd"]
  },
  {
    "name": "ruvvo",
    "description": "Vladivostok, Russia",
    "alias": ["Asia/Vladivostok"]
  },
  {
    "name": "ruyek",
    "description": "Yekaterinburg, Russia",
    "alias": ["Asia/Yekaterinburg"]
  },
  {
    "name": "ruyks",
    "description": "Yakutsk, Russia",
    "alias": ["Asia/Yakutsk"]
  },
  {
    "name": "rwkgl",
    "description": "Kigali, Rwanda",
    "alias": ["Africa/Kigali"]
  },
  {
    "name": "saruh",
    "description": "Riyadh, Saudi Arabia",
    "alias": ["Asia/Riyadh"]
  },
  {
    "name": "sbhir",
    "description": "Guadalcanal, Solomon Islands",
    "alias": ["Pacific/Guadalcanal"]
  },
  {
    "name": "scmaw",
    "description": "Mahé, Seychelles",
    "alias": ["Indian/Mahe"]
  },
  {
    "name": "sdkrt",
    "description": "Khartoum, Sudan",
    "alias": ["Africa/Khartoum"]
  },
  {
    "name": "sesto",
    "description": "Stockholm, Sweden",
    "alias": ["Europe/Stockholm"]
  },
  {
    "name": "sgsin",
    "description": "Singapore",
    "alias": ["Asia/Singapore", "Singapore"]
  },
  {
    "name": "shshn",
    "description": "Saint Helena",
    "alias": ["Atlantic/St_Helena"]
  },
  {
    "name": "silju",
    "description": "Ljubljana, Slovenia",
    "alias": ["Europe/Ljubljana"]
  },
  {
    "name": "sjlyr",
    "description": "Longyearbyen, Svalbard",
    "alias": ["Arctic/Longyearbyen", "Atlantic/Jan_Mayen"]
  },
  {
    "name": "skbts",
    "description": "Bratislava, Slovakia",
    "alias": ["Europe/Bratislava"]
  },
  {
    "name": "slfna",
    "description": "Freetown, Sierra Leone",
    "alias": ["Africa/Freetown"]
  },
  {
    "name": "smsai",
    "description": "San Marino",
    "alias": ["Europe/San_Marino"]
  },
  {
    "name": "sndkr",
    "description": "Dakar, Senegal",
    "alias": ["Africa/Dakar"]
  },
  {
    "name": "somgq",
    "description": "Mogadishu, Somalia",
    "alias": ["Africa/Mogadishu"]
  },
  {
    "name": "srpbm",
    "description": "Paramaribo, Suriname",
    "alias": ["America/Paramaribo"]
  },
  {
    "name": "ssjub",
    "description": "Juba, South Sudan",
    "alias": ["Africa/Juba"]
  },
  {
    "name": "sttms",
    "description": "São Tomé, São Tomé and Príncipe",
    "alias": ["Africa/Sao_Tome"]
  },
  {
    "name": "svsal",
    "description": "El Salvador",
    "alias": ["America/El_Salvador"]
  },
  {
    "name": "sxphi",
    "description": "Sint Maarten",
    "alias": ["America/Lower_Princes"]
  },
  {
    "name": "sydam",
    "description": "Damascus, Syria",
    "alias": ["Asia/Damascus"]
  },
  {
    "name": "szqmn",
    "description": "Mbabane, Swaziland",
    "alias": ["Africa/Mbabane"]
  },
  {
    "name": "tcgdt",
    "description": "Grand Turk, Turks and Caicos Islands",
    "alias": ["America/Grand_Turk"]
  },
  {
    "name": "tdndj",
    "description": "N'Djamena, Chad",
    "alias": ["Africa/Ndjamena"]
  },
  {
    "name": "tfpfr",
    "description": "Kerguelen Islands, French Southern Territories",
    "alias": ["Indian/Kerguelen"]
  },
  {
    "name": "tglfw",
    "description": "Lomé, Togo",
    "alias": ["Africa/Lome"]
  },
  {
    "name": "thbkk",
    "description": "Bangkok, Thailand",
    "alias": ["Asia/Bangkok"]
  },
  {
    "name": "tjdyu",
    "description": "Dushanbe, Tajikistan",
    "alias": ["Asia/Dushanbe"]
  },
  {
    "name": "tkfko",
    "description": "Fakaofo, Tokelau",
    "alias": ["Pacific/Fakaofo"]
  },
  {
    "name": "tldil",
    "description": "Dili, East Timor",
    "alias": ["Asia/Dili"]
  },
  {
    "name": "tmasb",
    "description": "Ashgabat, Turkmenistan",
    "alias": ["Asia/Ashgabat", "Asia/Ashkhabad"]
  },
  {
    "name": "tntun",
    "description": "Tunis, Tunisia",
    "alias": ["Africa/Tunis"]
  },
  {
    "name": "totbu",
    "description": "Tongatapu, Tonga",
    "alias": ["Pacific/Tongatapu"]
  },
  {
    "name": "trist",
    "description": "Istanbul, Turkey",
    "alias": ["Europe/Istanbul", "Asia/Istanbul", "Turkey"]
  },
  {
    "name": "ttpos",
    "description": "Port of Spain, Trinidad and Tobago",
    "alias": ["America/Port_of_Spain"]
  },
  {
    "name": "tvfun",
    "description": "Funafuti, Tuvalu",
    "alias": ["Pacific/Funafuti"]
  },
  {
    "name": "twtpe",
    "description": "Taipei, Taiwan",
    "alias": ["Asia/Taipei", "ROC"]
  },
  {
    "name": "tzdar",
    "description": "Dar es Salaam, Tanzania",
    "alias": ["Africa/Dar_es_Salaam"]
  },
  {
    "name": "uaiev",
    "description": "Kiev, Ukraine",
    "alias": ["Europe/Kiev"]
  },
  {
    "name": "uaozh",
    "description": "Zaporizhia (Zaporozhye), Ukraine",
    "alias": ["Europe/Zaporozhye"]
  },
  {
    "name": "uasip",
    "description": "Simferopol, Ukraine",
    "alias": ["Europe/Simferopol"]
  },
  {
    "name": "uauzh",
    "description": "Uzhhorod (Uzhgorod), Ukraine",
    "alias": ["Europe/Uzhgorod"]
  },
  {
    "name": "ugkla",
    "description": "Kampala, Uganda",
    "alias": ["Africa/Kampala"]
  },
  {
    "name": "umawk",
    "description": "Wake Island, U.S. Minor Outlying Islands",
    "alias": ["Pacific/Wake"]
  },
  {
    "name": "umjon",
    "description": "Johnston Atoll, U.S. Minor Outlying Islands",
    "alias": ["Pacific/Johnston"]
  },
  {
    "name": "ummdy",
    "description": "Midway Islands, U.S. Minor Outlying Islands",
    "alias": ["Pacific/Midway"]
  },
  {
    "name": "unk",
    "description": "Unknown time zone",
    "alias": ["Etc/Unknown"]
  },
  {
    "name": "usadk",
    "description": "Adak (Alaska), United States",
    "alias": ["America/Adak", "America/Atka", "US/Aleutian"]
  },
  {
    "name": "usaeg",
    "description": "Marengo (Indiana), United States",
    "alias": ["America/Indiana/Marengo"]
  },
  {
    "name": "usanc",
    "description": "Anchorage, United States",
    "alias": ["America/Anchorage", "US/Alaska"]
  },
  {
    "name": "usboi",
    "description": "Boise (Idaho), United States",
    "alias": ["America/Boise"]
  },
  {
    "name": "uschi",
    "description": "Chicago, United States",
    "alias": ["America/Chicago", "US/Central"]
  },
  {
    "name": "usden",
    "description": "Denver, United States",
    "alias": ["America/Denver", "America/Shiprock", "Navajo", "US/Mountain"]
  },
  {
    "name": "usdet",
    "description": "Detroit, United States",
    "alias": ["America/Detroit", "US/Michigan"]
  },
  {
    "name": "ushnl",
    "description": "Honolulu, United States",
    "alias": ["Pacific/Honolulu", "US/Hawaii"]
  },
  {
    "name": "usind",
    "description": "Indianapolis, United States",
    "alias": ["America/Indianapolis", "America/Fort_Wayne", "America/Indiana/Indianapolis", "US/East-Indiana"]
  },
  {
    "name": "usinvev",
    "description": "Vevay (Indiana), United States",
    "alias": ["America/Indiana/Vevay"]
  },
  {
    "name": "usjnu",
    "description": "Juneau (Alaska), United States",
    "alias": ["America/Juneau"]
  },
  {
    "name": "usknx",
    "description": "Knox (Indiana), United States",
    "alias": ["America/Indiana/Knox", "America/Knox_IN", "US/Indiana-Starke"]
  },
  {
    "name": "uslax",
    "description": "Los Angeles, United States",
    "alias": ["America/Los_Angeles", "US/Pacific", "US/Pacific-New"]
  },
  {
    "name": "uslui",
    "description": "Louisville (Kentucky), United States",
    "alias": ["America/Louisville", "America/Kentucky/Louisville"]
  },
  {
    "name": "usmnm",
    "description": "Menominee (Michigan), United States",
    "alias": ["America/Menominee"]
  },
  {
    "name": "usmtm",
    "description": "Metlakatla (Alaska), United States",
    "alias": ["America/Metlakatla"]
  },
  {
    "name": "usmoc",
    "description": "Monticello (Kentucky), United States",
    "alias": ["America/Kentucky/Monticello"]
  },
  {
    "name": "usndcnt",
    "description": "Center (North Dakota), United States",
    "alias": ["America/North_Dakota/Center"]
  },
  {
    "name": "usndnsl",
    "description": "New Salem (North Dakota), United States",
    "alias": ["America/North_Dakota/New_Salem"]
  },
  {
    "name": "usnyc",
    "description": "New York, United States",
    "alias": ["America/New_York", "US/Eastern"]
  },
  {
    "name": "usoea",
    "description": "Vincennes (Indiana), United States",
    "alias": ["America/Indiana/Vincennes"]
  },
  {
    "name": "usome",
    "description": "Nome (Alaska), United States",
    "alias": ["America/Nome"]
  },
  {
    "name": "usphx",
    "description": "Phoenix, United States",
    "alias": ["America/Phoenix", "US/Arizona"]
  },
  {
    "name": "ussit",
    "description": "Sitka (Alaska), United States",
    "alias": ["America/Sitka"]
  },
  {
    "name": "ustel",
    "description": "Tell City (Indiana), United States",
    "alias": ["America/Indiana/Tell_City"]
  },
  {
    "name": "uswlz",
    "description": "Winamac (Indiana), United States",
    "alias": ["America/Indiana/Winamac"]
  },
  {
    "name": "uswsq",
    "description": "Petersburg (Indiana), United States",
    "alias": ["America/Indiana/Petersburg"]
  },
  {
    "name": "usxul",
    "description": "Beulah (North Dakota), United States",
    "alias": ["America/North_Dakota/Beulah"]
  },
  {
    "name": "usyak",
    "description": "Yakutat (Alaska), United States",
    "alias": ["America/Yakutat"]
  },
  {
    "name": "utc",
    "description": "UTC (Coordinated Universal Time)",
    "alias": ["Etc/UTC", "Etc/UCT", "Etc/Universal", "Etc/Zulu", "UCT", "UTC", "Universal", "Zulu"]
  },
  {
    "name": "utce01",
    "description": "1 hour ahead of UTC",
    "alias": ["Etc/GMT-1"]
  },
  {
    "name": "utce02",
    "description": "2 hours ahead of UTC",
    "alias": ["Etc/GMT-2"]
  },
  {
    "name": "utce03",
    "description": "3 hours ahead of UTC",
    "alias": ["Etc/GMT-3"]
  },
  {
    "name": "utce04",
    "description": "4 hours ahead of UTC",
    "alias": ["Etc/GMT-4"]
  },
  {
    "name": "utce05",
    "description": "5 hours ahead of UTC",
    "alias": ["Etc/GMT-5"]
  },
  {
    "name": "utce06",
    "description": "6 hours ahead of UTC",
    "alias": ["Etc/GMT-6"]
  },
  {
    "name": "utce07",
    "description": "7 hours ahead of UTC",
    "alias": ["Etc/GMT-7"]
  },
  {
    "name": "utce08",
    "description": "8 hours ahead of UTC",
    "alias": ["Etc/GMT-8"]
  },
  {
    "name": "utce09",
    "description": "9 hours ahead of UTC",
    "alias": ["Etc/GMT-9"]
  },
  {
    "name": "utce10",
    "description": "10 hours ahead of UTC",
    "alias": ["Etc/GMT-10"]
  },
  {
    "name": "utce11",
    "description": "11 hours ahead of UTC",
    "alias": ["Etc/GMT-11"]
  },
  {
    "name": "utce12",
    "description": "12 hours ahead of UTC",
    "alias": ["Etc/GMT-12"]
  },
  {
    "name": "utce13",
    "description": "13 hours ahead of UTC",
    "alias": ["Etc/GMT-13"]
  },
  {
    "name": "utce14",
    "description": "14 hours ahead of UTC",
    "alias": ["Etc/GMT-14"]
  },
  {
    "name": "utcw01",
    "description": "1 hour behind UTC",
    "alias": ["Etc/GMT+1"]
  },
  {
    "name": "utcw02",
    "description": "2 hours behind UTC",
    "alias": ["Etc/GMT+2"]
  },
  {
    "name": "utcw03",
    "description": "3 hours behind UTC",
    "alias": ["Etc/GMT+3"]
  },
  {
    "name": "utcw04",
    "description": "4 hours behind UTC",
    "alias": ["Etc/GMT+4"]
  },
  {
    "name": "utcw05",
    "description": "5 hours behind UTC",
    "alias": ["Etc/GMT+5", "EST"]
  },
  {
    "name": "utcw06",
    "description": "6 hours behind UTC",
    "alias": ["Etc/GMT+6"]
  },
  {
    "name": "utcw07",
    "description": "7 hours behind UTC",
    "alias": ["Etc/GMT+7", "MST"]
  },
  {
    "name": "utcw08",
    "description": "8 hours behind UTC",
    "alias": ["Etc/GMT+8"]
  },
  {
    "name": "utcw09",
    "description": "9 hours behind UTC",
    "alias": ["Etc/GMT+9"]
  },
  {
    "name": "utcw10",
    "description": "10 hours behind UTC",
    "alias": ["Etc/GMT+10", "HST"]
  },
  {
    "name": "utcw11",
    "description": "11 hours behind UTC",
    "alias": ["Etc/GMT+11"]
  },
  {
    "name": "utcw12",
    "description": "12 hours behind UTC",
    "alias": ["Etc/GMT+12"]
  },
  {
    "name": "uymvd",
    "description": "Montevideo, Uruguay",
    "alias": ["America/Montevideo"]
  },
  {
    "name": "uzskd",
    "description": "Samarkand, Uzbekistan",
    "alias": ["Asia/Samarkand"]
  },
  {
    "name": "uztas",
    "description": "Tashkent, Uzbekistan",
    "alias": ["Asia/Tashkent"]
  },
  {
    "name": "vavat",
    "description": "Vatican City",
    "alias": ["Europe/Vatican"]
  },
  {
    "name": "vcsvd",
    "description": "Saint Vincent, Saint Vincent and the Grenadines",
    "alias": ["America/St_Vincent"]
  },
  {
    "name": "veccs",
    "description": "Caracas, Venezuela",
    "alias": ["America/Caracas"]
  },
  {
    "name": "vgtov",
    "description": "Tortola, British Virgin Islands",
    "alias": ["America/Tortola"]
  },
  {
    "name": "vistt",
    "description": "Saint Thomas, U.S. Virgin Islands",
    "alias": ["America/St_Thomas", "America/Virgin"]
  },
  {
    "name": "vnsgn",
    "description": "Ho Chi Minh City, Vietnam",
    "alias": ["Asia/Saigon", "Asia/Ho_Chi_Minh"]
  },
  {
    "name": "vuvli",
    "description": "Efate, Vanuatu",
    "alias": ["Pacific/Efate"]
  },
  {
    "name": "wfmau",
    "description": "Wallis Islands, Wallis and Futuna",
    "alias": ["Pacific/Wallis"]
  },
  {
    "name": "wsapw",
    "description": "Apia, Samoa",
    "alias": ["Pacific/Apia"]
  },
  {
    "name": "yeade",
    "description": "Aden, Yemen",
    "alias": ["Asia/Aden"]
  },
  {
    "name": "ytmam",
    "description": "Mayotte",
    "alias": ["Indian/Mayotte"]
  },
  {
    "name": "zajnb",
    "description": "Johannesburg, South Africa",
    "alias": ["Africa/Johannesburg"]
  },
  {
    "name": "zmlun",
    "description": "Lusaka, Zambia",
    "alias": ["Africa/Lusaka"]
  },
  {
    "name": "zwhre",
    "description": "Harare, Zimbabwe",
    "alias": ["Africa/Harare"]
  }
]
