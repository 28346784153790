import { Pipe, PipeTransform } from '@angular/core';
import { getUnitSymbolForUnitOfMeasurement } from './uomConversionUtility.helper';

@Pipe({
  name: 'appendUnit',
  standalone: true
})
export class AppendUnitPipe implements PipeTransform {
  transform(value: string | number, metric?: string, UOM?: string): string | number {
    if (this.checkvalue(value) && this.checkvalue(metric) && this.checkvalue(UOM)) {
      return this.getUnit(value, UOM, metric);
    }
    return value === 0 ? 0 : value || '--';
  }

  checkvalue(value: string | number): boolean {
    return value !== undefined && value !== null && value !== '' && value !== '--';
  }

  getUnit(value: string | number, UOM: string, metric: string): string {
    let unit = '';
    unit = getUnitSymbolForUnitOfMeasurement(UOM, metric);
    const _unit = unit !== '' ? `${value}${unit}` : '';
    return `${_unit}`;
  }
}
