import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separateText',
  standalone: true
})
// Sepeartes text based on '-' and return the index passed;
export class SeparateTextPipe implements PipeTransform {
  transform(value: string, key: number): string {
    return textSepartorPipeTransform(value, key);
  }
}

export const textSepartorPipeTransform = (value: string, key: number): string => {
  const text = value.split('-');
  if (key === 0) {
    return text[key];
  }
  return `-${text[key]}`;
};
