import { Pipe, PipeTransform } from '@angular/core';
import { Preference } from '@Terra/shared/widgets/interface';
import { dateTimeFormatterHelper, momentDateFormatterHelper, momentHelper } from '@Terra/shared/widgets/utils';
import { DatePipeOptionalParams } from '../common.model';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {
  transform(date: string | Date, format: string | Preference, optionalParams?: DatePipeOptionalParams): string {
    return dateFormatPipeTransform(date, format, optionalParams);
  }
}

export const dateFormatPipeTransform = (
  date: string | Date,
  format: string | Preference,
  optionalParams?: DatePipeOptionalParams
): string => {
  if (!date) {
    return '--';
  }
  const dateFormat: string = dateTimeFormatterHelper.getDateTimeInStringFormat(format, optionalParams?.removeSpace);
  const result = getConvertedDate(date, dateFormat, optionalParams?.timezone);
  return result === 'Invalid date' ? '--' : result;
};

const getConvertedDate = (date: string | Date, dateFormat: string, timezone?: string): string => {
  let result: string;
  if (timezone) {
    const value = typeof date === 'string' && !date.toUpperCase().includes('Z') ? date.concat('Z') : date;
    result = momentDateFormatterHelper.convertDateByTzId(value, timezone, dateFormat);
  } else {
    result = momentHelper.convertDateOrTimeFormat({ dateTime: date, currentFormat: '', convertTo: dateFormat }, true);
  }
  return result;
};
