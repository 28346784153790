export const MAP_UCID = {
    local: {
      parties: [
        {
          partyNumber: '2972056559',
          partyName: 'CDT APPS INT TEST ACCOUNT 2'
        },
        {
          partyNumber: '2972056558',
          partyName: 'CDT APPS INT TEST ACCOUNT 1'
        },
        {
          partyNumber: '2972056560',
          partyName: 'CDT APPS INT TEST ACCOUNT 3'
        },
        {
          partyNumber: '2972056562',
          partyName: 'CDT APPS INT TEST ACCOUNT 4'
        },
        {
          partyNumber: '2972056563',
          partyName: 'CDT APPS INT TEST ACCOUNT 5'
        }
      ],
      responseMetadata: {
        nextCursor: null
      }
    },
    devint: {
      parties: [
        {
          partyNumber: '2972056559',
          partyName: 'CDT APPS INT TEST ACCOUNT 2'
        },
        {
          partyNumber: '2972056558',
          partyName: 'CDT APPS INT TEST ACCOUNT 1'
        },
        {
          partyNumber: '2972056560',
          partyName: 'CDT APPS INT TEST ACCOUNT 3'
        },
        {
          partyNumber: '2972056562',
          partyName: 'CDT APPS INT TEST ACCOUNT 4'
        },
        {
          partyNumber: '2972056563',
          partyName: 'CDT APPS INT TEST ACCOUNT 5'
        }
      ],
      responseMetadata: {
        nextCursor: null
      }
    },
    dev: {
      parties: [
        {
          partyNumber: '2972056555',
          partyName: 'CDT APPS DEV TEST ACCOUNT 2'
        },
        {
          partyNumber: '2972056554',
          partyName: 'CDT APPS DEV TEST ACCOUNT 1'
        },
        {
          partyNumber: '2972056556',
          partyName: 'CDT APPS DEV TEST ACCOUNT 3'
        },
        {
          partyNumber: '2972056557',
          partyName: 'CDT APPS DEV TEST ACCOUNT 4'
        }
      ],
      responseMetadata: {
        nextCursor: null
      }
    },
    int: {
      parties: [
        {
          partyNumber: '2972056559',
          partyName: 'CDT APPS INT TEST ACCOUNT 2'
        },
        {
          partyNumber: '2972056558',
          partyName: 'CDT APPS INT TEST ACCOUNT 1'
        },
        {
          partyNumber: '2972056560',
          partyName: 'CDT APPS INT TEST ACCOUNT 3'
        },
        {
          partyNumber: '2972056562',
          partyName: 'CDT APPS INT TEST ACCOUNT 4'
        },
        {
          partyNumber: '2972056563',
          partyName: 'CDT APPS INT TEST ACCOUNT 5'
        }
      ],
      responseMetadata: {
        nextCursor: null
      }
    },
    qa: {
      parties: [
        {
          partyNumber: '2972056564',
          partyName: 'CDT APPS QA TEST ACCOUNT 1'
        },
        {
          partyNumber: '2972056573',
          partyName: 'CDT APPS QA TEST ACCOUNT 10'
        },
        {
          partyNumber: '2972056565',
          partyName: 'CDT APPS QA TEST ACCOUNT 2'
        },
        {
          partyNumber: '2972056566',
          partyName: 'CDT APPS QA TEST ACCOUNT 3'
        },
        {
          partyNumber: '2972056571',
          partyName: 'CDT APPS QA TEST ACCOUNT 8'
        },
        {
          partyNumber: '2972056572',
          partyName: 'CDT APPS QA TEST ACCOUNT 9'
        }
      ],
      responseMetadata: {
        nextCursor: null
      }
    },
    perf: {
      parties: [
        {
          partyNumber: '2972056574',
          partyName: 'CDT APPS PERF TEST ACCOUNT 1'
        },
        {
          partyNumber: '2972056575',
          partyName: 'CDT APPS PERF TEST ACCOUNT 2'
        },
        {
          partyNumber: '2972056576',
          partyName: 'CDT APPS PERF TEST ACCOUNT 3'
        },
        {
          partyNumber: '2972056577',
          partyName: 'CDT APPS PERF TEST ACCOUNT 4'
        },
        {
          partyNumber: '2972056578',
          partyName: 'CDT APPS PERF TEST ACCOUNT 5'
        },
        {
          partyNumber: '2972056579',
          partyName: 'CDT APPS PERF TEST ACCOUNT 6'
        },
        {
          partyNumber: '2972056580',
          partyName: 'CDT APPS PERF TEST ACCOUNT 7'
        },
        {
          partyNumber: '2972056581',
          partyName: 'CDT APPS PERF TEST ACCOUNT 8'
        },
        {
          partyNumber: '2972056582',
          partyName: 'CDT APPS PERF TEST ACCOUNT 9'
        },
        {
          partyNumber: '2972056583',
          partyName: 'CDT APPS PERF TEST ACCOUNT 10'
        }
      ],
      responseMetadata: {
        nextCursor: null
      }
    }
  };