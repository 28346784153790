import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebworkerService {
  /**
   * * Service will take care of entire lifecycle of the worker.
   *
   * * call run method with a callback function and input data to the worker
   *
   * * service will generate worker by ingesting the callback function and will do postmessage
   *
   * * once worker process, output postmessage from work will be returned as a promise by this workerService
   *
   * * once the promise either resolves/rejects the worker is terminated.
   */

  private workerFunctionToUrlMap = new WeakMap<(...args: any) => void, string>();
  private promiseToWorkerMap = new WeakMap<Promise<any>, Worker>();

  /**
   * Method that runs the given function with the given data.
   * @param workerFunction function to run on the web worker context.
   * @param data data to pass to the web worker context.
   *
   * the function must be self-contained, meaning that no external functions or
   * libraries can be passed through this parameter.
   *
   * For referring external libraries, use importScripts
   * for example: refer zone.worker.ts under safety summary dashboard
   */
  public run<T>(workerFunction: (input: any) => T, data?: any): Promise<T> {
    const url = this.getOrCreateWorkerUrl(workerFunction);
    return this.runUrl(url, data);
  }

  /**
   * Method that runs the given ObjectURL with the given data.
   * @param url ObjectURL to run on the web worker context.
   * @param data data to pass to the web worker context
   *
   * the function must be self-contained, meaning that no external functions or
   * libraries can be passed through this parameter.
   */
  public runUrl(url: string, data?: any): Promise<any> {
    const worker = new Worker(url);
    const promise = this.createPromiseForWorker(worker, data);
    const promiseCleaner = this.createPromiseCleaner(promise);

    this.promiseToWorkerMap.set(promise, worker);

    promise.then(promiseCleaner).catch(promiseCleaner);

    return promise;
  }

  /**
   * Method that terminates the given Promise and removes it from the
   * internal service maps.
   * @param promise promise to terminate.
   */
  public terminate<T>(promise: Promise<T>): Promise<T> {
    return this.removePromise(promise);
  }

  /**
   * Method that retrieves the web worker to which the given Promise
   * belongs to.
   * @param promise promise whose web worker we want to find.
   */
  public getWorker(promise: Promise<any>): Worker {
    return this.promiseToWorkerMap.get(promise);
  }

  /**
   * Method that handles the promise creation for the given web worker with
   * the given input data.
   * @param worker worker for which the promise will be created.
   * @param data data that will be passed into the worker object.
   */
  private createPromiseForWorker<T>(worker: Worker, data: any) {
    return new Promise<T>((resolve, reject) => {
      worker.addEventListener('message', event => resolve(event.data));
      worker.addEventListener('error', reject);
      worker.postMessage(data);
    });
  }

  /**
   * Method that allocates a web worker ObjectURL for the given function.
   * It's used to create caches for the (function, workerUrl) pairs in order to avoid
   * creating the urls more than once.
   * @param fn function whose worker we want to allocate.
   */
  private getOrCreateWorkerUrl(fn: (...args: any) => void): string {
    if (!this.workerFunctionToUrlMap.has(fn)) {
      const url = this.createWorkerUrl(fn);
      this.workerFunctionToUrlMap.set(fn, url);
      return url;
    }
    return this.workerFunctionToUrlMap.get(fn);
  }

  /**
   * Method that creates a web worker ObjectURL from the given
   * Function object.
   * @param resolve function the web worker will run.
   */
  private createWorkerUrl(resolve: (...args: any) => void): string {
    const resolveString = resolve.toString();
    // The template is basically an addEventListener attachment that creates a
    // closure (IIFE*) with the provided function and invokes it with the provided
    // data.
    // * IIFE stands for immediately Immediately-Invoked Function Expression
    // Removed the postMessage from this template in order to allow worker functions
    // to use asynchronous functions and resolve whenever they need to.
    const webWorkerTemplate = `
            self.addEventListener('message', function(e) {
                ((${resolveString})(e.data));
            });
        `;
    const blob = new Blob([webWorkerTemplate], { type: 'text/javascript' });
    return URL.createObjectURL(blob);
  }
  /**
   * Method that creates a function that removes the given promise from the
   * service context.
   * @param promise promise the cleaner function will be created for.
   */
  private createPromiseCleaner<T>(promise: Promise<T>): (input: any) => T {
    return event => {
      this.removePromise(promise);
      return event;
    };
  }

  /**
   * Method that removes the given promise from the service context.
   * It also terminates the associated worker in case it exists.
   * @param promise promise to be removed from the service context.
   */
  private removePromise<T>(promise: Promise<T>): Promise<T> {
    const worker = this.promiseToWorkerMap.get(promise);
    if (worker) {
      worker.terminate();
    }
    this.promiseToWorkerMap.delete(promise);
    return promise;
  }
}
