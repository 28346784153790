export class UtilTreeNode {
  label?: string;
  data?: any;
  icon?: any;
  expandedIcon?: any;
  collapsedIcon?: any;
  children?: UtilTreeNode[];
  leaf?: boolean;
  expanded?: boolean;
  type?: string;
  parent?: UtilTreeNode;
  partialSelected?: boolean;
  styleClass?: string;
  draggable?: boolean;
  droppable?: boolean;
  selectable?: boolean;

  constructor(label: string, data: any, children: UtilTreeNode[]) {
    this.label = label;
    this.data = data;
    this.children = children;
  }
}
