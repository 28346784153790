export const NODE_SERVER = {
  HANDLER_URL: 'handlerURL',
  TIMEZONE_ROUTE: '/timeZone',
  PARAMS: {
    Language: 'en'
  }
};

export interface TimeZone {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
}

export interface LatLngCoOrdinates {
  lat: number;
  lng: number;
  today: number;
}

export const DATE_TIME_FORMATS = {
  'DD MMM YYYY': 'DD MMM YYYY',
  'MMM DD, YYYY': 'MMM DD, YYYY',
  'MM/DD/YYYY': 'MM/DD/YYYY',
  'DD/MM/YYYY': 'DD/MM/YYYY',
  'YYYY/MM/DD': 'YYYY/MM/DD'
};
export const NON_PRECISION_FORMAT = {
  '12 hour': 'hh:mm A',
  '24 hour': 'HH:mm'
};

export const PRECISION_FORMAT_WITH_SECONDS = {
  '12 hour': 'hh:mm:ss A',
  '24 hour': 'HH:mm:ss'
};

export const NON_PRECISION_FORMAT_WITHOUT_SPACE = {
  '12 hour': 'hh:mmA',
  '24 hour': 'HH:mm'
};

export const PRECISION_FORMAT_WITH_SECONDS_WITHOUT_SPACE = {
  '12 hour': 'hh:mm:ssA',
  '24 hour': 'HH:mm:ss'
};

export const EXCEPTIONAL_DATE_VALIDATION = {
  dateFormat: 'DD/MM/YYYY',
  chatDateFormat: 'DD/MM'
};

export const COLUMN_CHART_WEEKLYDATEFORMAT = {
  fullDateFormat: 'MMM D, YYYY',
  dayInWords: 'dddd',
  onlyMonth: 'MMM',
  onlyYear: 'YYYY',
  monthAndDay: 'MMM D',
  dayAndYear: 'D, YYYY',
  isSunday: 'Sunday',
  ISO_DateFormat: 'YYYY-MM-DDTHH:mm:ss',
  DRILL_DOWN_FORMAT: 'MM/DD/YYYY'
};

export const UNIVERSAL_DATEFORMAT = 'YYYY-MM-DD';

export const INITIALIZE_DURATION = { y: 0, mo: 0, w: 0, d: 0, h: 0, m: 0, s: 0 };

export const DIFFERENT_DURATION = { y: 31536000, mo: 2592000, w: 604800, d: 86400, h: 3600, m: 60, s: 1 };

export const TIMEZONE_API_URL = 'https://maps.googleapis.com/maps/api/timezone/json';

export const ERROR_TIMEZONE: TimeZone = {
  dstOffset: 0,
  rawOffset: 0,
  status: '400',
  timeZoneId: '',
  timeZoneName: ''
};
