/**
 * function to set value in localstorage
 * @param key assign name of the localstorage item
 * @param value assign value of the localstorage item
 */
export const setItem = (key: string, value: any): void => {
  const str = JSON.stringify(value);
  localStorage.setItem(key, window.btoa(str));
};

/**
 * function to return value in localstorage
 * @param key retrieve value for name of the localstorage item using this key
 */
export const getItem = (key: string): any => {
  const getValue = localStorage.getItem(key);
  return getValue ? JSON.parse(window.atob(getValue)) : false;
};

/**
 * function to  remove localstorage items
 */
export const removeItem = (key: string): void => localStorage.removeItem(key);

/**
 * function to clear or remove localstorage items
 */
export const clearItem = (): void => localStorage.clear();
