export interface SiteFile {
  readyForSync?: boolean;
  uploadUrls?: string[];
  id?: string;
  allChunksCompleted?: boolean;
  multiPartUploadComplete?: boolean;
  multiPartUploadStarted?: boolean;
  isResumed?: boolean;
  surveyDateTime?: string;
  value?: string;
  isSelected?: boolean;
  isCrossIconHidden?: boolean;
  fileId: string;
  name: string;
  category: string;
  status: string;
  importedOn: string;
  isReadyForSync?: boolean;
  fileSize?: number | string;
  multipartUploadId?: string;
  chunks?: Chunk[];
  siteId?: string;
  files?: SvdSvlFileRes[];
  actualFileId?: string;
  reasonForFailure?: string;
  disableDeleteSurvey?: boolean;
  enableResumeForSurvey?: boolean;
  enableEllipseTooltip?: boolean;
  tooltipMessageForSurveyFile?: string;
}

export interface Chunk {
  blob: Blob;
  id: number;
  status: FileStatus;
}

export interface DesignFiles {
  files: SiteFile[];
}

export enum FileStatus {
  UploadInProgress = 'UPLOAD_IN_PROGRESS',
  UploadCompleted = 'UPLOAD_COMPLETED',
  UploadFailed = 'UPLOAD_FAILED',
  ParsingInProgress = 'PARSING_IN_PROGRESS',
  ParsingCompleted = 'PARSING_COMPLETED',
  ParsingFailed = 'PARSING_FAILED',
  AwaitingMultiPartUploadId = 'AWAITING_MULTIPART_UPLOAD_ID',
  NotStarted = 'NOT_STARTED',
  ReadyToUpload = 'READY_TO_UPLOAD',
  DeleteInProgress = 'DELETE_IN_PROGRESS',
  WaitingForUploadComplete = 'WAITING_FOR_UPLOAD_COMPLETE'
}

export interface ViewPreferencePayload {
  siteGuid: string;
  designFile?: string;
}

export interface SvdSvlFileRes {
  fileId: string;
  fileName: string;
  fileSize: number;
  siteId: string;
  fileStatus: string;
  fileSubStatus: string;
  fileImportDateTime: string;
  fileCategory: string;
  reasonFailure: string;
  parentId: string;
}
