import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  standalone: true
})
export class OrderByPipe implements PipeTransform {
  transform(value: Array<any>, ...args: any[]): Array<any> {
    return orderByPipeTransform(value, ...args);
  }
}

export const orderByPipeTransform = (value: Array<any>, ...args: any[]): Array<any> => {
  let orderBy: SORT_ORDER = SORT_ORDER.ASC,
    sortBy: string;
  [orderBy, sortBy] = [...args];

  value?.sort((a, b) => {
    if (sortBy) {
      return getComparedResults(a[sortBy], b[sortBy], orderBy);
    } else {
      return getComparedResults(a, b, orderBy);
    }
  });

  return value;
};

const getComparedResults = (a: any = null, b: any = null, orderBy: SORT_ORDER): number => {
  if (a === null || a.toString() === '') {
    return orderBy === SORT_ORDER.ASC ? -1 : 1;
  } else if (b === null || b.toString() === '') {
    return orderBy === SORT_ORDER.ASC ? 1 : -1;
  } else if (a.toString() === b.toString()) {
    return 0;
  }
  return orderBy === SORT_ORDER.ASC ? (a.toString() > b.toString() ? 1 : -1) : a.toString() > b.toString() ? -1 : 1;
};

enum SORT_ORDER {
  ASC = 'ASC',
  DES = 'DES'
}
