import { Pipe, PipeTransform } from '@angular/core';
import { dateTimeFormatterHelper } from '@Terra/shared/widgets/utils';

@Pipe({
  name: 'timeAgo',
  standalone: true
})
export class TimeAgoPipe implements PipeTransform {
  transform(time: number): string {
    return timeAgoPipeTransform(time);
  }
}

export const timeAgoPipeTransform = (time: number): string => {
  if (time) {
    return dateTimeFormatterHelper.getSubtractPeriod(time);
  }
  return '';
};
