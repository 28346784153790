import moment, { DurationInputArg1, DurationInputArg2, unitOfTime } from 'moment-timezone';
import { exceptionalDateFormatValidation } from './date-time-formatter-helper';
import { DateConvert, DateRange, DateTimeFormatTz, DrillDown, DrillDownDateRange, NthDateTime, NthUtcDate } from './models/util.model';
import { convertDateOrTimeFormat, getDateTimeByTZ, getUTCDateTimeWithTZ } from './moment-helper';
import { getDefaultTimeZoneOffset } from './moment-time-formatter-helper';
import { COLUMN_CHART_WEEKLYDATEFORMAT, UNIVERSAL_DATEFORMAT } from './timezone.config';

export const getNthDate = ({
  dateTime,
  currentFormat,
  convertTo,
  duration,
  period,
  timeZoneOffset = getDefaultTimeZoneOffset()
}: NthDateTime): string => getDateTimeByTZ(dateTime, currentFormat).utcOffset(timeZoneOffset).add(duration, period).format(convertTo);

/*
**
Returns DateRange
*/
export const getDateRangeWithTZ = ({ fromDate, toDate, dateFormatMoment }: DateRange, timezone: string): DateRange => ({
  fromDate: getUTCDateTimeWithTZ(fromDate, dateFormatMoment, timezone), //Convert to UTC
  toDate: getUTCDateTimeWithTZ(toDate, dateFormatMoment, timezone) //Convert to UTC
});

//time zone conversion with timezone offset
export const convertUTCDateByTZ = (
  { fromDate, toDate, dateFormatMoment }: DateRange,
  timeZoneOffset: string | number = getDefaultTimeZoneOffset()
): string => moment(fromDate, toDate).utcOffset(timeZoneOffset).format(dateFormatMoment);

// returns the date by adding hours to the given date
export const getNextDateBasedOnUnit = (date: Date | string, amount: DurationInputArg1, unit: DurationInputArg2): Date =>
  moment(date).add(amount, unit).toDate();

export const getCurrentDateByTZ = (dateTimeFormat: string, timeZoneOffset: string | number = getDefaultTimeZoneOffset()): string =>
  moment().utcOffset(timeZoneOffset).endOf('day').format(dateTimeFormat);

/**
 * @param toDateString
 * @param currentFormat
 * @param convertTo
 */
export const getDateFromUtcDate = ({ dateString, localeName, currentFormat, convertTo }: DateConvert): string =>
  moment.utc(`${dateString} ${localeName}`, currentFormat).endOf('day').format(convertTo);

//returns the nth utc date time from today's date
export const getNthUTCDateWithDST = ({ dateTime, currentFormat, tzName, convertDateTimeTo, period, duration }: NthUtcDate): string => {
  const dateTimeData = dateTime as string;
  const periodData = period as unitOfTime.DurationConstructor;
  return moment.tz(dateTimeData, currentFormat, tzName).utc().add(duration, periodData).format(convertDateTimeTo);
};

//converts the given datetime to the given timezone
export const convertDateByTzId = (date: moment.MomentInput, tzName: string, dateTimeFormat: string): string =>
  moment(date).tz(tzName)?.format(dateTimeFormat);

//returns converted system datetime to given timeZone and then convert it to UTC time
export const convertDateByTzToUTC = (date: moment.MomentInput, tzName?: string, option?: string): string => {
  const dateValue = moment(date);
  if (tzName) {
    dateValue.tz(tzName, true).format();
  }

  if (option === 'start') {
    dateValue.startOf('day');
  } else if (option === 'end') {
    dateValue.endOf('day');
  }
  return dateValue.toISOString();
};

/**
 * Sets given from date and toDate and then converts in given format
 */
export const convertDateRange = ({ fromDate, toDate, dateFormatMoment, fromTime, toTime }: DateRange): DateRange => {
  let fromDateWithTime: string;
  let toDateWithTime: string;
  const fromDateTime = new Date(fromDate).setHours(0, 0, 0);
  const toDateTime = new Date(toDate).setHours(0, 0, 0);
  const fromDateTZ = moment(new Date(fromDateTime));
  const toDateTZ = moment(new Date(toDateTime));
  if (fromTime && toTime) {
    fromDateWithTime = moment(fromDateTZ)
      .set({ hour: +fromTime.split(':')[0], minute: +fromTime.split(':')[1], second: +fromTime.split(':')[2], milliseconds: 0 })
      .format(dateFormatMoment); // Main Line
    toDateWithTime = moment(toDateTZ)
      .set({ hour: +toTime.split(':')[0], minute: +toTime.split(':')[1], second: +toTime.split(':')[2], milliseconds: 0 })
      .format(dateFormatMoment); // Main Line
  } else {
    fromDateWithTime = moment(fromDateTZ).set({ hour: 0, minute: 0, second: 0, milliseconds: 0 }).format(dateFormatMoment); // Main Line
    toDateWithTime = moment(toDateTZ).set({ hour: 23, minute: 59, second: 59, milliseconds: 59 }).format(dateFormatMoment); // Main Line
  }
  return {
    fromDate: fromDateWithTime,
    toDate: toDateWithTime
  };
};

export const getNthDateFromGivenDate = ({ dateTime, currentFormat, duration, period, convertTo }: NthDateTime): string =>
  moment.utc(dateTime, currentFormat).add(duration, period).format(convertTo);

//returns current date time of the given timezone
export const getCurrentDateByTZWithDST = (tzName: string, dateTimeFormat: string): string =>
  moment().tz(tzName).endOf('day').format(dateTimeFormat);

// parse custome date string to date
export const parseDateStringToDate = (currentDateTimeFormat: string, dateTimeFormat: string): Date =>
  moment(currentDateTimeFormat, dateTimeFormat).toDate();

export const getPreviousDate = (days: number, convertTo: string): string => moment().subtract(days, 'days').format(convertTo);

//returns previous nth date from given date and converts to given format
export const getPreviousNthDate = (date: string, next: number, format: string): string =>
  moment(date).subtract(next, 'days').format(format);

//returns next nth date from given date and converts to given format
export const getNextNthDate = (date: string | Date, next: number, format: string): string => moment(date).add(next, 'days').format(format);

/* Determines whether the two date ranges overlap */
export const isDatesOverlapped = (fromDate: string, toDate: string): boolean => moment(fromDate).isSameOrBefore(toDate);

export const getDiffInDays = (startDate: string | moment.Moment, endDate: string | moment.Moment, format: string): number =>
  // +1 to include start date
  moment(endDate, format).diff(moment(startDate, format), 'days') + 1;

/* Returns day's position in a week (Monday(1) - Sunday(7)) */
export const getDayPositionInWeek = (date: string | Date, format: string): number => moment(date, format).isoWeekday();

/** to check a date is before another date*/
export const isBeforeDate = (date: string, source: string, sourceFormat?: string): boolean => {
  const sourceMoment = sourceFormat ? moment(source, sourceFormat) : moment(source);
  return moment(date).isBefore(sourceMoment);
};

/** To check a date is valid date or not */
export const isValidDate = (date: string, format: string): boolean => moment(date, format, true).isValid();

/** To check a date is future date or not */
export const isFutureDate = (date: string, dateFormat: string): boolean => moment().diff(moment(date, dateFormat)) < 0;

/* returns boolean value if the end date is greater than start date */
export const checkDatesWithTz = (todayWithTZ: string, toDateTime: string, dateFormat: string): boolean =>
  moment(todayWithTZ, dateFormat) < moment(toDateTime, dateFormat);

/**
 * @param date -  date
 * @param locale - selected language in which date to be returned
 * @param toFormat - format to which date to be converted
 * @param currentFormat - present format of the date(optional)
 * @returns return date in the given format of the selected language
 */
export const getLocaleDate = ({ dateString, localeName, convertTo, currentFormat }: DateConvert): string => {
  const dateStringData = dateString as string;
  const momentDate = currentFormat ? moment(dateStringData, currentFormat) : moment(dateStringData);
  return momentDate.locale(localeName).format(convertTo);
};

export const getPreferredDateFormat = (date: string, preferredFormat: string): string => {
  const dateFormat = exceptionalDateFormatValidation(date, preferredFormat);
  const dateformatting = moment(dateFormat, [preferredFormat], true);
  if (dateformatting.isValid()) {
    return dateformatting.format(UNIVERSAL_DATEFORMAT);
  }
  return moment(dateFormat).format(UNIVERSAL_DATEFORMAT);
};

/**
 *
 * @param dateTime - Input datetime
 * @param duration - Actual length to be calculated
 * @param unit - days or hour or month..etc
 * @param convertTo - Preferred Format
 * @returns New Date in required format.
 */
export const getNthDateOrTime = ({ dateTime, duration, convertDateTimeTo }: NthUtcDate, unit: unitOfTime.Diff): string => {
  const momentObj = dateTime ? moment(dateTime) : moment();
  return momentObj.add(duration, unit).format(convertDateTimeTo);
};

export const getFormatedDateValue = (dateValue: string, formatValue: string): string => moment(dateValue).format(formatValue);

/**
 * @param endDate - Selected Date
 * @param dateFormat - Date format of input value
 * @param convertTo - Date format of output value
 * @param unit - Date format of return value
 * @returns End date of specific month or Today date of current month
 */
export const getEndMonthDate = ({ dateTime, currentFormat, convertTo }: DateTimeFormatTz, unit: unitOfTime.StartOf): string =>
  moment(dateTime, currentFormat).isSame(moment(), 'month')
    ? moment().format(convertTo)
    : moment(dateTime, currentFormat).endOf(unit).format(convertTo);

export const getWeeklyFormat = (date: string, selectedEndDay: string): string => {
  const isoFormat = COLUMN_CHART_WEEKLYDATEFORMAT.ISO_DateFormat;
  const weekStartDateInMonth = moment(date).format(COLUMN_CHART_WEEKLYDATEFORMAT.onlyMonth);
  const weekEndDateInMonth = moment(selectedEndDay).format(COLUMN_CHART_WEEKLYDATEFORMAT.onlyMonth);
  const weekStartDateInYear = moment(date).format(COLUMN_CHART_WEEKLYDATEFORMAT.onlyYear);
  const WeekendDateInYear = moment(selectedEndDay).format(COLUMN_CHART_WEEKLYDATEFORMAT.onlyYear);
  if (weekStartDateInMonth === weekEndDateInMonth && weekStartDateInYear === WeekendDateInYear) {
    return `${moment(date, isoFormat).format(COLUMN_CHART_WEEKLYDATEFORMAT.monthAndDay)} - ${moment(selectedEndDay, isoFormat).format(
      COLUMN_CHART_WEEKLYDATEFORMAT.dayAndYear
    )}`;
  } else if (weekStartDateInYear !== WeekendDateInYear) {
    return `${moment(date, isoFormat).format(COLUMN_CHART_WEEKLYDATEFORMAT.fullDateFormat)} - ${moment(selectedEndDay, isoFormat).format(
      COLUMN_CHART_WEEKLYDATEFORMAT.fullDateFormat
    )}`;
  }
  return `${moment(date, isoFormat).format(COLUMN_CHART_WEEKLYDATEFORMAT.monthAndDay)} - ${moment(selectedEndDay, isoFormat).format(
    COLUMN_CHART_WEEKLYDATEFORMAT.fullDateFormat
  )}`;
};

export const getSingleDayFormat = (date: string, selectedEndDay: string): string => {
  let singleDayFormat: string;
  const dateDiffOnEndDate = moment(selectedEndDay).diff(moment(date), 'days');
  const dateIsSunday = moment(date).format(COLUMN_CHART_WEEKLYDATEFORMAT.dayInWords);
  const isoFormat = COLUMN_CHART_WEEKLYDATEFORMAT.ISO_DateFormat;
  if (dateIsSunday === COLUMN_CHART_WEEKLYDATEFORMAT.isSunday) {
    singleDayFormat = `${moment(date, isoFormat).format(COLUMN_CHART_WEEKLYDATEFORMAT.fullDateFormat)}`;
  } else if (dateDiffOnEndDate === 0) {
    singleDayFormat = `${moment(selectedEndDay, isoFormat).format(COLUMN_CHART_WEEKLYDATEFORMAT.fullDateFormat)}`;
  }
  return singleDayFormat;
};

export const getWeeklySunday = (formattedDate: string): string => {
  const isoFormat = COLUMN_CHART_WEEKLYDATEFORMAT.ISO_DateFormat;
  return moment(formattedDate).day(7).format(isoFormat);
};

export const drillDownDateRangeForWeekly = ({
  selectedDate,
  selectedEndDay,
  currentFormat,
  metaDatadateFormat
}: DrillDown): DrillDownDateRange => {
  const dateDiffOnEndDate = moment(selectedEndDay).diff(moment(selectedDate), 'days');
  const dateIsSunday = moment(selectedDate).format(COLUMN_CHART_WEEKLYDATEFORMAT.dayInWords);
  const startDate = convertDateOrTimeFormat(
    { dateTime: moment(selectedDate).format(currentFormat), currentFormat, convertTo: COLUMN_CHART_WEEKLYDATEFORMAT.DRILL_DOWN_FORMAT },
    true
  );
  const endDate =
    dateIsSunday === COLUMN_CHART_WEEKLYDATEFORMAT.isSunday || dateDiffOnEndDate === 0
      ? startDate
      : convertDateOrTimeFormat(
          { dateTime: selectedEndDay, currentFormat: metaDatadateFormat, convertTo: COLUMN_CHART_WEEKLYDATEFORMAT.DRILL_DOWN_FORMAT },
          true
        );
  return { startDate, endDate };
};

export const getPreferredDate = (date: string, dateFormat: string): Date | null => {
  const timeZone = moment(getPreferredDateFormat(date, dateFormat)).format();
  return date && date !== 'Invalid date' ? new Date(timeZone) : null;
};

export const formattedDate = (date: Date | string, format: string): string => moment(date).format(format);
