export const HELPER_LOGIC_CONSTANTS = {
  DEFAULT: 'DEFAULT',
  COST_CONVERSION: 'COST_CONVERSION'
};

export const PREF_TO_MT = {
  Imperial: 1.0160469088,
  Metric: 1,
  'SI Units': 1,
  'US Standard': 0.90718474,
  'U.S. Customary': 0.90718474
};

export const CUBIC_METER_TO_PREF = {
  'SI Units': 1,
  'U.S. Customary': 1.30795
};
export const PREF_TO_CUBIC_METER = {
  'SI Units': 1,
  'U.S. Customary': 0.7645552200007646
};

export const MT_TO_PREF = {
  WeightMetric: 'US Tons',
  FuelMetric: 'gal',
  literToGallon: 0.264172,
  Imperial: 0.98420652761106,
  Metric: 1,
  'SI Units': 1,
  'US Standard': 1.1023113109244,
  'U.S. Customary': 1.1023113109244,
  TonnesPerHourToTonsPerHour: 1.1023113109244,
  KmToMiles: 0.621371,
  MeterPerSecToKilometerPerHr: 3.6,
  MeterPerSecToMilesPerHr: 2.237,
  MilesToKm: 1.60934,
  TonnesPerLitreToTonsPerGal: 4.1727,
  kgToPound: 2.20462,
  feetToMetre: 3.280839895,
  cubicMetreToCubicYard: 1.30795,
  squareMetreToSquareYard: 1.19599,
  tonnesToTons: 1.10231,
  metrePerLitreToFeetPerGallon: 12.4193,
  truck: 1.1023,
  material: 0.062428,
  MetersSqToMiSq: 0.0000003861,
  MetersSqToKmSq: 1000000
};

// Includes funtion which will be called on Conversion;
export const UOM_CONVERSION_FUNC_TO_CALL = {
  LITRE_TO_GALLON: 'convertLiterToGallon',
  LITRE_TO_GALLON_COST: 'convertLiterToGallonCost',
  GALLON_TO_LITRE: 'convertGallonToLiter',
  METER_TO_YARD: 'convertMeterToYard',
  METER_TO_YARD_COST: 'convertMeterToYardCost',
  CONVERT_PREFERENCE_TO_METRIC: 'getValueOnPreferenceType',
  CONVERT_METRIC_TO_PREFERENCE: 'getValueOnPreferenceType',
  USCUSTOMARY_TO_SIUNITS: 'getValueOnPreferenceType',
  PREF_TO_CUBIC_METER: 'getValueOnPreferenceType',
  CUBIC_METER_TO_PREF: 'getValueOnPreferenceType',
  // COST_CONVERSION is used Where there is conversion type SIUnits_TO_USCustomary and Cost Conversion Is true;
  COST_CONVERSION: 'getCostConversionOnPrefType',
  FEET_TO_METRE: 'convertFeetToMetre',
  METRE_TO_FEET: 'convertMetreToFeet',
  METRE_PER_SEC_TO_KILOMETRE_PER_HR: 'convertMeterPerSecToKilometerPerHr',
  METRE_PER_SEC_TO_MILES_PER_HR: 'convertMeterPerSecToMilesPerHr',
  CELSIUS_TO_FAHRENHEIT: 'convertCelsiustoFahrenheit',
  KILOMETRE_TO_MILES: 'convertKilometerToMiles',
  TONNES_PER_HOUR_TO_TONS_PER_HOUR: 'convertTonnesPerHourToTonsPerHour',
  TONNES_PER_lITRE_TO_TONS_PER_GAL: 'convertTonnesPerLitreToTonsPerGal',
  KILOGRAM_TO_POUNDS: 'convertKilogramsToPounds',
  SQUAREMETERS_TO_SQUAREYARDS: 'convertSquareMetresToSqaureYards',
  CUBICMETERS_TO_CUBICYARDS: 'convertCubicMetresToCubicYards',
  // DEFAULT is used at place of SIUnits_TO_USCustomary;
  DEFAULT: 'getValueOnPreferenceType',
  TONNES_TO_TONS: 'convertTonnesToTons',
  TONS_TO_TONNES: 'convertToNumber',
  TONNES_TO_TONS_WEIGHT: 'convertTonnesToTonsWeight',
  TONS_TO_TONNES_WEIGHT: 'convertTonsToTonnesWeight',
  TONS_TO_TONNES_WEIGHT_US: 'convertTonsToTonnesWeightInUS',
  TONNES_TO_POUND: 'convertTonnesToPound',
  TONNES_TO_KG: 'convertTonnesToKG',
  KM_TO_YARDS: 'convertKmToYards',
  KM_TO_METER: 'convertKmToMeter',
  METER_PER_LITRE_TO_FEET_PER_GAL: 'convertMeterPerLitreToFeetPerGal',
  VALUE_TO_ENGLISH: 'convertValueToEnglish',
  VALUE_TO_METRIC: 'convertValueToMetric',
  ROUND_OFF_VALUE: 'convertRoundOffValue',
  Msq_TO_KMsq: 'convertMeterSqToKmSq',
  Msq_TO_MIsq: 'convertMeterSqToMilesSq',
  KM_TO_FEET: 'convertKmToFeet'
};

// Includes funtion which will be called on Conversion;
export const UOM_CONVERSION_FUNC_CONFIG = {
  litreToGallon: 'LITRE_TO_GALLON',
  litreToGallonCost: 'LITRE_TO_GALLON_COST',
  gallonToLitre: 'GALLON_TO_LITRE',
  meterToYard: 'METER_TO_YARD',
  meterToYardCost: 'METER_TO_YARD_COST',
  preferenceoMetric: 'CONVERT_PREFERENCE_TO_METRIC',
  metricToPreference: 'CONVERT_METRIC_TO_PREFERENCE',
  usCustomaryToSIUnits: 'USCUSTOMARY_TO_SIUNITS',
  prefToCubicMeter: 'PREF_TO_CUBIC_METER',
  cubicMeterToPref: 'CUBIC_METER_TO_PREF',
  costConversion: 'COST_CONVERSION',
  feetToMeter: 'FEET_TO_METRE',
  meterToFeet: 'METRE_TO_FEET',
  meterPerSecToKilometerPerHr: 'METRE_PER_SEC_TO_KILOMETRE_PER_HR',
  meterPerSecToMilesPerHr: 'METRE_PER_SEC_TO_MILES_PER_HR',
  celsiusToFahrenheit: 'CELSIUS_TO_FAHRENHEIT',
  kilometerToMiles: 'KILOMETRE_TO_MILES',
  tonnesPerHrToTonsPerHr: 'TONNES_PER_HOUR_TO_TONS_PER_HOUR',
  tonnesPerLitreToTonsPerGal: 'TONNES_PER_lITRE_TO_TONS_PER_GAL',
  kilogramToPounds: 'KILOGRAM_TO_POUNDS',
  squareMetersToSquareYards: 'SQUAREMETERS_TO_SQUAREYARDS',
  cubicMetersToSquareYards: 'CUBICMETERS_TO_CUBICYARDS',
  getValueOnPreferenceType: 'DEFAULT',
  TONNES_TO_TONS: 'TONNES_TO_TONS',
  TONS_TO_TONNES: 'TONS_TO_TONNES',
  TONNES_TO_KG: 'TONNES_TO_KG',
  TONNES_TO_POUND: 'TONNES_TO_POUND',
  TONS_TO_TONNES_WEIGHT: 'TONS_TO_TONNES_WEIGHT',
  TONS_TO_TONNES_WEIGHT_US: 'TONS_TO_TONNES_WEIGHT_US',
  kmToYards: 'KM_TO_YARDS',
  kmToMeter: 'KM_TO_METER',
  metrePerLitreTofeetPerGallon: 'METER_PER_LITRE_TO_FEET_PER_GAL',
  valueToEnglish: 'VALUE_TO_ENGLISH',
  valueToMetric: 'VALUE_TO_METRIC',
  roundOffValue: 'ROUND_OFF_VALUE',
  kmToFeet: 'KM_TO_FEET'
};

export const UOM_CONVERSION_PIPE_CONFIG = {
  payload: UOM_CONVERSION_FUNC_CONFIG.tonnesPerHrToTonsPerHr,
  distance: UOM_CONVERSION_FUNC_CONFIG.kilometerToMiles,
  speed: UOM_CONVERSION_FUNC_CONFIG.kilometerToMiles,
  fuel: UOM_CONVERSION_FUNC_CONFIG.litreToGallon,
  volume: UOM_CONVERSION_FUNC_CONFIG.cubicMetersToSquareYards,
  area: UOM_CONVERSION_FUNC_CONFIG.squareMetersToSquareYards,
  weight: UOM_CONVERSION_FUNC_CONFIG.tonnesPerHrToTonsPerHr,
  payloadPerFuel: UOM_CONVERSION_FUNC_CONFIG.tonnesPerLitreToTonsPerGal
};

export const LOAD_CONVERSIONS = {
  LITRE_TO_GALLON: 0.264172,
  TONNES_TO_TONS: 1.1023, //1.1023113109244,
  CUBICMETERS_TO_CUBICYARDS: 1.30795, //1.30795062
  TONNESPERLITRE_TO_TONSPERGALLON: 4.17266, //4.172660236512575,
  KM_TO_MILES: 0.621371,
  km_TO_YARDS: 1094,
  km_TO_METER: 1000,
  TONS_TO_POUND: 2000,
  TONNES_TO_KG: 1000,
  KM_TO_FEET: 3281
};

export const UNITS_OF_MEASUREMENT = {
  siUnit: 'SI Units',
  usCustomary: 'U.S. Customary'
};

// Conversion constant which is picked while converting value;
export const CONVERSION_TYPE_VALUE = {
  CONVERT_PREFERENCE_TO_METRIC: PREF_TO_MT,
  CONVERT_METRIC_TO_PREFERENCE: MT_TO_PREF,
  USCUSTOMARY_TO_SIUNITS: PREF_TO_MT,
  PREF_TO_CUBIC_METER,
  CUBIC_METER_TO_PREF,
  // DEFAULT is used at place of SIUnits_TO_USCustomary;
  DEFAULT: MT_TO_PREF,
  // COST_CONVERSION is used Where there is conversion type SIUnits_TO_USCustomary and Cost Conversion Is true;
  COST_CONVERSION: MT_TO_PREF
};

// Conversion which uses 'commonUomConversions' from helper.ts file;
export const CONVERSION_COMON = [
  'CONVERT_PREFERENCE_TO_METRIC',
  'CONVERT_METRIC_TO_PREFERENCE',
  'USCUSTOMARY_TO_SIUNITS',
  'PREF_TO_CUBIC_METER',
  'CUBIC_METER_TO_PREF',
  HELPER_LOGIC_CONSTANTS.COST_CONVERSION,
  HELPER_LOGIC_CONSTANTS.DEFAULT
];

// Unit Label;
export const UNITS_SYMBOL = {
  [UNITS_OF_MEASUREMENT.usCustomary]: {
    weight: 'ton',
    fuel: 'gal',
    volume: 'yd³',
    payloadPerHour: 'ton/h',
    payloadPerFuel: 'ton/gal',
    distance: 'mi',
    fuelPerRate: 'gal/h',
    mass: 'lb',
    height: 'ft',
    english: 'english',
    speed: 'mph',
    elevation: 'us_feet',
    temperature: 'Fahrenheit',
    volumeInWords: 'cubicYard',
    density: 'lbPerCubicYard'
  },
  [UNITS_OF_MEASUREMENT.siUnit]: {
    weight: 't',
    fuel: 'L',
    volume: 'm³',
    payloadPerHour: 't/h',
    payloadPerFuel: 't/L',
    distance: 'km',
    fuelPerRate: 'L/h',
    mass: 'kg',
    height: 'm',
    english: 'metric',
    speed: 'mph',
    elevation: 'meter',
    temperature: 'Celsius',
    volumeInWords: 'cubicMeter',
    density: 'kgPerCubicMeter'
  }
};

export const UOM_CONVERSION_CONSTANT = {
  tonnesToTons: 'tonnesToTons'
};

export const UOM_CONVERSION_CONSTANT_BASED_ON_UNIT = {
  [UNITS_OF_MEASUREMENT.usCustomary]: {
    TONNES_TONS: UOM_CONVERSION_FUNC_CONFIG.TONNES_TO_TONS,
    LITRE_TO_GALLON_COST: UOM_CONVERSION_FUNC_CONFIG.litreToGallonCost,
    LITRE_TO_GALLON: UOM_CONVERSION_FUNC_CONFIG.litreToGallon,
    METER_TO_YARD: UOM_CONVERSION_FUNC_CONFIG.meterToYard,
    SQUAREMETERS_TO_SQUAREYARDS: UOM_CONVERSION_FUNC_CONFIG.squareMetersToSquareYards,
    TONNES_POUND_KG: UOM_CONVERSION_FUNC_CONFIG.TONNES_TO_POUND,
    TONNES_WEIGHT_TONS: UOM_CONVERSION_FUNC_CONFIG.TONNES_TO_TONS,
    METER_FEET: UOM_CONVERSION_FUNC_CONFIG.meterToFeet,
    DISTANCE_METER_FEET: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    US_SI_COST: UOM_CONVERSION_FUNC_CONFIG.usCustomaryToSIUnits,
    DEFAULT_US_SI: UOM_CONVERSION_FUNC_CONFIG.getValueOnPreferenceType,
    METER_YARD_COST: UOM_CONVERSION_FUNC_CONFIG.meterToYardCost,
    TONS_TONNES_WEIGHT_US: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES_WEIGHT_US,
    COST_TONNES_TONS: UOM_CONVERSION_FUNC_CONFIG.costConversion,
    VALUE_ENGLISH_ROUND: UOM_CONVERSION_FUNC_CONFIG.valueToEnglish,
    VALUE_METRIC_ROUND: UOM_CONVERSION_FUNC_CONFIG.valueToMetric,
    VALUE_ENGLISH_METRIC: UOM_CONVERSION_FUNC_CONFIG.valueToEnglish,
    targetspeed: UOM_CONVERSION_FUNC_CONFIG.meterPerSecToMilesPerHr,
    temperature: UOM_CONVERSION_FUNC_CONFIG.celsiusToFahrenheit,
    distanceKM_Miles: UOM_CONVERSION_FUNC_CONFIG.kilometerToMiles,
    distance_Metre_Feet: UOM_CONVERSION_FUNC_CONFIG.feetToMeter
  },
  [UNITS_OF_MEASUREMENT.siUnit]: {
    TONNES_TONS: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    LITRE_TO_GALLON_COST: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    LITRE_TO_GALLON: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    METER_TO_YARD: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    SQUAREMETERS_TO_SQUAREYARDS: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    TONNES_POUND_KG: UOM_CONVERSION_FUNC_CONFIG.TONNES_TO_KG,
    TONNES_WEIGHT_TONS: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES_WEIGHT,
    METER_FEET: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    DISTANCE_METER_FEET: UOM_CONVERSION_FUNC_CONFIG.feetToMeter,
    US_SI_COST: UOM_CONVERSION_FUNC_CONFIG.costConversion,
    DEFAULT_US_SI: UOM_CONVERSION_FUNC_CONFIG.usCustomaryToSIUnits,
    METER_YARD_COST: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    TONS_TONNES_WEIGHT_US: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    COST_TONNES_TONS: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    VALUE_ENGLISH_ROUND: UOM_CONVERSION_FUNC_CONFIG.roundOffValue,
    VALUE_METRIC_ROUND: UOM_CONVERSION_FUNC_CONFIG.roundOffValue,
    VALUE_ENGLISH_METRIC: UOM_CONVERSION_FUNC_CONFIG.valueToMetric,
    targetspeed: UOM_CONVERSION_FUNC_CONFIG.meterPerSecToKilometerPerHr,
    temperature: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    distanceKM_Miles: UOM_CONVERSION_FUNC_CONFIG.TONS_TO_TONNES,
    distance_Metre_Feet: UOM_CONVERSION_FUNC_CONFIG.meterToFeet
  }
};

export const UOM_CONVERSION_METRIC_TYPE = {
  volume: UOM_CONVERSION_FUNC_CONFIG.meterToYard,
  costvolume: UOM_CONVERSION_FUNC_CONFIG.meterToYardCost,
  costfuel: UOM_CONVERSION_FUNC_CONFIG.litreToGallonCost,
  fuel: UOM_CONVERSION_FUNC_CONFIG.litreToGallon,
  fuelPerRate: UOM_CONVERSION_FUNC_CONFIG.litreToGallon,
  payloadPerHour: UOM_CONVERSION_FUNC_CONFIG.tonnesPerHrToTonsPerHr,
  payloadPerFuel: UOM_CONVERSION_FUNC_CONFIG.tonnesPerLitreToTonsPerGal,
  distance: UOM_CONVERSION_FUNC_CONFIG.kilometerToMiles,
  mass: UOM_CONVERSION_FUNC_CONFIG.kilogramToPounds,
  // DEFAULT is used at place of SIUnits_TO_USCustomary;
  weight: UOM_CONVERSION_FUNC_CONFIG.getValueOnPreferenceType,
  // COST_CONVERSION is used Where there is conversion type SIUnits_TO_USCustomary and Cost Conversion Is true;
  costweight: UOM_CONVERSION_FUNC_CONFIG.costConversion
};

export const APPLY_ROUND_OFF_CONVERSIONS = {
  [UOM_CONVERSION_FUNC_CONFIG.feetToMeter]: {
    applyRoundOff: true,
    decimalLength: 2
  }
};

export const DEFAULT_ROUNDOFF_VAL = { applyRoundOff: false, decimalLength: 2 };

export const TABLE_FIELDS_ROUNDOFF = [2, 'Material', 'Truck'];

export const UOM_CONVERSION_VALUES_BASED_ON_UNITS = {
  [UNITS_OF_MEASUREMENT.usCustomary]: {
    payload: 1.1023113109244,
    volume: 1.30795,
    APPLY_ROUND_OFF: true
  },
  [UNITS_OF_MEASUREMENT.siUnit]: {
    payload: 1,
    volume: 1,
    APPLY_ROUND_OFF: false
  }
};

export const UOM_CONVERSION_METRIC_DEFAULT = ['weight', 'costweight'];

export const SI_UNITS_METRIC_CONSTANT = ['volume', 'weight', 'fuel', 'cost', 'costvolume', 'costweight', 'mass'];

export const COST_CONSTANT = 'cost';

export const UOM_CONVERSION_TRANSLATION_BASED_ON_UNITS = {
  [UNITS_OF_MEASUREMENT.usCustomary]: {
    WEIGHT_TONNES_TON: 'weights.ton',
    DESTINY: 'assetServices.density_us_units',
    TARGETWEIGHT: 'assetServices.targetWeight_us_units',
    IS_TONNES_TONS: 'insightsSummary.units.ton',
    IS_CUBIC_YARD_METER: 'insightsSummary.units.cubicYard',
    USI_MINSPEED: 'uscus_minspeed',
    USI_MAXSPEED: 'uscus_maxspeed',
    SHARED_LITER_GALLON: 'sharedUtil.gallon',
    LITER_GALLON_HOUR: 'sharedUtil.gallonperhour',
    SHARED_TONNES_TONS: 'sharedUtil.tons',
    YD_M: 'yd<sup>3</sup>',
    SD_LITER_GALLON: 'summaryDashboard.gallon',
    SD_TONNES_TONS: 'summaryDashboard.tons',
    TOOLTIP_CUBIC_YARD_METER: 'summaryDashboard.assetList.cubic_yard',
    SD_CUBIC_YARD_METER: 'summaryDashboard.yd',
    SD_SQUARE_YARD_METER: 'summaryDashboard.yd_square',
    YAXIS_TONNES_TONS: 'summaryDashboard.chart.payload_ton',
    FT_M: 'ft',
    FLEET_TONNES_TONS: 'summaryDashboard.fleetView.values_in_tons',
    FLEET_CUBIC_YARDS_METERS: 'summaryDashboard.fleetView.values_in_cubic_yards',
    COLUMN_CUBIC_YARD_METER: 'summaryDashboard.assetList.cubic_yard',
    GALLON_LITER_HOUR: 'summaryDashboard.gallonperhour',
    GALLON_LITER: 'summaryDashboard.gallon',
    FUEL_BURN_COLUMN_GALLONS_LITER: 'summaryDashboard.chart.label_fuelburn_gal',
    TONNES_TONS_TEXT: 'tons',
    LANDMILES_METERS: 'landmiles',
    COLUMN_YAXIS_CUBIC_YARD_METER: 'summaryDashboard.chart.label_cubic_yard',
    COLUMN_TOOLTIP_CUBIC_YARD_METER: 'summaryDashboard.assetList.cubic_meter',
    MEASURED_VOLUME_LABEL: 'summaryDashboard.chart.label_measured_volume_yard',
    DENSITY_TITLE: 'summaryDashboard.chart.density_title_volume_yard'
  },
  [UNITS_OF_MEASUREMENT.siUnit]: {
    WEIGHT_TONNES_TON: 'weights.tonnes',
    DESTINY: 'assetServices.density_si_units',
    TARGETWEIGHT: 'assetServices.targetWeight_si_units',
    IS_TONNES_TONS: 'insightsSummary.units.tonnes',
    IS_CUBIC_YARD_METER: 'insightsSummary.units.cubicMeter',
    USI_MINSPEED: 'si_minspeed',
    USI_MAXSPEED: 'si_maxspeed',
    SHARED_LITER_GALLON: 'sharedUtil.litre',
    LITER_GALLON_HOUR: 'sharedUtil.litreperhour',
    SHARED_TONNES_TONS: 'sharedUtil.tonnes',
    YD_M: 'm<sup>3</sup>',
    SD_LITER_GALLON: 'summaryDashboard.litre',
    SD_TONNES_TONS: 'summaryDashboard.tonnes',
    TOOLTIP_CUBIC_YARD_METER: 'summaryDashboard.assetList.cubic_meter',
    SD_CUBIC_YARD_METER: 'summaryDashboard.m',
    SD_SQUARE_YARD_METER: 'summaryDashboard.m_square',
    YAXIS_TONNES_TONS: 'summaryDashboard.chart.payload_tonnes',
    FT_M: 'm',
    FLEET_TONNES_TONS: 'summaryDashboard.fleetView.values_in_tonnes',
    FLEET_CUBIC_YARDS_METERS: 'summaryDashboard.fleetView.values_in_cubic_meters',
    COLUMN_CUBIC_YARD_METER: 'summaryDashboard.assetList.cubic_meter',
    GALLON_LITER_HOUR: 'summaryDashboard.litreperhour',
    GALLON_LITER: 'summaryDashboard.litre',
    FUEL_BURN_COLUMN_GALLONS_LITER: 'summaryDashboard.chart.label_fuelburn_litre',
    TONNES_TONS_TEXT: 'tonnes',
    LANDMILES_METERS: 'metres',
    COLUMN_YAXIS_CUBIC_YARD_METER: 'summaryDashboard.chart.label_cubic_meter',
    COLUMN_TOOLTIP_CUBIC_YARD_METER: 'summaryDashboard.assetList.cubic_yard',
    MEASURED_VOLUME_LABEL: 'summaryDashboard.chart.label_measured_volume_meter',
    DENSITY_TITLE: 'summaryDashboard.chart.density_title_volume_meter'
  }
};

export const UOM_CONVERSION_PREFERENCE_BASED_ON_UNITS = {
  [UNITS_OF_MEASUREMENT.usCustomary]: {
    COST_TONNES_TONS: UOM_CONVERSION_CONSTANT.tonnesToTons
  },
  [UNITS_OF_MEASUREMENT.siUnit]: {
    COST_TONNES_TONS: UNITS_OF_MEASUREMENT.siUnit
  }
};
