import { Pipe, PipeTransform } from '@angular/core';
import { Preference } from '@Terra/shared/widgets/interface';
import { getConvertUom } from './convert-and-format.helper';

@Pipe({
  name: 'convertAndFormat',
  standalone: true
})
export class ConvertAndFormatPipe implements PipeTransform {
  transform(value: string, params: { preference: Preference; metric: string; isTwoDigitPrecision?: boolean }): string {
    return getConvertUom(value, params);
  }
}
