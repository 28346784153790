import { ConvertUnitOfMeasurement } from '@Terra/shared/widgets/interface';
import { Pipe, PipeTransform } from '@angular/core';
import { uomHelperConfig } from '..';
import { convertUnitOfMeasurement } from './uomConversionUtility.helper';
import { COST_CONSTANT } from './uomConversionUtility.helper.config';
import { UomConversionPipeFormat } from './uomConversionUtility.helper.model';

@Pipe({
  name: 'uomConversionPipe',
  standalone: true
})
export class UomConversionPipe implements PipeTransform {
  transform(value: any, format: UomConversionPipeFormat) {
    if (this.stringNullCheck(value)) {
      return '--';
    } else if (value && !format?.valueToConvert) {
      return this.convertUOM(value, format?.uom, format?.metric);
    } else if (format?.valueToConvert) {
      return this.convertUnitOfMeasurement(format);
    }
    const formatedValue = value ?? format?.valueToConvert;
    return formatedValue ?? '--';
  }

  convertUnitOfMeasurement(format: UomConversionPipeFormat): number | string {
    const { valueToConvert, conversionType, preferenceType } = format;
    const params: ConvertUnitOfMeasurement = {
      valueToConvert: valueToConvert,
      conversionType: conversionType,
      preferenceType: preferenceType,
      applyRoundOff: format?.applyRoundOff ?? false,
      decimalLength: format?.decimalLength ?? 0
    };
    return convertUnitOfMeasurement(params);
  }

  convertUOM(value: any, uom: string, metric: string): any {
    if (this.validateUomSIUnit(metric, uom)) {
      return Number(value);
    } else if (
      Object.keys(uomHelperConfig.UOM_CONVERSION_METRIC_TYPE).includes(metric) &&
      uomHelperConfig.UNITS_OF_MEASUREMENT.usCustomary
    ) {
      const params: ConvertUnitOfMeasurement = {
        valueToConvert: value,
        conversionType: uomHelperConfig.UOM_CONVERSION_METRIC_TYPE[metric],
        preferenceType: uom,
        applyRoundOff: false,
        decimalLength: uomHelperConfig.UOM_CONVERSION_METRIC_DEFAULT.includes(metric) ? 20 : 0
      };
      return convertUnitOfMeasurement(params);
    }
    return value;
  }

  stringNullCheck(value: any): boolean {
    return value === 'null' || value?.toString().trim() === '';
  }

  validateUomSIUnit(metric: string, uom: string): boolean {
    return (
      (uom === uomHelperConfig.UNITS_OF_MEASUREMENT.siUnit && uomHelperConfig.SI_UNITS_METRIC_CONSTANT.includes(metric)) ||
      (uom === uomHelperConfig.UNITS_OF_MEASUREMENT.usCustomary && metric === COST_CONSTANT)
    );
  }
}
